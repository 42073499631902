import {config} from "../Constants";
import useMyAssignments from "../hooks/useMyAssignments";
import useAssignments from "../hooks/useAssignments";
import {useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useFeed from "../hooks/useFeed";


const callApiAssignments = () => {
  const navigate = useNavigate();
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;
  const { handleLoadMyAssignments } = useMyAssignments();
  const { handleLoadAssignments, handleLoadAssignmentsAndFilter} = useAssignments();
  const { handleLoadAssignmentsAvailableToday } = useFeed();

  const fetchMyAssignments = () => {
    // console.log('[callApiAssignments] fetchMyAssignments')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl + "/myAssignments", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log(data, ' [callApiAssignments] data load success')
        handleLoadMyAssignments(data);
      });
  }

  const fetchAssignments = (filterAlso) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/assignments", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log(data, ' [callApiAssignments] data load success')
        if(filterAlso) {
          handleLoadAssignmentsAndFilter(data);
        } else {
          handleLoadAssignments(data);
        }
      });
  }

  const fetchAssignmentsForCompany = (companyId, handleData) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/assignments/byCompanyId/filter/"+companyId, {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        handleData(data);
      });
  }

  const fetchAssignmentsAvailableToday = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/assignments/availableToday/filter", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('[fetchPersons] fetchPersons: data ' + JSON.stringify(data));
        handleLoadAssignmentsAvailableToday(data);
      });
  }


  const deleteMyAssignment = (assignmentIdToDelete) => {
    // console.log(assignmentIdToDelete, ' [callApiAssignments] deleteAssignment')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
        return fetch(apiUrl + "/assignments/" + assignmentIdToDelete, requestOptions)
      })
      .then(response => {
        if (response.status === 200) {
          // console.log('[MyAssignments] deleteAssignment success 200')
          fetchMyAssignments();
        } else {
          // console.log('[callApiAssignments] deleteAssignment ERROR')
        }
      });
  };

  const fetchAssignment = async (assignmentId, callbackWithAssignmentData) => {
    try {
      getAccessToken()
        .then((accessToken) => {
          let headers = {"Content-Type": "application/json"};
          headers["Authorization"] = `Bearer ${accessToken}`;
          return fetch(apiUrl+"/assignments/"+assignmentId, {headers,})
        })
        .then(response => {
          if(response.status===204) {
            // console.log('[callApiAssignments] fetchAssignment. No assignment found. ')
            callbackWithAssignmentData(null);
          }
          else if(response.status===200) {
            return response.json()
          }
        })
        .then(data => {
          callbackWithAssignmentData(data);
        });
    } catch (error) {
      console.error(error, ' [callApiAssignments] Failed to fetch assignment from api');
    }
  };

  const saveNewAssignment = (formData) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/assignments", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('successfull save. data: ' + JSON.stringify(data));
        //console.log('successfull save. id: ' + data.id);
        navigate('/assignment/edit/'+data.id, { replace: true });
      });
  }

  const saveChangesToAssignment = (assignmentId, formData, setFormDataUsingDefaults) => {
    //console.log(formData, '--> [callApiAssignments] - Submitted data');
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/assignments/"+assignmentId, requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        console.log(data,' --> [callApiAssignments] successfull save.');
        fetchAssignment(assignmentId,setFormDataUsingDefaults);
      });
  };

  const assignmentApply = (assignmentId, assignmentOwnerPersonId, formData) => {
    //console.log(formData, '--> [callApiAssignments] - assignmentApply');
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/assignments/"+assignmentId+"/apply", requestOptions)
      })
      .then(response => {
        if(response.ok) {
          // console.log('[Banner] createNewMessages. successfull save');
          return response.json()
        } else {
          //console.log('Could not intiate a chat thread with user. ' + response);
          throw new Error("Network error");
        }
      })
      .then(data => {
        navigate('/profile/chat/'+data.id);
      });
  };

  return {
    fetchMyAssignments,
    fetchAssignments,
    fetchAssignmentsForCompany,
    fetchAssignmentsAvailableToday,
    deleteMyAssignment,
    fetchAssignment,
    saveNewAssignment,
    saveChangesToAssignment,
    assignmentApply
  };
};

export default callApiAssignments;