import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col, Form,
  Row
} from 'react-bootstrap';
import Flex from "falcon-react-lib/components/common/Flex";
import AvailabilityCalendar from "components/app/availability/AvailabilityCalendar";
import {MyProfileContext} from "context/Context";
import callApiMyProfile from "api/callApiMyProfile";
import ProfileBanner from "components/app/profile/ProfileBanner";
import useUrlHelpers from "hooks/useUrlHelpers";
import Confirmation from "../../../confirmation/Confirmation";

const Availability = () => {
  const {getAvatarUrl} = useUrlHelpers();
  const {saveMyAvailability, saveMyAvailabilityEvent, deleteMyAvailabilityEvent} = callApiMyProfile();
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [shareOnFeed, setShareOnFeed] = useState(false);
  const [percentAvailability, setPercentAvailability] = useState(100);
  const [availabilityStatus, setAvailabilityStatus] = useState("CALENDAR");
  const {myProfileState: { myProfilePerson, myAvailabilityEvents }} = useContext(MyProfileContext);

  useEffect(() => {
    if(myProfilePerson.percentAvailability!==undefined&&myProfilePerson.percentAvailability!=='') {
      setPercentAvailability(myProfilePerson.percentAvailability);
    }
    if(myProfilePerson.availabilityStatus!==undefined&&myProfilePerson.availabilityStatus!=='') {
      setAvailabilityStatus(myProfilePerson.availabilityStatus);
    }
  }, [myProfilePerson]);

  // Hide the submit form warning modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const confirmInConfirmationModal = () => {
    //console.log(' --> confirmInConfirmationModal called')
    setDisplayConfirmationModal(false);
    saveMyAvailability(myProfilePerson.id, availabilityStatus, percentAvailability,true);
  };

  const saveChanges = () => {
    //console.log(shareOnFeed,' --> shareOnFeed')
    if(availabilityStatus !== "NONE" && shareOnFeed) {
      setDisplayConfirmationModal(true);
    } else {
      setShareOnFeed(false);
      saveMyAvailability(myProfilePerson.id, availabilityStatus, percentAvailability,false);
    }
  }

  //console.log(percentAvailability,' --> [Availability]')
  //console.log(availabilityStatus,' --> [Availability]')
  let avatarUrl = getAvatarUrl(myProfilePerson.thumbnail?.image, myProfilePerson.firstName,myProfilePerson.lastName)
  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={null}
          avatar={avatarUrl}
          className="mb-8"
        />
      </ProfileBanner>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Header as="h4">
              Endre min tilgjengelighet
            </Card.Header>
            <Card.Body className="bg-light">
              <Row className="">
                <Col>
                  <Form.Group>
                    <Form.Check
                      type="radio"
                      id="availabilityNone"
                      label="Ikke tilgjengelig akkurat nå"
                      name="availabilityToggleRadio"
                      checked={availabilityStatus==="NONE"}
                      onChange={() => setAvailabilityStatus("NONE")}
                    />
                    <Form.Check
                      type="radio"
                      id="availabilityCalendar"
                      label="Kalenderstyrt"
                      name="availabilityToggleRadio"
                      checked={availabilityStatus==="CALENDAR"}
                      onChange={() => setAvailabilityStatus("CALENDAR")}
                    />
                    <Form.Check
                      type="radio"
                      id="availabilityAlways"
                      label="Alltid tilgjengelig"
                      name="availabilityToggleRadio"
                      checked={availabilityStatus==="ALWAYS"}
                      onChange={() => setAvailabilityStatus("ALWAYS")}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="percentAvailability">
                    <Form.Label>Kapasitet</Form.Label>
                    <Form.Select
                      size="sm"
                      value={percentAvailability}
                      disabled={availabilityStatus==="NONE"}
                      onChange={(e) => setPercentAvailability(e.target.value)}
                    >
                      <option key="10" value="10">10%</option>
                      <option key="20" value="20">20%</option>
                      <option key="30" value="30">30%</option>
                      <option key="40" value="40">40%</option>
                      <option key="50" value="50">50%</option>
                      <option key="60" value="60">60%</option>
                      <option key="70" value="70">70%</option>
                      <option key="80" value="80">80%</option>
                      <option key="90" value="90">90%</option>
                      <option key="100" value="100">100%</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="py-2">
              <Flex wrap="wrap" className="gap-2 mb-3 flex-" direction="end-center">
                {myProfilePerson.registeredAsTemp && availabilityStatus !== "NONE" && (
                  <Form.Check
                    type="checkbox"
                    key="99"
                    id="postOnFeed"
                    label="Del på folkin startsiden"
                    className="form-label-nogutter"
                    onChange={() => setShareOnFeed(!shareOnFeed)}
                    name="postOnFeed"
                  />
                )}
                <Button
                  size="md"
                  variant="primary"
                  className="fw-medium float-end"
                  onClick={saveChanges}
                >
                  <span>Lagre</span>
                </Button>

              </Flex>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      {availabilityStatus === "CALENDAR" && (
        <Row>
          <Col>
            <AvailabilityCalendar myAvailabilityEvents={myAvailabilityEvents} myProfilePerson={myProfilePerson} handleAddMyAvailabilityEvent={saveMyAvailabilityEvent} handleRemoveMyAvailabilityEvent={deleteMyAvailabilityEvent} />
          </Col>
        </Row>
      )}
      <Confirmation
        showModal={displayConfirmationModal}
        hideModal={hideConfirmationModal}
        confirmModal={confirmInConfirmationModal}
        title={'Dele din ny tilgjengelighet'}
        message={'Din nye tilgjengelighetsstatus vil nå bli delt på folkin startsiden. Andre vil da kunne se dette.'}
      />
    </>
  );
};

export default Availability;
