import React from 'react';
import {Card} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import Flex from "../../../../falcon-react-lib/components/common/Flex";
import {Link} from "react-router-dom";
import callApiCompanies from "../../../../api/callApiCompanies";
import Avatar from "../../../../falcon-react-lib/components/common/Avatar";
import useUrlHelpers from "hooks/useUrlHelpers";
import TooltipBadge from "../../../../falcon-react-lib/components/common/TooltipBadge";
import CompanyPersonsSearchBox from "./CompanyPersonsSearchBox";
import classNames from "classnames";

const EditCompanyPersonsList = ({formData, setFormData}) => {
  // console.log('[EditCompanyPersonsList] companyId: ' + JSON.stringify(companyId));
  const {searchPersonsByEmail} = callApiCompanies();
  const {getAvatarUrl} = useUrlHelpers();


  const handleAddPerson = (person) => {
    // console.log(person, ' --> handleAddPerson');
    let personIsAlreadyAdded = false;
    if(formData.persons && formData.persons.length>0) {
      personIsAlreadyAdded = formData.persons.find((element) => element.id === person.id);
    }
    if(!personIsAlreadyAdded) {
      setFormData({
        ...formData,
        persons: [
          ...formData.persons,
          person
        ]
      });
    }
  }

  const handleAddInvitation = (email) => {
    //console.log(email, ' --> handleAddInvitation');
    let invitationsIsAlreadyAdded = false;
    if(formData.invitations && formData.invitations.length>0) {
      invitationsIsAlreadyAdded = formData.invitations.find((element) => element.email === email);
    }

    let invitation = {
      email: email,
      firstName: '',
      lastName: ''
    }
    if(!invitationsIsAlreadyAdded) {
      setFormData({
        ...formData,
        invitations: [
          ...formData.invitations,
          invitation
        ]
      });
    }
  }

  const handleRemovePerson = (person) => {
    // console.log(person, ' --> handleRemovePerson');

    let newList = formData.persons;
    const index = newList.indexOf(person);
    if (index > -1) {
      newList.splice(index, 1);
    }
    setFormData({
      ...formData,
      persons: newList
    });
  }

  const handleRemoveInvitation = (invitation) => {
    //console.log(invitation, ' --> handleRemoveInvitation');

    let newList = formData.invitations;
    const index = newList.indexOf(invitation);
    if (index > -1) {
      newList.splice(index, 1);
    }
    setFormData({
      ...formData,
      invitations: newList
    });
  }

  //console.log(formData.invitations, ' --> formData.invitations');

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0">
            Administratorer
            <TooltipBadge
              tooltip="Kan endre på all informasjon tilknyttet enheten."
              icon="question-circle"
            />
          </h5>
        </Card.Header>
        <Card.Body>
          <div className={'mb-4'}>
            <CompanyPersonsSearchBox
              searchForPersons={searchPersonsByEmail}
              handleAddPerson={handleAddPerson}
              handleAddInvitation={handleAddInvitation}
            />
          </div>

          {formData.invitations && formData.invitations.length > 0 && formData.invitations.map((invitation,index) => (
            <div key={index}>
              <Flex
                alignItems="center"
                className={classNames('border', 'p-3', 'rounded-3', 'dark__bg-1000', ' my-1', 'fs--1', { 'bg-soft-primary': invitation.id === undefined })}
                key={index}
              >
                <FontAwesomeIcon
                  icon="envelope"
                  className="fs-1 me-2 ms-2"
                  ///transform="shrink-4"
                />
                <div className="flex-1 align-self-center ms-2">
                  <p className="mb-1 lh-1">
                    {invitation.id !== undefined && (
                      <span>Invitasjon sendt til</span>
                    )}
                    {invitation.id === undefined && (
                      <span>Invitasjon vil bli sendt til</span>
                      )}
                  </p>
                  <p className="mb-0 lh-1">
                    <span className="fw-semi-bold">
                      {invitation?.email}
                    </span>
                  </p>
                </div>
                <span
                  className="text-300 p-1 ml-auto cursor-pointer"
                  onClick={() => handleRemoveInvitation(invitation)}
                >
                  <FontAwesomeIcon icon="times" />
                  </span>
              </Flex>
            </div>
          ))}

          {formData.persons && formData.persons.length > 0 && formData.persons.map((person,index) => (
                <div key={index}>
                <Flex
                  alignItems="center"
                  className="border p-3 rounded-3 bg-white dark__bg-1000 my-1 fs--1"
                  key={index}
                >
                  <Link className="d-flex pe-2" to={'/profile/'+person?.id}>
                    <Avatar size="2xl" src={getAvatarUrl(person.avatarSrc, person.firstName,person.lastName)} className={''} />
                  </Link>
                  <div className="flex-1 align-self-center ms-2">
                    <p className="mb-1 lh-1">
                      <Link className="fw-semi-bold" to={'/profile/'+person?.id}>
                        {person?.fullName}
                      </Link>
                    </p>
                    <p className="mb-0 fs--1"> {person?.email}</p>
                  </div>
                  <span
                    className="text-300 p-1 ml-auto cursor-pointer"
                    onClick={() => handleRemovePerson(person)}
                  >
                  <FontAwesomeIcon icon="times" />
                  </span>
                </Flex>

                  {/*<Row key={person.id}>*/}
                  {/*  <Col>*/}
                  {/*    <Flex alignItems="center" className="mb-2" key={index}>*/}
                  {/*      <Link className="d-flex pe-2" to={'/profile/'+person?.id}>*/}
                  {/*        <Avatar size="2xl" src={getAvatarUrl(person.thumbnail?.image, person.firstName,person.lastName)} className={''} />*/}
                  {/*      </Link>*/}


                  {/*      /!*<div className="flex-1">*!/*/}
                  {/*      /!*  <Link to={'/profile/'+person?.id}>*!/*/}
                  {/*      /!*    <h6 className="mb-0">{person?.fullName}</h6>*!/*/}
                  {/*      /!*  </Link>*!/*/}

                  {/*      /!*</div>*!/*/}

                  {/*      <div className="flex-1 align-self-center ms-2">*/}
                  {/*        <p className="mb-2 lh-1">*/}
                  {/*          <Link className="fw-semi-bold" to={'/profile/'+person?.id}>*/}
                  {/*            {person?.fullName}*/}
                  {/*          </Link>*/}
                  {/*        </p>*/}
                  {/*      </div>*/}

                  {/*    </Flex>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                  {/*{index>0 && (*/}
                  {/*  <div className="border-dashed border-bottom my-3"  key={9999}/>*/}
                  {/*)}*/}
                </div>
            ))}
        </Card.Body>
      </Card>
    </>
  );
};

EditCompanyPersonsList.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};


export default EditCompanyPersonsList;
