import Flex from "../../../falcon-react-lib/components/common/Flex";
import SoftBadge from "../../../falcon-react-lib/components/common/SoftBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

const AssignmentCategoriesPills = ({assignment}) => {
  return (
    <Flex wrap="wrap" className="gap-2 mb-3">
      {assignment.regions!==undefined && assignment.regions.map((region) => (
        <SoftBadge key={region.id} pill bg="primary">
          <FontAwesomeIcon
            icon="location-arrow"
            className="me-1"
            transform="shrink-4"
          />
          {region.name}
        </SoftBadge>
      ))}
      {assignment.competences!==undefined && assignment.competences.map((competence) => (
        <SoftBadge key={competence.id} pill bg="primary">
          <FontAwesomeIcon
            icon="graduation-cap"
            className="me-1"
            transform="shrink-4"
          />
          {competence.name}
        </SoftBadge>
      ))}
      {assignment.currentStudies!==undefined && assignment.currentStudies.map((currentStudy) => (
        <SoftBadge key={currentStudy.id} pill bg="primary">
          <FontAwesomeIcon
            icon="graduation-cap"
            className="me-1"
            transform="shrink-4"
          />
          {currentStudy.name}
        </SoftBadge>
      ))}
      {assignment.industries!==undefined && assignment.industries.map((industry) => (
        <SoftBadge key={industry.id} pill bg="primary">
          <FontAwesomeIcon
            icon="briefcase"
            className="me-1"
            transform="shrink-4"
          />
          {industry.name}
        </SoftBadge>
      ))}
      <SoftBadge key={9999} pill bg="dark">
        <FontAwesomeIcon
          icon="calendar-alt"
          className="me-1"
          transform="shrink-4"
        />
        {assignment.startDate} - {assignment.endDate}
      </SoftBadge>
    </Flex>
  );
};

AssignmentCategoriesPills.propTypes = {
  assignment: PropTypes.object.isRequired
};


export default AssignmentCategoriesPills;