import { AuthWizardContext } from 'falcon-react-lib/context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from 'falcon-react-lib/components/wizard/lottie/celebration.json';

const Success = ({ successHandle }) => {
  const { user } = useContext(AuthWizardContext);
  console.log(user,' --> Success user');

  const handleStartButtonClick = () => {
    successHandle(user,false);
  };

  const handleTempWizardButtonClick = () => {
    successHandle(user,true);
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Du er klar til å starte å bruke folkin!</h4>

          {(user.companyId === undefined || user.companyId === null) && (
            <>
              <p className="fs-0 mt-3">Men du er ikke synlig for oppdragsgivere ennå. For å bli søkbar som vikar på folkin må du gjøre vikarprofilen din helt ferdig. Da trenger vi enda litt mer opplysninger om deg.</p>
              <Button variant="primary" className="px-5 my-3" onClick={handleTempWizardButtonClick}>
                Lag ferdig vikarprofilen min
              </Button>

              <Button variant="outline-primary" className="px-5 my-3" onClick={handleStartButtonClick}>
                Jeg gjør det senere
              </Button>
            </>
             )}
          {user.companyId !== undefined && user.companyId !== null && (
            <>
              <Button color="primary" className="px-5 my-3" onClick={handleStartButtonClick}>
                Start
              </Button>

            </>
          )}

        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  successHandle: PropTypes.func.isRequired
};

export default Success;
