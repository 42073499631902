import React, {useContext, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import AppContext from '../falcon-react-lib/context/Context';
import classNames from 'classnames';
import NavbarTop from '../components/navbar/top/NavbarTop';
import NavbarVertical from '../components/navbar/vertical/NavbarVertical';
import Footer from '../components/footer/Footer';
import AssignmentProvider from '../components/app/assignment/search/AssignmentProvider';
import MyAssignmentProvider from '../components/app/assignment/my-assignments/MyAssignmentProvider';
import CompaniesProvider from '../components/app/companies/list/CompaniesProvider';
import MyProfileProvider from "../components/app/myProfile/MyProfileProvider";
import NotificationsProvider from "../components/notification/NotificationsProvider";
import CategoriesProvider from "../components/categories/CategoriesProvider";
import ChatProvider from "../components/app/chat/ChatProvider";
import FeedProvider from "../components/app/dashboard/default/FeedProvider";
import FavouritesProvider from "../components/app/favourites/FavouritesProvider";
import TempsProvider from "../components/app/temps/TempsProvider";
import {useNavigate} from "react-router-dom";
import AuthProvider from "../components/auth/AuthProvider";

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const navigate = useNavigate();

  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    let redirectUrl = window.localStorage.getItem('redirectUrl');
    if(redirectUrl) {
      window.localStorage.removeItem('redirectUrl'); // Clean up after redirection when logging in
      navigate(redirectUrl, { replace: true });
    }
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <MyProfileProvider>
        <CategoriesProvider>
          <AuthProvider>
            <NotificationsProvider>
              <FeedProvider>
                <CompaniesProvider>
                  <AssignmentProvider>
                    <TempsProvider>
                      <MyAssignmentProvider>
                        <ChatProvider>
                          <FavouritesProvider>
                            {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
                              <NavbarVertical />
                            )}
                            <div className={classNames('content', { 'pb-0': isKanban })}>
                              <NavbarTop />
                              {/*------ Main Routes ------*/}
                              <Outlet />
                              {!isKanban && <Footer />}
                            </div>
                          </FavouritesProvider>
                        </ChatProvider>
                      </MyAssignmentProvider>
                    </TempsProvider>
                  </AssignmentProvider>
                </CompaniesProvider>
              </FeedProvider>
            </NotificationsProvider>
          </AuthProvider>
        </CategoriesProvider>
      </MyProfileProvider>
    </div>
  );
};

export default MainLayout;
