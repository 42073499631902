import React from 'react';
import TinymceEditor from 'components/common/TinymceEditor';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';

const AssignmentInformationForm = ({ formData, setFormData }) => {

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCompanySelectChange = e => {
    const selectedCompany = formData.owner?.companies?.find(c => {
      return c.id === e.target.value
    })
    //console.log(selectedCompany,' -> handleSelectChange selectedCompany')
    setFormData({
      ...formData,
      company: selectedCompany
    });
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-light">Informasjon om oppdraget</Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="name">
              <Form.Label>
                Tittel på oppdraget<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                placeholder="Tittel"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="company">
              <Form.Label>Selskap</Form.Label>
              <Form.Select
                size="sm"
                value={formData.company?.id}
                onChange={handleCompanySelectChange}
                >
                {formData.owner?.companies?.map(company => (
                  <option key={company.id} value={company.id}>{company.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs="12">
            <Form.Group>
              <Form.Label>
                Beskrivelse av oppdraget<span className="text-danger">*</span>
              </Form.Label>
              <div className="create-Assignment-description-textarea">
                <TinymceEditor
                  height="13.438rem"
                  value={formData.description}
                  handleChange={newValue =>
                    setFormData({
                      ...formData,
                      description: newValue
                    })
                  }
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

AssignmentInformationForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};
export default AssignmentInformationForm;
