import {config} from "../Constants";
import usePersons from "../hooks/usePersons";
import useAuth from "../hooks/useAuth";

const callApiPersons = () => {
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;
  const { handleLoadPersons, handleLoadPersonsAndFilter } = usePersons();

  const fetchPersons = (filterAlso) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/person", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        console.log('[fetchPersons] fetchPersons: filterAlso: ' + filterAlso);
        if(filterAlso) {
          handleLoadPersonsAndFilter(data);
        } else {
          handleLoadPersons(data)
        }
      });
  }

  const deletePerson = (personIdToDelete) => {
    // console.log(assignmentIdToDelete, ' [callApiAssignments] deleteAssignment')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
        return fetch(apiUrl + "/person/" + personIdToDelete, requestOptions)
      })
      .then(response => {
        if (response.status === 200) {
          // console.log('[MyAssignments] deleteAssignment success 200')
          fetchPersons();
        } else {
          // console.log('[callApiAssignments] deleteAssignment ERROR')
        }
      });
  };

  return {
    fetchPersons,
    deletePerson
  };
};

export default callApiPersons;