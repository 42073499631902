import React from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from "prop-types";

const ChatBottomBar = ({showRemovedThreads, setShowRemovedThreads}) => {
  const handleShowSoftDeletedThreads = () => {
    //console.log(showRemovedThreads, ' -> showRemovedThreads Vis fjernede samtaler ')
    setShowRemovedThreads(!showRemovedThreads);
  }

  // d-none d-md-flex"

  return (
    <div className="contacts-search-wrapper d-none d-md-flex">
        <Button
          variant="link"
          size="sm"
          className=""
          type="button"
          onClick={() => {handleShowSoftDeletedThreads()}}
        >
          {showRemovedThreads ? 'Skjul fjernede samtaler' : 'Vis fjernede samtaler'}
        </Button>
    </div>
)};

ChatBottomBar.propTypes = {
  showRemovedThreads: PropTypes.bool.isRequired,
  setShowRemovedThreads: PropTypes.func.isRequired
};

export default ChatBottomBar;
