import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Section from 'falcon-react-lib/components/common/Section';
import RegisterLogo from '../components/pages/welcomeWizard/RegisterLogo'
import {Outlet} from "react-router-dom";
import CategoriesProvider from "../components/categories/CategoriesProvider";
import AuthProvider from "../components/auth/AuthProvider";

const RegisterWizardLayout = () => {
  return (
    <CategoriesProvider>
      <AuthProvider>
        <Section className="py-0">
          <Row className="justify-content-center pt-6">
            <Col sm={10} lg={7} className="col-xxl-5">
              <RegisterLogo width={100} height={20}/>
              <Outlet />
            </Col>
          </Row>
        </Section>
      </AuthProvider>
    </CategoriesProvider>
  );
};

export default RegisterWizardLayout;
