import React, { useEffect, useRef } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Bottombar from 'components/common/Bottombar';
import useVisibilityObserver from 'falcon-react-lib/hooks/useVisibilityObserver';
import PropTypes from "prop-types";

const SubmitForm = ({saveAndSubmitForm}) => {
  //console.log(formData, '--> [SubmitCompany] formData ');
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '0px'
  );

  useEffect(() => {
    return () => {
      observer &&
      targetElRef.current &&
      observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="mb-lg-3 order-lg-0 order-1">
        {/*<Card.Header className="py-2 d-flex flex-between-center bg-light">*/}
        {/*  <h5 className="mb-0">Meld din interesse</h5>*/}
        {/*</Card.Header>*/}
        <Card.Body ref={targetElRef}>
          <Row className="flex-row-reverse g-0">
            <Col xs="auto">
              <Button
                size="md"
                variant="primary"
                className="fw-medium"
                onClick={saveAndSubmitForm}
              >
                <span>Opprett kontakt</span>
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Bottombar
        inViewport={inViewport}
        buttonText={'Opprett kontakt'}
        handleClick={saveAndSubmitForm}
      />
    </>
  );
};

SubmitForm.propTypes = {
  saveAndSubmitForm: PropTypes.func.isRequired
};

export default SubmitForm;
