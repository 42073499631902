import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Calendar from 'falcon-react-lib/components/common/Calendar';
import Flex from 'falcon-react-lib/components/common/Flex';

const FeedEvent = ({ title, calendar, author, regFee, eventImage }) => (
  <Card className="p-0 shadow-none">

    {!!eventImage && <img className="card-img-top" src={eventImage} alt="" />}
    <Card.Body className="overflow-hidden">
      <Row className="flex-center">
        <Col>
          <Flex>
            <Calendar {...calendar} />
            <div className="fs--1 ms-2">
              <h5 className="fs-0 text-capitalize">
                <Link to="/events/event-detail">{title}</Link>
              </h5>
              <p className="mb-0 text-capitalize">
                by <a href="falcon-react-lib/components/app/social/feed/FeedEvent#!">{author}</a>
              </p>
              <span className="fs-0 text-warning fw-semi-bold">{regFee}</span>
            </div>
          </Flex>
        </Col>
        {/*<Col md="auto" className="d-none d-md-block">*/}
        {/*  <Button variant="falcon-default" size="sm" className="px-4">*/}
        {/*    Register*/}
        {/*  </Button>*/}
        {/*</Col>*/}
      </Row>
    </Card.Body>
  </Card>
);

FeedEvent.propTypes = {
  title: PropTypes.string.isRequired,
  calendar: PropTypes.object.isRequired,
  author: PropTypes.string.isRequired,
  regFee: PropTypes.string,
  eventImage: PropTypes.string
};

export default FeedEvent;
