import {useContext} from "react";
import {FavouritesContext} from "../context/Context";

const useFavourites = () => {
  const {favouritesDispatch: favouritesDispatch } = useContext(FavouritesContext);

  const handleReloadFavouriteAssignments = (data) => {
    favouritesDispatch({
      type: 'RELOAD_FAVOURITE_ASSIGNMENTS',
      payload: {
        favouriteAssignments: data
      }
    });
  };
  const handleReloadFavouritePersons = (data) => {
    favouritesDispatch({
      type: 'RELOAD_FAVOURITE_PERSONS',
      payload: {
        favouritePersons: data
      }
    });
  };

  return {
    handleReloadFavouriteAssignments,
    handleReloadFavouritePersons
  };
};

export default useFavourites;