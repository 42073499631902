import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Alert, Form} from "react-bootstrap";
import AvailabilityCalendar from "../../app/availability/AvailabilityCalendar";
import {formatISO} from "date-fns";
import { v4 as uuidv4 } from 'uuid'

const RegisterTempAvailabilityForm = ({ register, setValue, getValues, errors, profile, events }) => {
  //console.log(events, ' RegisterTempAvailabilityForm   events');
  //console.log(profile, ' RegisterTempAvailabilityForm   profile');
  const [availabilityStatus, setAvailabilityStatus] = useState();
  const [availabilityEvents, setAvailabilityEvents] = useState(events?events:[]);

  const handleAddMyAvailabilityEvent = (eventId, myPersonId, startDate, endDate) => {
    //onsole.log(eventId, ' handleAddMyAvailabilityEvent   eventId');
    //console.log(myPersonId, ' handleAddMyAvailabilityEvent   myPersonId');
    //console.log(formatISO(startDate), ' handleAddMyAvailabilityEvent   startDate');
    //console.log(startDate, ' handleAddMyAvailabilityEvent   startDate');
    //console.log(formatISO(endDate), ' handleAddMyAvailabilityEvent   endDate');
    const event = {
      id: uuidv4(),
      className: 'bg-soft-success',
      description: '',
      title: 'Tilgjengelig',
      allDay: false,
      personId: myPersonId,
      start: formatISO(startDate),
      end: formatISO(endDate)
    }
    const newEventsList =  Array.from(availabilityEvents);
    console.log(newEventsList, ' RegisterTempAvailabilityForm   newEventsList 1');
    newEventsList.push(event);
    console.log(newEventsList, ' RegisterTempAvailabilityForm   newEventsList 2');
    setAvailabilityEvents(newEventsList);
    setValue('events',newEventsList);
  }

  const handleRemoveMyAvailabilityEvent = (id) => {
    console.log(id, ' handleRemoveMyAvailabilityEvent   event id');
    const newEventsList =  Array.from(availabilityEvents);
    const objWithIdIndex = newEventsList.findIndex((obj) => obj.id === id);
    newEventsList.splice(objWithIdIndex, 1);
    setAvailabilityEvents(newEventsList);
    setValue('events',newEventsList);
  }

  useEffect(() => {
    setAvailabilityStatus(getValues('availabilityStatus'))
  }, [])

  return (
    <>

      <div className="pb-3">
        <Alert variant="secondary">
          <div className="mb-3 fw-bold">
            Tilgjengelighet
          </div>
          <p>
            For en oppdragsgiver er din tilgjengelighet viktig når de søker etter vikarer.
          </p>
          <p>
            For at din vikarprofil skal få best mulig treff i vikarsøk velg kalenderstyrt tilgjengelighet. Da må du også legge inn i kalenderen nøyaktig hvilke dager du er tilgjengelig.
          </p>
        </Alert>

        {availabilityStatus==='NONE' && (
          <Alert variant="warning">
            <span>Du er søkbar i vikarsøket men står som ikke tilgjengelig for oppdrag</span>
          </Alert>
        )}
        {availabilityStatus==='CALENDAR' && (!availabilityEvents || availabilityEvents.length===0) && (
          <Alert variant="warning">
            <span>Du har ikke lagt inn perioder/dager i tilgjengelighetskalerender din</span>
          </Alert>
        )}
      </div>

      <Form.Group className="pb-3">
        <Form.Label className="fw-bold">Tilgjengelighet *</Form.Label>
          <Form.Check className="pb-0 pt-0" type="checkbox" id={'9999' + Math.floor(Math.random() * 100)} key={'9999'}>
            <Form.Check.Input
              type="radio"
              {...register('availabilityStatus', {
                onChange: (e) => {setAvailabilityStatus(e.target.value)},
                required: 'Du må velge en av disse'
              })}
              //onChange={() => setAvailabilityStatus("NONE")}
              value={'NONE'}
              isInvalid={errors['availabilityStatus']}
              isValid={Object.keys(errors).length > 0 && !errors['availabilityStatus']}
            />
            <Form.Check.Label className="ms-2">{'Ikke tilgjengelig akkurat nå'}</Form.Check.Label>
            <Form.Control.Feedback type="invalid" className="mt-0">
              {errors['availabilityStatus']?.message}
            </Form.Control.Feedback>
          </Form.Check>
        <Form.Check className="pb-0 pt-0" type="checkbox" id={'8888' + Math.floor(Math.random() * 100)} key={'8888'}>
          <Form.Check.Input
            type="radio"
            {...register('availabilityStatus', {
              onChange: (e) => {setAvailabilityStatus(e.target.value)},
              required: 'Du må velge en av disse'
            })}
            //onChange={() => setAvailabilityStatus("CALENDAR")}
            value={'CALENDAR'}
            isInvalid={errors['availabilityStatus']}
            isValid={Object.keys(errors).length > 0 && !errors['availabilityStatus']}
          />
          <Form.Check.Label className="ms-2">{'Kalenderstyrt'}</Form.Check.Label>
          <Form.Control.Feedback type="invalid" className="mt-0">
            {errors['availabilityStatus']?.message}
          </Form.Control.Feedback>
        </Form.Check>
        <Form.Check className="pb-0 pt-0" type="checkbox" id={'7777' + Math.floor(Math.random() * 100)} key={'7777'}>
          <Form.Check.Input
            type="radio"
            {...register('availabilityStatus', {
              onChange: (e) => {setAvailabilityStatus(e.target.value)},
              required: 'Du må velge en av disse'
            })}
            //onChange={() => setAvailabilityStatus("ALWAYS")}
            value={'ALWAYS'}
            isInvalid={errors['availabilityStatus']}
            isValid={Object.keys(errors).length > 0 && !errors['availabilityStatus']}
          />
          <Form.Check.Label className="ms-2">{'Alltid tilgjengelig'}</Form.Check.Label>
          <Form.Control.Feedback type="invalid" className="mt-0">
            {errors['availabilityStatus']?.message}
          </Form.Control.Feedback>
        </Form.Check>
      </Form.Group>

      <Form.Group>
        <Form.Label className="fw-bold">Kapasitet *</Form.Label>
        <Form.Select
          type="select"
          {...register('percentAvailability', {
            required: 'Du må velge kapasitet'
          })}
          isInvalid={errors['percentAvailability']}
          isValid={Object.keys(errors).length > 0 && !errors['percentAvailability']}
        >
          <option value="">{' '}</option>
          <option key="10" value="10">10%</option>
          <option key="20" value="20">20%</option>
          <option key="30" value="30">30%</option>
          <option key="40" value="40">40%</option>
          <option key="50" value="50">50%</option>
          <option key="60" value="60">60%</option>
          <option key="70" value="70">70%</option>
          <option key="80" value="80">80%</option>
          <option key="90" value="90">90%</option>
          <option key="100" value="100">100%</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {errors['percentAvailability']?.message}
        </Form.Control.Feedback>
      </Form.Group>

      {availabilityStatus==='CALENDAR' && (
        <div className="pt-5">
          <label className="fw-bold pb-3">Tilgjengelighetskalender</label>
          <AvailabilityCalendar myAvailabilityEvents={availabilityEvents} myProfilePerson={profile} handleAddMyAvailabilityEvent={handleAddMyAvailabilityEvent} handleRemoveMyAvailabilityEvent={handleRemoveMyAvailabilityEvent} />
        </div>
      )}
    </>
  );
};

RegisterTempAvailabilityForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  profile: PropTypes.object,
  events: PropTypes.array
};

export default RegisterTempAvailabilityForm;
