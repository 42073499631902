import React from 'react';
import PropTypes from 'prop-types';
import {Col, Image, Row} from 'react-bootstrap';
import Hoverbox from "../../../../falcon-react-lib/components/common/Hoverbox";
import useUrlHelpers from "../../../../hooks/useUrlHelpers";

const AddImagesFeedGallery = ({ images, removeImage }) => {
  // 1 = 12
  // 2 = 6 + 6
  // 3 = 4 + 4 + 4
  // 4 = 6 + 6
  // 5 = 6 + 6 -- 4 + 4 + 4
  // 6 = 4 + 4 + 4 -- 4 + 4 + 4
  const map1 = new Map();
  map1.set(0,12);
  const map2 = new Map();
  map2.set(0,6)
  map2.set(1,6)
  const map3 = new Map();
  map3.set(0,4)
  map3.set(1,4)
  map3.set(2,4)
  const map4 = new Map();
  map4.set(0,6)
  map4.set(1,6)
  map4.set(2,6)
  map4.set(3,6)
  const map5 = new Map();
  map5.set(0,6)
  map5.set(1,6)
  map5.set(2,4)
  map5.set(3,4)
  map5.set(4,4)
  const map6 = new Map();
  map6.set(0,4)
  map6.set(1,4)
  map6.set(2,4)
  map6.set(3,4)
  map6.set(4,4)
  map6.set(5,4)

  let currentMap = map1;
  if(images.length===2) {
    currentMap = map2;
  }
  if(images.length===3) {
    currentMap = map3;
  }
  if(images.length===4) {
    currentMap = map4;
  }
  if(images.length===5) {
    currentMap = map5;
  }
  if(images.length===6) {
    currentMap = map6;
  }

  const {getImageUrl} = useUrlHelpers();

  return (

      <Row className="g-2 pt-3 p-3">
        {images.map((image,index) => (
          <Col xs={currentMap.get(index)} key={index}>
            <Hoverbox className="text-center">
              <Image
                src={getImageUrl(image)}
                fluid
                rounded
              />
              <Hoverbox.Content className="light bg-dark p-5 flex-center">
                <div>
                  <a className="btn btn-light btn-sm mt-1" href="#!" onClick={() => removeImage(image)}>
                    Fjern
                  </a>
                </div>
              </Hoverbox.Content>
            </Hoverbox>
          </Col>
        ))};
      </Row>

  );
};

AddImagesFeedGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  removeImage: PropTypes.func.isRequired
};

export default AddImagesFeedGallery;
