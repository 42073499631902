import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AssignmentFooterOwner = ({ assignment }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <h5 className="mb-0">Oppdragsstatus</h5>
      </Card.Header>
      <Card.Body>
        <h6 className="mb-0">Bare synlig for deg som administrator av oppdraget</h6>

        <Row className={'mt-3'}>
          <Col>
            <ul className="list-unstyled fs--1 mb-0">
              <li
                key="status"
                className=""
              >
                <FontAwesomeIcon icon="file" className="me-2"/>
                <span className="ms-1">Status:</span>
                <span className="text-800 fw-medium me-2">
                    {' '}
                  {assignment.status !== undefined && assignment.status !== '' ? (
                    assignment.status
                  ) : (
                    <span>Ikke lagret</span>
                  )}
                  </span>
              </li>
              <li
                key="publishedTimestamp"
                className=""
              >
                <FontAwesomeIcon icon="clock" className="me-2"/>
                <span className="ms-1">Publisert:</span>
                <span className="text-800 fw-medium me-2">
                    {' '}
                  {assignment.publishedTimestamp !== undefined && assignment.publishedTimestamp !== '' ? (
                    assignment.publishedTimestamp
                  ) : (
                    <span>-</span>
                  )}
                  </span>
              </li>
              <li
                key="updatedTimestamp"
                className=""
              >
                <FontAwesomeIcon icon="clock" className="me-2"/>
                <span className="ms-1">Sist lagret:</span>
                <span className="text-800 fw-medium me-2">
                    {' '}
                  {assignment.updatedTimestamp !== undefined && assignment.updatedTimestamp !== '' ? (
                    assignment.updatedTimestamp
                  ) : (
                    <span>-</span>
                  )}
                  </span>
              </li>
              <li
                key="ownerPerson"
                className=""
              >
                <FontAwesomeIcon icon="unlock-alt" className="me-2"/>
                <span className="ms-1">Eier:</span>
                <span className="text-800 fw-medium me-2">
                    {' '}
                  {assignment.owner.firstName} {assignment.owner.lastName}
                  </span>
              </li>
              <li
                key="company"
                className=""
              >
                <FontAwesomeIcon icon="unlock-alt" className="me-2"/>
                <span className="ms-1">Selskap:</span>
                <span className="text-800 fw-medium me-2">
                    {' '}
                  {assignment.company?.name}
                  </span>
              </li>
              <li
                key="match"
                className=""
              >
                <FontAwesomeIcon icon="user" className="me-2"/>
                <span className="ms-1">Antall vikarer matchet:</span>
                <span className="text-800 fw-medium me-2">
                    {' '}
                  {assignment.noOfMatchesNotified}
                  </span>
              </li>
            </ul>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

AssignmentFooterOwner.propTypes = {
  assignment: PropTypes.object.isRequired
};

export default AssignmentFooterOwner;
