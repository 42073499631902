import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Bottombar from './Bottombar';
import useVisibilityObserver from 'falcon-react-lib/hooks/useVisibilityObserver';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

const PublishAssignment = ({assignmentId, formData, saveChanges, saveChangesAndChangePublishedStatus}) => {
  //console.log(formData, '--> [PublishAssignment] formData ');
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '0px'
  );

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="mb-lg-3 order-lg-0 order-1">
        <Card.Header className="py-2 d-flex flex-between-center bg-light">
          <h5 className="mb-0">Publiser ditt oppdrag</h5>
          {assignmentId!==undefined && assignmentId!==null && (
            <Link
              to={`/assignment/assignment-details/${assignmentId}`}
              target="_blank"
            >
              <Button
                variant="link"
                size="sm"
                className="px-0 fw-medium text-secondary "
              >
                <span className="d-lg-none d-xxl-inline-block">Preview</span>
                <FontAwesomeIcon icon="external-link-alt" className="ms-2 fs--2" />
              </Button>
            </Link>
          )}
        </Card.Header>
        <Card.Body>
          <ul className="list-unstyled fs--1 mb-0">
            <li
              key="status"
              className=""
            >
              <FontAwesomeIcon icon="file" className="me-2" />
              <span className="ms-1">Status:</span>
              <span className="text-800 fw-medium me-2">
                  {' '}
                {formData.status!==undefined && formData.status!=='' ? (
                  formData.status
                ) : (
                 <span>Ikke lagret</span>
                )}
                </span>
            </li>
            <li
              key="publishedTimestamp"
              className=""
            >
              <FontAwesomeIcon icon="clock" className="me-2" />
              <span className="ms-1">Publisert:</span>
              <span className="text-800 fw-medium me-2">
                  {' '}
                {formData.publishedTimestamp!==undefined && formData.publishedTimestamp!=='' ? (
                  formData.publishedTimestamp
                ) : (
                  <span>-</span>
                )}
                </span>
            </li>
            <li
              key="updatedTimestamp"
              className=""
            >
              <FontAwesomeIcon icon="clock" className="me-2" />
              <span className="ms-1">Sist lagret:</span>
              <span className="text-800 fw-medium me-2">
                  {' '}
                {formData.updatedTimestamp!==undefined && formData.updatedTimestamp!=='' ? (
                  formData.updatedTimestamp
                ) : (
                  <span>-</span>
                )}
                </span>
            </li>
            <li
              key="ownerPerson"
              className=""
            >
              <FontAwesomeIcon icon="unlock-alt" className="me-2" />
              <span className="ms-1">Eier:</span>
              <span className="text-800 fw-medium me-2">
                  {' '}
                {formData.owner.firstName} {formData.owner.lastName}
                </span>
            </li>
            <li
              key="company"
              className=""
            >
              <FontAwesomeIcon icon="unlock-alt" className="me-2" />
              <span className="ms-1">Selskap:</span>
              <span className="text-800 fw-medium me-2">
                  {' '}
                {formData.company?.name}
                </span>
            </li>
            <li
              key="match"
              className=""
            >
              <FontAwesomeIcon icon="user" className="me-2" />
              <span className="ms-1">Antall vikarer matchet:</span>
              <span className="text-800 fw-medium me-2">
                  {' '}
                {formData.noOfMatchesNotified}
                </span>
            </li>
          </ul>
        </Card.Body>
        <Card.Footer ref={targetElRef} className="py-2 bg-light">
          <Row className="flex-between-center g-0">
            <Col xs="auto">
              <Button
                variant="link"
                size="sm"
                className="fw-medium"
                onClick={saveChanges}
              >
                {formData.status===undefined || formData.status==='' || formData.status==='DRAFT' ? (
                  <span>Lagre kladd</span>
                ) : (
                  <span>Lagre</span>
                )}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                size="md"
                variant="primary"
                className="fw-medium"
                onClick={saveChangesAndChangePublishedStatus}
              >
                {formData.status===undefined || formData.status==='' || formData.status==='DRAFT' || formData.status==='UNPUBLISHED' ? (
                  <span>Publiser</span>
                ) : (
                  <span>Avpubliser</span>
                )}
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <Bottombar
        inViewport={inViewport}
        assignmentId={assignmentId}
        formData={formData}
        saveChanges={saveChanges}
        saveChangesAndChangePublishedStatus={saveChangesAndChangePublishedStatus}
      />
    </>
  );
};

PublishAssignment.propTypes = {
  assignmentId: PropTypes.string,
  formData: PropTypes.object.isRequired,
  saveChanges: PropTypes.func.isRequired,
  saveChangesAndChangePublishedStatus: PropTypes.func.isRequired
};

export default PublishAssignment;
