import React from 'react';
import {Card} from 'react-bootstrap';
import PropTypes from "prop-types";
import Flex from "falcon-react-lib/components/common/Flex";
import AttachedFile from "components/app/myProfile/editTempInfo/AttachedFile";

const AssignmentFiles = ({assignment}) => {

  // console.log('[ProfileFiles] assignment: ' + JSON.stringify(assignment));

  return (
    <>
      {assignment && assignment.files && assignment.files.length > 0 && (
        <Card className="mb-3">
          <Card.Header className="bg-light">
            <h5 className="mb-0">Filer</h5>
          </Card.Header>
          <Card.Body>
            <Flex direction="column" className="mt-3">
              {assignment.files.map((fileInfo, index) => (
                <AttachedFile
                  key={'attached-file-' + index}
                  index={index}
                  fileInfo={fileInfo}
                />
              ))}
            </Flex>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

AssignmentFiles.propTypes = {
  assignment: PropTypes.object.isRequired
};


export default AssignmentFiles;
