import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {config} from "../../Constants";

const Footer = () => {
  const envTag = config.url.ENV_TAG;
  return (
  <footer className="footer">
    <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
          <a href="https://www.folkin.no">Folkin</a>
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">v0.1
          {envTag!=='prod' && (
            <span> {envTag}</span>
          )}
        </p>

      </Col>
    </Row>
  </footer>
)};

export default Footer;
