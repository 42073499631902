import PropTypes from 'prop-types';
import React from 'react';
import {Link} from "react-router-dom";
import useUrlHelpers from "../../../../hooks/useUrlHelpers";


const FeedUrl = ({ imgUrl, urlAddress, title, description }) => {
  const {getImageUrl} = useUrlHelpers();
  return (
    <Link rel={'noopener'} target={'_blank'} className={'text-decoration-none'} to={urlAddress}>
      {!!imgUrl && <img className="img-fluid rounded" src={getImageUrl(imgUrl)} alt="" />}
      <div>
        <small className="text-uppercase text-700">{urlAddress}</small>
        <h6 className="fs-0 mb-0">{title}</h6>
        {!!description && <p className="fs--1 mb-0 text-700">{description}</p>}
      </div>
    </Link>
  );
};

FeedUrl.propTypes = {
  urlAddress: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imgUrl: PropTypes.string
};
export default FeedUrl;
