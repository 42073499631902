import React, {useContext, useEffect} from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Notification from 'components/notification/Notification';
import classNames from 'classnames';
import {NotificationsContext} from "../../../../context/Context";
import callApiNotifications from "../../../../api/callApiNotifications";
import {Link} from "react-router-dom";

const ActivityLog = () => {

  const {notificationsState: { allNotifications }} = useContext(NotificationsContext);
  const {fetchAllNotifications} = callApiNotifications();
  const {updateAllNotificationsReadFlag} = callApiNotifications()

  const markAsRead = e => {
    e.preventDefault();
    // console.log('[ActivityLog] markAsRead');
    updateAllNotificationsReadFlag();
  };

  useEffect(() => {
    fetchAllNotifications();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <Row>
          <Col>
            <h5 className="mb-1 mb-md-0">Alle varslinger</h5>
          </Col>
          <Col sm="auto">
            <Link
              className="card-link fw-normal justify-content-end"
              to="#!"
              onClick={markAsRead}
            >
              Merk alle som lest
            </Link>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        {allNotifications.length === 0  && (
          <Row className="">
            <Col key={'999'} className="p-2">
              <div className="ps-3">{' - Ingen -'}</div>
            </Col>
          </Row>
        )}
        {allNotifications.map((notification, index) => (
          <Notification
            {...notification}
            key={notification.id}
            className={classNames(
              'border-x-0 border-bottom-0 border-300',
              index + 1 === allNotifications.length ? 'rounded-top-0' : 'rounded-0'
            )}
          />
        ))}
      </Card.Body>
    </Card>
  );
};

export default ActivityLog;
