import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import Success from './Success';
import AppContext, { AuthWizardContext } from 'falcon-react-lib/context/Context';
import IconButton from 'falcon-react-lib/components/common/IconButton';
import WizardModal from 'falcon-react-lib/components/wizard/WizardModal';
import Flex from 'falcon-react-lib/components/common/Flex';
import RegisterForm from "./RegisterForm";
import {useAuth0} from "@auth0/auth0-react";
import {config} from "../../../Constants";
import {useNavigate} from "react-router-dom";

const RegisterWizardSteps = ({ variant, validation, progressBar }) => {
  const navigate = useNavigate();
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const values = {
    firstName: '',
    lastName: '',
    email: '',
    companyId: '',
    companyName: ''
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    //watch,
    setValue,
    // reset,
    clearErrors,
  } = useForm(values);

  const [modal, setModal] = useState(false);

  const navItems = [
    // {
    //   icon: 'lock',
    //   label: 'Velkommen'
    // },
    {
      icon: 'user',
      label: 'Profilinformasjon'
    },
    {
      icon: 'thumbs-up',
      label: 'Ferdig'
    }
  ];

  const onSubmitData = data => {
    setUser({ ...user, ...data });
    setStep(step + 1);
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 3) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }


  const saveRegistration = (user, goToTempWizard) => {
    // console.log(user, ' -- > [RegisterWizardSteps] saveRegistration ')

    const formData = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      companyName: user.companyName,
      companyId: user.companyId,
      isCompanyUser: user.companyId!==undefined && user.companyId!==null && user.companyId!=="",
      agreedToTermsFlag: user.agreedToTerms
    }
    // console.log(formData, ' -- > [RegisterWizardSteps] formData ')

    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(formData)
    };
    fetch(apiUrl+"/registration", requestOptions)
      .then(response => {
        if(response.ok) {
          // console.log('[RegisterWizardSteps] Registration saved ok ')
          if(goToTempWizard) {
            navigate('/temp/wizard', { replace: true });
          } else {
            navigate('/', { replace: true });
          }
        } else {
          throw Error('Registration was not saved');
        }
      });
  }

  const getMyProfile = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
      fetch(apiUrl+"/myProfile", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        console.log(data,'  getMyProfile data success')
        setValue('firstName',data.firstName);
        setValue('lastName',data.lastName);
        setValue('email',data.email);
        setValue('companyId',data.companies?.[0]?.id);
        setValue('companyName',data.companies?.[0]?.name);
      });
  };

  useEffect(() => {
    if(accessToken!=="") {
      getMyProfile();
    }
  }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])

  console.log(step,' step');
  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 50} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {/*{step === 1 && (*/}
          {/*  <WelcomeForm register={register} errors={errors} />*/}
          {/*)}*/}
          {step === 1 && (
            <RegisterForm register={register} errors={errors} />
          )}
          {/*{step === 3 && (*/}
          {/*  <BillingForm*/}
          {/*    register={register}*/}
          {/*    errors={errors}*/}
          {/*    setValue={setValue}*/}
          {/*  />*/}
          {/*)}*/}
          {step === 2 && <Success successHandle={saveRegistration} />}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 2,
            ' d-flex': step < 2
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Forrige
          </IconButton>

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Neste
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 2 ? step > index : step > 1,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

RegisterWizardSteps.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default RegisterWizardSteps;
