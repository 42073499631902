import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Section from 'falcon-react-lib/components/common/Section';
import { Outlet } from 'react-router-dom';
import RegisterLogo from "../components/pages/welcomeWizard/RegisterLogo";

const AuthSimpleLayout = () => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <RegisterLogo height={200} width={150} />
          <Card>
            <Card.Body className="p-4 p-sm-5">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default AuthSimpleLayout;
