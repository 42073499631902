import { useContext } from 'react';
import { PersonContext } from '../context/Context';

const usePersons = () => {
  const {personsDispatch: personsDispatch} = useContext(PersonContext);

  const handleLoadPersons = (data) => {
    //console.log(companies,' ->[useCompanies] handleLoadCompanies')
    personsDispatch({
      type: 'RELOAD_PERSON',
      payload: {
        persons: data
      }
    });
  };

  const handleLoadPersonsAndFilter = (data) => {
    //console.log(companies,' ->[useCompanies] handleLoadCompanies')
    personsDispatch({
      type: 'RELOAD_PERSON_AND_FILTER',
      payload: {
        persons: data
      }
    });
  };

  const handleFilter = (id,type,name,value,checked) => {
    //console.log('[usePersons] handleFilter id ' + id + ' type ' + type + ' name ' + name + ' value' + value );
    personsDispatch({
      type: 'FILTER_PERSON',
      payload: {
        id: id,
        type: type,
        name: name,
        value: value,
        checked: checked
      }
    });
  };

  const emptyFilter = () => {
    personsDispatch({
      type: 'EMPTY_FILTER'
    });
  };

  const handleSearch = searchedText => {
    personsDispatch({
      type: 'SEARCH_PERSON',
      payload: {
        searchedText
      }
    });
  };

  const handleIsAscClick = isAsc => {
    personsDispatch({
      type: 'SORT_PERSON',
      payload: {
        isAsc: isAsc
      }
    });
  };

  return {
    handleLoadPersons,
    handleLoadPersonsAndFilter,
    handleFilter,
    emptyFilter,
    handleSearch,
    handleIsAscClick
  };
};

export default usePersons;
