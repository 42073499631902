import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {Dropdown} from 'react-bootstrap';
import PropTypes from "prop-types";
import callApiFeed from "../../../../api/callApiFeed";

const FeedDropdown = ({feedItemId, feedItemName, pinned, showDeleteModal, hasDeleteRights, hasPinRights}) => {
  const {setFeedItemPinnedFlag} = callApiFeed();
  const onClickDelete = () => {
    //console.log(e,'  -> onClick')
    showDeleteModal(feedItemId,feedItemName);
  }

  const onClickPin = () => {
    //console.log(' -> onClickPin')
    setFeedItemPinnedFlag(feedItemId,!pinned);
  }

  return (
    <Dropdown className="font-sans-serif btn-reveal-trigger">
      <Dropdown.Toggle
        variant="link"
        size="sm"
        className="px-1 text-600 btn-reveal"
      >
        <FontAwesomeIcon icon="ellipsis-h" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        {hasPinRights() && (
          <Dropdown.Item className="" onClick={onClickPin}>

            {pinned ? 'Fjern fra toppen' : 'Fest til toppen'}
          </Dropdown.Item>
        )}
        {hasDeleteRights() && (
          <Dropdown.Item className="text-danger" onClick={onClickDelete}>
            Slett
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

FeedDropdown.propTypes = {
  feedItemId: PropTypes.string.isRequired,
  feedItemName: PropTypes.string,
  pinned: PropTypes.bool.isRequired,
  showDeleteModal: PropTypes.func,
  hasDeleteRights: PropTypes.func.isRequired,
  hasPinRights: PropTypes.func.isRequired
};

export default FeedDropdown;
