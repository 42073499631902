/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const notificationsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'RELOAD_NEW_NOTIFICATIONS':
      //console.log('[notificationsReducer] RELOAD_NEW_NOTIFICATIONS data: ' + JSON.stringify(payload.notifications));
      const newNotificationsVariable = payload.newNotifications;
      const isAllRead = !newNotificationsVariable.some(el => el.unread === true);
      //console.log('[notificationsReducer] RELOAD_NEW_NOTIFICATIONS isAllRead: ' + isAllRead);
      return {
        ...state,
        newNotifications: payload.newNotifications,
        isAllRead: isAllRead
      };
    case 'RELOAD_ALL_NOTIFICATIONS':
      //console.log('[notificationsReducer] RELOAD_NEW_NOTIFICATIONS data: ' + JSON.stringify(payload.notifications));
      const allNotificationsVariable = payload.allNotifications;
      const isAllRead2 = !allNotificationsVariable.some(el => el.unread === true);
      //console.log('[notificationsReducer] RELOAD_NEW_NOTIFICATIONS isAllRead: ' + isAllRead);
      return {
        ...state,
        allNotifications: payload.allNotifications,
        isAllRead: isAllRead2
      };
    case 'SET_IS_ALL_READ':
      //console.log('[notificationsReducer] SET_IS_ALL_READ data: ' + payload.isAllRead);
      return {
        ...state,
        isAllRead: payload.isAllRead
      };
    case 'SET_IS_OPEN':
      //console.log('[notificationsReducer] SET_IS_OPEN data: ' + payload.isOpen);
      return {
        ...state,
        isOpen: payload.isOpen
      };
    case 'SET_ALL_NOTIFICATIONS_READ':
      //console.log('[notificationsReducer] SET_ALL_NOTIFICATIONS_READ ');
      //console.log('[notificationsReducer] SET_ALL_NOTIFICATIONS_READ newNotifications: ' + JSON.stringify([...state.newNotifications]));
      const updatedNewNotifications = [...state.newNotifications].map(notification =>
        Object.prototype.hasOwnProperty.call(notification, 'unread')
          ? { ...notification, unread: false }
          : notification
      );
      //console.log('[notificationsReducer] SET_ALL_NOTIFICATIONS_READ earlierNotifications: ' + JSON.stringify([...state.earlierNotifications]));
      const updatedAllNotifications = [...state.allNotifications].map(notification =>
        Object.prototype.hasOwnProperty.call(notification, 'unread')
          ? { ...notification, unread: false }
          : notification
      );

      return {
        ...state,
        isAllRead: true,
        newNotifications: updatedNewNotifications,
        allNotifications: updatedAllNotifications,
      };
    case 'SET_NOTIFICATION_READ':
      // console.log(payload, '[notificationsReducer] SET_NOTIFICATION_READ payload');
      //console.log('[notificationsReducer] SET_NOTIFICATION_READ newNotifications: ' + JSON.stringify([...state.newNotifications]));
      const updatedNewNotifications2 = [...state.newNotifications].map(notification =>
        notification.id===payload.notificationId?{...notification, unread: false }:notification
      );
      const updatedAllNotifications2 = [...state.allNotifications].map(notification =>
        notification.id===payload.notificationId?{...notification, unread: false }:notification
      );
      return {
        ...state,
        isAllRead: true,
        newNotifications: updatedNewNotifications2,
        allNotifications: updatedAllNotifications2,
      };
    default:
      return state;
  }
};
