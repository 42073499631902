import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProfileBannerBackground = ({ className, style }) => {
  return (
    <div
      className={classNames(
        'bg-holder',
        className
      )}
      style={style}
    >
    </div>
  );
};

ProfileBannerBackground.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default ProfileBannerBackground;
