import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from 'falcon-react-lib/components/common/Avatar';
import Flex from 'falcon-react-lib/components/common/Flex';
import useUrlHelpers from "hooks/useUrlHelpers";

const ChatContentBodyIntro = ({ user, isGroup }) => {
  const {getAvatarUrl,getAvatarUrlsFromChatUsersArray} = useUrlHelpers();

  let avatarSrc = null;
  let linkUrl = null;
  if(isGroup) {
    avatarSrc = getAvatarUrlsFromChatUsersArray(user);
    linkUrl = "/assignment/assignment-details/"+user.groupAssignment.id;
  } else {
    avatarSrc = getAvatarUrl(user?.avatarSrc, user?.name,null);
    linkUrl = "/profile/"+user.id;
  }
  //let avatarUrl = getAvatarUrl(user?.avatarSrc, user?.firstName,user?.lastName)
  //console.log(user.groupAssignment,' --> [ChatContentBodyIntro] user.groupAssignment' );
  return (
    <Flex
      alignItems="center"
      className="position-relative p-3 border-bottom mb-3"
    >
      <Avatar className={`me-3`} size="2xl" src={avatarSrc} />
      <div className="flex-1">
        <h6 className="mb-0">
          <Link
            to={linkUrl}
            //className=" text-decoration-none stretched-link text-700"
          >
            {isGroup
              ? ('Oppdrag: ' + user.groupAssignment.name)
              : (user.fullName)}
          </Link>
        </h6>
        <div className="mb-0">
          {isGroup ?
            (
              <ul className="m-0 list-unstyled">
                <li className="ms-3" key={99}>
                  <Link
                    key={99}
                    to={`/company/${user.groupAssignment.companyId}`}
                    className="link"
                  >
                  {user.groupAssignment.companyName}
                  </Link>
                </li>
                {user.groupUsers.map((user,index) => (
                  <Link
                    key={index}
                    to={`/profile/${user.id}`}
                    className="link"
                  >
                    <li className="ms-3" key={index}>{user.fullName}</li>
                  </Link>
                ))}
              </ul>
            )
            :
            `Si hei for å starte samtalen`}
        </div>
      </div>
    </Flex>
)};

ChatContentBodyIntro.propTypes = {
  isGroup: PropTypes.bool,
  user: PropTypes.object.isRequired
};

export default ChatContentBodyIntro;
