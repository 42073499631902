/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const myProfileReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'RELOAD_MY_PROFILE':
      //console.log('myProfileReducer. RELOAD_MY_PROFILE data: ' + JSON.stringify(payload.myProfilePerson));
      return {
        ...state,
        myProfilePerson: payload.myProfilePerson
      };
    case 'RELOAD_MY_AVAILABILITY_EVENTS':
      return {
        ...state,
        myAvailabilityEvents: payload.myAvailabilityEvents
      };
    default:
      return state;
  }
};
