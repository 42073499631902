import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import cloudUpload from "../../../../falcon-react-lib/assets/img/icons/cloud-upload.svg";
import {Button, Card} from 'react-bootstrap';
import ErrorConfirmation from "../../../confirmation/ErrorConfirmation";
import PropTypes from "prop-types";
import Flex from "../../../../falcon-react-lib/components/common/Flex";
import AttachedFile from "./AttachedFile";
import useS3Upload from "../../../../hooks/useS3Upload";

const EditProfileFiles = ({ formData, setFormData }) => {
  const {uploadFilePrivateBucket} = useS3Upload();
  const [profileFiles, setProfileFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  //console.log(profileFiles, '  -> [EditProfileFiles] profileFiles')
  //console.log(formData, '  -> [EditProfileFiles] formData')


  // Handle the displaying of the error modal
  const showErrorModal = () => {
    setDisplayConfirmationModal(true);
  };
  // Hide the delete modal
  const hideErrorModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleFileUploadResponse = (fileInfo) => {
    // console.log(fileInfo, ' [EditProfileAvatar] onDrop - fileInfo');
    if(fileInfo.status!==undefined) {
      setErrorMessage(fileInfo.message);
      showErrorModal();
    } else {
      setProfileFiles(
        [
          ...profileFiles,
          fileInfo
        ]
      );

      setFormData({
        ...formData,
        files: [
          ...formData.files,
          fileInfo
        ]
      });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ],
    onDrop: acceptedFiles => {
      // console.log(acceptedFiles, '  ---> onDrop')
      if(acceptedFiles && acceptedFiles.length > 0) {
        uploadFilePrivateBucket(acceptedFiles[0], handleFileUploadResponse);
      } else {
        setErrorMessage('Denne filtypen kan ikke lastes opp');
        showErrorModal();
      }
    }
  });

  const loadFromFormData = () => {
    if(formData.files!==undefined && formData.files!==null && formData.files!=='') {
      setProfileFiles(formData.files);
    }
  }

  const handleRemovedFile = (fileInfo) => {
    //console.log(fileInfo,' --> handleRemovedFile');
    var indexInPropFiles = profileFiles.indexOf(fileInfo);
    //console.log(indexInPropFiles,' --> indexInPropFiles');
    //console.log(profileFiles,' --> profileFiles');
    //console.log(profileFiles.filter(function(file) {return file!==fileInfo}),' --> profileFiles.filter(function(file) {return file!==fileInfo})');
    if (indexInPropFiles > -1) {
      setProfileFiles(
        profileFiles.filter(function(file) {return file!==fileInfo})
      );
    }


    var indexInFormData = formData.files.indexOf(fileInfo);
    //console.log(indexInFormData,' --> indexInFormData');
    //console.log(formData.files,' --> formData.files');
    if (indexInFormData > -1) {
      setFormData({
        ...formData,
        files: formData.files.filter(function(file) {return file!==fileInfo})
      });
    }
  }

  useEffect(() => {
    loadFromFormData();
  }, [formData]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0 d-inline-block">
            Endre filer
          </h5>
        </Card.Header>
        <Card.Body>
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps({ multiple: false })} />
            <div className="fs--1">
              <img src={cloudUpload} alt="" width={20} className="me-2" />
              <span className="d-none d-lg-inline">
                Dra filer hit
                <br />
                eller,{' '}
              </span>
              <Button variant="link" size="sm" className="p-0 fs--1">
                Hent
              </Button>
            </div>
            <div className="fs--1 mt-2">
              <span className="d-none d-lg-inline">
                (.pdf eller .doc/.docx)
              </span>
            </div>
          </div>
          {profileFiles && profileFiles.length > 0 && (
            <Flex direction="column" className="mt-3">
              {profileFiles.map((fileInfo,index) => (
                <AttachedFile
                  key={'attached-file-'+index}
                  index={index}
                  fileInfo={fileInfo}
                  handleRemovedFile={handleRemovedFile}
                />
              ))}
            </Flex>
          )}
        </Card.Body>
      </Card>
      <ErrorConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideErrorModal}
        message={errorMessage}
      />
    </>
  );
};

EditProfileFiles.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};


export default EditProfileFiles;