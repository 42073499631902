/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const chatReducer = (state, action) => {
  const { type, payload } = action;

  // const getCurrentThread = (threads, personId, activeThreadId) => {
  //   if(personId===undefined||personId===null) {
  //     if(activeForPersonId!==undefined&&activeForPersonId!==null) {
  //       personId = activeForPersonId;
  //     }
  //   }
  //   let newCurrentThread =  threads.at(0)
  //   if(personId!==undefined&&personId!==null) {
  //     newCurrentThread = threads.find(thread => thread.userId === personId);
  //     if(newCurrentThread===undefined) {
  //       newCurrentThread =threads.at(0);
  //     }
  //   }
  //   return newCurrentThread;
  // }

  const getCurrentThread = (threads, activeThreadId) => {
    let newCurrentThread =  threads.at(0)
    if(activeThreadId!==undefined&&activeThreadId!==null) {
      newCurrentThread = threads.find(thread => thread.id === activeThreadId);
      if(newCurrentThread===undefined) {
        newCurrentThread =threads.at(0);
      }
    }
    return newCurrentThread;
  }


  switch (type) {
    case 'RELOAD_CHAT':
      //console.log(payload.chat,'RELOAD_CHAT ')
      if(payload.nextFunction) {
        payload.nextFunction();
        //console.log('RELOAD_CHAT nextFunction ')
      }
      return {
        ...state,
        groups: payload.chat.groups,
        assignments: payload.chat.assignments,
        users: payload.chat.persons,
        messages: payload.chat.messages,
        threads: payload.chat.threads
      };
    case 'RELOAD_GROUPS':
      //console.log('RELOAD_GROUPS ' + payload.groups)
      payload.nextFunction();
      return {
        ...state,
        groups: payload.groups
      };
    case 'RELOAD_ASSIGNMENTS':
      //console.log('RELOAD_ASSIGNMENTS ' + payload.assignments)
      payload.nextFunction();
      return {
        ...state,
        assignments: payload.assignments
      };
    case 'RELOAD_USERS':
      //console.log('RELOAD_USERS ' + payload.users)
      payload.nextFunction();
      return {
        ...state,
        users: payload.users
      };
    case 'RELOAD_CHAT_THREADS':
      //console.log('RELOAD_CHAT_THREADS ' + payload.threads)
      //console.log('RELOAD_CHAT_THREADS ' + payload.threads[0])
      if(payload.nextFunction) {
        payload.nextFunction();
      }
      return {
        ...state,
        threads: payload.threads
      };
    case 'RELOAD_CHAT_THREAD_MESSAGES':
      //console.log('RELOAD_CHAT_THREAD_MESSAGES ' + payload.messages)
      payload.nextFunction();
      return {
        ...state,
        messages: payload.messages
      };
    case 'SET_OPEN_THREAD_INFO':
      //console.log('SET_OPEN_THREAD_INFO ' + payload.isOpenThreadInfo)
      return {
        ...state,
        isOpenThreadInfo: payload.isOpenThreadInfo
      };
    case 'SET_CURRENT_THREAD':
      //console.log('SET_CURRENT_THREAD ' + payload.threadId)
      //console.log('SET_CURRENT_THREAD thread: ' + [...state.threads].find(thread => thread.id === payload.threadId));
      return {
        ...state,
        currentThread: [...state.threads].find(thread => thread.id === payload.threadId)
      };
    case 'SET_CURRENT_THREAD_BY_INDEX':
      //console.log('SET_CURRENT_THREAD_BY_INDEX ' + payload.index)
      // console.log('SET_CURRENT_THREAD_BY_ID thread: ' + [...state.threads].at(payload.index));
      //console.log('SET_CURRENT_THREAD_BY_INDEX thread: ' + [...state.threads].find(thread => thread.threadIndex === payload.index));
      const newCurrentThread = [...state.threads].find(thread => thread.threadIndex === payload.index);
      return {
        ...state,
        currentThread: newCurrentThread
      };
    case 'SET_ACTIVE_TAB_KEY_BY_INDEX':
      //console.log('SET_ACTIVE_TAB_KEY_BY_INDEX ' + payload.index)
      return {
        ...state,
        activeTabKey: payload.index
      };
    // case 'SET_CURRENT_THREAD_AND_ACTIVE_TAB_KEY_BY_PERSON_ID': {
    //   // console.log('SET_CURRENT_THREAD_AND_ACTIVE_TAB_KEY_BY_PERSON_ID payload.personId: ' + payload.personId)
    //
    //   return {
    //     ...state,
    //     currentThread: getCurrentThread([...state.threads],payload.personId,state.activeForPersonId),
    //     activeTabKey:  getCurrentThread([...state.threads],payload.personId,state.activeForPersonId).threadIndex,
    //     scrollToBottom: true,
    //   };
    // }
    case 'SET_ACTIVE_FOR_PERSON_ID': {
      //console.log('SET_ACTIVE_FOR_PERSON_ID activeForPersonId: ' + payload.activeForPersonId)
      return {
        ...state,
        activeForPersonId: payload.activeForPersonId
      };
    }
    case 'SET_ACTIVE_FOR_THREAD_ID': {
      //console.log('SET_ACTIVE_FOR_THREAD_ID activeThreadId: ' + payload.activeThreadId)
      return {
        ...state,
        activeThreadId: payload.activeThreadId
      };
    }
    case 'SET_CURRENT_THREAD_FIRST_TIME':
      //console.log('SET_CURRENT_THREAD_FIRST_TIME ')
      //console.log(state.threads, ' -> SET_CURRENT_THREAD_FIRST_TIME ')
      //console.log(state.activeForPersonId, ' -> SET_CURRENT_THREAD_FIRST_TIME ')
      //console.log('SET_CURRENT_THREAD_FIRST_TIME ' + [...state.threads].at(0).threadIndex)
      //currentThread: [...state.threads].at(0),
      //activeTabKey: [...state.threads].at(0).threadIndex
      let currentThread = getCurrentThread([...state.threads],state.activeThreadId);
      let activeTabKey = 0;
      if(currentThread) {
        activeTabKey = currentThread.threadIndex;
      } else {
        activeTabKey = 9999;
        console.log(activeTabKey, '  -> activeTabKey SET_CURRENT_THREAD_FIRST_TIME ')
      }
      return {
        ...state,
        currentThread: currentThread,
        activeTabKey:  activeTabKey,
        scrollToBottom: true
      };
    case 'SET_THREAD_READ':
      //console.log('SET_THREAD_READ ' + payload.threadId + ' - ' + payload.read)
      const threads = [...state.threads];
      //console.log('SET_THREAD_READ thread: ' + threads.find(thread => thread.id === payload.threadId));
      threads.find(thread => thread.id === payload.threadId).read = payload.read;
      return {
        ...state,
        threads: threads
      };
    case 'SET_THREAD_READ_BY_INDEX': {
      //console.log('SET_THREAD_READ_BY_INDEX ' + payload.index + ' - ' + payload.read)
      const threads = [...state.threads];
      //console.log('SET_THREAD_READ_BY_INDEX thread: ' + threads.at(payload.index));
      threads.find(thread => thread.threadIndex === payload.index).read = payload.read;
      //threads.at(payload.index).read = payload.read;
      return {
        ...state,
        threads: threads
      }}
    case 'SET_SCROLL_TO_BOTTOM':
      //console.log('[chatReducer] SET_SCROLL_TO_BOTTOM ' + payload.scrollToBottom);
      //console.log(payload.scrollToBottom!==undefined?payload.scrollToBottom:true,' ---> [chatReducer] SET_SCROLL_TO_BOTTOM ');
      return {
        ...state,
        scrollToBottom: payload.scrollToBottom!==undefined?payload.scrollToBottom:true
      };
    case 'ADD_NEW_MESSAGE':
      //console.log('[chatReducer] ADD_NEW_MESSAGE ' + payload.messagesId);
      const messages = [...state.messages];
      const message = [...state.messages].find(message => message.id === payload.messagesId);
      if(!message.content.find(content => content.id === payload.newMessage.id)) {
        //console.log('[chatReducer] adding to content list ' + payload.newMessage.id);
        message.content = [...message.content, payload.newMessage];
      }
      return {
        ...state,
        messages: messages
      };
    case 'SET_UPDATE_STARTED': {
      //console.log('[chatReducer] SET_UPDATE_STARTED ');
      const threads = [...state.threads];
      threads.find(thread => thread.id === payload.threadId).isUpdatedStart = payload.updateStarted;
      return {
        ...state,
        threads: threads
      }}
    default:
      return state;
  }
};
