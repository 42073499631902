import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import useCompanies from 'hooks/useCompanies';
import {CompanyContext} from "../../../../context/Context";

const CompaniesHeader = () => {
  const {companiesState: { isAsc }} = useContext(CompanyContext);
  const { handleSearch, handleIsAscClick } = useCompanies();
  //
  // console.log('[CompaniesHeader] setShowFilterOffcanvas: ' + setShowFilterOffcanvas);
  return (
    <Card className="mb-3">
      <Card.Header className="position-relative">
        <h5 className="mb-0 mt-1">Selskaper/Avdelinger</h5>
        {/*<Background image={corner6} className="d-none d-md-block bg-card" />*/}
      </Card.Header>
      <Card.Body className="pt-0 pt-md-3">
        <Row className="g-3 align-items-center">
          {/*<Col xs="auto" className="d-xl-none">*/}
          {/*  <Button*/}
          {/*    onClick={() => setShowFilterOffcanvas(true)}*/}
          {/*    className="position-relative p-0"*/}
          {/*    size="sm"*/}
          {/*    variant="link"*/}
          {/*  >*/}
          {/*    <FontAwesomeIcon icon="filter" className="fs-0 text-700" />*/}
          {/*  </Button>*/}
          {/*</Col>*/}
          <Col xs={12} sm={9}>
            <Form className="position-relative">
              <Form.Control
                type="search"
                placeholder="Navnesøk..."
                size="sm"
                aria-label="Search"
                className="rounded search-input ps-4"
                onChange={({ target }) => handleSearch(target.value)}
              />
              <FontAwesomeIcon
                icon="search"
                className="fs--1 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
              />
            </Form>
          </Col>
          <Col xs="auto" sm={3}>
            <Row className="g-0 justify-content-end">
              <Col
                xs="auto"
                className="position-md-static position-absolute top-0 end-0 me-3 mt-3 mt-md-0"
              >
                <Form as={Row} className="gx-2">
                  <Col xs="auto" className="d-none d-lg-block">
                    <small>Sorter:</small>
                  </Col>
                  <Col xs="auto">
                    <InputGroup size="sm">
                      {/*<Form.Select*/}
                      {/*  className="pe-5"*/}
                      {/*  defaultValue="price"*/}
                      {/*  style={{ maxWidth: '6.7rem' }}*/}
                      {/*  onChange={({ target }) =>*/}
                      {/*    handleSortByChange(target.value)*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*  <option value="price">Price</option>*/}
                      {/*  <option value="rating">Rating</option>*/}
                      {/*  <option value="review">Review</option>*/}
                      {/*</Form.Select>*/}
                      <InputGroup.Text
                        as={Button}
                        variant="link"
                        className="border border-300 text-700"
                        onClick={() => handleIsAscClick(!isAsc)}
                      >
                        <FontAwesomeIcon
                          icon={isAsc ? 'sort-amount-up' : 'sort-amount-down'}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

// CompaniesHeader.propTypes = {
//  setShowFilterOffcanvas: PropTypes.func,
  //layout: PropTypes.string,
/*
  isAsc: PropTypes.bool,
  setIsAsc: PropTypes.func,
  setShowFilterOffcanvas: PropTypes.func,
  searchedText: PropTypes.string,
  handleSearch: PropTypes.func
*/
// };

export default CompaniesHeader;
