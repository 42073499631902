import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AuthWizardProvider from 'falcon-react-lib/components/wizard/AuthWizardProvider';
import RegisterTempProfileWizardSteps from "./RegisterTempProfileWizardSteps";
import {useAuth0} from "@auth0/auth0-react";
import {config} from "../../../Constants";

const RegisterTempProfileWizard = ({ validation }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const [profile, setProfile] = useState({});
  const [events, setEvents] = useState([]);
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      // console.log(e);
    }
  }

  const fetchMyAvailabilityEvents = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/myProfile/availabilityEvents", {headers,})
      .then(async response => {
        if (response.status === 200) {
          let json = await response.json();
          setEvents(json);
        }
      });
  };

  const fetchMyProfile = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/myProfile", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        console.log(data,'  getMyProfile data success')
        setProfile(data);
      });
  };

  useEffect(() => {
    if(accessToken!=="") {
      fetchMyProfile();
      fetchMyAvailabilityEvents();
    }
  }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])

  return (
    <AuthWizardProvider>
      <RegisterTempProfileWizardSteps
        profile={profile}
        events={events}
        variant={'pills'}
        validation={validation}
        progressBar={true}
      />
    </AuthWizardProvider>
  );
};

RegisterTempProfileWizard.propTypes = {
  //variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool
  //progressBar: PropTypes.bool
};

export default RegisterTempProfileWizard;
