import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from '../../../falcon-react-lib/components/common/Avatar';
import {MyProfileContext} from "../../../context/Context";
import useUrlHelpers from "hooks/useUrlHelpers";
import useAuth from "../../../hooks/useAuth";
import SoftBadge from "../../../falcon-react-lib/components/common/SoftBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAuth0} from "@auth0/auth0-react";

const ProfileDropdown = () => {
  const {getAvatarUrl} = useUrlHelpers();
  const {logout} = useAuth0();
  const {isSuperAdmin, isCompanyAdmin} = useAuth();
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);
  const thumbnailSrc = myProfilePerson===undefined||myProfilePerson===null||myProfilePerson.thumbnail===undefined||myProfilePerson.thumbnail===null?null:myProfilePerson.thumbnail.image
  let avatarUrl = getAvatarUrl(thumbnailSrc, myProfilePerson?.firstName,myProfilePerson?.lastName)
  //console.log(isCompanyAdmin(),'  -> isCompanyAdmin() ProfileDropdown');
  //console.log(isSuperAdmin(),'  -> isSuperAdmin() ProfileDropdown');
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={avatarUrl} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {(isSuperAdmin() || isCompanyAdmin()) && (
            <Dropdown.ItemText>
              Dine roller:
            </Dropdown.ItemText>
          )}
          {isSuperAdmin() && (
            <Dropdown.Item as={Link} to="/company/mine">
              <SoftBadge pill bg="primary" className="text-uppercase">
                <FontAwesomeIcon
                  icon="hat-cowboy"
                  className="me-1"
                  ///transform="shrink-4"
                />
                <span className="">Superadmin</span>
              </SoftBadge>
            </Dropdown.Item>
          )}
          {isCompanyAdmin() && (
            <Dropdown.Item as={Link} to="/company/mine">
              <SoftBadge pill bg="primary" className="text-uppercase">
                <FontAwesomeIcon
                  icon="hat-cowboy"
                  className="me-1"
                  ///transform="shrink-4"
                />
                Selskapsadmin
              </SoftBadge>
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/invite">
            <FontAwesomeIcon
              icon="envelope-open-text"
              className="me-1"
              ///transform="shrink-4"
            />
            Inviter venner
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/profile">
            <FontAwesomeIcon
              icon="user"
              className="me-1"
              ///transform="shrink-4"
            />
            Vis min profil
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/profile/basic/edit">
            <FontAwesomeIcon
              icon="edit"
              className="me-1"
              ///transform="shrink-4"
            />
            Endre basisinfo
          </Dropdown.Item>
          {(!isCompanyAdmin() || isSuperAdmin()) && (
            <>
              <Dropdown.Item as={Link} to="/profile/temp/edit">
                <FontAwesomeIcon
                  icon="edit"
                  className="me-1"
                  ///transform="shrink-4"
                />
                Endre vikarinfo
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/profile/accessibility/edit">
                <FontAwesomeIcon
                  icon="edit"
                  className="me-1"
                  ///transform="shrink-4"
                />
                Endre tilgjengelighet
              </Dropdown.Item>
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item as={Link} to="/user/edit">
            <FontAwesomeIcon
              icon="user"
              className="me-1"
              ///transform="shrink-4"
            />
            Brukerkonto
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} as={Link}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
