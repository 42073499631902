import {config} from "../Constants";
import useCompanies from "../hooks/useCompanies";
import {useNavigate} from "react-router-dom";
import callApiMyProfile from "./callApiMyProfile";
import useAuth from "../hooks/useAuth";

const callApiCompanies = () => {
  const navigate = useNavigate();
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;
  const { handleLoadMyCompanies, handleLoadCompanies } = useCompanies();
  const { fetchMyProfile } = callApiMyProfile();


  const fetchMyCompanies = () => {
    //console.log('[callApiCompanies] fetchMyAssignments')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl + "/myCompanies", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        // console.log(data, ' [callApiCompanies] data load success')
        handleLoadMyCompanies(data);
      })
  }

  const fetchCompanies = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/company", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        handleLoadCompanies(data);
      });
  };

  const fetchCompanyWithInvitations = async (companyId, handleData) => {
    try {
      //console.log(companyId, ' [callApiCompanies] fetchCompany')
      getAccessToken()
        .then((accessToken) => {
          let headers = {"Content-Type": "application/json"};
          headers["Authorization"] = `Bearer ${accessToken}`;
          return fetch(apiUrl+"/company/"+companyId+"/withInvitations", {headers,})
        })
        .then(response => {
          return response.json()
        })
        .then(data => {
          handleData(data);
        });
    } catch (error) {
      console.error(error, ' [callApiCompanies] Failed to fetch company from api');
    }
  }

  const fetchCompany = async (companyId, handleData) => {
    try {
      //console.log(companyId, ' [callApiCompanies] fetchCompany')
      getAccessToken()
        .then((accessToken) => {
          let headers = {"Content-Type": "application/json"};
          headers["Authorization"] = `Bearer ${accessToken}`;
          return fetch(apiUrl+"/company/"+companyId, {headers,})
        })
        .then(response => {
          return response.json()
        })
        .then(data => {
          handleData(data);
        });
    } catch (error) {
      console.error(error, ' [callApiCompanies] Failed to fetch company from api');
    }
  };


  // const checkBeforeDeleteCompany = async (companyId, handleData) => {
  //   try {
  //     //console.log(companyId, ' [callApiCompanies] fetchCompany')
  //     getAccessToken()
  //       .then((accessToken) => {
  //         let headers = {"Content-Type": "application/json"};
  //         headers["Authorization"] = `Bearer ${accessToken}`;
  //         return fetch(apiUrl+"/company/"+companyId, {headers,})
  //       })
  //       .then(response => {
  //         return response.json()
  //       })
  //       .then(data => {
  //         handleData(data);
  //       });
  //   } catch (error) {
  //     console.error(error, ' [callApiCompanies] Failed to fetch company from api');
  //   }
  // };

  const deleteMyCompany = (companyIdToDelete) => {
    //console.log(companyIdToDelete, ' [callApiCompanies] deleteAssignment')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
        return fetch(apiUrl + "/company/" + companyIdToDelete, requestOptions)
      })
      .then(response => {
        if (response.status === 200) {
          //console.log('[callApiCompanies] deleteMyCompany success 200')
          fetchMyCompanies();
          fetchMyProfile();
        } else {
          // console.log('[callApiCompanies] deleteMyCompany ERROR')
        }
      });
  };

  const saveNewCompany = (formData) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/company", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        fetchMyProfile();
        //console.log('successfull save. data: ' + JSON.stringify(data));
        //console.log('successfull save. id: ' + data.id);
        navigate('/company/edit/'+data.id);
      });
  }

  const saveChangesToCompany = (companyId, formData, handleData) => {
    //console.log(formData, '--> [callApiCompanies] - Submitted data');
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/company/"+companyId, requestOptions)
      })
      .then(response => {
        if(response.status===200) {
          fetchCompany(companyId,handleData);
          fetchMyProfile();
          navigate('/company/'+companyId);
        }
      });
  };

  const fetchPersonsConnectedToCompany = (companyId, handleLoadPersonsConnectedToCompany) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/person/connectedToCompany/filter/"+companyId, {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('[fetchPersons] fetchPersonsConnectedToCompany: data ' + JSON.stringify(data));
        handleLoadPersonsConnectedToCompany(data);
      });
  }

  const searchPersonsByEmail = (emailSearchParam, handleLoadPersons) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/person/emailSearch/filter/"+emailSearchParam, {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('[fetchPersons] fetchPersonsConnectedToCompany: data ' + JSON.stringify(data));
        handleLoadPersons(data);
      });
  }

  return {
    fetchMyCompanies,
    fetchCompanies,
    fetchCompany,
    fetchCompanyWithInvitations,
    deleteMyCompany,
    saveNewCompany,
    saveChangesToCompany,
    fetchPersonsConnectedToCompany,
    searchPersonsByEmail
  };
};

export default callApiCompanies;