import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap';
import usePersons from 'hooks/usePersons';
import {PersonContext} from "../../../context/Context";

const PersonsHeader = ({ setShowFilterOffcanvas }) => {
  const {personsState: { searchedText, isAsc }} = useContext(PersonContext);
  const { handleSearch, handleIsAscClick } = usePersons();
  //console.log('[PersonsHeader] isAsc: ' + isAsc);
  return (
    <Card className="mb-3">
      <Card.Header className="position-relative">
        <h5 className="mb-0 mt-1">Alle personer</h5>
        {/*<Background image={corner6} className="d-none d-md-block bg-card" />*/}
      </Card.Header>
      <Card.Body className="pt-0 pt-md-3">
        <Row className="g-3 align-items-center">
          <Col xs={12} sm={9}>
            <Row>
              <Col className="d-xl-none" xs="auto">
                <Button
                  onClick={() => setShowFilterOffcanvas(true)}
                  className="position-relative p-0"
                  size="sm"
                  variant="link"
                >
                  <FontAwesomeIcon icon="filter" className="fs-0 text-700" /> Filter
                </Button>
              </Col>
              <Col>
                <Form className="position-relative">
                  <Form.Control
                    type="search"
                    placeholder="Navnesøk..."
                    size="sm"
                    aria-label="Search"
                    value={searchedText}
                    className="rounded search-input ps-4"
                    onChange={({ target }) => handleSearch(target.value)}
                  />
                  <FontAwesomeIcon
                    icon="search"
                    className="fs--1 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
                  />
                </Form>

              </Col>
            </Row>
          </Col>
          <Col xs="auto" sm={3}>
            <Row className="g-0 justify-content-end">
              <Col
                xs="auto"
                className="position-md-static position-absolute top-0 end-0 me-3 mt-3 mt-md-0"
              >
                <Form as={Row} className="gx-2">
                  <Col xs="auto" className="d-none d-lg-block">
                    <small>Sorter:</small>
                  </Col>
                  <Col xs="auto">
                    <InputGroup size="sm">
                      {/*<Form.Select*/}
                      {/*  className="pe-5"*/}
                      {/*  defaultValue="price"*/}
                      {/*  style={{ maxWidth: '6.7rem' }}*/}
                      {/*  onChange={({ target }) =>*/}
                      {/*    handleSortByChange(target.value)*/}
                      {/*  }*/}
                      {/*>*/}
                      {/*  <option value="price">Price</option>*/}
                      {/*  <option value="rating">Rating</option>*/}
                      {/*  <option value="review">Review</option>*/}
                      {/*</Form.Select>*/}
                      <InputGroup.Text
                        as={Button}
                        variant="link"
                        className="border border-300 text-700"
                        onClick={() => handleIsAscClick(!isAsc)}
                      >
                        <FontAwesomeIcon
                          icon={isAsc ? 'sort-amount-up' : 'sort-amount-down'}
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

PersonsHeader.propTypes = {
  //layout: PropTypes.string,
  isAsc: PropTypes.bool,
  setIsAsc: PropTypes.func,
  setShowFilterOffcanvas: PropTypes.func,
  searchedText: PropTypes.string,
  handleSearch: PropTypes.func
};

export default PersonsHeader;
