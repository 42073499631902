import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import createMarkup from 'falcon-react-lib/helpers/createMarkup';
import Avatar from 'falcon-react-lib/components/common/Avatar';
import useUrlHelpers from "../../hooks/useUrlHelpers";
import callApiNotifications from "../../api/callApiNotifications";

const Notification = ({
                        id,
                        avatar,
                        time,
                        className,
                        unread = false,
                        flush = false,
                        emoji,
                        children,
                        to
                      }) => {

  //console.log(to, '  --> Notification ');
  const {getAvatarUrl} = useUrlHelpers();
  const {updateNotificationReadFlag} = callApiNotifications();
  let avatarUrl = getAvatarUrl(avatar?.src, '', '')


  const markAsRead = () => {
    //e.preventDefault();
    // console.log(id,' [Notification] markAsRead. id');
    updateNotificationReadFlag(id);
  };

  return (
    <Link
      className={classNames(
        'notification',
        {'notification-unread': unread, 'notification-flush': flush},
        className
      )}
      onClick={markAsRead}
      to={to}
    >
      {avatar && (
        <div className="notification-avatar">
          <Avatar src={avatarUrl} className="me-3"/>
        </div>
      )}
      <div className="notification-body">
        <p className="mb-1" dangerouslySetInnerHTML={createMarkup(children)}/>
        <span className="notification-time">
        {emoji && (
          <span className="me-2" role="img" aria-label="Emoji">
            {emoji}
          </span>
        )}
          {time}
      </span>
      </div>
    </Link>
  )
};

Notification.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.shape(Avatar.propTypes),
  time: PropTypes.string.isRequired,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
};

//Notification.defaultProps = {unread: false, flush: false};

export default Notification;
