import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "falcon-react-lib/components/common/IconButton";

const ShareButtonOnFacebook = ({
                       url,
                      ...rest
                    }) => {
    return (
        <a
          href={'https://www.facebook.com/sharer/sharer.php?u='+url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton
            className="d-block w-100 mb-xl-0"
            variant="falcon-default"
            icon={['fab', 'facebook-square']}
            iconClassName="text-facebook me-2"
            transform="grow-2"
            {...rest}
          >
            Del på Facebook
          </IconButton>
        </a>
    )
};

ShareButtonOnFacebook.propTypes = {
  url: PropTypes.string
};

export default ShareButtonOnFacebook;