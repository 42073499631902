import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import cloudUpload from "falcon-react-lib/assets/img/icons/cloud-upload.svg";
import {Button, Card} from 'react-bootstrap';
import ErrorConfirmation from "components/confirmation/ErrorConfirmation";
import PropTypes from "prop-types";
import Flex from "falcon-react-lib/components/common/Flex";
import AttachedFile from "components/app/myProfile/editTempInfo/AttachedFile";
import useS3Upload from "hooks/useS3Upload";

const EditAssignmentFiles = ({ formData, setFormData }) => {
  const {uploadFilePrivateBucket} = useS3Upload();
  const [assignmentFiles, setAssignmentFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  //console.log(assignmentFiles, '  -> [EditProfileFiles] assignmentFiles')
  //console.log(formData, '  -> [EditProfileFiles] formData')


  // Handle the displaying of the error modal
  const showErrorModal = () => {
    setDisplayConfirmationModal(true);
  };
  // Hide the delete modal
  const hideErrorModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleFileUploadResponse = (fileInfo) => {
    // console.log(fileInfo, ' [AssignmentFiles] onDrop - fileInfo');
    if(fileInfo.status!==undefined) {
      setErrorMessage(fileInfo.message);
      showErrorModal();
    } else {
      setAssignmentFiles(
        [
          ...assignmentFiles,
          fileInfo
        ]
      );

      setFormData({
        ...formData,
        files: [
          ...formData.files,
          fileInfo
        ]
      });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ],
    onDrop: acceptedFiles => {
      // console.log(acceptedFiles, '  ---> onDrop')
      if(acceptedFiles && acceptedFiles.length > 0) {
        uploadFilePrivateBucket(acceptedFiles[0], handleFileUploadResponse);
      } else {
        setErrorMessage('Denne filtypen kan ikke lastes opp');
        showErrorModal();
      }
    }
  });

  const loadFromFormData = () => {
    if(formData.files!==undefined && formData.files!==null && formData.files!=='') {
      setAssignmentFiles(formData.files);
    }
  }

  const handleRemovedFile = (fileInfo) => {
    //console.log(fileInfo,' --> handleRemovedFile');
    var indexInPropFiles = assignmentFiles.indexOf(fileInfo);
    //console.log(indexInPropFiles,' --> indexInPropFiles');
    //console.log(assignmentFiles,' --> assignmentFiles');
    //console.log(assignmentFiles.filter(function(file) {return file!==fileInfo}),' --> assignmentFiles.filter(function(file) {return file!==fileInfo})');
    if (indexInPropFiles > -1) {
      setAssignmentFiles(
        assignmentFiles.filter(function(file) {return file!==fileInfo})
      );
    }


    var indexInFormData = formData.files.indexOf(fileInfo);
    //console.log(indexInFormData,' --> indexInFormData');
    //console.log(formData.files,' --> formData.files');
    if (indexInFormData > -1) {
      setFormData({
        ...formData,
        files: formData.files.filter(function(file) {return file!==fileInfo})
      });
    }
  }

  useEffect(() => {
    loadFromFormData();
  }, [formData]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0 d-inline-block">
            Endre filer
          </h5>
        </Card.Header>
        <Card.Body>
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps({ multiple: false })} />
            <div className="fs--1">
              <img src={cloudUpload} alt="" width={20} className="me-2" />
              <span className="d-none d-lg-inline">
                Dra filer hit
                <br />
                eller,{' '}
              </span>
              <Button variant="link" size="sm" className="p-0 fs--1">
                Hent
              </Button>
            </div>
            <div className="fs--1 mt-2">
              <span className="d-none d-lg-inline">
                (.pdf eller .doc/.docx)
              </span>
            </div>
          </div>
          {assignmentFiles && assignmentFiles.length > 0 && (
            <Flex direction="column" className="mt-3">
              {assignmentFiles.map((fileInfo,index) => (
                <AttachedFile
                  key={'attached-file-'+index}
                  index={index}
                  fileInfo={fileInfo}
                  handleRemovedFile={handleRemovedFile}
                />
              ))}
            </Flex>
          )}
        </Card.Body>
      </Card>
      <ErrorConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideErrorModal}
        message={errorMessage}
      />
    </>
  );
};

EditAssignmentFiles.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};


export default EditAssignmentFiles;