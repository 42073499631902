import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import callApiAssignments from "../../../api/callApiAssignments";
import {useAuth0} from "@auth0/auth0-react";
import NarrowAssignmentList from "./NarrowAssignmentList";

const NarrowAssignmentListForCompany = ({companyId, cardTitle}) => {
  const { isAuthenticated } = useAuth0();
  const {fetchAssignmentsForCompany} = callApiAssignments();
  const [assignments, setAssignments] = useState([]);
  const handleLoadAssignments = (data) => {
    setAssignments(data);
  };

  useEffect(() => {
    fetchAssignmentsForCompany(companyId,handleLoadAssignments);
  }, [isAuthenticated]);

  return (
    <NarrowAssignmentList assignments={assignments} cardTitle={cardTitle} />
  );
};

NarrowAssignmentListForCompany.propTypes = {
  companyId: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired
};


export default NarrowAssignmentListForCompany;
