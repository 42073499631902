import {useState} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {useContext} from "react";
import {AuthContext} from "../context/Context";


const useAuth = () => {
  const {authState: { superAdmin, companyAdmin }} = useContext(AuthContext);
  const {getAccessTokenSilently} = useAuth0();
  const [accessToken, setAccessToken] = useState("");

  const getRoleDisplayName = (roleName) => {
    if(roleName==='super-admin') {
      return "SUPERADMIN";
    }
    if(roleName==='company-admin') {
      return "SELSKAPSADMIN";
    } else {
      return "N/A";
    }
  }

  const getAccessToken = async () => {
    try {
      //console.log('[useAuthProvider] getAccessToken accessToken:' + accessToken);
      if(accessToken==="") {
        const token = await getAccessTokenSilently();
        //console.log('[useAuthProvider] getAccessToken token:' + token);
        setAccessToken(token);
        //console.log('[useAuthProvider] getAccessToken accessToken:' + token);
        return token;
      } else {
        return accessToken;
      }
    } catch (e) {
      console.log(e);
    }
  }

  const isSuperAdmin = () => {
    //console.log(superAdmin,'  superAdmin  -> isSuperAdmin')
    return superAdmin;
  }

  const isCompanyAdmin = () => {
    //console.log(companyAdmin,'  companyAdmin  -> isCompanyAdmin')
    return companyAdmin;
  }

  return {
    getRoleDisplayName,
    getAccessToken,
    isSuperAdmin,
    isCompanyAdmin
  };
};

export default useAuth;