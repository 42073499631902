import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import cloudUpload from "../../../../falcon-react-lib/assets/img/icons/cloud-upload.svg";
import {Button, Card} from 'react-bootstrap';
import ErrorConfirmation from "../../../confirmation/ErrorConfirmation";
import PropTypes from "prop-types";
import Flex from "../../../../falcon-react-lib/components/common/Flex";
import AttachedFile from "./AttachedFile";
import useS3Upload from "../../../../hooks/useS3Upload";

const EditProfileCV = ({ formData, setFormData }) => {
  const {uploadFilePrivateBucket} = useS3Upload();
  const [profileCVFile, setProfileVCFile] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  //console.log(profileCVFile, '  -> [EditProfileCV] profileCVFile')
  //console.log(formData, '  -> [EditProfileCV] formData')


  // Handle the displaying of the error modal
  const showErrorModal = () => {
    setDisplayConfirmationModal(true);
  };
  // Hide the delete modal
  const hideErrorModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleFileUploadResponse = (fileInfo) => {
    // console.log(fileInfo, ' [EditProfileCV] onDrop - fileInfo');
    if(fileInfo.status!==undefined) {
      setErrorMessage(fileInfo.message);
      showErrorModal();
    } else {
      setProfileVCFile(fileInfo);
      setFormData({
        ...formData,
        cvFile: fileInfo
      });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ],
    onDrop: acceptedFile => {
      // console.log(acceptedFile, '  ---> onDrop')
      if(acceptedFile && acceptedFile.length > 0) {
        uploadFilePrivateBucket(acceptedFile[0], handleFileUploadResponse);
      } else {
        setErrorMessage('Denne filtypen kan ikke lastes opp');
        showErrorModal();
      }
    }
  });


  const loadFromFormData = () => {
    if(formData.cvFile!==undefined && formData.cvFile!==null && formData.cvFile!=='') {
      setProfileVCFile(formData.cvFile);
    }
  }

  const handleRemovedFile = () => {
    setProfileVCFile({});
    setFormData({
      ...formData,
      cvFile: {}
    });
  }

  useEffect(() => {
    loadFromFormData();
  }, [formData]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0 d-inline-block">
            Endre CV
          </h5>
        </Card.Header>
        <Card.Body>
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps({ multiple: false })} />
            <div className="fs--1">
              <img src={cloudUpload} alt="" width={20} className="me-2" />
              <span className="d-none d-lg-inline">
                Dra CV fil hit
                <br />
                eller,{' '}
              </span>
              <Button variant="link" size="sm" className="p-0 fs--1">
                Hent
              </Button>
            </div>
            <div className="fs--1 mt-2">
              <span className="d-none d-lg-inline">
                (.pdf eller .doc/.docx)
              </span>
            </div>
          </div>
          {profileCVFile && profileCVFile!==null && profileCVFile.url && (
            <Flex direction="column" className="mt-3">
              <AttachedFile
                key={'attached-file-0'}
                index={0}
                fileInfo={profileCVFile}
                handleRemovedFile={handleRemovedFile}
              />
            </Flex>
          )}
        </Card.Body>
      </Card>
      <ErrorConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideErrorModal}
        message={errorMessage}
      />
    </>
  );
};

EditProfileCV.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};


export default EditProfileCV;