import {config} from "../Constants";
import Compressor from 'compressorjs';
import useAuth from "../hooks/useAuth";

const callApiFiles = () => {
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;
  const awsS3BucketPrivateUpload = config.url.AWS_S3_BUCKET_PRIVATE_UPLOAD;


  const retrievePresignedS3UploadUrl = async (bucketName,s3Key) => {
    // console.log(s3Key,' --> retrievePresignedS3UploadUrl');
    let formData = {
      method: 'PUT',
      bucket: bucketName,
      s3Key: s3Key
    }
    return getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return  fetch(apiUrl+"/files/s3PresignedUrl", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        // console.log(data,' --> retrievePresignedS3UploadUrl. data');
        return data.url;
      });
  }

  const retrieveS3Credentials = async () => {
    //console.log(fileName,' --> retrievePresignedS3UploadUrl');
    return getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return  fetch(apiUrl+"/files/s3Credentials", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        return data;
      });
  }

  const retrievePresignedS3GetUrl = async (s3Key) => {
    //console.log(s3Key,' --> retrievePresignedS3GetUrl');
    let formData = {
      method: 'GET',
      bucket: awsS3BucketPrivateUpload,
      s3Key: s3Key
    }
    return getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return  fetch(apiUrl+"/files/s3PresignedUrl", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log(data,' --> retrievePresignedS3GetUrl. data');
        return data.url;
      });
  }

  const compressAndUploadFile = async (file, handleFileUploadResponse) => {
    //console.log(file.size,' file size compressAndUploadFile');
    //console.log(file,' file compressAndUploadFile');
    if( (file.type==='image/jpeg' || file.type==='image/png') && file.size > 500000) {
      const qualityFactor = 500000 / file.size;
      //console.log(qualityFactor,' compressing with this qualityFactor. compressAndUploadFile');
      new Compressor(file, {
        quality: qualityFactor,
        success: (compressedResult) => {
          uploadFile(compressedResult, handleFileUploadResponse);
        },
      });

    } else {
      uploadFile(file, handleFileUploadResponse);
    }
  }

  const uploadFile = async (file, handleFileUploadResponse) => {
    //console.log(file.size,' file size uploadFile');
    let formData = new FormData();
    formData.append("file", file);

    getAccessToken()
      .then((accessToken) => {
        let headers = {"XXX": "YY"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: formData
        };
        //console.log('[callApiFiles] uploadFile. Url: ' + apiUrl + "/files");
        return fetch(apiUrl + "/files", requestOptions);
      })
      .then(async response => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 417) {
          let json = await response.json();
          return {
            status: response.status,
            statusText: response.statusText,
            message: json.message
          };
        } else {
          const data = {
            status: response.status,
              statusText: response.statusText,
            message: response.statusText
          }
          return data
        }
      })
      .then(data => {
        // console.log(data, '  --> fileupload ')
        handleFileUploadResponse(data);
      });
  }

  return {
    retrievePresignedS3UploadUrl,
    retrievePresignedS3GetUrl,
    retrieveS3Credentials,
    uploadFile,
    compressAndUploadFile
  };
};

export default callApiFiles;