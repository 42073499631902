import React, {useContext} from 'react';
import PropTypes from "prop-types";
import {CategoriesContext} from "../../../context/Context";
import {Alert, Form} from "react-bootstrap";

const RegisterTempIndustriesForm = ({ register, errors }) => {
  const {categoriesState: { industries }} = useContext(CategoriesContext);
  return (
    <>
      <div className="pb-3">
        <Alert variant="secondary">
          <div className="mb-3 fw-bold">
            Bransje
          </div>
          <span className="">Du ønsker å være synlig som vikar innen disse bransjene</span>
        </Alert>
      </div>
      <div className="ps-0">
        <Form.Group>
          <Form.Label className="fw-bold">Bransje *</Form.Label>
          {industries.map(industry => (
            <Form.Check className="pb-0 pt-0" type="checkbox" id={industry.id + Math.floor(Math.random() * 100)} key={industry.id}>
              <Form.Check.Input
                type="checkbox"
                {...register('chosenIndustries', {
                  required: 'Du må velge en av disse'
                })}
                value={industry.id}
                isInvalid={errors['chosenIndustries']}
                isValid={Object.keys(errors).length > 0 && !errors['chosenIndustries']}
              />
              <Form.Check.Label className="ms-2">{industry.name}</Form.Check.Label>
              <Form.Control.Feedback type="invalid" className="mt-0">
                {errors['chosenIndustries']?.message}
              </Form.Control.Feedback>
            </Form.Check>
          ))}
        </Form.Group>
      </div>
    </>
  );
};

RegisterTempIndustriesForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default RegisterTempIndustriesForm;
