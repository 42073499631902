import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import SubmitFormWarning from "../../../confirmation/SubmitFormWarning";
import CreateCompanyHeader from "./CreateCompanyHeader";
import CompanyInformationForm from "./CompanyInformationForm";
import SubmitCompany from "./SubmitCompany";
import CompanyLogoPhoto from "./CompanyLogoPhoto";
import callApiCompanies from "../../../../api/callApiCompanies";
import {useParams} from "react-router-dom";
import EditCompanyPersonsList from "./EditCompanyPersonsList";

const EditCompany = () => {
  //console.log('[EditCompany]');
  const { companyId } = useParams();

  const [displaySubmitFormWarningModal, setDisplaySubmitFormWarningModal] = useState(false);
  const [mandatoryMissingFields, setMandatoryMissingFields] = useState([]);
  const { fetchCompanyWithInvitations, saveChangesToCompany } = callApiCompanies();
  const defaultValues = {
    name: '',
    introduction: '',
    location: '',
    thumbnail: {},
    persons: [],
    invitations: []
  };
  const [formData, setFormData] = useState(defaultValues);

  const setFormDataUsingDefaults = (loadedData) => {
    let updatedDataWithDefaultValues = {
      ...defaultValues
    };

    // console.log('[EditCompany] Fetched data: ' +JSON.stringify(loadedData));
    if(loadedData.name) {
      updatedDataWithDefaultValues.name = loadedData.name;
    }
    if(loadedData.location) {
      updatedDataWithDefaultValues.location = loadedData.location;
    }
    if(loadedData.introduction) {
      updatedDataWithDefaultValues.introduction = loadedData.introduction;
    }
    if(loadedData.thumbnail) {
      updatedDataWithDefaultValues.thumbnail = loadedData.thumbnail;
    }
    if(loadedData.persons) {
      updatedDataWithDefaultValues.persons = loadedData.persons;
    }
    if(loadedData.invitations) {
      updatedDataWithDefaultValues.invitations = loadedData.invitations;
    }
    setFormData({
      ...formData,
      name: updatedDataWithDefaultValues.name,
      location: updatedDataWithDefaultValues.location,
      introduction: updatedDataWithDefaultValues.introduction,
      thumbnail: updatedDataWithDefaultValues.thumbnail,
      persons: updatedDataWithDefaultValues.persons,
      invitations: updatedDataWithDefaultValues.invitations
    });
  }

  useEffect(() => {
    fetchCompanyWithInvitations(companyId, setFormDataUsingDefaults);
  }, [companyId]);


  // Hide the submit form warning modal
  const hideSubmitFormWarningModal = () => {
    setDisplaySubmitFormWarningModal(false);
  };

  const missingMandatoryFieldsBeforeSave = () => {
    let missingMandatoryField = false;
    let mandatoryMissingFieldNames = [];
    //console.log(' missingMandatoryFieldsBeforeSave. formData ' + JSON.stringify(formData));
    if(formData.name === undefined || formData.name === "") {
      mandatoryMissingFieldNames.push("Tittel");
      missingMandatoryField = true;
    }
    if(formData.introduction === undefined || formData.introduction === "") {
      mandatoryMissingFieldNames.push("Beskrivelse");
      missingMandatoryField = true;
    }
    setMandatoryMissingFields(mandatoryMissingFieldNames);
    setDisplaySubmitFormWarningModal(missingMandatoryField);
    return missingMandatoryField;
  }

  const saveChanges = () => {
    //console.log(formData, '--> onSaveDraft - Submitted data');
    if(!missingMandatoryFieldsBeforeSave()) {
      saveChangesToCompany(companyId,formData,setFormDataUsingDefaults);
    }
  };

  return (
    <>
      <Form id="company-form">
        <Row className="g-lg-3 font-sans-serif">
          <Col lg={8}>
            <CreateCompanyHeader companyId={companyId} />
            <CompanyInformationForm
              formData={formData}
              setFormData={setFormData}
            />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar top-navbar-height d-flex flex-column">
              <SubmitCompany
                companyId={null}
                //formData={formData}
                saveChanges={saveChanges}
              />
              <CompanyLogoPhoto
                formData={formData}
                setFormData={setFormData}
              />
              <EditCompanyPersonsList
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </Col>
        </Row>
      </Form>
      <SubmitFormWarning
        showModal={displaySubmitFormWarningModal}
        hideModal={hideSubmitFormWarningModal}
        mandatoryMissingFields={mandatoryMissingFields}
      />
    </>
  );
};

export default EditCompany;
