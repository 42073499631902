import React, {useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import { MyAssignmentContext } from 'context/Context';
import { config } from 'Constants'
import {myAssignmentReducer} from "reducers/myAssignmentReducer";
import {useAuth0} from "@auth0/auth0-react";

const MyAssignmentProvider = ({ children }) => {
  const initData = {
    initMyAssignments: [],
    myAssignments: [],
    primaryMyAssignments: [],
    myAssignmentFilters: [],
    filterOptions: [],
    searchedText: null,
    isAsc: true
  };
  const [myAssignmentsState, myAssignmentsDispatch] = useReducer(myAssignmentReducer,initData);
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      // console.log(e);
    }
  }

  const fetchMyAssignmentFilters = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/myAssignments/filters", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        myAssignmentsDispatch({
          type: 'RELOAD_MY_ASSIGNMENT_FILTERS',
          payload: {
            myAssignmentFilters: data,
          }
        });
      })
  }

  const fetchMyAssignments = () => {
    //console.log('[MyAssignmentProvider] fetchMyAssignments')
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/myAssignments", {headers,})
      .then(async response => {
        if (response.status === 200) {
          let json = await response.json();
          myAssignmentsDispatch({
            type: 'RELOAD_MY_ASSIGNMENT',
            payload: {
              myAssignments: json
            }
          });
        }
      })
  }

  useEffect(() => {
    if(accessToken!=="") {
      fetchMyAssignmentFilters();
      fetchMyAssignments();
    }
  }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])
  
  return (
    <MyAssignmentContext.Provider
      value={{
        myAssignmentsState,
        myAssignmentsDispatch
      }}
    >
      {children}
    </MyAssignmentContext.Provider>
  );
};

MyAssignmentProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default MyAssignmentProvider;
