import React, {useEffect, useState} from 'react';
import {Card, Form} from 'react-bootstrap';
import PropTypes from "prop-types";
import SoftBadge from "../../../falcon-react-lib/components/common/SoftBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useAuth from "../../../hooks/useAuth";
import callApiAdmin from "../../../api/callApiAdmin";

const ProfileSsoInfo = ({personId}) => {
  //console.log('[ProfileSsoInfo] personId: ' + personId);
  const {getRoleDisplayName} = useAuth();
  const {fetchPersonSsoInfo} = callApiAdmin();
  const initSsoUser = {
    user_id: '',
    last_login: '',
    logins_count: ''
  }
  const [ssoUser, setSsoUser] = useState(initSsoUser);
  const initSsoRoles = []
  const [ssoRoles, setSsoRoles] = useState(initSsoRoles);
  //console.log('[ProfileSsoInfo] ssoUser: ' + JSON.stringify(ssoUser));
  //console.log('[ProfileSsoInfo] ssoRoles: ' + JSON.stringify(ssoRoles));

  const handleLoadData = (data) => {
    setSsoUser(data.ssoUser);
    setSsoRoles(data.ssoRoles);
  }

  useEffect(() => {
    if(personId) {
      fetchPersonSsoInfo(personId,handleLoadData);
    }
  }, [personId]);

  return (
    <Card className="mb-3" bg={'secondary'} text={'white'}>
      <Card.Header className={'bg-700'}>
        <h5 className="mb-0 text-white">Auth0 informasjon</h5>
        <h6 className="mb-0 text-white">(Bare synlig for superadmin)</h6>
      </Card.Header>
      <Card.Body>
        <Form.Group className="mb-3" controlId="user_id">
            <Form.Label>Brukerid</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              value={ssoUser?.user_id}
              name="user_id"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="last_login">
            <Form.Label>Siste innlogging</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              value={ssoUser?.last_login}
              name="last_login"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="user_id">
            <Form.Label>Antall innlogginger</Form.Label>
            <Form.Control type="text" size="sm" value={ssoUser?.logins_count} className={''} disabled={true}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="user_id">
            <Form.Label>Roller:</Form.Label>
            {!ssoRoles || ssoRoles.length===0 && (
              <div>- Ingen -</div>
            )}
            {ssoRoles && ssoRoles.length>0 && ssoRoles.map((role,index) => (
              <div key={index}>
                <SoftBadge pill bg="primary" className="text-uppercase">
                  <FontAwesomeIcon
                    icon="hat-cowboy"
                    className="me-1"
                  />
                  <span className="">{getRoleDisplayName(role.name)}</span>
                </SoftBadge>
              </div>
            ))}
          </Form.Group>
      </Card.Body>
    </Card>
  );
};

ProfileSsoInfo.propTypes = {
  personId: PropTypes.string
};


export default ProfileSsoInfo;
