import React, {useEffect, useState} from "react";
import Spinner from 'react-bootstrap/Spinner';
import {useAuth0} from "@auth0/auth0-react";
import {config} from "../../../Constants";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

const RegisterSwitch = ({ children }) => {

  const navigate = useNavigate();
  const [registerDone, setregisterDone] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  const postProfileData = (newProfileData) => {
    console.log('postProfileData: ' + JSON.stringify(newProfileData));
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(newProfileData)
    };
    fetch(apiUrl+"/myProfile", requestOptions)
      .then(response => {
        // console.log(response.status, 'Posted profile data. response code')
        if(response.ok) {
          navigate("/register/wizard",{ replace: true });
        } else {
          console.log(response,'Could not save profile data')
        }
      });
  }

  const fetchUserDetailsFromKeycloak = async () => {
    try {
      console.info('Loaded data from provider : ', user);
      let firstNameFromProvider = "";
      let lastNameFromProvider = "";
      let emailFromProvider = "";
      let emailVerifiedFromProvider = false
      if(user.given_name) {
        firstNameFromProvider = user.given_name;
      }
      if(user.family_name) {
        lastNameFromProvider = user.family_name;
      }
      if(!user.family_name && user.name && user.name.lastIndexOf('@')<0) {
        firstNameFromProvider = user.name.substring(0,user.name.indexOf(" "));
        lastNameFromProvider = user.name.substring(user.name.indexOf(" "),user.name.length);
      }
      if(user.email) {
        emailFromProvider = user.email;
      }
      if(user.email_verified) {
        emailVerifiedFromProvider = true;
      }

      const newProfileData = {
        firstName: firstNameFromProvider,
        lastName: lastNameFromProvider,
        email: emailFromProvider,
        emailVerified: emailVerifiedFromProvider
      }
      // console.log('fetchUserDetailsFromKeycloak: formData.firstName ' + newProfileData.firstName);
      postProfileData(newProfileData);
    } catch (error) {
      console.error('Failed to fetch user details', error);
    }
  };


  const checkProfileStateApi = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/myProfile", {headers,})
      .then(async response => {
        if (response.status === 204) {
          // console.log('checkProfileStateApi: no data returned ');
          fetchUserDetailsFromKeycloak();
        } else {
          let json = await response.json();
          //console.log(json, ' --> checkProfileStateApi: data returned. json: ');
          if(json.registrationDone) {
            setregisterDone(true)
          } else {
            navigate("/register/wizard",{ replace: true });
          }
        }
      });
  };


  useEffect(() => {
    if(accessToken!=="" && !registerDone ) {
      checkProfileStateApi();
    }
  }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])
  if(!registerDone) {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Venter...</span>
          </Spinner>
        </div>
      </>
    );
  } else {
    return children;
  }
};

RegisterSwitch.propTypes = {
  children: PropTypes.node.isRequired
};

export default RegisterSwitch;