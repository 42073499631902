import React from 'react';
import NavbarDropdown from 'falcon-react-lib/components/navbar/top/NavbarDropdown';
import {companyAdminRoutes, defaultRoutes, superAdminRoutes} from '../../../routes/siteMaps';
import NavbarDropdownApp from "./NavbarDropdownApp";
import useAuth from "../../../hooks/useAuth";

const NavbarTopDropDownMenus = () => {
  const { isSuperAdmin,isCompanyAdmin } = useAuth();

  let routes = defaultRoutes;
  if(isCompanyAdmin()) {
    routes = companyAdminRoutes;
  }
  if(isSuperAdmin()) {
    routes = superAdminRoutes;
  }

  return (
    <>
      <NavbarDropdown title="app">
        <NavbarDropdownApp items={routes} />
      </NavbarDropdown>
    </>
  );
};

export default NavbarTopDropDownMenus;
