import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from "prop-types";
import callApiChats from "../../../../api/callApiChats";

const ChatSidebarDropdownAction = ({thread, setShowRemovedThreads}) => {
  const {removeOrShowThreadByIndex} = callApiChats();

  const onClick = () => {
    console.log(thread.threadIndex,'  -> onClick')
    if(thread.markedAsDeleted) {
      removeOrShowThreadByIndex(thread.threadIndex, false, false);
    } else {
      removeOrShowThreadByIndex(thread.threadIndex, true, false);
    }
    setShowRemovedThreads(false);
  }

  return (
  <Dropdown className="dropdown-active-trigger dropdown-chat">
    <Dropdown.Toggle
      id="dropdown-button"
      className="text-400 dropdown-caret-none fs-0 z-index-1 "
      variant="link"
      size="sm"
    >
      <FontAwesomeIcon icon="cog" transform="shrink-3 down-4" />
    </Dropdown.Toggle>

    <Dropdown.Menu className="py-2">
      {/*<Dropdown.Item className="cursor-pointer">Mute</Dropdown.Item>*/}
      {/*<Dropdown.Divider />*/}
      {/*<Dropdown.Item className="cursor-pointer">Archive</Dropdown.Item>*/}
      <Dropdown.Item className="cursor-pointer text-danger" onClick={onClick}>
        {thread.markedAsDeleted ? 'Ikke fjern samtale' : 'Fjern samtale'}
      </Dropdown.Item>
      {/*<Dropdown.Divider />*/}
      {/*<Dropdown.Item className="cursor-pointer">Mark as Unread</Dropdown.Item>*/}
      {/*<Dropdown.Item className="cursor-pointer">*/}
      {/*  Something's Wrong*/}
      {/*</Dropdown.Item>*/}
      {/*<Dropdown.Item className="cursor-pointer">Ignore Messages</Dropdown.Item>*/}
      {/*<Dropdown.Item className="cursor-pointer">Block Messages</Dropdown.Item>*/}
    </Dropdown.Menu>
  </Dropdown>
)};

ChatSidebarDropdownAction.propTypes = {
  setShowRemovedThreads: PropTypes.func.isRequired,
  thread: PropTypes.object.isRequired
};

export default ChatSidebarDropdownAction;
