import React from 'react';
import {Card} from 'react-bootstrap';
import PropTypes from "prop-types";
import Flex from "../../../../falcon-react-lib/components/common/Flex";
import {Link} from "react-router-dom";
import Avatar from "../../../../falcon-react-lib/components/common/Avatar";
import useUrlHelpers from "hooks/useUrlHelpers";
import TooltipBadge from "../../../../falcon-react-lib/components/common/TooltipBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CompanyPersonsList = ({company}) => {
  const {getAvatarUrl} = useUrlHelpers();

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0">
            Personer
            <TooltipBadge
              tooltip="Administratorer"
              icon="question-circle"
            />
          </h5>
        </Card.Header>
        <Card.Body>
          {company.invitations && company.invitations.length > 0 && company.invitations.map((invitation,index) => (
            <div key={index}>
              {index>0 && (
                <div className="border-dashed border-bottom my-3"/>
              )}
              <Flex alignItems="center" className="mb-2" key={index}>
                <FontAwesomeIcon
                  icon="envelope"
                  className="fs-1 me-2 ms-2"
                  ///transform="shrink-4"
                />
                <div className="flex-1 align-self-center ms-2">
                  <p className="mb-1 lh-1">
                    {invitation.id !== undefined && (
                      <span>Invitasjon sendt til</span>
                    )}
                    {invitation.id === undefined && (
                      <span>Invitasjon vil bli sendt til</span>
                    )}
                  </p>
                  <p className="mb-0 lh-1">
                    <span className="fw-semi-bold">
                      {invitation?.email}
                    </span>
                  </p>
                </div>
              </Flex>
            </div>
          ))}

          {company.invitations && company.invitations.length > 0 && (
            <div className="border-dashed border-bottom my-3"/>
          )}

          {company.persons && company.persons.length > 0 && company.persons.map((person, index) => (
            <div key={index}>
              {index > 0 && (
                <div className="border-dashed border-bottom my-3"/>
              )}
              <Flex alignItems="center" className="mb-2" key={index}>
                <Link className="d-flex pe-2" to={'/profile/' + person?.id}>
                  <Avatar size="2xl" src={getAvatarUrl(person.avatarSrc, person.firstName, person.lastName)} className={''} />
                    </Link>
                <div className="flex-1 align-self-center ms-2">
                  <p className="mb-2 lh-1">
                    <Link className="fw-semi-bold" to={'/profile/' + person?.id}>
                      {person?.fullName}
                    </Link>
                  </p>
                  <p className="mb-0 fs--1"> {person?.email}</p>
                </div>
              </Flex>
            </div>
            ))}
        </Card.Body>
      </Card>
    </>
  );
};

CompanyPersonsList.propTypes = {
  company: PropTypes.object
};

export default CompanyPersonsList;
