import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';

const ApplyForm = ({ formData, setFormData }) => {
  return (
    <Card className="mb-3 mt-3">
      {/*<Card.Header as="h5" className="bg-light">Informasjon til oppdragsgiver</Card.Header>*/}
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col xs="12">
            <Form.Group className="mb-3" controlId="intro">
              <Form.Label>Melding til oppdragsgiver<span className="text-danger">*</span></Form.Label>
              <Form.Control
                as="textarea"
                rows={13}
                placeholder="Skriv en melding her"
                value={formData.message}
                name="introduction"
                onChange={e =>
                  setFormData({
                    ...formData,
                    message: e.target.value
                  })
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ApplyForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};
export default ApplyForm;
