import React, {useContext, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import EditProfileIntro from './EditProfileIntro';
import ProfileBanner from "../../profile/ProfileBanner";
import {MyProfileContext} from "../../../../context/Context";
import useUrlHelpers from "hooks/useUrlHelpers";
import EditProfileAvatar from "./EditProfileAvatar";
import callApiMyProfile from "../../../../api/callApiMyProfile";
import SubmitFormWarning from "../../../confirmation/SubmitFormWarning";
import SubmitProfile from "./SubmitProfile";

const MyBasicInfoProfileEdit = () => {
  const {getAvatarUrl} = useUrlHelpers();
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);
  const {saveMyBasicInfoProfile} = callApiMyProfile();
  const [displaySubmitFormWarningModal, setDisplaySubmitFormWarningModal] = useState(false);
  const [mandatoryMissingFields, setMandatoryMissingFields] = useState([]);
  //console.log(myProfilePerson, ' --> [MyProfileEdit] myProfilePerson')

  const getValuesFromMyProfile = (data) => {
    return {
      firstName: data.firstName===undefined?'':data.firstName,
      lastName: data.lastName===undefined?'':data.lastName,
      email: data.email===undefined?'':data.email,
      phone: data.phone===undefined?'':data.phone,
      professionalTitle: data.professionalTitle===undefined?'':data.professionalTitle,
      location: data.location===undefined?'':data.location,
      introduction: data.introduction===undefined?'':data.introduction,
      thumbnail: data.thumbnail===undefined?{}:data.thumbnail
    };
  }
  const defaultValues = getValuesFromMyProfile( myProfilePerson) ;
  const [formData, setFormData] = useState(defaultValues);
  //console.log(formData, ' --> [MyProfileEdit] formData')

  // Hide the submit form warning modal
  const hideSubmitFormWarningModal = () => {
    setDisplaySubmitFormWarningModal(false);
  };

  const missingMandatoryFieldsBeforeSave = () => {
    let missingMandatoryField = false;
    let mandatoryMissingFieldNames = [];
    //console.log(' missingMandatoryFieldsBeforeSave. formData ' + JSON.stringify(formData));
    if(formData.firstName === undefined || formData.firstName === "") {
      mandatoryMissingFieldNames.push("Fornavn");
      missingMandatoryField = true;
    }
    if(formData.lastName === undefined || formData.lastName === "") {
      mandatoryMissingFieldNames.push("Etternavn");
      missingMandatoryField = true;
    }
    if(formData.email === undefined || formData.email === "") {
      mandatoryMissingFieldNames.push("Epost");
      missingMandatoryField = true;
    }
    setMandatoryMissingFields(mandatoryMissingFieldNames);
    setDisplaySubmitFormWarningModal(missingMandatoryField);
    return missingMandatoryField;
  }

  const saveChanges = () => {
    //console.log(formData, '--> onSaveDraft - Submitted data');
    if(!missingMandatoryFieldsBeforeSave()) {
      saveMyBasicInfoProfile(formData);
    }
  };

  useEffect(() => {
    setFormData(getValuesFromMyProfile( myProfilePerson));
    //console.log(myProfilePerson, ' --> [MyProfileEdit] data reloaded ')
  }, [myProfilePerson])

  let avatarUrl = getAvatarUrl(myProfilePerson.thumbnail?.image, myProfilePerson.firstName,myProfilePerson.lastName)
  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={null}
          avatar={avatarUrl}
          className="mb-8"
        />
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={8}>
          <EditProfileIntro
            formData={formData}
            setFormData={setFormData}
          />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar top-navbar-height d-flex flex-column">
            <SubmitProfile
              saveChanges={saveChanges}
            />
            <EditProfileAvatar
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </Col>
      </Row>
      <SubmitFormWarning
        showModal={displaySubmitFormWarningModal}
        hideModal={hideSubmitFormWarningModal}
        mandatoryMissingFields={mandatoryMissingFields}
      />
    </>
  );
};

export default MyBasicInfoProfileEdit;
