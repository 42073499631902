import React, {useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import { TempContext } from 'context/Context';
import { tempReducer } from 'reducers/tempReducer';
import { config } from 'Constants'
import {useAuth0} from "@auth0/auth0-react";
import useAuth from "../../../hooks/useAuth";

const TempsProvider = ({ children }) => {
  const initData = {
    initTemps: [],
    temps: [],
    primaryTemps: [],
    competences: [],
    industries: [],
    regions: [],
    tempFilters: [],
    filterOptions: [],
    searchedText: null,
    searchedFromDate: null,
    searchedToDate: null,
    availabilityPercent: null,
    isAsc: true
  };
  const [tempsState, tempsDispatch] = useReducer(tempReducer, initData);
  const {getAccessTokenSilently} = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;
  const {isSuperAdmin,isCompanyAdmin} = useAuth();
  const loadCategoriesAndFilters = isSuperAdmin() || isCompanyAdmin();

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  
  const fetchTempFilters = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/temp/filters", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        tempsDispatch({
          type: 'RELOAD_TEMPS_FILTERS',
          payload: {
            tempFilters: data,
          }
        });
      })
  }

  const fetchCategories = async () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl + "/categories", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        tempsDispatch({
          type: 'RELOAD_CATEGORIES',
          payload: {
            categories: data,
          }
        });
      })
  }

  const fetchTemps = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/temp", {headers,})
      .then(response => {
          return response.json()
      })
      .then(data => {
          //console.log('[TempsProvider] fetchTemps: data ' + JSON.stringify(data));
          tempsDispatch({
              type: 'RELOAD_TEMPS',
              payload: {
                  temps: data
              }
          });
      })
  }

  useEffect(() => {
    if(accessToken!=="") {
      if(loadCategoriesAndFilters) {
        fetchTempFilters();
        fetchCategories();
      }
      fetchTemps();
    }
  }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])
  
  return (
    <TempContext.Provider
      value={{
        tempsState,
        tempsDispatch
      }}
    >
      {children}
    </TempContext.Provider>
  );
};

TempsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default TempsProvider;
