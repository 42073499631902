import React, {useReducer, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { ChatContext } from 'falcon-react-lib/context/Context';
//import groups from 'data/groups';
import { chatReducer } from 'reducers/chatReducer';
import {config} from "../../../Constants";
import {useAuth0} from "@auth0/auth0-react";
import useUrlHelpers from "../../../hooks/useUrlHelpers";

const ChatProvider = ({ children }) => {
  const initData = {
    threads: [],
    messages: [],
    users: [],
    assignments: [],
    groups: [],
    currentThread: null,
    textAreaInitialHeight: null,
    activeThreadId: null,
    isOpenThreadInfo: null,
    scrollToBottom: null,
    activeTabKey: 9999,
    activeForPersonId: null
  };
  const [chatState, chatDispatch] = useReducer(chatReducer, initData);
  const {getAccessTokenSilently} = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;
  const {getAvatarUrl} = useUrlHelpers();

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  const initCurrentThread = () => {
      chatDispatch({
        type: 'SET_CURRENT_THREAD_FIRST_TIME'
      });
  };

  const fetchChat = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/chat", {headers,})
      .then(async response => {
        if (response.ok) {
          let json = await response.json();
          // console.log(json,' ---> fetchChat')
          chatDispatch({
            type: 'RELOAD_CHAT',
            payload: {
              chat: json,
              nextFunction: initCurrentThread
            }
          });
        }
      });
  };


  const getUser = (users,assignments,groups,thread) => {
    //console.log(assignments, ' ->  getUser, assignments ');
    let user = {};
    if (thread.type === 'group') {
      //console.log(thread.groupId, ' ->  thread.groupId ');
      //console.log(groups, ' ->  groups ');
      const { name, members } = groups.find(({ id }) => id === thread.groupId);
      //console.log(name, ' ->  name ');
      //console.log(members, ' ->  members ');
      let membersUsers = members.filter(member => {return member.userId!==undefined && member.userId!==null});
      //console.log(membersUsers, ' ->  membersUsers ');
      let membersAssignments = members.filter(member => {return member.assignmentId!==undefined && member.assignmentId!==null});
      //console.log(membersAssignments, ' ->  membersAssignments ');
      let groupUsers = [];
      let avatarSrcUsers = membersUsers.map(
         member => {
           let user = users.find(({ id }) => (member.userId!==undefined && member.userId!==null && id === member.userId));
           groupUsers.push(user);
           return getAvatarUrl(user.avatarSrc,user.firstName,user.lastName);
         }
      )
      let groupAssignment = null;
      let avatarSrcAssignments = membersAssignments.map(
        member => {
          let assignment = assignments.find(({ id }) => (member.assignmentId!==undefined && member.assignmentId!==null && id === member.assignmentId));
          groupAssignment = assignment;
          return getAvatarUrl(assignment.companyAvatarSrc,assignment.companyName,null);
        }
      )
      user = {
        name,
        isGroup: true,
        //assignmentId: gropAssignment.id,
        //companyId: gropAssignment.companyId,
        //companyName: gropAssignment.companyName,
        avatarSrc: avatarSrcUsers.concat(avatarSrcAssignments),
        groupUsers: groupUsers,
        groupAssignment: groupAssignment
      };
      //console.log(user, ' ->  getUser: user ');
    } else {
      user = users.find(({ id }) => id === thread.userId);
    }
    return user;
  };

  useEffect(() => {
    if(accessToken!=="") {
      fetchChat();
    }
  }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])

  const value = {
    chatState,
    chatDispatch,
    getUser
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired };

export default ChatProvider;
