import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import Flex from 'falcon-react-lib/components/common/Flex';
import { ChatContext } from 'falcon-react-lib/context/Context';
import ChatContentHeaderDropdownAction from "./ChatContentHeaderDropdownAction";

const ChatContentHeader = ({ thread, setHideSidebar, setShowRemovedThreads }) => {
  const { getUser, chatState: { users,assignments,groups } } = useContext(ChatContext);
  const user = getUser(users,assignments,groups,thread);

  //console.log(user,' --> [ChatContentHeader] user')
  return (
    <div className="chat-content-header">
      <Row className="flex-between-center">
        <Col xs={6} md={8} as={Flex} alignItems="center" className="mt-2">
          <div
            className="pe-3 text-700 d-md-none contacts-list-show cursor-pointer"
            onClick={() => setHideSidebar(true)}
          >
            <FontAwesomeIcon icon="chevron-left" />
          </div>
          <div className="min-w-0">
            <h5 className="mb-0 text-truncate fs-0">
              {thread.type==='group' ? user.name : user.fullName}
            </h5>
          </div>
        </Col>
        <Col xs="auto">
          <ChatContentHeaderDropdownAction index={thread.index} setShowRemovedThreads={setShowRemovedThreads} thread={thread}/>
        </Col>
      </Row>
    </div>
  );
};

ChatContentHeader.propTypes = {
  thread: PropTypes.object.isRequired,
  setHideSidebar: PropTypes.func.isRequired,
  setShowRemovedThreads: PropTypes.func.isRequired
};

export default ChatContentHeader;
