import {config} from "../Constants";
import useFeed from "../hooks/useFeed";
import useAuth from "../hooks/useAuth";
import {useState} from "react";

const callApiFeed = () => {
  const {getAccessToken} = useAuth();
  const [doneReloadFeed, setDoneReloadFeed] = useState(true);
  const [doneReloadFeedEvents, setDoneReloadFeedEvents] = useState(true);
  const apiUrl = config.url.API_URL;
  const { handleLoadFeed, handleLoadFeedEvents, handleSetShowFeedLoadSpinner } = useFeed();

  const checkIfHideLoadFeedSpinner = () => {
    if(doneReloadFeed && doneReloadFeedEvents) {
      handleSetShowFeedLoadSpinner(false);
    }
  }

  const reloadAllDashboardFeeds = (isPullToRefreshEvent) => {
    //console.log(isPullToRefreshEvent, ' --> reloadAllDashboardFeeds. isPullToRefreshEvent')
    if(!isPullToRefreshEvent) {
      handleSetShowFeedLoadSpinner(true);
      setDoneReloadFeed(false);
      setDoneReloadFeedEvents(false);
    }

    fetchFeed();
    fetchFeedEvents()
  }

  const fetchFeed = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return  fetch(apiUrl+"/feed", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        handleLoadFeed(data);
        setDoneReloadFeed(true);
      })
      .then(() => checkIfHideLoadFeedSpinner());
  };

  const fetchFeedEvents = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return  fetch(apiUrl+"/feed/events", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        handleLoadFeedEvents(data);
        setDoneReloadFeedEvents(true);
      })
      .then(() => checkIfHideLoadFeedSpinner());
  };

  const saveNewFeedItem = (formData, afterSuccessfullSaveCallBack) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/feed", requestOptions)
      })
      .then(response => {
        if(response.status<300) {
          afterSuccessfullSaveCallBack();
          fetchFeed();
          fetchFeedEvents();
        } else {
          // console.log(response.statusText,' -> ')
        }
      });
  };

  const deleteFeedItem = (feedIdToDelete) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
        return fetch(apiUrl + "/feed/" + feedIdToDelete, requestOptions)
      })
      .then(response => {
        if (response.status === 200) {
          // console.log('[callApiFeed] deleteFeedItem success 200')
          fetchFeed();
          fetchFeedEvents();
        } else {
          console.log('[callApiFeed] deleteFeedItem ERROR')
        }
      });
  };

  const setFeedItemPinnedFlag = (feedId, pinned) => {
    let formData = {
      pinned: pinned
    }
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl + "/feed/" + feedId + "/pinned", requestOptions)
      })
      .then(response => {
        if (response.status === 200) {
          // console.log('[callApiFeed] deleteFeedItem success 200')
          fetchFeed();
          fetchFeedEvents();
        } else {
          console.log('[callApiFeed] setFeedItemPinnedFlag ERROR')
        }
      });
  };

  return {
    reloadAllDashboardFeeds,
    fetchFeed,
    fetchFeedEvents,
    saveNewFeedItem,
    deleteFeedItem,
    setFeedItemPinnedFlag
  };
};

export default callApiFeed;