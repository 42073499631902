
import {config} from "../Constants";
import useAuth from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import useChats from "../hooks/useChats";

const callApiChats = () => {
  const navigate = useNavigate();
  //const {handleThreadReadByIndex,handleNewMessage,handleReloadUsers,handleReloadChatThreads,handleReloadChatMessages,handleReloadAssignments,handleReloadGroups,handleInitCurrentThreadFirstTime, handleReloadChat, handleScrollToBottom} = useChats();
  const {handleThreadReadByIndex,handleNewMessage,handleInitCurrentThreadFirstTime, handleReloadChat} = useChats();
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;

  const fetchChat = (init, showRemovedThreads) => {
    //console.log(showRemovedThreads, '  -> showRemovedThreads [callApiChats] fetchChat.')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/chat?showRemovedThreads="+showRemovedThreads, {headers,})
      })
      .then(response => {
        if(response.status===204) {
          // console.log('[callApiChats] fetchChat. Nothing found. ')
          handleInitCurrentThreadFirstTime();
        }
        else if(response.status===200) {
          return response.json()
        }
      })
      .then(data => {
        // console.log('[callApiChats] fetchChat. data found.  ' + JSON.stringify(data))
        if(init) {
          handleReloadChat(data, handleInitCurrentThreadFirstTime);
        } else {
          handleReloadChat(data, null);
        }
      })
  };


  // const fetchThreads = () => {
  //   //console.log('[callApiChats] fetchThreads.')
  //   getAccessToken()
  //     .then((accessToken) => {
  //       let headers = {"Content-Type": "application/json"};
  //       headers["Authorization"] = `Bearer ${accessToken}`;
  //       return fetch(apiUrl+"/chat/thread", {headers,})
  //     })
  //     .then(response => {
  //       if(response.status===204) {
  //         // console.log('[callApiChats] fetchThreads. No threads found. ')
  //         handleInitCurrentThreadFirstTime();
  //       }
  //       else if(response.status===200) {
  //         return response.json()
  //       }
  //     })
  //     .then(data => {
  //       //console.log('[callApiChats] fetchThreads. data found.  ' + JSON.stringify(data))
  //       handleReloadChatThreads(data, handleInitCurrentThreadFirstTime);
  //     })
  // };
  //
  // const fetchMessages = () => {
  //   //console.log('[callApiChats] fetchMessages.')
  //   getAccessToken()
  //     .then((accessToken) => {
  //       let headers = {"Content-Type": "application/json"};
  //       headers["Authorization"] = `Bearer ${accessToken}`;
  //       return fetch(apiUrl+"/chat/message", {headers,})
  //     })
  //     .then(response => {
  //       if(response.status===204) {
  //         // console.log('[callApiChats] fetchMessages. No messages found. ')
  //         fetchThreads();
  //       }
  //       else if(response.status===200) {
  //         return response.json()
  //       }
  //     })
  //     .then(data => {
  //       //console.log('[callApiChats] fetchMessages. data found.  ' + JSON.stringify(data))
  //       handleReloadChatMessages(data,fetchThreads);
  //     });
  // };
  //
  // const fetchPersonsForChat = async () => {
  //   //console.log('[callApiChats] fetchPersonsForChat.')
  //   getAccessToken()
  //     .then((accessToken) => {
  //       let headers = {"Content-Type": "application/json"};
  //       headers["Authorization"] = `Bearer ${accessToken}`;
  //       return fetch(apiUrl + "/chat/person", {headers,})
  //     })
  //     .then(response => {
  //       if(response.status===204) {
  //         // console.log('[callApiChats] fetchPersonsForChat. No persons found. ')
  //         fetchAssignmentsForChat();
  //       }
  //       else if(response.status===200) {
  //         return response.json()
  //       }
  //     })
  //     .then(data => {
  //       //console.log('[callApiChats] fetchPersonsForChat. data found.  ' + JSON.stringify(data))
  //       handleReloadUsers(data,fetchAssignmentsForChat);
  //   });
  // };
  //
  //
  //
  // const fetchAssignmentsForChat = async () => {
  //   getAccessToken()
  //     .then((accessToken) => {
  //       let headers = {"Content-Type": "application/json"};
  //       headers["Authorization"] = `Bearer ${accessToken}`;
  //       return fetch(apiUrl + "/chat/assignments", {headers,})
  //     })
  //     .then(response => {
  //       if(response.status===204) {
  //         // console.log('[callApiChats] fetchAssignmentsForChat. No assignments found. ')
  //         fetchGroups();
  //       }
  //       else if(response.status===200) {
  //         return response.json()
  //       }
  //     })
  //     .then(data => {
  //       //console.log('[callApiChats] fetchPersonsForChat. data found.  ' + JSON.stringify(data))
  //       handleReloadAssignments(data,fetchGroups);
  //     });
  // };
  //
  //
  // const fetchGroups = async () => {
  //   getAccessToken()
  //     .then((accessToken) => {
  //       let headers = {"Content-Type": "application/json"};
  //       headers["Authorization"] = `Bearer ${accessToken}`;
  //       return fetch(apiUrl + "/chat/groups", {headers,})
  //     })
  //     .then(response => {
  //       if(response.status===204) {
  //         // console.log('[callApiChats] fetchGroups. No groups found. ')
  //         fetchMessages();
  //       }
  //       else if(response.status===200) {
  //         return response.json()
  //       }
  //     })
  //     .then(data => {
  //       //console.log('[callApiChats] fetchPersonsForChat. data found.  ' + JSON.stringify(data))
  //       handleReloadGroups(data,fetchMessages);
  //     });
  // };

  const createNewMessages = (personId) => {
    const body = {
      withPersonId: personId
    }
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        };
        return fetch(apiUrl+"/chat/initiate", requestOptions)
      })
      .then(response => {
        if(response.ok) {
          // console.log('[Banner] createNewMessages. successfull save');
          return response.json()
        } else {
          //console.log('Could not intiate a chat thread with user. ' + response);
          throw new Error("Network error");
        }
      })
      .then(data => {
        navigate('/profile/chat/'+data.id, { replace: true });
      });
  }

  const updateThreadReadFlag = (index, read) => {
    //console.log('[useChats] updateThreadReadFlag. index: ' + index);
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers
        };
        return fetch(apiUrl+"/chat/thread/"+index+"/readByIndex/"+read, requestOptions)
      })
      .then(response => {
        if(!response.ok) {
          // console.log('Could not update read flag on thread');
          throw new Error("Could not update read flag on thread");
        } else {
          handleThreadReadByIndex(index,read);
          //console.log('[useChats] updateThreadReadFlag successfull');
        }
      });
  };


  const removeOrShowThreadByIndex = (index, removeFlag, showRemovedThreads) => {
    //console.log('[useChats] updateThreadReadFlag. index: ' + index);
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers
        };
        return fetch(apiUrl+"/chat/thread/"+index+"/index/remove-or-show/"+removeFlag, requestOptions)
      })
      .then(response => {
        if(!response.ok) {
          // console.log('Could not update read flag on thread');
          throw new Error("Could not update read flag on thread");
        } else {
          fetchChat(true, showRemovedThreads);
        }
      });
  };

  const saveNewMessage = (messagesId,newMessage) => {
    //console.log('[useChats] saveNewMessage. messagesId: ' + messagesId+ ', newMessage: '+ JSON.stringify(newMessage));
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(newMessage)
        };
        return fetch(apiUrl+"/chat/message/"+messagesId, requestOptions)
      })
      .then(response => {
        if(!response.ok) {
          // console.log('Could not save a new chat message');
          throw new Error("Could not save a new chat message");
        } else {
          //console.log('[useChats] saveNewMessage successfull');
          handleNewMessage(messagesId,newMessage);
        }
      });
  };

  return {
    fetchChat,
    //fetchPersonsForChat,
    //fetchMessages,
    //fetchThreads,
    createNewMessages,
    updateThreadReadFlag,
    removeOrShowThreadByIndex,
    saveNewMessage
  };
};

export default callApiChats;

