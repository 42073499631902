import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'react-bootstrap';
import corner1 from 'falcon-react-lib/assets/img/illustrations/corner-1.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'falcon-react-lib/components/common/Background';

const TempStatusWarning = ({tempStatusVisibility}) => {
  return (
    <Card className={`overflow-hidden`}>
      <Background image={corner1} className="p-x1 bg-card"/>
      <Card.Body className="position-relative">
        {tempStatusVisibility?.isTemp===false && (
          <>
          <h5 className="text-warning">
            Du har ennå ikke registrert deg som vikar
          </h5>
          <p className="mb-0">
          Ønsker du å registrere deg som vikar og bli synlig og søkbar for oppdragsgivere? Det gjør du enklest ved å bruke vår vikarprofilbygger.
          </p>
          <Button
            as={Link}
            variant="link"
            className="text-warning mt-lg-2 ps-0"
            to="/temp/wizard"
          >
            Ta meg til vikarprofilbyggeren
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2"/>
          </Button>
          </>
        )}
        {tempStatusVisibility?.isTemp===true && tempStatusVisibility?.showTempAvailabilityWarning===false && (
          <h5 className="text-success">
            <FontAwesomeIcon icon={['fas', 'check-circle']} className="me-2 text-success"/>
            Din vikarprofil er søkbar og du er tilgjengelig for oppdrag
          </h5>
        )}
        {tempStatusVisibility?.isTemp===true && tempStatusVisibility?.showTempAvailabilityWarning===true && (
          <>
            <h5 className="text-warning">
              Du er ikke tilgjengelig for oppdrag
            </h5>
            <p className="mb-0">
              Du er synlig for oppdragsgivere men søker de på vikarer som er tilgjengelige vil du ikke bli vist i søkeresultatene.
            </p>
            <ul className="mt-4 list-unstyled">
              {tempStatusVisibility?.isAvailableNever===true && (
                <li>
                  <FontAwesomeIcon icon={['fas', 'calendar-alt']} className="me-2 ms-3"/>
                  Din tilgjengelighet er satt til 'Ikke tilgjengelig'
                </li>
              )}
              {tempStatusVisibility?.isAvailableUsingCalendar===true && tempStatusVisibility?.hasAvailabilityEvents===false && (
                <li>
                  <FontAwesomeIcon icon={['fas', 'calendar-alt']} className="me-2 ms-3"/>
                  Din tilgjengelighet er kalenderstyrt men du har ikke lagt inn noen tilgjengelige datoer
                </li>
              )}
              {tempStatusVisibility?.isAvailableUsingCalendar===true && tempStatusVisibility?.hasAvailabilityEvents===true && tempStatusVisibility?.hasAvailabilityEventsTodayOrInTheFuture===false && (
                <li>
                  <FontAwesomeIcon icon={['fas', 'calendar-alt']} className="me-2 ms-3"/>
                  Din tilgjengelighet er kalenderstyrt men du har ingen tilgjengelige datoer nå eller i fremtiden
                </li>
              )}
            </ul>
            <Button
              as={Link}
              variant="link"
              className="text-warning mt-lg-2 ps-0"
              to="/profile/accessibility/edit"
            >
              Sett tilgjengelighet
              <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2"/>
            </Button>
            <Button
              as={Link}
              variant="link"
              className="text-warning mt-lg-2 ps-0"
              to="/temp/wizard"
            >
              Jeg vil bruke vikarprofilbyggeren
              <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2"/>
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

TempStatusWarning.propTypes = {
  tempStatusVisibility: PropTypes.object.isRequired
};

export default TempStatusWarning;
