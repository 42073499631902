import React, {useContext, useEffect, useState} from 'react';
import {Card, Dropdown} from 'react-bootstrap';
import Flex from 'falcon-react-lib/components/common/Flex';
import Avatar from 'falcon-react-lib/components/common/Avatar';
import CreatePostForm from './CreatePostForm';
import {MyProfileContext} from "../../../../context/Context";
import useUrlHelpers from "hooks/useUrlHelpers";

const CreatePost = ({ ...rest }) => {
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);
  const {getAvatarUrl,getCompanyAvatarUrl} = useUrlHelpers();
  const [postAuthorId, setPostAuthorId] = useState('');
  //const [postAuthorId, setPostAuthorId] = useState(myProfilePerson.id);
  const [postAuthorIsACompany, setPostAuthorIsACompany] = useState(false);
  const [avatarSrcMap, setAvatarSrcMap] = useState(new Map());

  const calculateAvatarUrlMap = () => {
    let avatarMap = new Map();
    avatarMap.set(myProfilePerson.id,getAvatarUrl(myProfilePerson?.thumbnail?.image, myProfilePerson?.firstName,myProfilePerson?.lastName));
    myProfilePerson?.companies?.map((company)  => {
      avatarMap.set(company.id,getCompanyAvatarUrl(company));
    });
    setAvatarSrcMap(avatarMap)
  }

  useEffect(() => {
    calculateAvatarUrlMap();
  }, []);

  useEffect(() => {
    calculateAvatarUrlMap();
    setPostAuthorId(myProfilePerson.id);
  }, [myProfilePerson]);

  return (
    <Card {...rest}>
      <Card.Header className="bg-light">
        <Flex alignItems="center">
          <Avatar size="2xl" src={avatarSrcMap.get(postAuthorId)} />
          <div className="flex-1 ms-2">
            <h5 className="mb-0 fs-0">Nytt innlegg</h5>
          </div>

          {myProfilePerson.companies&&myProfilePerson.companies.length>0 && (
            <Dropdown className="d-inline-block me-1">
              <Dropdown.Toggle
                variant="none"
                size="sm"
                className="px-1 shadow-none"
              >
                {/*<FontAwesomeIcon icon="user-circle" className="fs-2 text-700" />*/}
                Bytt forfatter{' '}
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2" align="end">
                {postAuthorId!==myProfilePerson.id && (
                  <Dropdown.Item href="#!" key={99} onClick={() => {
                    setPostAuthorId(myProfilePerson.id);
                    setPostAuthorIsACompany(false);
                  }}>
                    <Flex alignItems="center">
                      <Avatar size="m" src={avatarSrcMap.get(myProfilePerson.id)} />
                      <div className="flex-1 ms-2">
                        {myProfilePerson.fullName}
                      </div>
                    </Flex>
                  </Dropdown.Item>
                )}
                {myProfilePerson.companies.map((company,index) => (
                  <div key={index}>
                    {postAuthorId!==company.id && (
                      <Dropdown.Item href="#!" key={index} onClick={() => {
                        setPostAuthorId(company.id);
                        setPostAuthorIsACompany(true);
                      }}>
                        <Flex alignItems="center">
                          <Avatar size="m" src={avatarSrcMap.get(company.id)} />
                          <div className="flex-1 ms-2">
                            {company.name}
                          </div>
                        </Flex>
                      </Dropdown.Item>
                    )}
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Flex>
      </Card.Header>
      <Card.Body className="p-0">
        <CreatePostForm postAuthorId={postAuthorId} postAuthorIsACompany={postAuthorIsACompany} />
      </Card.Body>
    </Card>
  );
};

export default CreatePost;
