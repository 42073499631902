import React, {useContext, useEffect, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import AssignmentCoverPhoto from '../edit-an-assignment/AssignmentCoverPhoto';
import AssignmentInformationForm from '../edit-an-assignment/AssignmentInformationForm';
import CreateAssignmentHeader from '../edit-an-assignment/CreateAssignmentHeader';
import PublishAssignment from '../edit-an-assignment/PublishAssignment';
import AssignmentDuration from "../edit-an-assignment/AssignmentDuration";
import {format, add} from 'date-fns'
import {CategoriesContext, MyProfileContext} from "../../../../context/Context";
import callApiAssignments from "../../../../api/callApiAssignments";
import AssignmentCategories from "../edit-an-assignment/AssignmentCategories";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SubmitFormWarning from "../../../confirmation/SubmitFormWarning";

const CreateAssignment = () => {
  //console.log('[CreateAssignment]');
  const datePattern = 'dd.MM.yyyy'
  const [displaySubmitFormWarningModal, setDisplaySubmitFormWarningModal] = useState(false);
  const [mandatoryMissingFields, setMandatoryMissingFields] = useState([]);
  const { saveNewAssignment } = callApiAssignments();
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);
  //console.log(myProfilePerson, ' --> [CreateAssignment] myProfilePerson');
  const {categoriesState: { regions, competences, industries, currentStudies }} = useContext(CategoriesContext);
  const defaultValues = {
    name: '',
    competences: [],
    industries: [],
    regions: [],
    currentStudies: [],
    description: '',
    company: myProfilePerson.companies?.at(0),
    thumbnail: {},
    startDate: format(add(new Date(),{days: 10}),datePattern),
    endDate: format(add(new Date(),{days: 20}),datePattern),
    publishedTimestamp: '',
    updatedTimestamp: '',
    owner: myProfilePerson,
    status: '',
    noOfMatchesNotified: 0
  };
  const [formData, setFormData] = useState(defaultValues);

  const setFormDataFromMyProfile = () => {
    setFormData({
      ...formData,
      company: myProfilePerson.companies?.at(0),
      owner: myProfilePerson
    });
  }

  useEffect(() => {
    setFormDataFromMyProfile();
  }, [myProfilePerson]);

  // Hide the submit form warning modal
  const hideSubmitFormWarningModal = () => {
    setDisplaySubmitFormWarningModal(false);
  };

  const missingMandatoryFieldsBeforeSave = () => {
    let missingMandatoryField = false;
    let mandatoryMissingFieldNames = [];
    //console.log(' missingMandatoryFieldsBeforeSave. formData ' + JSON.stringify(formData));
    if(formData.name === undefined || formData.name === "") {
      mandatoryMissingFieldNames.push("Tittel");
      missingMandatoryField = true;
    }
    if(formData.description === undefined || formData.description === "") {
      mandatoryMissingFieldNames.push("Beskrivelse");
      missingMandatoryField = true;
    }
    if(formData.startDate === undefined || formData.startDate === "") {
      mandatoryMissingFieldNames.push("Fra dato");
      missingMandatoryField = true;
    }
    setMandatoryMissingFields(mandatoryMissingFieldNames);
    setDisplaySubmitFormWarningModal(missingMandatoryField);
    return missingMandatoryField;
  }


  const saveChangesAndChangePublishedStatus = () => {
    //console.log(formData, '--> onSubmit - Submitted data');
    if(!missingMandatoryFieldsBeforeSave()) {
      formData.status = 'PUBLISHED';
      saveNewAssignment(formData);
    }
  };

  const saveChanges = () => {
    //console.log(formData, '--> onSaveDraft - Submitted data');
    if(!missingMandatoryFieldsBeforeSave()) {
      saveNewAssignment(formData);
    }
  };
  //console.log(myProfilePerson.companies, ' -> [CreateAssignment] myProfilePerson.companies')

  return (
    <>
      {myProfilePerson.companies===undefined||myProfilePerson.companies===null||myProfilePerson.companies.length===0 ? (
          <Card className="bg-transparent shadow-none">
            <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
              <div className="fs--1">
                <FontAwesomeIcon
                  icon="exclamation-triangle"
                  className="fs-6 mb-3"
                />
                <h5>Kan ikke registrere et nytt oppdrag</h5>
                <p className="mb-0">
                  Du må være tilknyttet et selskap for å kunne legge inn et oppdrag
                </p>
              </div>
            </Card.Body>
          </Card>
      ):(
        <Form id="assignment-form">
          <Row className="g-lg-3 font-sans-serif">
            <Col lg={8}>
              <CreateAssignmentHeader />
              <AssignmentInformationForm
                formData={formData}
                setFormData={setFormData}
              />
              <AssignmentDuration
                formData={formData}
                setFormData={setFormData}
              />
              <AssignmentCategories
                formData={formData}
                setFormData={setFormData}
                availableRegions={regions}
                availableCompetences={competences}
                availableIndustries={industries}
                availableCurrentStudies={currentStudies}
              />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar top-navbar-height d-flex flex-column">
                <PublishAssignment
                  assignmentId={null}
                  formData={formData}
                  saveChanges={saveChanges}
                  saveChangesAndChangePublishedStatus={saveChangesAndChangePublishedStatus}
                />
                <AssignmentCoverPhoto
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </Col>
          </Row>
        </Form>
        )}
      <SubmitFormWarning
        showModal={displaySubmitFormWarningModal}
        hideModal={hideSubmitFormWarningModal}
        mandatoryMissingFields={mandatoryMissingFields}
      />
    </>
  );
};

export default CreateAssignment;
