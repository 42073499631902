import React from "react";
import PersonsList from "./PersonsList";
import PersonsProvider from "./PersonsProvider";

const Persons = () => {
  return (
    <PersonsProvider>
      <PersonsList />
    </PersonsProvider>
  );
};

export default Persons;
