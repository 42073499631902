import React from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import TooltipBadge from "../../../../falcon-react-lib/components/common/TooltipBadge";
import PropTypes from 'prop-types';

const AssignmentCategories = ({ availableRegions, availableCompetences, availableIndustries, availableCurrentStudies, formData, setFormData }) => {
  // console.log(availableCurrentStudies, ' -> [AssignmentCategories] formData ')

  const handleChangeRegions = e => {
    setFormData({
      ...formData,
      regions: handleChangeCategories(availableRegions,formData.regions,e.target.name,e.target.checked)
    });
  };

  const handleChangeCompetence = e => {
    setFormData({
      ...formData,
      competences: handleChangeCategories(availableCompetences,formData.competences,e.target.name,e.target.checked)
    });
  };

  const handleChangeIndustries = e => {
    setFormData({
      ...formData,
      industries: handleChangeCategories(availableIndustries,formData.industries,e.target.name,e.target.checked)
    });
  };

  const handleChangeCurrentStudies = e => {
    setFormData({
      ...formData,
      currentStudies: handleChangeCategories(availableCurrentStudies,formData.currentStudies,e.target.name,e.target.checked)
    });
  };
  const handleChangeCategories = (categoriesList,storedCategoriesList,name,checked) => {
    let newList = storedCategoriesList;
    if(checked) {
      let singleElement = categoriesList.find(e => e.id === name);
      newList.push(singleElement);
    } else {
      let singleElement = newList.find(e => e.id === name);
      const index = newList.indexOf(singleElement);
      if (index > -1) {
        newList.splice(index, 1);
      }
    }
    return newList;
  };

  return (
    <Card className="mb-3 mb-lg-0">
      <Card.Header as="h5" className="bg-light">
        Kategorisering
      </Card.Header>
      <Card.Body>
        <Row className="g-lg-3 font-sans-serif">
          <Col sm={6}>
            <Row>
              <Col>
                <h6 className="fw-bold">
                  Profesjon/Utdannelse
                  <TooltipBadge
                    tooltip="Hvilken kompetanse ønsker du fra vikaren?"
                    icon="question-circle"
                  />
                </h6>

                <div className="ps-2">
                  {availableCompetences.map(competence => (
                    <Form.Check
                      type="checkbox"
                      key={competence.id}
                      id={competence.id}
                      label={competence.name}
                      className="form-label-nogutter"
                      name={competence.id}
                      onChange={handleChangeCompetence}
                      checked={formData.competences!==undefined&&formData.competences.map(c => c.id).includes(competence.id)}
                    />
                  ))}
                </div>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col>
                <h6 className="fw-bold">
                  Student
                  <TooltipBadge
                    tooltip="Du kan også angi at vikaren kan være student innenfor et kompetanseområde"
                    icon="question-circle"
                  />
                </h6>

                <div className="ps-2">
                  {availableCurrentStudies.map(currentStudy => (
                    <Form.Check
                      type="checkbox"
                      key={currentStudy.id}
                      id={currentStudy.id}
                      label={currentStudy.name}
                      className="form-label-nogutter"
                      name={currentStudy.id}
                      onChange={handleChangeCurrentStudies}
                      checked={formData.currentStudies!==undefined&&formData.currentStudies.map(c => c.id).includes(currentStudy.id)}
                    />
                  ))}
                </div>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col>
                <h6 className="fw-bold">
                  Bransje
                  <TooltipBadge
                    tooltip="Hvilken bransje omfatter oppdraget?"
                    icon="question-circle"
                  />
                </h6>

                <div className="ps-2">
                  {availableIndustries.map(industry => (
                    <Form.Check
                      type="checkbox"
                      key={industry.id}
                      id={industry.id}
                      label={industry.name}
                      className="form-label-nogutter"
                      name={industry.id}
                      onChange={handleChangeIndustries}
                      checked={formData.industries!==undefined&&formData.industries.map(c => c.id).includes(industry.id)}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <h6 className="fw-bold">
              Regioner
              <TooltipBadge
                tooltip="Hvilke geografiske regioner ønsker du å knytte oppdrag til?"
                icon="question-circle"
              />
            </h6>

            <div className="ps-2">
              {availableRegions.map(region => (
                <Form.Check
                  type="checkbox"
                  key={region.id}
                  id={region.id}
                  label={region.name}
                  className="form-label-nogutter"
                  name={region.id}
                  onChange={handleChangeRegions}
                  checked={formData.regions!==undefined&&formData.regions.map(c => c.id).includes(region.id)}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

AssignmentCategories.propTypes = {
  availableRegions: PropTypes.array,
  availableCompetences: PropTypes.array,
  availableIndustries: PropTypes.array,
  availableCurrentStudies: PropTypes.array,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};

export default AssignmentCategories;
