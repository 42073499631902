/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const companyReducer = (state, action) => {
  const { type, payload } = action;

  const searchAndFilterCompanies = function (isAsc, companies, searchedText) {
    //console.log('[companyReducer] searchAndFilterCompanies. isAsc ' + isAsc + ' searchedText '+ searchedText + ' options ' + JSON.stringify(options) + ' companies ' + companies.length)
    // Search text
    let searchedCompanies = companies;
    if(searchedText) {
      // console.log('searchedText ' + searchedText)
      searchedCompanies = searchedCompanies.filter(company =>
        company.name.toUpperCase().includes(searchedText.toUpperCase())
      );
    }

    // Sort companies
    let sortedCompanies = sortCompanies(isAsc,searchedCompanies)

    return sortedCompanies;
  };


  const sortCompanies = function (isAsc, companies) {
    //console.log('[companyReducer] sortCompanies. isAsc: ' + isAsc);
    let sortedCompanies = companies.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (isAsc) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
      }
      // names must be equal
      return 0;
    });
    // Force relaod of pagination reducer state
    sortedCompanies = sortedCompanies.map(e => ({...e, timestamp: new Date().valueOf()}));
    return sortedCompanies;
  }

  switch (type) {
    case 'RELOAD_MY_COMPANY':
      //console.log('RELOAD_MY_COMPANY ' + payload.myCompanies.length);
      return {
        ...state,
        myCompanies: payload.myCompanies
      };
    case 'RELOAD_COMPANY':
      //console.log('RELOAD_COMPANY ' + payload.companies.length);
      return {
        ...state,
        initCompanies: payload.companies,
        companies: payload.companies,
        primaryCompanies: payload.companies,
      };
    case 'SORT_COMPANY':
      //console.log('SORT_COMPANY order ' + payload.isAsc);
      return {
        ...state,
        isAsc: payload.isAsc,
        companies: searchAndFilterCompanies(payload.isAsc, state.primaryCompanies,state.searchedText)
      };
    case 'SEARCH_COMPANY':
      return {
        ...state,
        searchedText: payload.searchedText,
        companies: searchAndFilterCompanies(state.isAsc,state.primaryCompanies,payload.searchedText)
      };
    default:
      return state;
  }
};
