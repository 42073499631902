import React from 'react';
import PropTypes from 'prop-types';
import FeedCardHeader from './FeedCardHeader';
import { Card } from 'react-bootstrap';
import FeedCardContent from './FeedCardContent';
import FeedCardFooter from 'falcon-react-lib/components/app/social/feed/FeedCardFooter';

const FeedCard = ({ showDeleteModal, feed, ...rest }) => {
  const { id, user, content, details } = feed;
  //console.log(feed,' --> [FeedCard] feed');
  //console.log(user,' --> [FeedCard] user');
  return (
    <Card {...rest}>
      {!!user && <FeedCardHeader showDeleteModal={showDeleteModal} user={user} feedItemId={feed.id} feedItemName={feed.user.name} pinned={feed.pinned} />}
      {!!content && <FeedCardContent {...content} />}
      {!!details && <FeedCardFooter id={id} {...details} />}
    </Card>
  );
};

FeedCard.Header = FeedCardHeader;

FeedCard.propTypes = {
  showDeleteModal: PropTypes.func,
  feed: PropTypes.object
};

export default FeedCard;
