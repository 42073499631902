import React from "react";
import SoftBadge from "../../../falcon-react-lib/components/common/SoftBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import Flex from "../../../falcon-react-lib/components/common/Flex";

const TempCategoriesPills = ({temp}) => {
  return (
    <Flex wrap="wrap" className="gap-2 mb-3">
      {temp.yearsSeniority && (
        <SoftBadge key={7777} pill bg="primary">
          <FontAwesomeIcon
            icon="clock"
            className="me-1"
            transform="shrink-4"
          />
          {temp.yearsSeniority.name}
        </SoftBadge>
      )}
      {temp.interestedInRegions.map((region) => (
        <SoftBadge key={region.id} pill bg="primary">
          <FontAwesomeIcon
            icon="location-arrow"
            className="me-1"
            transform="shrink-4"
          />
          {region.name}
        </SoftBadge>
      ))}
      {temp.competences.map((competence) => (
        <SoftBadge key={competence.id} pill bg="primary">
          <FontAwesomeIcon
            icon="graduation-cap"
            className="me-1"
            transform="shrink-4"
          />
          {competence.name}
        </SoftBadge>
      ))}
      {temp.currentStudies.map((currentStudy) => (
        <SoftBadge key={currentStudy.id} pill bg="primary">
          <FontAwesomeIcon
            icon="graduation-cap"
            className="me-1"
            transform="shrink-4"
          />
          {currentStudy.name}
        </SoftBadge>
      ))}
      {temp.interestedInIndustries.map((industry) => (
        <SoftBadge key={industry.id} pill bg="primary">
          <FontAwesomeIcon
            icon="briefcase"
            className="me-1"
            transform="shrink-4"
          />
          {industry.name}
        </SoftBadge>
      ))}
      {temp.percentAvailability!==undefined && (
        <SoftBadge key={9999} pill bg="dark">
          <FontAwesomeIcon
            icon="calendar-alt"
            className="me-1"
            transform="shrink-4"
          />
          {temp.percentAvailability}%
        </SoftBadge>
      )}
      <SoftBadge key={8888} pill bg="dark">
        <FontAwesomeIcon
          icon="calendar-alt"
          className="me-1"
          transform="shrink-4"
        />
        {temp.availabilityStatus === 'CALENDAR' && (
          <span>Kalenderstyrt</span>
        )}
        {temp.availabilityStatus === 'NONE' && (
          <span>Ikke tilgjengelig akkurat nå</span>
        )}
        {temp.availabilityStatus === 'ALWAYS' && (
          <span>Alltid tilgjengelig</span>
        )}
      </SoftBadge>
    </Flex>
  );
};

TempCategoriesPills.propTypes = {
  temp: PropTypes.object.isRequired
};


export default TempCategoriesPills;
