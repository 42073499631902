import React, {useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import { FeedContext } from '../../../../falcon-react-lib/context/Context';
import { feedReducer } from 'reducers/feedReducer';
import {config} from "../../../../Constants";
import {useAuth0} from "@auth0/auth0-react";

const FeedProvider = ({ children }) => {
    const initData = {
        feedItems: [],
        feedEvents: [],
        tempsAvailableToday: [],
        assignmentsAvailableToday: [],
        showFeedLoadSpinner: false
    };

    const [feedState, feedDispatch] = useReducer(feedReducer, initData);
    const {getAccessTokenSilently} = useAuth0();
    const [accessToken, setAccessToken] = useState("");
    const apiUrl = config.url.API_URL;

    const getAccessToken = async () => {
        try {
            const token = await getAccessTokenSilently();
            setAccessToken(token);
        } catch (e) {
            console.log(e);
        }
    }

    const fetchFeed = () => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        fetch(apiUrl+"/feed", {headers,})
          .then(response => {
              return response.json()
          })
          .then(data => {
              feedDispatch({
                  type: 'RELOAD_FEED_ITEMS',
                  payload: {
                      feedItems: data
                  }
              });
          });
    };

    const fetchFeedEvents = () => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        fetch(apiUrl+"/feed/events", {headers,})
          .then(response => {
              return response.json()
          })
          .then(data => {
              // console.log(data, '  --> fetchFeedEvents success data');
              feedDispatch({
                  type: 'RELOAD_FEED_EVENTS',
                  payload: {
                      feedEvents: data
                  }
              });
          });
    };

    const fetchTempsAvailableToday = () => {
      let headers = {"Content-Type": "application/json"};
      headers["Authorization"] = `Bearer ${accessToken}`;
      fetch(apiUrl+"/temp/tempsAvailaibleToday/filter", {headers,})
          .then(response => {
              if (response.status === 200) {
                  return response.json()
              }
          })
          .then(data => {
              //console.log(data, '  --> fetchPersonsRegisteredAsTemps success data');
              feedDispatch({
                  type: 'RELOAD_TEMPS_AVAILABLE_TODAY',
                  payload: {
                      persons: data
                  }
              });
          });
    }

    const fetchAssignmentsAvailableToday = () => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        fetch(apiUrl+"/assignments/availableToday/filter", {headers,})
          .then(response => {
              if (response.status === 200) {
                  return response.json()
              }
          })
          .then(data => {
              //console.log(data, '  --> fetchAssignmentsAvailableToday success data');
              feedDispatch({
                  type: 'RELOAD_ASSIGNMENTS_AVAILABLE_TODAY',
                  payload: {
                      assignments: data
                  }
              });
          });
    }

    useEffect(() => {
        if(accessToken!=="") {
            fetchFeed();
            fetchFeedEvents();
            fetchTempsAvailableToday();
            fetchAssignmentsAvailableToday();
        }
    }, [accessToken])

    useEffect(() => {
        getAccessToken();
    }, [])

    const value = {
        feedState,
        feedDispatch
    };
    return (
        <FeedContext.Provider value={value}>
            {children}
        </FeedContext.Provider>
    );
};

FeedProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default FeedProvider;
