import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import classNames from 'classnames';
import { CompanyContext } from 'context/Context';
import usePagination from '../../../../falcon-react-lib/hooks/usePagination';
import CompanyListElement from '../CompanyListElement';
import CompaniesHeader from './CompaniesHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../../falcon-react-lib/components/common/Flex';
import callApiCompanies from "../../../../api/callApiCompanies";
import {useAuth0} from "@auth0/auth0-react";
import useAuth from "../../../../hooks/useAuth";
import DeleteConfirmation from "../../../confirmation/DeleteConfirmation";

const Companies = () => {
  const { isAuthenticated } = useAuth0();
  const {isSuperAdmin} = useAuth();
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [companiesPerPage, setCompaniesPerPage] = useState(6);
  const {companiesState: { companies }} = useContext(CompanyContext);
  const { fetchCompanies, deleteMyCompany } = callApiCompanies();
  // console.log(showFilterOffcanvas, '--> [Companies] showFilterOffcanvas ');
  const {
    paginationState: {
      data: paginatedCompanies,
      totalItems,
      itemsPerPage,
      currentPage,
      canNextPage,
      canPreviousPage,
      paginationArray
    },
    nextPage,
    prevPage,
    goToPage,
    setItemsPerPage
  } = usePagination(companies, companiesPerPage);


  useEffect(() => {
    fetchCompanies();
  }, [isAuthenticated]);


  // Handle the displaying of the delete modal
  const showDeleteModal = (id, name) => {
    setCompanyId(id);
    setCompanyName(name);
    setDisplayConfirmationModal(true);
  };

  // Hide the delete modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deleteAndHideModal = (companyIdToDelete) => {
    deleteMyCompany(companyIdToDelete);
    hideConfirmationModal();
  }

  return (
    <>
      <Row className="g-3">
        <Col>
          {/* Companies Header */}
          <CompaniesHeader />
          {/* Companies */}
          <Row className="mb-3 g-3">
            {paginatedCompanies.length > 0 ? (
              paginatedCompanies.map(company =>
                (
                  <Col key={company.id} md={6}>
                    <CompanyListElement company={company} isOwner={false} isSuperAdmin={isSuperAdmin()} deleteCompany={showDeleteModal} />
                  </Col>
                )
              )
            ) : (
              <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="fs-6 mb-3"
                    />
                    <h5>Fant ingen selskaper/avdelinger!</h5>
                    <p className="mb-0">
                      Søket ditt matchet ingen enheter.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Row>
          {/* Company pagination */}
          {paginatedCompanies.length > 0 && (
            <Card>
              <Card.Body>
                <Row className="g-3 flex-center justify-content-sm-between">
                  <Col xs="auto" as={Flex} alignItems="center">
                    <small className="d-none d-lg-block me-4">Totalt: {companies.length}</small>
                    <small className="d-none d-lg-block me-2">Per side:</small>
                    <Form.Select
                      size="sm"
                      value={itemsPerPage}
                      onChange={({ target }) => {
                        setItemsPerPage(target.value);
                        setCompaniesPerPage(target.value);
                      }}
                      style={{ maxWidth: '4.875rem' }}
                    >
                      <option value={2}>2</option>
                      <option value={4}>4</option>
                      <option value={6}>6</option>
                      <option value={totalItems}>Alle</option>
                    </Form.Select>
                  </Col>
                  <Col xs="auto" as={Flex}>
                    <div>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={{ position: 'fixed' }}>Prev</Tooltip>
                        }
                      >
                        <Button
                          variant="falcon-default"
                          size="sm"
                          disabled={!canPreviousPage}
                          onClick={prevPage}
                          className="me-2"
                          trigger="focus"
                        >
                          <FontAwesomeIcon icon="chevron-left" />
                        </Button>
                      </OverlayTrigger>
                    </div>

                    <ul className="pagination mb-0">
                      {paginationArray.map(page => (
                        <li
                          key={page}
                          className={classNames({
                            active: currentPage === page
                          })}
                        >
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="page me-2"
                            onClick={() => goToPage(page)}
                          >
                            {page}
                          </Button>
                        </li>
                      ))}
                    </ul>
                    <div>
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={
                          <Tooltip
                            style={{ position: 'fixed' }}
                            id="button-tooltip-2"
                          >
                            Next
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="falcon-default"
                          size="sm"
                          disabled={!canNextPage}
                          onClick={nextPage}
                          trigger="focus"
                        >
                          <FontAwesomeIcon icon="chevron-right" />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideConfirmationModal}
        confirmModal={deleteAndHideModal}
        id={companyId}
        name={companyName}
        message="Er du sikker på at vil slette selskapet/avdelingen"
      />
    </>
  );
};

export default Companies;
