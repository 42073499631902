import {config} from "../Constants";
import useAuth from "../hooks/useAuth";

const callApiRegistration = () => {
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;

  const fetchRegistrationForPerson = (personId, handleData) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/registration/"+personId+"/person", {headers,})
      })
      .then(response => {
        if(response.status===200) {
          return response.json();
        }
      })
      .then(data => {
        //console.log(data, '  -> fetchRegistration: data ');
        handleData(data);
      });
  };

  return {
    fetchRegistrationForPerson
  };
};

export default callApiRegistration;