import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import {AuthContext} from "../../context/Context";
import {authReducer} from "../../reducers/authReducer";

const AuthProvider = ({ children }) => {
  const initData = {
    companyAdmin: false,
    superAdmin: false,
    roles: []
  };
  const {getIdTokenClaims} = useAuth0();
  const [authState, authDispatch] = useReducer(authReducer, initData);

  const loadAuthState = async () => {
    const claims = await getIdTokenClaims();
    //console.log(claims,' claims  -> loadAuthState');
    const rolesValue = claims['https://localhost:8081/roles']
    //console.log(rolesValue,' rolesValue  -> loadAuthState');
    authDispatch({
      type: 'LOAD_ROLES',
      payload: {
        roles: rolesValue,
      }
    });
  }

  useEffect(() => {
    loadAuthState();
  }, [])

  return (
    <AuthContext.Provider
      value={{
        authState,
        authDispatch
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthProvider;