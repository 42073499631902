import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col, Image, Row} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import Flex from '../../../falcon-react-lib/components/common/Flex';
import useUrlHelpers from "../../../hooks/useUrlHelpers";
import useAssignments from "../../../hooks/useAssignments";
import AssignmentCategoriesPills from "./AssignmentCategoriesPills";

const AssignmentListElement = ({ assignment, isOwner, deleteAssignment }) => {
  //console.log(assignment,' -> [AssignmentListElement]')
  const {getAssignmentCoverPhoto} = useUrlHelpers();
  const {fixAssignmentDescriptionForListView} = useAssignments();
  const {
    thumbnail: { image },
    id,
    name,
    company,
    description,
  } = assignment;
  const navigate = useNavigate();
  const editAssignment = () => {
    navigate('/assignment/edit/'+assignment.id);
  }
  const deleteAssignmentProxy = () => {
    //console.log('[AssignmentListElement] deleteAssignmentProxy');
    deleteAssignment(assignment.id, assignment.name);
  }

  return (
    <>
      <Card className="overflow-hidden">
        <Card.Body className="p-0">
          <Row className="g-0">
            <Col md={4} lg={3}>
                  <Image src={getAssignmentCoverPhoto(image,company)} alt="" className="w-100 h-100 fit-cover" />
            </Col>
            <Col md={8} lg={9} className="p-x1">
              <Row className="g-0 h-100">
                <Col as={Flex} className="flex-column pe-x1">
                  <AssignmentCategoriesPills assignment={assignment} />

                  <h5 className="fs-0" >
                  <Link
                      to={`/assignment/assignment-details/${id}`}
                  >
                    {company?.name}
                  </Link>
                  </h5>
                  <h4 className="mt-3 mt-sm-0 fs-0 fs-lg-1">
                    <Link
                      to={`/assignment/assignment-details/${id}`}
                      className="text-900"
                    >
                      {name}
                    </Link>
                  </h4>
                  <p className="fs--1 mt-2">
                    {fixAssignmentDescriptionForListView(description,142)}...
                  </p>
                  {isOwner===true && (
                    <Row className="g-0">
                      <Col>
                        <Button
                          size="md"
                          variant="primary"
                          className="w-medium float-end" //px-xxl-5 px-4
                          onClick={editAssignment}
                        >
                          Endre
                        </Button>
                        <Button
                          variant="falcon-danger"
                          size="md"
                          className="w-medium float-end me-2"
                          onClick={deleteAssignmentProxy}
                        >
                          Slett
                        </Button>
                       </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

AssignmentListElement.propTypes = {
  assignment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    regions: PropTypes.array.isRequired,
    competences: PropTypes.array.isRequired,
    industries: PropTypes.array.isRequired,
    currentStudies: PropTypes.array.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    //companyName: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape({
      image: PropTypes.string,
      video: PropTypes.string,
      videoPoster: PropTypes.string
    }).isRequired,
    // tags: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    //     type: PropTypes.string,
    //     icon: PropTypes.string,
    //     content: PropTypes.string
    //   })
    // ),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // price: PropTypes.number,
    // totalEnrolled: PropTypes.number,
    // oldPrice: PropTypes.number,
    // rating: PropTypes.number,
    // review: PropTypes.number,
    description: PropTypes.string
  }),
  isOwner: PropTypes.bool.isRequired,
  deleteAssignment: PropTypes.func
};

export default AssignmentListElement;
