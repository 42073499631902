import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Flex from 'falcon-react-lib/components/common/Flex';
import useUrlHelpers from "hooks/useUrlHelpers";
import TempCategoriesPills from "../../temps/TempCategoriesPills";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SoftBadge from "../../../../falcon-react-lib/components/common/SoftBadge";
import Avatar from "../../../../falcon-react-lib/components/common/Avatar";

const FeedAvailability = ({availability}) => {
  // console.log('[FeedAvailability] assignment: ' + JSON.stringify(assignment));
  const {getAvatarUrl} = useUrlHelpers();
  let avatarUrl = getAvatarUrl(availability.temp.thumbnail?.image, availability.temp.firstName, availability.temp.lastName)
  return (
    <Card.Body className="p-0">

        <div className="feed-availability-box rounded-3 ">
          <Link
            to={`/profile/${availability.temp.id}`}
          >
            <Flex
              key={availability.temp.id}
              alignItems="center"
              justifyContent="center"
              className="ps-3 pt-3 pe-3 pb-2 bg-soft-primary"
            >
              <h6 className="mb-0">
                <FontAwesomeIcon
                  icon="user"
                  className={`fs--1 me-3 text-primary`}
                />
                Vikar ledig for oppdrag
              </h6>
            </Flex>
          </Link>
          <Flex
            key={availability.temp.id}
            alignItems="center"
            justifyContent="center"
            className="pb-3 ps-3 pe-3 bg-soft-primary flex-wrap"
          >
            {availability.temp.availabilityStatus === "CALENDAR" && (
              <>
                <h6 className="mb-0 me-0 me-sm-1">
                  Den første ledige datoen er:
                </h6>
                {availability.availabilityCalenderEvents.map((calendarEvent) => (
                  <SoftBadge key={calendarEvent.id} pill bg="dark" className="mt-2 mt-md-0 ms-md-3">
                    <FontAwesomeIcon
                      icon="calendar-alt"
                      className="me-1"
                      transform="shrink-4"
                    />
                    {calendarEvent.start} - {calendarEvent.end}
                  </SoftBadge>
                ))}
              </>
            )}

          </Flex>
          <Row className="mt-3 ms-1 mb-1">
            <Col className="">
              <TempCategoriesPills temp={availability.temp} />
            </Col>
          </Row>
          <Row className="ms-1 me-1 mb-1">
            <Col className="col-auto">
              <Flex wrap="no-wrap" direction="center" className="gap-3 mb-3">

                <Avatar size="4xl" src={avatarUrl} className="avatar-flex-size-4xl"/>
                <div>
                  <h5 className="fs-0" >
                    <Link
                      to={`/profile/${availability.temp.id}`}
                    >
                      {availability.temp.firstName} {availability.temp.lastName}
                    </Link>
                  </h5>
                  <p className="fs--1 mt-2">
                    {availability.temp.introduction?.substring(0, 142)}...
                  </p>
                </div>
              </Flex>
            </Col>
          </Row>
        </div>
    </Card.Body>
  );
}

FeedAvailability.propTypes = {
  availability: PropTypes.object.isRequired
};

export default FeedAvailability;
