import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Image, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'falcon-react-lib/components/common/Flex';
import Avatar from "../../../../falcon-react-lib/components/common/Avatar";
import useUrlHelpers from "hooks/useUrlHelpers";
import IconButton from "../../../../falcon-react-lib/components/common/IconButton";
import classNames from "classnames";
import callApiFavourites from "../../../../api/callApiFavourites";
import {FavouritesContext} from "../../../../context/Context";
import {v4 as uuidv4} from "uuid";
import {formatISO} from "date-fns";

const AssignmentBanner = ({ assignment }) => {
  const {favouritesState: { favouriteAssignments }} = useContext(FavouritesContext);
  const {getImageUrl,getCompanyAvatarUrl} = useUrlHelpers()
  const {addFavouriteAssignment,removeFavouriteAssignment} = callApiFavourites();
  const avatarUrl = getCompanyAvatarUrl(assignment.company)

  const getFavouriteItem = () => {
    if(!favouriteAssignments) {
      return null;
    }
    return favouriteAssignments.find((fav) => fav.assignmentId === assignment.id);
  }

  const isInFavouriteAssignments = () => {
    var check = getFavouriteItem();
    return check !== undefined;
  }

  const handleFavouriteClick  = (e) => {
    e.preventDefault();
    var element = getFavouriteItem();
    if(element !== undefined) {
      //console.log(element, ' -> removeFavouriteAssignment element');
      var index = favouriteAssignments.indexOf(element);
      favouriteAssignments.splice(index,1);
      removeFavouriteAssignment(element.id);
    } else {
      let newFavItem = {
        assignmentId: assignment.id,
        id: uuidv4(),
        note: null,
        registered: formatISO(new Date())
      }
      favouriteAssignments.push(newFavItem);
      //console.log('addFavouriteAssignment');
      addFavouriteAssignment(assignment.id);
    }
  }

  return (
    <Card className="overflow-hidden light mb-3">
      <Card.Body className="bg-dark">
        <Row>
          <Col xl={12} className="position-relative">
            <Row className="g-3 align-items-center">
              {assignment.thumbnail && assignment.thumbnail.image && (
                <Col lg={3}>
                  <div className="position-relative text-center">
                    <Image src={getImageUrl(assignment.thumbnail?.image)} rounded fluid />
                  </div>
                </Col>
              )}
              <Col lg={(assignment.thumbnail && assignment.thumbnail.image)?9:12}>
                <h6 className="fw-semi-bold text-400">
                  <Flex>
                    <Link className="d-flex" to={`/company/${assignment.company?.id}`}>
                      <Avatar size="xl" src={avatarUrl} className={status} />
                    </Link>
                    <div className="flex-1 align-self-center ms-2">
                      <Link to={`/company/${assignment.company?.id}`} className="link">
                        <h5 className="fw-bold text-white">{assignment.company?.name}</h5>
                      </Link>
                    </div>
                  </Flex>
                </h6>
                <h2 className="fw-bold text-white">{assignment.name}</h2>
              </Col>
            </Row>
            {/*<hr className="text-secondary text-opacity-50" />*/}
            <ul className="list-unstyled d-md-flex flex-wrap gap-3 fs--1 fw-semi-bold text-300 mt-3 mb-3">
              {assignment.regions!==undefined && assignment.regions.map((region) => (
                <li key={region.id}>
                  <FontAwesomeIcon
                    icon="location-arrow"
                    className="text-white me-1"
                  />
                  {region.name}
                </li>
              ))}
              {assignment.competences!==undefined && assignment.competences.map((competence) => (
                <li key={competence.id}>
                  <FontAwesomeIcon
                    icon="graduation-cap"
                    className="text-white me-1"
                  />
                  {competence.name}
                </li>
              ))}
              {assignment.currentStudies!==undefined && assignment.currentStudies.map((currentStudy) => (
                <li key={currentStudy.id}>
                  <FontAwesomeIcon
                    icon="graduation-cap"
                    className="text-white me-1"
                  />
                  {currentStudy.name}
                </li>
              ))}
              {assignment.industries!==undefined && assignment.industries.map((industry) => (
                <li key={industry.id}>
                  <FontAwesomeIcon
                    icon="briefcase"
                    className="text-white me-1"
                  />
                  {industry.name}
                </li>
              ))}
            </ul>
            {/*<hr className="text-secondary text-opacity-50" />*/}
            <Row className="g-3 align-items-center">
              <Col>
                <span className="text-white">Oppdragets varighet:</span> <h5 className="fw-bold text-white">{assignment.startDate} - {assignment.endDate}</h5>
              </Col>
              <Col sm="auto" className="">
                    <IconButton
                      size="sm"
                      variant={"outline-light"}
                      className={classNames('float-end d-lg-block me-0 mt-3 w-lg-100', {
                        'border-300': !isInFavouriteAssignments()
                      })}
                      icon={isInFavouriteAssignments() ? 'heart' : ['far', 'heart']}
                      onClick={handleFavouriteClick}
                    >
                      {isInFavouriteAssignments() ? 'Fjern fra favoritter' : 'Legg til som favoritt'}
                    </IconButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

AssignmentBanner.propTypes = {
  assignment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    regions: PropTypes.array.isRequired,
    competences: PropTypes.array.isRequired,
    industries: PropTypes.array.isRequired,
    currentStudies: PropTypes.array.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
        image: PropTypes.string,
        video: PropTypes.string,
        videoPoster: PropTypes.string
      }),
    }).isRequired,
    thumbnail: PropTypes.shape({
      image: PropTypes.string,
      video: PropTypes.string,
      videoPoster: PropTypes.string
    }),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    discription: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    publishedTimestamp: PropTypes.string
  })
};

export default AssignmentBanner;
