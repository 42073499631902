import React, {useEffect, useReducer, useState} from "react";
import {categoriesReducer} from "../../reducers/categoriesReducer";
import {config} from "../../Constants";
import {CategoriesContext} from "../../context/Context";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

const CategoriesProvider = ({ children }) => {
  const initData = {
    competences: [],
    industries: [],
    regions: [],
    currentStudies: [],
    yearsSeniorityList: []
  };
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const [categoriesState, categoriesDispatch] = useReducer(categoriesReducer, initData);

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  const apiUrl = config.url.API_URL;

  const fetchCategories = async () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl + "/categories", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        categoriesDispatch({
          type: 'RELOAD_CATEGORIES',
          payload: {
            categories: data,
          }
        });
      })
  }

  useEffect(() => {
    getAccessToken();
  }, [])

  useEffect(() => {
    if(accessToken!=="") {
      fetchCategories();
    }
  }, [accessToken])

  return (
    <CategoriesContext.Provider
      value={{
        categoriesState,
        categoriesDispatch
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

CategoriesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CategoriesProvider;