import { useContext } from 'react';
import { TempContext } from '../context/Context';

const useTemps = () => {
  const {tempsDispatch: tempsDispatch} = useContext(TempContext);

  const handleLoadTemps = (data) => {
    //console.log(companies,' ->[useCompanies] handleLoadCompanies')
    tempsDispatch({
      type: 'RELOAD_TEMPS',
      payload: {
        temps: data
      }
    });
  };

  const handleLoadTempsAndFilter = (data) => {
    //console.log(companies,' ->[useCompanies] handleLoadCompanies')
    tempsDispatch({
      type: 'RELOAD_TEMPS_AND_FILTER',
      payload: {
        temps: data
      }
    });
  };

  const handleSearchOnDates = (searchedFromDate, searchedToDate) => {
    tempsDispatch({
      type: 'SEARCH_TEMPS_ON_DATES',
      payload: {
        searchedFromDate,
        searchedToDate
      }
    });
  };

  const handleSearchOnAvailabilityPercent = (availabilityPercent) => {
    tempsDispatch({
      type: 'SEARCH_TEMPS_ON_AVAIL_PERCENT',
      payload: {
        availabilityPercent
      }
    });
  };


  const handleFilter = (id,type,name,value,checked) => {
    //console.log('[useTemps] handleFilter id ' + id + ' type ' + type + ' name ' + name + ' value' + value );
    tempsDispatch({
      type: 'FILTER_TEMPS',
      payload: {
        id: id,
        type: type,
        name: name,
        value: value,
        checked: checked
      }
    });
  };

  const emptyFilter = () => {
    tempsDispatch({
      type: 'EMPTY_FILTER'
    });
  };

  const handleSearch = searchedText => {
    tempsDispatch({
      type: 'SEARCH_TEMPS',
      payload: {
        searchedText
      }
    });
  };

  const handleIsAscClick = isAsc => {
    tempsDispatch({
      type: 'SORT_TEMPS',
      payload: {
        isAsc: isAsc
      }
    });
  };

  return {
    handleLoadTemps,
    handleLoadTempsAndFilter,
    handleSearchOnDates,
    handleSearchOnAvailabilityPercent,
    handleFilter,
    emptyFilter,
    handleSearch,
    handleIsAscClick
  };
};

export default useTemps;
