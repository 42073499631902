import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Card, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import AppContext from "../../../falcon-react-lib/context/Context";
import DropdownFilter from "../../../falcon-react-lib/components/common/DropdownFilter";
import FullCalendar from "@fullcalendar/react";
import noLocale from '@fullcalendar/core/locales/nb';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import multiMonthPlugin from "@fullcalendar/multimonth";
import ForwardToEditAvailabilityModal from "./ForwardToEditAvailabilityModal";

const ProfileAvailabilityCalendar = ({isMyProfile, events}) => {
  const {config: { isRTL }} = useContext(AppContext);
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState('Flermånedsvisning');
  const [isOpenModal, setIsOpenModal] = useState(false);

  //const [initialEvents,setInitialEvents] = useState(myAvailabilityEvents);
  const viewName = [
    'Flermånedsvisning',
    'Månedsvisning',
    'Ukesvisning',
    'Dagsvisning',
    'Listevisning',
    'Årsvisning'
  ];

  const handleEventClick = () => {
    if(isMyProfile) {
      setIsOpenModal(true);
    }
  };

  const handleSelectEvent = () => {
    if(isMyProfile) {
      setIsOpenModal(true);
    }
  }


  const handleFilter = filter => {
    setCurrentFilter(filter);
    switch (filter) {
      case 'Flermånedsvisning':
        calendarApi.changeView('multiMonthYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Månedsvisning':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Ukesvisning':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Dagsvisning':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Listevisning':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
  }, []);

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0">Tilgjengelighetskalender</h5>
        </Card.Header>
        <Card.Body className="">
          <Row className="align-items-center mb-3">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                    Forrige
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="previousTooltip">
                    Neste
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                I dag
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>


          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            locale={noLocale}
            firstDay={1}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
              multiMonthPlugin
            ]}
            initialView="multiMonthYear"
            //themeSystem="bootstrap5"
            //multiMonthMaxColumns={1}
            themeSystem="bootstrap5"
            dayMaxEvents={2}
            dayMaxEventRows={true}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            editable
            selectable
            stickyHeaderDates={false}
            displayEventTime={false}
            select={handleSelectEvent}
            eventClick={handleEventClick}
            events={events}
          />
          {isMyProfile && (
            <div className="mt-2">For å endre tilgjengelighetskalenderen gå <Link className="text-decoration-underline" to={'/profile/accessibility/edit'}>hit</Link></div>
          )}
        </Card.Body>
      </Card>
      <ForwardToEditAvailabilityModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  );
};

ProfileAvailabilityCalendar.propTypes = {
  events: PropTypes.array,
  isMyProfile: PropTypes.bool.isRequired
};


export default ProfileAvailabilityCalendar;
