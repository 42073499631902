import {useContext} from "react";
import {FeedContext} from "../falcon-react-lib/context/Context";


const useFeed = () => {
  const {feedDispatch: feedDispatch,} = useContext(FeedContext);


  const handleLoadFeed = (items) => {
    //console.log(assignments,' ->[useAssignments] handleLoadAssignments')
    feedDispatch({
      type: 'RELOAD_FEED_ITEMS',
      payload: {
        feedItems: items
      }
    });
  };

  const handleLoadFeedEvents = (events) => {
    //console.log(assignments,' ->[useAssignments] handleLoadAssignments')
    feedDispatch({
      type: 'RELOAD_FEED_EVENTS',
      payload: {
        feedEvents: events
      }
    });
  };

  const handleLoadTempsAvailableToday = (data) => {
    //console.log(companies,' ->[useCompanies] handleLoadCompanies')
    feedDispatch({
      type: 'RELOAD_TEMPS_AVAILABLE_TODAY',
      payload: {
        persons: data
      }
    });
  };

  const handleLoadAssignmentsAvailableToday = (data) => {
    //console.log(companies,' ->[useCompanies] handleLoadCompanies')
    feedDispatch({
      type: 'RELOAD_ASSIGNMENTS_AVAILABLE_TODAY',
      payload: {
        assignments: data
      }
    });
  };

  const handleSetShowFeedLoadSpinner = (showSpinner) => {
    //console.log(assignments,' ->[useAssignments] handleLoadAssignments')
    feedDispatch({
      type: 'SET_SHOW_FEED_LOAD_SPINNER',
      payload: {
        showSpinner: showSpinner
      }
    });
  };

  return {
    handleLoadFeed,
    handleLoadFeedEvents,
    handleLoadTempsAvailableToday,
    handleLoadAssignmentsAvailableToday,
    handleSetShowFeedLoadSpinner
  };
};

export default useFeed;