import React, {useState} from 'react';
import WizardInput from "falcon-react-lib/components/wizard/WizardInput";
import PropTypes from "prop-types";
import {Alert, Button, Col, Row} from "react-bootstrap";
import AiWizardIntroductionForm from "./AiWizardIntroductionForm";

const RegisterTempProfileForm = ({ register, getValues, setValue, errors }) => {
  // console.log(user,' --> RegisterTempProfileForm user');
  const [displayAiIntroModal, setDisplayAiIntroModal] = useState(false);

  const hideAiIntroModal = () => {
    setDisplayAiIntroModal(false);
  };

  const openAiIntroModal = () => {
    setDisplayAiIntroModal(true);
  }

  return (
    <>
      <div className="pb-3">
        <Alert variant="secondary">
          <div className="mb-3 fw-bold">
            Profilinformasjon
          </div>
          <p className="">Disse opplysningene er ikke nødvendig for at en oppdragsgiver skal finne vikarprofilen din, men gjør at oppdragsgiver blir litt bedre kjent med deg.</p>
        </Alert>
      </div>

      <WizardInput
        label="Bosted"
        name="location"
        placeholder="Bosted - Feks: 'Oslo"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('location')
        }}
      />
      {/*<WizardInput*/}
      {/*  label="Tittel"*/}
      {/*  name="professionalTitle"*/}
      {/*  placeholder="Tittel - Feks: 'Sosionom og Gründer'"*/}
      {/*  errors={errors}*/}
      {/*  formGroupProps={{ className: 'mb-3' }}*/}
      {/*  formControlProps={{*/}
      {/*    ...register('professionalTitle')*/}
      {/*  }}*/}
      {/*/>*/}

      <Row className="flex-row-reverse">
        <Col xs="auto">
          <Button
            size="md"
            variant="dark"
            className="fw-medium mt-3"
            onClick={openAiIntroModal}
          >
            <span>Bruk AI for å fylle ut "om meg"</span>
          </Button>
        </Col>
      </Row>

      <WizardInput
        type="textarea"
        label="Mer om deg"
        name="introduction"
        placeholder="Introduksjon - Fortell om deg selv"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('introduction')
        }}
      />

      <AiWizardIntroductionForm
        showModal={displayAiIntroModal}
        hideModal={hideAiIntroModal}
        getValues={getValues}
        setValue={setValue}
      />
    </>
  );
};

RegisterTempProfileForm.propTypes = {
  register: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default RegisterTempProfileForm;
