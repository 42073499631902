import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import ChatContentHeader from './ChatContentHeader';
import ChatContentBody from './ChatContentBody';
import MessageTextArea from './MessageTextArea';
import {ChatContext} from "../../../../falcon-react-lib/context/Context";
import {isIterableArray} from "../../../../falcon-react-lib/helpers/utils";

const ChatContent = ({ setHideSidebar, setShowRemovedThreads }) => {
  const {chatState: { threads }} = useContext(ChatContext);
  return (
    <Tab.Content className="card-chat-content">
      {!isIterableArray(threads) && (
        <div className="m-1">Du har ingen meldinger</div>
      )}
      {isIterableArray(threads) && threads.map((thread) => (
        <Tab.Pane key={thread.threadIndex} eventKey={thread.threadIndex} className="card-chat-pane">
          <ChatContentHeader thread={thread} setHideSidebar={setHideSidebar} setShowRemovedThreads={setShowRemovedThreads} />
          <ChatContentBody thread={thread} />
        </Tab.Pane>
      ))}
      {isIterableArray(threads) && (<MessageTextArea />)}
    </Tab.Content>
  );
};

ChatContent.propTypes = {
  setShowRemovedThreads: PropTypes.func.isRequired,
  setHideSidebar: PropTypes.func.isRequired
};

export default ChatContent;
