import {useContext} from 'react';
import {MyProfileContext} from "../context/Context";
import useAuth from "./useAuth";

const useMyAccess = () => {
  const {isCompanyAdmin} = useAuth();
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);

  const isCompanyAdminAndHaveAccessToCompany = (companyId) => {
    if(!isCompanyAdmin()) {
      return false;
    }
    //console.log(myProfilePerson,' -> useMyAccess');
    if(!myProfilePerson.companies) {
      return false;
    }
    if(myProfilePerson.companies.length===0) {
      return false;
    }
    return myProfilePerson.companies?.some(company => company.id === companyId);
  }

  return {
    isCompanyAdminAndHaveAccessToCompany
  };
};

export default useMyAccess;