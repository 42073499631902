import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col, Row} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import Flex from '../../../falcon-react-lib/components/common/Flex';
import useUrlHelpers from "hooks/useUrlHelpers";
import Avatar from "../../../falcon-react-lib/components/common/Avatar";

const CompanyListElement = ({ company, isOwner, isSuperAdmin, deleteCompany }) => {
  const {getCompanyAvatarUrl} = useUrlHelpers();
  let avatarUrl = getCompanyAvatarUrl(company)
  // console.log('[CompanyListElement] company ' + JSON.stringify(company))

  const navigate = useNavigate();
  const editCompany = () => {
    navigate('/company/edit/'+company.id);
  }
  const deleteCompanyProxy = () => {
    //console.log('[CompanyListElement] deleteCompanyProxy');
    deleteCompany(company.id, company.name);
  }

  const parse = require('html-react-parser');

  const fixDescription = (description) => {
    let descriptionPart = description;
    descriptionPart = descriptionPart.replaceAll("<p>","");
    descriptionPart = descriptionPart.replaceAll("</p>","");
    descriptionPart = descriptionPart.replaceAll("<div>","");
    descriptionPart = descriptionPart.replaceAll("</div>","");
    //console.log(descriptionPart, '  -> fixDescription ');
    if (descriptionPart.length > 200) {
      //console.log(descriptionPart, '  -> fixDescription ');
      descriptionPart = descriptionPart.substring(0, descriptionPart.substring(0, 199).lastIndexOf(" "));
    }
    return parse(descriptionPart);
  }


  return (
    <>
      <Card className="overflow-hidden">
        <Card.Body className="p-0">
          <Row className="g-0">
            <Col xs={2} md={2} lg={1}>
              <Avatar
                size="3xl"
                className="company-list-avatar"
                src={avatarUrl}
                mediaClass="img-thumbnail shadow-sm"
              />
                  {/*<Image src={avatarUrl} alt="" className="w-100 h-100 fit-cover" />*/}
            </Col>
            <Col xs={10} md={10} lg={11} className="p-x1">
              <Row className="g-0 h-100">
                <Col as={Flex} className="flex-column pe-x1">

                  <h5 className="fs-0" >
                  <Link
                      to={`/company/${company.id}`}
                  >
                    {company.name}
                  </Link>
                  </h5>
                  <div className="fs--1 mt-2">
                    {fixDescription(company.introduction)}...
                  </div>
                  {(isOwner===true || isSuperAdmin===true) && (
                    <Row className="g-0">
                      <Col>
                        <Button
                          size="md"
                          variant="primary"
                          className="w-medium float-end" //px-xxl-5 px-4
                          onClick={editCompany}
                        >
                          Endre
                        </Button>
                        <Button
                          variant="falcon-danger"
                          size="md"
                          className="w-medium float-end me-2"
                          onClick={deleteCompanyProxy}
                        >
                          Slett
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

CompanyListElement.propTypes = {
  company: PropTypes.object.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  deleteCompany: PropTypes.func
};

export default CompanyListElement;
