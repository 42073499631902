import useNotifications from "../hooks/useNotifications";
import useAuth from "../hooks/useAuth";
import {config} from "../Constants";

const callApiNotifications = () => {
  const apiUrl = config.url.API_URL;
  const { handleAllNotificationsRead,handleNotificationRead, handleReloadNewNotifications, handleReloadAllNotifications } = useNotifications();
  const {getAccessToken} = useAuth();

  const fetchAllNotifications = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/notification", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        // console.log(data, ' [callApiNotifications] fetchAllNotifications data load success')
        handleReloadAllNotifications(data);
      });
  }

  const fetchNewNotifications = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/notification/new", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        // console.log(data, ' [callApiNotifications] fetchNewNotifications data load success')
        handleReloadNewNotifications(data);
      });
  }

  const updateAllNotificationsReadFlag = () => {
    getAccessToken()
      .then((accessToken) => {
        //console.log('[useNotifications] accessToken ' + accessToken);
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers
        };
        return fetch(apiUrl+"/notification/readAll", requestOptions);
      })
      .then(response => {
        if(!response.ok) {
          // console.log('[useNotifications] Could not update read flag on notifications');
          throw new Error("Could not update read flag on notifications");
        } else {
          //console.log('[useNotifications] update read flag successfull');
          handleAllNotificationsRead();
        }
      });
  };


  const updateNotificationReadFlag = (notificationId) => {
    getAccessToken()
      .then((accessToken) => {
        //console.log('[useNotifications] accessToken ' + accessToken);
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers
        };
        return fetch(apiUrl+"/notification/read/"+notificationId, requestOptions);
      })
      .then(response => {
        if(!response.ok) {
          // console.log('[useNotifications] Could not update read flag on notification with id: ' + notificationId);
          throw new Error("Could not update read flag on notification");
        } else {
          // console.log('[useNotifications] update read flag successfull notificationId: ' + notificationId);
          handleNotificationRead(notificationId);
        }
      });
  };

  return {
    fetchAllNotifications,
    fetchNewNotifications,
    updateAllNotificationsReadFlag,
    updateNotificationReadFlag
  };
};

export default callApiNotifications;