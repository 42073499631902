import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const RegisterLogo = ({ at, width, height, className}) => {
  return (
      <div
        style={{height: height}}
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img className="me-2" src={'https://backend.folkin.no/assets/img/folkin-logo/folkin_logo_green_RGB.png'} alt="folkin" width={width} />
        {/*<span style={{color: "#efe7e7"}} className={classNames('font-sans-serif', textClass)}>{'-'}</span>*/}
      </div>
  );
};

RegisterLogo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string
};

RegisterLogo.defaultProps = { at: 'auth', width: 58 };

export default RegisterLogo;
