import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import Flex from "../../../falcon-react-lib/components/common/Flex";
import {Link} from "react-router-dom";
import Avatar from "../../../falcon-react-lib/components/common/Avatar";
import useUrlHelpers from "../../../hooks/useUrlHelpers";
import AssignmentCategoriesPills from "./AssignmentCategoriesPills";

const NarrowAssignmentList = ({assignments, cardTitle}) => {
  const {getCompanyAvatarUrl} = useUrlHelpers();
  const fixDescription = (description) => {
    description = description.replace(/(<([^>]+)>)/ig, '')
    description = description.replace(/&nbsp;/g, '');
    description = description.substring(0, 140);
    return description;
  }
  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-2">{cardTitle}</h5>
        </Card.Header>
      <Card.Body className="p-0">
      <Row className="mb-3 g-3">
        {assignments.length === 0  && (
          <Col key={'999'} className="p-3">
            - Ingen -
          </Col>
        )}
        {assignments.map((assignment,index) => (
          <Col key={assignment.id} xs={12}>
            {/*<Card className="overflow-hidden">*/}
            {/*  <Card.Body className="p-0">*/}
                {index>0 && (
                  <div className="border-dashed border-bottom"/>
                )}
                <Row className="g-0">
                  <Col className="p-x1">
                    <Row className="g-0 h-100">
                      <Col as={Flex} className="flex-column pe-x1">
                        <AssignmentCategoriesPills assignment={assignment} />

                        <Flex>
                          <div className="d-flex" to={`/company/${assignment.company.id}`}>
                            <Avatar size="xl" src={getCompanyAvatarUrl(assignment.company)}/>
                          </div>
                          <div className="flex-1 align-self-center ms-2">
                            <h5 className="fs-0" >
                              <Link
                                to={`/company/${assignment.company.id}`}
                              >
                                {assignment.company?.name}
                              </Link>
                            </h5>
                          </div>
                        </Flex>
                        <h4 className="mt-3 mt-sm-0 fs-0 fs-lg-1">
                          <Link
                            to={`/assignment/assignment-details/${assignment.id}`}
                            className="text-900"
                          >
                            {assignment.name}
                          </Link>
                        </h4>
                        <p className="fs--1 mt-2">
                          {fixDescription(assignment.description)}...
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
            {/*  </Card.Body>*/}
            {/*</Card>*/}
          </Col>
        ))}
      </Row>
      </Card.Body>
      </Card>

    </>
  );
};

NarrowAssignmentList.propTypes = {
  assignments: PropTypes.array.isRequired,
  cardTitle: PropTypes.string.isRequired
};

export default NarrowAssignmentList;
