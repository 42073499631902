import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Row} from 'react-bootstrap';
import callApiFeed from "../../../../api/callApiFeed";
import SubmitFormWarning from "../../../confirmation/SubmitFormWarning";
//import image from 'falcon-react-lib/assets/img/icons/spot-illustrations/image.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AddImagesModal from "./AddImagesModal";
import AddUrlModal from "./AddUrlModal";
import AddImagesFeedGallery from "./AddImagesFeedGallery";
import FeedUrl from "./FeedUrl";


const CreatePostForm = ({postAuthorId,postAuthorIsACompany}) => {
  const { saveNewFeedItem } = callApiFeed();
  const [inputRows, setInputRows] = useState(1);

  // Text to post
  const [status, setStatus] = useState('');

  // Image gallery
  const [galleryImages, setGalleryImages] = useState([]);

  // Url
  const [feedUrl, setFeedUrl] = useState(null);

  // Modals
  const [displaySubmitFormWarningModal, setDisplaySubmitFormWarningModal] = useState(false);
  const [mandatoryMissingFields, setMandatoryMissingFields] = useState([]);
  const [displayAddImagesModal, setDisplayAddImagesModal] = useState(false);
  const [displayAddUrlModal, setDisplayAddUrlModal] = useState(false);

  const missingMandatoryFieldsBeforeSave = () => {
    let missingMandatoryField = false;
    let mandatoryMissingFieldNames = [];
    //console.log(' missingMandatoryFieldsBeforeSave. formData ' + status);
    if(status === undefined || status === "" && galleryImages.length===0 && feedUrl===null) {
      mandatoryMissingFieldNames.push("Innlegg/Tekst");
      missingMandatoryField = true;
    }
    setMandatoryMissingFields(mandatoryMissingFieldNames);
    setDisplaySubmitFormWarningModal(missingMandatoryField);
    return missingMandatoryField;
  }

  // Hide the submit form warning modal
  const hideSubmitFormWarningModal = () => {
    setDisplaySubmitFormWarningModal(false);
  };

  // Hide the add images modal
  const hideAddImagesModal = () => {
    setDisplayAddImagesModal(false);
  };

  // Hide the add images modal
  const showAddImagesModal = () => {
    setDisplayAddImagesModal(true);
  };

  // Hide the add url modal
  const hideAddUrlModal = () => {
    setDisplayAddUrlModal(false);
  };

  // Hide the add images modal
  const showAddUrlModal = () => {
    setDisplayAddUrlModal(true);
  };

  const afterSuccessfullSaveCallBack = () => {
    setInputRows(1)
    setStatus('');
    setGalleryImages([]);
    setFeedUrl(null);
  }

  const removeImage = (image) => {
    setGalleryImages(galleryImages.filter(function (i) {
      return i !== image;
    }));
  }

  const handleSubmit = e => {
    e.preventDefault();
    if(!missingMandatoryFieldsBeforeSave()) {
      let url = null;
      if(feedUrl) {
        url = {
          imgUrl: feedUrl.imgUrl,
          urlAddress: feedUrl.urlAddress,
          title: feedUrl.title,
          description: status
        };
      }
      //console.log(feedUrl,'  -> handleSubmit. feedUrl ')
      const formData = {
        userId: postAuthorId,
        userType: postAuthorIsACompany?"COMPANY":"PERSON",
        status: status,
        gallery: galleryImages,
        url: url
      }
      saveNewFeedItem(formData, afterSuccessfullSaveCallBack);
    }
  };

  const changeInputRows = (e, rows) => {
    e.preventDefault();
    setInputRows(rows)
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Control
          as="textarea"
          rows={inputRows}
          placeholder="Skriv noe du ønsker å dele"
          className="shadow-none rounded-0 resize-none border-y-0 px-x1 border-200"
          value={status}
          onChange={e => setStatus(e.target.value)}
          onClick={e => changeInputRows(e,4)}
        />
        {/*<Form.Group*/}
        {/*  as={Flex}*/}
        {/*  alignItems="center"*/}
        {/*  className="ps-x1 border border-200"*/}
        {/*  controlId="hashtag"*/}
        {/*>*/}
        {/*  <Form.Label className="text-nowrap mb-0 me-2">*/}
        {/*    <FontAwesomeIcon icon="plus" className="me-1 fs--2" />*/}
        {/*    <span className="fw-medium fs--1">Add hashtag</span>*/}
        {/*  </Form.Label>*/}
        {/*  <Form.Control*/}
        {/*    type="text"*/}
        {/*    placeholder="Help the right person to see"*/}
        {/*    className="border-0 fs--1  shadow-none"*/}
        {/*  />*/}
        {/*</Form.Group>*/}

        <Row className="g-0 mt-3 px-x1 pb-3 pt-3 justify-content-end border border-200">
          <Col>
            <PostButton icon={'image'} title="Bilder" onClickAction={showAddImagesModal} />
            <PostButton icon={'globe-americas'} title="Link" onClickAction={showAddUrlModal} />
          </Col>
          <Col xs="auto">
            {/*<Dropdown className="d-inline-block me-1">*/}
            {/*  <Dropdown.Toggle*/}
            {/*    variant="none"*/}
            {/*    size="sm"*/}
            {/*    className="px-1 shadow-none"*/}
            {/*  >*/}
            {/*    <FontAwesomeIcon*/}
            {/*      icon={classNames({*/}
            {/*        users: privacy === 'friends',*/}
            {/*        lock: privacy === 'private',*/}
            {/*        'globe-americas': privacy === 'public'*/}
            {/*      })}*/}
            {/*    />*/}
            {/*  </Dropdown.Toggle>*/}
            {/*  <Dropdown.Menu className="py-2" align="end">*/}
            {/*    <Dropdown.Item href="#!" onClick={() => setPrivacy('public')}>*/}
            {/*      Public*/}
            {/*    </Dropdown.Item>*/}
            {/*    <Dropdown.Item href="#!" onClick={() => setPrivacy('private')}>*/}
            {/*      Private*/}
            {/*    </Dropdown.Item>*/}
            {/*    <Dropdown.Item href="#!" onClick={() => setPrivacy('friends')}>*/}
            {/*      Friends*/}
            {/*    </Dropdown.Item>*/}
            {/*  </Dropdown.Menu>*/}
            {/*</Dropdown>*/}
            <Button size="sm" className="px-4 px-sm-5 float-end" type="submit">
              Del
            </Button>
          </Col>
        </Row>
      </Form>
      {galleryImages.length>0 && (
        <AddImagesFeedGallery images={galleryImages} removeImage={removeImage} />
      )}
      {feedUrl && (
        <FeedUrl title={feedUrl.title} urlAddress={feedUrl.urlAddress} imgUrl={feedUrl.imgUrl} />
      )}
      <SubmitFormWarning
        showModal={displaySubmitFormWarningModal}
        hideModal={hideSubmitFormWarningModal}
        mandatoryMissingFields={mandatoryMissingFields}
      />
      <AddImagesModal
        showModal={displayAddImagesModal}
        hideModal={hideAddImagesModal}
        setImages={setGalleryImages}
      />
      <AddUrlModal
        showModal={displayAddUrlModal}
        hideModal={hideAddUrlModal}
        setFeedUrlData={setFeedUrl}
      />
    </>
  );
};
CreatePostForm.propTypes = {
  postAuthorId: PropTypes.string,
  postAuthorIsACompany: PropTypes.bool.isRequired
};

const PostButton = ({ icon, title, onClickAction }) => {
  return (
  <Button
    variant="light"
    size="sm"
    className="rounded-pill shadow-none d-inline-flex align-items-center fs--1 mb-0 me-1"
    onClick={onClickAction}
  >
    <FontAwesomeIcon
      icon={icon}
    />
    {/*<img src={image} alt="" width={17} />*/}
    <span className="ms-2 d-none d-md-inline-block">{title}</span>
  </Button>
)};
PostButton.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired
};

export default CreatePostForm;
