// import { OpenAI } from 'openai';
import {config} from "../Constants";
import useAuth from "../hooks/useAuth";

const callApiOpenAi = () => {
  // const openai = new OpenAI({
  //   apiKey: process.env.REACT_APP_OPENAI_API_KEY
  // });
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;

  const callOpenAiForBasicIntroSuggestion = async (location, professionalTitle, extraPromptInput, successCallback) => {
    let formData = {
      location: location,
      professionalTitle: professionalTitle,
      extraPromptInput: extraPromptInput
    }
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/ai/profile-intro", requestOptions)
      })
      .then(response => {
        if(response.status<300) {
          return response.json();
        } else {
          console.log(response.statusText,' -> ERROR')
        }
      })
      .then(json => {
        successCallback(json);
      });
  };

  const callOpenAiForWizardBasicIntroSuggestion = async (chosenCompetencesAndCurrentStudies,
                                                         chosenIndustries,
                                                         chosenRegions,
                                                         yearsSeniority,
                                                         location,
                                                         professionalTitle,
                                                         extraPromptInput,
                                                         successCallback) => {
    let formData = {
      competencesAndStudiesIds: chosenCompetencesAndCurrentStudies,
      interestedInIndustriesIds: chosenIndustries,
      interestedInRegionsIds: chosenRegions,
      location: location,
      professionalTitle: professionalTitle,
      yearsSeniorityId: yearsSeniority,
      extraPromptInput: extraPromptInput
    }
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/ai/wizard-profile-intro", requestOptions)
      })
      .then(response => {
        if(response.status<300) {
          return response.json();
        } else {
          console.log(response.statusText,' -> ERROR')
        }
      })
      .then(json => {
        successCallback(json);
      });
  };

  return {
    callOpenAiForBasicIntroSuggestion,
    callOpenAiForWizardBasicIntroSuggestion
  };
};

export default callApiOpenAi;