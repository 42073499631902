import { useContext } from 'react';
import { MyAssignmentContext } from '../context/Context';

const useMyAssignments = () => {
  const {
    myAssignmentsDispatch: myAssignmentsDispatch
  } = useContext(MyAssignmentContext);


  const handleLoadMyAssignments = (myAssignments) => {
    // console.log(myAssignments,' ->[useMyAssignments] handleLoadAssignments')
    myAssignmentsDispatch({
      type: 'RELOAD_MY_ASSIGNMENT',
      payload: {
        myAssignments: myAssignments
      }
    });
  };

  const handleFilter = (id,type,name,value,checked) => {
    myAssignmentsDispatch({
      type: 'FILTER_MY_ASSIGNMENT',
      payload: {
        id: id,
        type: type,
        name: name,
        value: value,
        checked: checked
      }
    });
  };

  const emptyFilter = () => {
    myAssignmentsDispatch({
      type: 'EMPTY_FILTER'
    });
  };

  const handleSearch = searchedText => {
    myAssignmentsDispatch({
      type: 'SEARCH_MY_ASSIGNMENT',
      payload: {
        searchedText
      }
    });
  };

  const handleIsAscClick = isAsc => {
    myAssignmentsDispatch({
      type: 'SORT_MY_ASSIGNMENT',
      payload: {
        isAsc: isAsc
      }
    });
  };

  return {
    handleLoadMyAssignments,
    handleFilter,
    emptyFilter,
    handleSearch,
    handleIsAscClick
  };
};

export default useMyAssignments;
