import React, {useContext, useEffect, useState} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import AssignmentCoverPhoto from './AssignmentCoverPhoto';
import AssignmentInformationForm from './AssignmentInformationForm';
import CreateAssignmentHeader from './CreateAssignmentHeader';
import PublishAssignment from './PublishAssignment';
import AssignmentDuration from "./AssignmentDuration";
import {useParams} from "react-router-dom";
import {add, format} from "date-fns";
import callApiAssignments from "../../../../api/callApiAssignments";
import {CategoriesContext} from "../../../../context/Context";
import AssignmentCategories from "./AssignmentCategories";
import EditAssignmentFiles from "./EditAssignmentFiles";


const EditAssignment = () => {
  const { assignmentId } = useParams();
  //console.log(assignmentId, '--> [EditAssignment] assignmentId');
  const datePattern = 'dd.MM.yyyy'

  const {categoriesState: { regions, competences, industries, currentStudies }} = useContext(CategoriesContext);

  const defaultValues = {
    name: '',
    competences: [],
    industries: [],
    regions: [],
    currentStudies: [],
    description: '',
    company: {},
    thumbnail: {},
    startDate: format(add(new Date(),{days: 10}),datePattern),
    endDate: format(add(new Date(),{days: 20}),datePattern),
    publishedTimestamp: '',
    updatedTimestamp: '',
    owner: {},
    status: '',
    files: [],
    noOfMatchesNotified: 0
  }
  const [formData, setFormData] = useState(defaultValues);
  const { fetchAssignment, saveChangesToAssignment } = callApiAssignments();

  const setFormDataUsingDefaults = (loadedData) => {
    let updatedDataWithDefaultValues = {
      ...defaultValues
    };

    // console.log('[EditAssignment] Fetched data: ' +JSON.stringify(loadedData));
    if(loadedData.name) {
      updatedDataWithDefaultValues.name = loadedData.name;
    }
    if(loadedData.competences) {
      updatedDataWithDefaultValues.competences = loadedData.competences;
    }
    if(loadedData.industries) {
      updatedDataWithDefaultValues.industries = loadedData.industries;
    }
    if(loadedData.regions) {
      updatedDataWithDefaultValues.regions = loadedData.regions;
    }
    if(loadedData.currentStudies) {
      updatedDataWithDefaultValues.currentStudies = loadedData.currentStudies;
    }
    if(loadedData.description) {
      updatedDataWithDefaultValues.description = loadedData.description;
    }
    if(loadedData.company) {
      updatedDataWithDefaultValues.company = loadedData.company;
    }
    if(loadedData.thumbnail) {
      updatedDataWithDefaultValues.thumbnail = loadedData.thumbnail;
    }
    if(loadedData.startDate!==undefined && loadedData.startDate!=='') {
      updatedDataWithDefaultValues.startDate = loadedData.startDate;
    }
    if(loadedData.endDate!==undefined && loadedData.endDate!=='') {
      updatedDataWithDefaultValues.endDate = loadedData.endDate;
    }
    if(loadedData.publishedTimestamp) {
      updatedDataWithDefaultValues.publishedTimestamp = loadedData.publishedTimestamp;
    }
    if(loadedData.updatedTimestamp) {
      updatedDataWithDefaultValues.updatedTimestamp = loadedData.updatedTimestamp;
    }
    if(loadedData.owner) {
      updatedDataWithDefaultValues.owner = loadedData.owner;
    }
    if(loadedData.status) {
      updatedDataWithDefaultValues.status = loadedData.status;
    }
    if(loadedData.files) {
      updatedDataWithDefaultValues.files = loadedData.files;
    }
    if(loadedData.noOfMatchesNotified) {
      updatedDataWithDefaultValues.noOfMatchesNotified = loadedData.noOfMatchesNotified;
    }
    setFormData({
      ...formData,
      name: updatedDataWithDefaultValues.name,
      competences: updatedDataWithDefaultValues.competences,
      industries: updatedDataWithDefaultValues.industries,
      regions: updatedDataWithDefaultValues.regions,
      currentStudies: updatedDataWithDefaultValues.currentStudies,
      description: updatedDataWithDefaultValues.description,
      company: updatedDataWithDefaultValues.company,
      thumbnail: updatedDataWithDefaultValues.thumbnail,
      startDate: updatedDataWithDefaultValues.startDate,
      endDate: updatedDataWithDefaultValues.endDate,
      publishedTimestamp: updatedDataWithDefaultValues.publishedTimestamp,
      updatedTimestamp: updatedDataWithDefaultValues.updatedTimestamp,
      owner: updatedDataWithDefaultValues.owner,
      status: updatedDataWithDefaultValues.status,
      files: updatedDataWithDefaultValues.files,
      noOfMatchesNotified: updatedDataWithDefaultValues.noOfMatchesNotified
    });
  }

  useEffect(() => {
    fetchAssignment(assignmentId, setFormDataUsingDefaults);
  }, [assignmentId]);

  const saveChangesAndChangePublishedStatus = () => {
    //console.log(formData, '--> onSubmit - Submitted data');
    formData.status = formData.status==='PUBLISHED'?'UNPUBLISHED':'PUBLISHED';
    saveChangesToAssignment(assignmentId,formData,setFormDataUsingDefaults);
  };

  const saveChanges = () => {
    //console.log(formData, '--> onSaveDraft - Submitted data');
    saveChangesToAssignment(assignmentId,formData,setFormDataUsingDefaults);
  };


  return (
    <>
      <Form id="assignment-form">
        <Row className="g-lg-3 font-sans-serif">
          <Col lg={8}>
            <CreateAssignmentHeader
              assignmentId={assignmentId}
            />
            <AssignmentInformationForm
              formData={formData}
              setFormData={setFormData}
            />
            <AssignmentDuration
              formData={formData}
              setFormData={setFormData}
            />
            <AssignmentCategories
              formData={formData}
              setFormData={setFormData}
              availableRegions={regions}
              availableCompetences={competences}
              availableIndustries={industries}
              availableCurrentStudies={currentStudies}
            />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar top-navbar-height d-flex flex-column">
              <PublishAssignment
                assignmentId={assignmentId}
                formData={formData}
                saveChanges={saveChanges}
                saveChangesAndChangePublishedStatus={saveChangesAndChangePublishedStatus}
              />
              <AssignmentCoverPhoto
                formData={formData}
                setFormData={setFormData}
              />
              <EditAssignmentFiles
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditAssignment;
