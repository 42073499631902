import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Flex from '../../../falcon-react-lib/components/common/Flex';
import useUrlHelpers from "hooks/useUrlHelpers";
import TempCategoriesPills from "./TempCategoriesPills";
import Avatar from "../../../falcon-react-lib/components/common/Avatar";

const TempListElement = ({ temp }) => {
  const {getAvatarUrl} = useUrlHelpers();
  let avatarUrl = getAvatarUrl(temp.thumbnail?.image, temp.firstName,temp.lastName)
  return (
    <>
      <Card className="overflow-hidden">
        <Card.Body className="p-3">
          <Row>
            <Col className="">
              <TempCategoriesPills temp={temp} />
            </Col>
          </Row>
          <Row className="">
            <Col className="col-auto">
              <Flex wrap="no-wrap" direction="center" className="gap-3 mb-3">

                <Avatar size="4xl" src={avatarUrl} className="avatar-flex-size-4xl"/>
                <div>
                    <h5 className="fs-0" >
                      <Link
                        to={`/profile/${temp.id}`}
                      >
                        {temp.firstName} {temp.lastName}
                      </Link>
                    </h5>
                    <p className="fs--1 mt-2">
                      {temp.introduction?.substring(0, 142)}...
                    </p>
                </div>
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

TempListElement.propTypes = {
  temp: PropTypes.object.isRequired
};

export default TempListElement;
