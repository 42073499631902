import React, {useContext, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import { isIterableArray } from 'falcon-react-lib/helpers/utils';
import FalconCardHeader from 'falcon-react-lib/components/common/FalconCardHeader';
import {NotificationsContext} from "../../../context/Context";
import useNotifications from "../../../hooks/useNotifications";
import NotificationsList from "../../notification/NotificationsList";
import callApiNotifications from "../../../api/callApiNotifications";

const NotificationDropdown = () => {

  const {notificationsState: { newNotifications, isAllRead, isOpen }} = useContext(NotificationsContext);
  const {handleSetIsOpen} = useNotifications();
  const {fetchNewNotifications} = callApiNotifications();
  const {updateAllNotificationsReadFlag} = callApiNotifications()

  // Handler
  const handleToggle = () => {
    if(!isOpen) {
      fetchNewNotifications();
    }
    handleSetIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && handleSetIsOpen(false);
    });
  }, []);

  const markAsRead = e => {
    e.preventDefault();
    // console.log('[NotificationDropdown] markAsRead');
    updateAllNotificationsReadFlag();
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Varslinger"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                Merk alle som lest
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar border-top"
            style={{ maxHeight: '19rem' }}
          >
            {/*<div className="list-group-title">Nye</div>{' '}*/}
            {!isIterableArray(newNotifications) && (
              <div className="m-1">Ingen nye varsler</div>
            )}
            {isIterableArray(newNotifications) && <NotificationsList notifications={newNotifications} handleToggle={handleToggle}/>}
            {/*<div className="list-group-title">Eldre</div>*/}
            {/*{!isIterableArray(earlierNotifications) && (*/}
            {/*  <div className="m-1">Ingen eldre varsler</div>*/}
            {/*)}*/}
            {/*{isIterableArray(earlierNotifications) && <NotificationsList notifications={earlierNotifications} handleToggle={handleToggle}/>}*/}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to={'/user/edit'}>
              Se alle
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
