import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Form, Nav, ProgressBar} from 'react-bootstrap';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useForm} from 'react-hook-form';
import Success from './Success';
import AppContext, {AuthWizardContext} from 'falcon-react-lib/context/Context';
import IconButton from 'falcon-react-lib/components/common/IconButton';
import WizardModal from 'falcon-react-lib/components/wizard/WizardModal';
import Flex from 'falcon-react-lib/components/common/Flex';
import {useAuth0} from "@auth0/auth0-react";
import {config} from "../../../Constants";
import {useNavigate} from "react-router-dom";
import RegisterTempProfessionalBackgroundForm from "./RegisterTempProfessionalBackgroundForm";
import RegisterTempProfileForm from "./RegisterTempProfileForm";
import RegisterTempAvailabilityForm from "./RegisterTempAvailabilityForm";
import RegisterTempIndustriesForm from "./RegisterTempIndustriesForm";
import RegisterTempRegionsForm from "./RegisterTempRegionsForm";

const RegisterTempProfileWizardSteps = ({ profile, events, variant, validation, progressBar }) => {
  const navigate = useNavigate();
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const values = {
    id: '',
    yearsSeniority: '',
    chosenCompetencesAndCurrentStudies: [],
    chosenIndustries: [],
    chosenRegions: [],
    availabilityStatus: '',
    percentAvailability: '',
    location: '',
    professionalTitle: '',
    introduction: '',
    thumbnail: {},
    events: []
  }
  //console.log(values,' values before useForm')
  const {
    register,
    handleSubmit,
    formState: { errors },
    //watch,
    setValue,
    getValues,
    // reset,
    clearErrors
    //setError
  } = useForm(values);


  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'graduation-cap'
      //label: 'Bakgrunn'
    },
    {
      icon: 'briefcase'
      //label: 'Bransjer'
    },
    {
      icon: 'location-arrow'
      //label: 'Regioner'
    },
    {
      icon: 'clock'
      //label: 'Tilgjengelighet'
    },
    {
      icon: 'user'
      //label: 'Profil'
    },
    {
      icon: 'thumbs-up'
      //label: 'Ferdig'
    }
  ];

  const onSubmitData = data => {
    console.log(data, ' ->>> onSubmitData data ')
    setUser({ ...user, ...data });
    setStep(step + 1);
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  //const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    console.log('handleNavs');
    //if (step !== 6) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    // } else {
    //   toggle();
    // }
  };

  const getOutOfHere = () => {
    navigate('/profile/temp/edit', { replace: true });
  }

  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      // console.log(e);
    }
  }


  const saveUserData = (user) => {
    console.log(user, ' -- > [TempWizardSteps] saveTempData ')

    const formData = {
      id: user.id,
      chosenCompetencesAndCurrentStudies: user.chosenCompetencesAndCurrentStudies,
      chosenIndustries: user.chosenIndustries,
      chosenRegions: user.chosenRegions,
      yearsSeniority: user.yearsSeniority,
      availabilityStatus: user.availabilityStatus,
      percentAvailability: user.percentAvailability,
      location: user.location,
      professionalTitle: user.professionalTitle,
      introduction: user.introduction,
      thumbnail: user.thumbnail,
      events: user.events
    }
    console.log(formData, ' -- > [TempWizardSteps] formData ')

    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    const requestOptions = {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(formData)
    };
    fetch(apiUrl+"/myProfile/temp-wizard", requestOptions)
      .then(response => {
        if(response.ok) {
          // console.log('[TempWizardSteps] Registration saved ok ')
          navigate('/', { replace: true });
        } else {
          throw Error('Registration was not saved');
        }
      });
  }
  //
  // const getMyProfile = () => {
  //   let headers = {"Content-Type": "application/json"};
  //   headers["Authorization"] = `Bearer ${accessToken}`;
  //   fetch(apiUrl+"/myProfile", {headers,})
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data => {
  //       // console.log(data,'  getMyProfile data success')
  //       setValue('id',data.id);
  //       setValue('competences',data.competences);
  //       setValue('interestedInIndustries',data.interestedInIndustries);
  //       setValue('interestedInRegions',data.interestedInRegions);
  //       setValue('currentStudies',data.currentStudies);
  //       setValue('yearsSeniority',data.yearsSeniority);
  //       setValue('phone',data.phone);
  //       setValue('location',data.location);
  //       setValue('professionalTitle',data.professionalTitle);
  //       setValue('introduction',data.introduction);
  //       setValue('thumbnail',data.thumbnail);
  //     });
  // };
  //
  // useEffect(() => {
  //   if(accessToken!=="") {
  //     getMyProfile();
  //   }
  // }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])

  useEffect(() => {
    let chosenCompetencesAndCurrentStudies = [];
    if(profile.competences) {
      chosenCompetencesAndCurrentStudies = profile.competences?.map(competence => competence.id);
    }
    let chosenCurrenStudies = [];
    if(profile.currentStudies) {
      chosenCurrenStudies = profile.currentStudies?.map(currentStudy => currentStudy.id);
    }
    chosenCompetencesAndCurrentStudies = chosenCompetencesAndCurrentStudies.concat(chosenCurrenStudies);
    const chosenIndustries = profile.interestedInIndustries?.map(industry => industry.id);
    const chosenRegions = profile.interestedInRegions?.map(region => region.id);

    setValue('chosenCompetencesAndCurrentStudies',chosenCompetencesAndCurrentStudies?chosenCompetencesAndCurrentStudies:[]);

    setValue('id',profile.id);
    setValue('chosenIndustries',chosenIndustries?chosenIndustries:[]);
    setValue('chosenRegions',chosenRegions?chosenRegions:[]);
    setValue('yearsSeniority',profile.yearsSeniority?profile.yearsSeniority.id:'');
    setValue('availabilityStatus',profile.availabilityStatus?profile.availabilityStatus:'');
    setValue('percentAvailability',profile.percentAvailability?profile.percentAvailability:'');
    setValue('location',profile.location);
    setValue('professionalTitle',profile.professionalTitle);
    setValue('introduction',profile.introduction);
    setValue('thumbnail',profile.thumbnail);
    setValue('events',events);
    //console.log('useEffect set Value ')
    console.log(getValues(),'  useEffect getValues');
    setUser({ ...user, ...values });
  }, [profile,events])

  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={index}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={index}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 16} style={{ height: 3 }} />}
        <Card.Body className="fw-normal px-md-5 py-4">
          {step === 1 && (
            <RegisterTempProfessionalBackgroundForm register={register} setValue={setValue} getValues={getValues} errors={errors} clearErrors={clearErrors} user={user} />
          )}
          {step === 2 && (
            <RegisterTempIndustriesForm register={register} errors={errors} />
          )}
          {step === 3 && (
            <RegisterTempRegionsForm register={register} setValue={setValue} errors={errors} clearErrors={clearErrors} />
          )}
          {step === 4 && (
            <RegisterTempAvailabilityForm register={register} setValue={setValue} getValues={getValues} errors={errors} profile={profile} events={events} />
          )}
          {step === 5 && (
            <RegisterTempProfileForm register={register} getValues={getValues} setValue={setValue} errors={errors} />
          )}
          {step === 6 && <Success successHandle={saveUserData} />}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 6,
            ' d-flex': step < 6
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Forrige
          </IconButton>

          <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step !== 1
            })}
            onClick={() => {
              getOutOfHere();
            }}
          >
            Avbryt
          </IconButton>

          {/*<Button*/}
          {/*  variant="link"*/}
          {/*  className={classNames('px-3')}*/}
          {/*  onClick={() => {*/}
          {/*    getOutOfHere();*/}
          {/*  }}*/}
          {/*  transform="down-1 shrink-4"*/}
          {/*>*/}
          {/*  Avbryt*/}
          {/*</Button>*/}

          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Neste
          </IconButton>
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 6 ? step > index : step > 5,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

RegisterTempProfileWizardSteps.propTypes = {
  profile: PropTypes.object,
  events: PropTypes.array,
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string
};

NavItem.propTypes = NavItemPill.propTypes;

export default RegisterTempProfileWizardSteps;
