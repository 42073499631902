import React, {useContext, useState} from "react";
import {AssignmentContext, MyAssignmentContext} from "../../../../context/Context";
import {useParams} from "react-router-dom";
import ApplyForm from "./ApplyForm";
import ApplyHeader from "./ApplyHeader";
import SubmitForm from "./SubmitForm";
import AssignmentListElement from "../AssignmentListElement";
import callApiAssignments from "../../../../api/callApiAssignments";
import SubmitFormWarning from "../../../confirmation/SubmitFormWarning";

const Apply = () => {
  const { assignmentApply } = callApiAssignments();
  const [displaySubmitFormWarningModal, setDisplaySubmitFormWarningModal] = useState(false);
  const [mandatoryMissingFields, setMandatoryMissingFields] = useState([]);
  const {assignmentsState: { assignments }} = useContext(AssignmentContext);
  const {myAssignmentsState: { myAssignments }} = useContext(MyAssignmentContext);
  const { assignmentId } = useParams();
  let assignment = assignments.find(assignment => assignment.id === assignmentId);
  if(assignment===undefined) {
    assignment = myAssignments.find(assignment => assignment.id === assignmentId);
  }
  //console.log(assignment,' -> [Apply] assignment')

  const defaultValues = {
    assignmentId: assignmentId,
    message: ''
  };
  const [formData, setFormData] = useState(defaultValues);

  // Hide the submit form warning modal
  const hideSubmitFormWarningModal = () => {
    setDisplaySubmitFormWarningModal(false);
  };

  const missingMandatoryFieldsBeforeSave = () => {
    let missingMandatoryField = false;
    let mandatoryMissingFieldNames = [];
    //console.log(' missingMandatoryFieldsBeforeSave. formData ' + JSON.stringify(formData));
    if(formData.message === undefined || formData.message === "") {
      mandatoryMissingFieldNames.push("Melding til oppdragsgiver");
      missingMandatoryField = true;
    }
    setMandatoryMissingFields(mandatoryMissingFieldNames);
    setDisplaySubmitFormWarningModal(missingMandatoryField);
    return missingMandatoryField;
  }

  const saveAndSubmitForm = () => {
    if(!missingMandatoryFieldsBeforeSave()) {
      assignmentApply(assignmentId, assignment.owner.id, formData);
    }
  }



  return assignment ? (
    <>
      <ApplyHeader />
      <AssignmentListElement assignment={assignment} isOwner={false} deleteAssignment={null} />
      <ApplyForm formData={formData} setFormData={setFormData} />
      <SubmitForm saveAndSubmitForm={saveAndSubmitForm} />
      <SubmitFormWarning
        showModal={displaySubmitFormWarningModal}
        hideModal={hideSubmitFormWarningModal}
        mandatoryMissingFields={mandatoryMissingFields}
      />
    </>
  ) : (
    <span>Oppdraget er ikke tilgjengelig lenger</span>
  );
};

export default Apply;