import React from 'react';
import corner4 from 'falcon-react-lib/assets/img/illustrations/corner-4.png';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Background from 'falcon-react-lib/components/common/Background';
import PropTypes from "prop-types";

const CreateAssignmentHeader = ({assignmentId}) => {
  return (
    <Card className="mb-3">
      <Background
        image={corner4}
        className="bg-card d-none d-md-block"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body className="position-relative">
        <Row>
          <Col xl={10}>
            <h3>
              {assignmentId===undefined || assignmentId===null ?(<span>Legg inn et nytt oppdrag</span>) : (<span>Endre et oppdrag</span>)}
              </h3>
            <p className="mb-0">
              Et {(assignmentId===undefined || assignmentId===null) && (<span>nytt</span>)} oppdrag blir synlig for vikarer når det blir publisert. Det vil også bli automatisk matchet mot vikarer som er ledig og som matcher kategoriseringen på oppdraget.
              <br />
              <br />

              <br className="d-lg-none" />
              Ønsker du å endre på et {assignmentId!==undefined && assignmentId!==null && (<span>annet</span>)} eksisterende oppdrag? Gå til
              <Link to="/assignment/mine"> Mine oppdrag</Link>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CreateAssignmentHeader.propTypes = {
  assignmentId: PropTypes.string
};

export default CreateAssignmentHeader;
