import React, {useState} from 'react';
import {Alert, Button, Card, Col, Form, Row} from 'react-bootstrap';
import IconButton from 'falcon-react-lib/components/common/IconButton';
import InvitePeopleModal from './InvitePeopleModal';
import callApiInvitePeople from "../../../../api/callApiInvitePeople";
import ShareButton from "../../../share/ShareButton";
import ShareOnFacebookButton from "../../../share/ShareOnFacebookButton";
import ShareOnLinkedInButton from "../../../share/ShareOnLinkedInButton";
import ShareButtonWithEmail from "../../../share/ShareButtonWithEmail";

const InvitePeople = () => {
  const [showModal, setShowModal] = useState(false);
  const [invitationSentSuccessfullyToEmail, setInvitationSentSuccessfullyToEmail] = useState('');
  const shareInviteText = 'Hei! Jeg vil invitere deg til å lage en profil på folkin. Se mer her ';
  const shareInviteUrl = 'https://www.folkin.no';
  const {inviteByEmail} = callApiInvitePeople();
  const [state, setState] = useState({
    email: ""
  });


  const handleCopy = () => {
    setShowModal(true);
  };

  const handleInputChange = (event) => {
    setInvitationSentSuccessfullyToEmail('');
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    if(state.email.length > 0) {
      inviteByEmail(state.email, invitationSentSuccessfullyCallback);
    }
  };

  const invitationSentSuccessfullyCallback = () => {
    setInvitationSentSuccessfullyToEmail(state.email);
    setState((prevProps) => ({
      ...prevProps,
      email: ''
    }));
  }

  return (
    <>
      <Card>
        <Card.Body className="text-center py-5">
          {/*<Row className="justify-content-center">*/}
          {/*  <Col xs={7} md={5}>*/}
          {/*    <img className="img-fluid" src={invite} alt="image" width="58%" />*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <h3 className="mt-3 fw-normal fs-2 mt-md-4 fs-md-3">
            Inviter vennene dine til folkin
          </h3>
          <p className="lead mb-5">
            Inviter både venner og kollegaer til folkin for et sosialt og faglig felleskap.{' '}
            {/*<br className="d-none d-md-block" /> bla bla bla.*/}
          </p>
          <Row className="justify-content-center">
            <Col md={7}>
              <Form onSubmit={handleSubmit}>
                <Row className="g-2">
                  <Col sm>
                    <Form.Control
                      type="email"
                      placeholder="Epost"
                      name={'email'}
                      aria-label="mottakers epost"
                      value={state.email}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col sm="auto">
                    <Button type="submit" className="d-block w-100">
                      Send invitasjon
                    </Button>
                  </Col>
                </Row>
                {invitationSentSuccessfullyToEmail.length > 0 && (
                  <Alert variant="secondary" className="mt-3">
                    En invitasjon ble sendt til {invitationSentSuccessfullyToEmail}
                  </Alert>
                )}
              </Form>
            </Col>
          </Row>

        </Card.Body>
        <Card.Footer className="bg-light text-center pt-4">
          <Row className="justify-content-center">
            <Col xs={11} sm={10}>
              <h4 className="fw-normal mb-0 fs-1 fs-md-2">
                Flere måter å invitere <br className="d-sm-none" /> vennene dine på
              </h4>

              <Row className="gx-2 my-4">
                {navigator.share && (
                  <>
                    <Col lg={6} className="mb-2 mb-lg-0">
                      <ShareButton
                        className="d-block w-100 mb-2 mb-xl-0"
                        iconClassName="me-2"
                        variant="falcon-default"
                        icon="share"
                        title="Del invitasjon med en venn"
                        text={shareInviteText}
                        url={shareInviteUrl}
                      >
                        Del invitasjon med en venn
                      </ShareButton>
                    </Col>
                    <Col lg={6} className="mb-2 mb-lg-0">
                      <IconButton
                        className="d-block w-100 mb-2 mb-xl-0"
                        iconClassName="me-2"
                        variant="falcon-default"
                        icon="link"
                        onClick={handleCopy}
                      >
                        Kopier invitasjonslenke
                      </IconButton>
                    </Col>
                  </>
                )}
                {!navigator.share && (
                  <>
                    <Col lg={3} className="mb-2 mb-lg-0">
                      <ShareOnFacebookButton url={shareInviteUrl} />
                    </Col>
                    <Col lg={3} className="mb-2 mb-lg-0">
                      <ShareOnLinkedInButton url={shareInviteUrl} />
                    </Col>
                    <Col lg={3} className="mb-2 mb-lg-0">
                      <ShareButtonWithEmail text={shareInviteText} url={shareInviteUrl} />
                    </Col>
                    <Col lg={3} className="mb-2 mb-lg-0">
                      <IconButton
                        className="d-block w-100 mb-2 mb-xl-0"
                        iconClassName="me-2"
                        variant="falcon-default"
                        icon="link"
                        onClick={handleCopy}
                      >
                        Kopier lenke
                      </IconButton>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <InvitePeopleModal show={showModal} setShow={setShowModal} />
    </>
  );
};

export default InvitePeople;
