import { AuthWizardContext } from 'falcon-react-lib/context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import celebration from 'falcon-react-lib/components/wizard/lottie/celebration.json';

const Success = ({ successHandle }) => {
  const { user } = useContext(AuthWizardContext);
  // console.log(user,' --> Success user');

  const handleStartButtonClick = () => {
    //setStep(1);
    //setUser({});
    successHandle(user);
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Gratulerer! Du har nå en søkbar vikarprofil på folkin</h4>

          <Button color="primary" className="px-5 my-3" onClick={handleStartButtonClick}>
            Gå til forsiden
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  successHandle: PropTypes.func.isRequired
};

export default Success;
