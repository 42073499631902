import React from 'react';
import Feed from "./Feed";
const Dashboard = () => {
  return (
      <>
        <Feed />
      </>
  );
};

export default Dashboard;
