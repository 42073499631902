import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ChatContentBodyIntro from './ChatContentBodyIntro';
import Message from './Message';
import SimpleBarReact from 'simplebar-react';
import ThreadInfo from './ThreadInfo';
import { ChatContext } from 'falcon-react-lib/context/Context';
import useChats from "../../../../hooks/useChats";

const ChatContentBody = ({ thread }) => {
  let lastDate = null;
  const messagesEndRef = useRef();

  const {getUser, chatState: { users, assignments, groups, messages, scrollToBottom }} = useContext(ChatContext);
  const { handleScrollToBottom } = useChats();

  const user = getUser(users,assignments,groups,thread);
  const { content } = messages.find(({ id }) => id === thread.messagesId);

  const doScrollToBottom = () => {
    //console.log(' -> [ChatContentBody] doScrollToBottom')
    if(messagesEndRef && messagesEndRef.current){
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    handleScrollToBottom(false);
  }

  useEffect(() => {
    //console.log(scrollToBottom,' -> [ChatContentBody] scrollToBottom')
    if (scrollToBottom && scrollToBottom===true) {
      setTimeout(() => {
        //console.log(' -> [ChatContentBody] setting timeout on doScrollToBottom')
        doScrollToBottom();
      }, 500);
    }
  }, [scrollToBottom]);

  return (
    <div className="chat-content-body" style={{ display: 'inherit' }}>
      <ThreadInfo thread={thread} isOpenThreadInfo={true} />
      <SimpleBarReact style={{ height: '100%' }}>
        <div className="chat-content-scroll-area">
          <ChatContentBodyIntro user={user} isGroup={thread.type==='group'}/>
          {content.map(({ message, time, senderUserId, status }, index) => (
            <div key={index}>
              {lastDate !== time.date && (
                <div className="text-center fs--2 text-500">{`${time.date}, ${time.hour}`}</div>
              )}
              {(() => {
                lastDate = time.date;
              })()}
              <Message
                message={message}
                senderUserId={senderUserId}
                time={time}
                status={status}
                isGroup={thread.type === 'group'}
              />
            </div>
          ))}
        </div>
        <div ref={messagesEndRef} />
      </SimpleBarReact>
    </div>
  );
};

ChatContentBody.propTypes = {
  thread: PropTypes.object.isRequired
};

export default ChatContentBody;
