import React from 'react';
import {Card} from 'react-bootstrap';
import {Link} from "react-router-dom";

const TempWizardLink = () => {

  return (
    <Card className="mb-3">
      <Card.Body>
        Du kan også bruke vår
        <Link to={`/temp/wizard`} className="ms-2">
          vikarprofilbygger
        </Link>
      </Card.Body>
    </Card>
  );
};

export default TempWizardLink;
