import { createContext } from 'react';

export const AssignmentContext = createContext();
export const MyAssignmentContext = createContext();
export const PersonContext = createContext();
export const TempContext = createContext();
export const CompanyContext = createContext();
export const MyProfileContext = createContext();
export const CategoriesContext = createContext();
export const NotificationsContext = createContext();
export const EventsContext = createContext();
export const FavouritesContext = createContext();
export const AuthContext = createContext();
