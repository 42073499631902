import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Col, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Flex from '../../../falcon-react-lib/components/common/Flex';
import SoftBadge from "../../../falcon-react-lib/components/common/SoftBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useUrlHelpers from "hooks/useUrlHelpers";
import Avatar from "../../../falcon-react-lib/components/common/Avatar";

const PersonListElement = ({ person, isSuperAdmin, deletePerson }) => {
  const deletePersonProxy = () => {
    console.log('[PersonListElement] deletePersonProxy');
    deletePerson(person.id, person.fullName);
  }
  const {getAvatarUrl} = useUrlHelpers();
  let avatarUrl = getAvatarUrl(person.thumbnail?.image, person.firstName,person.lastName)
  // console.log('[PersonListElement] person ' + JSON.stringify(person))
  return (
    <>
      <Card className="overflow-hidden">
        <Card.Body className="p-3">
          <Row>
            <Col className="">
              <Flex wrap="wrap" className="gap-2 mb-3">
                {person.deleted && (
                  <SoftBadge key={8888} pill bg="danger">
                    <FontAwesomeIcon
                      icon="calendar-alt"
                      className="me-1"
                      transform="shrink-4"
                    />
                    Slettet
                  </SoftBadge>
                )}
                {person.companies?.map((company) => (
                  <SoftBadge key={company.id} pill bg="primary">
                    <FontAwesomeIcon
                      icon="briefcase"
                      className="me-1"
                      transform="shrink-4"
                    />
                    {company.name}
                  </SoftBadge>
                ))}
                {person.registeredAsTemp && (
                  <SoftBadge key={9999} pill bg="dark">
                    <FontAwesomeIcon
                      icon="calendar-alt"
                      className="me-1"
                      transform="shrink-4"
                    />
                    Vikar
                  </SoftBadge>
                )}
              </Flex>
            </Col>
          </Row>
          <Row className="">
            <Col className="col-auto">
              <Flex wrap="no-wrap" direction="center" className="gap-3 mb-3">

                <Avatar size="4xl" src={avatarUrl} className="avatar-flex-size-4xl"/>
                <div>
                  <h5 className="fs-0" >
                    <Link
                      to={`/profile/${person.id}`}
                    >
                      {person.firstName} {person.lastName}
                    </Link>
                  </h5>
                  <p className="fs--1 mt-2">
                    {person.introduction?.substring(0, 142)}...
                  </p>
                  {isSuperAdmin===true && !person.deleted && (
                    <Row className="g-0">
                      <Col>
                        <Button
                          variant="falcon-danger"
                          size="md"
                          className="w-medium float-end me-2"
                          onClick={deletePersonProxy}
                        >
                          Slett
                        </Button>
                      </Col>
                    </Row>
                  )}
                </div>
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

PersonListElement.propTypes = {
  person: PropTypes.object.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  deletePerson: PropTypes.func.isRequired
};

export default PersonListElement;
