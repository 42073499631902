import React, {useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import { AssignmentContext } from 'context/Context';
import { assignmentReducer } from 'reducers/assignmentReducer';
import { config } from 'Constants'
import {useAuth0} from "@auth0/auth0-react";

const AssignmentProvider = ({ children }) => {
  //console.log(' AssignmentProvider ')
  const initData = {
    initAssignments: [],
    assignments: [],
    primaryAssignments: [],
    //cartItems: [{ ...assignmentData[1] }, { ...assignmentData[2] }],
    favouriteItems: [],
    competences: [],
    industries: [],
    regions: [],
    currentStudies: [],
    assignmentFilters: [],
    filterOptions: [],
    searchedText: null,
    searchedFromDate: null,
    searchedToDate: null,
    isAsc: true
  };
  const [assignmentsState, assignmentsDispatch] = useReducer(assignmentReducer, initData);
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }
  
  const fetchAssignmentFilters = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/assigntments/filters", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        assignmentsDispatch({
          type: 'RELOAD_ASSIGNMENT_FILTERS',
          payload: {
            assignmentFilters: data,
          }
        });
      })
  }

  const fetchCategories = async () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl + "/categories", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        assignmentsDispatch({
          type: 'RELOAD_CATEGORIES',
          payload: {
            categories: data,
          }
        });
      })
  }

  const fetchAssignments = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/assignments", {headers,})
      .then(response => {
          return response.json()
      })
      .then(data => {
          assignmentsDispatch({
              type: 'RELOAD_ASSIGNMENT',
              payload: {
                  assignments: data
              }
          });
      })
  }

  useEffect(() => {
    if(accessToken!=="") {
      fetchAssignmentFilters();
      fetchAssignments();
      fetchCategories();
    }
  }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])
  
  // const isInCart = id =>
  //   !!assignmentsState.cartItems.find(cartItem => cartItem.id === id);
  const isInFavouriteItems = id =>
    !!assignmentsState.favouriteItems.find(
      favouriteItem => favouriteItem.id === id
    );

  return (
    <AssignmentContext.Provider
      value={{
        assignmentsState,
        assignmentsDispatch,
        //isInCart,
        isInFavouriteItems
      }}
    >
      {children}
    </AssignmentContext.Provider>
  );
};

AssignmentProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AssignmentProvider;
