import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import classNames from 'classnames';
import {MyAssignmentContext} from 'context/Context';
import usePagination from '../../../../falcon-react-lib/hooks/usePagination';
import AssignmentListElement from '../AssignmentListElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../../falcon-react-lib/components/common/Flex';
import { useBreakpoints } from '../../../../falcon-react-lib/hooks/useBreakpoints';
import MyAssignmentFilters from "./MyAssignmentFilters";
import MyAssignmentHeader from "./MyAssignmentHeader";
import DeleteConfirmation from "components/confirmation/DeleteConfirmation";
import callApiAssignments from "../../../../api/callApiAssignments";
import {useAuth0} from "@auth0/auth0-react";

const MyAssignments = () => {
  const { isAuthenticated } = useAuth0();
  const [assignmentId, setAssignmentId] = useState(null);
  const [assignmentName, setAssignmentName] = useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const [assignmentPerPage, setAssignmentPerPage] = useState(6);
  const { breakpoints } = useBreakpoints();
  const { fetchMyAssignments,deleteMyAssignment } = callApiAssignments();

  const { myAssignmentsState: { myAssignments } } = useContext(MyAssignmentContext);

  const {
    paginationState: {
      data: paginatedAssignments,
      totalItems,
      itemsPerPage,
      currentPage,
      canNextPage,
      canPreviousPage,
      paginationArray
    },
    nextPage,
    prevPage,
    goToPage,
    setItemsPerPage
  } = usePagination(myAssignments, assignmentPerPage);

  useEffect(() => {
    fetchMyAssignments();
  }, [isAuthenticated]);

  //console.log(myAssignments, " -> [MyAssignments] myAssignments");

  // Handle the displaying of the delete modal
  const showDeleteModal = (id, name) => {
    setAssignmentId(id);
    setAssignmentName(name);
    setDisplayConfirmationModal(true);
  };

  // Hide the delete modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deleteAndHideModal = (assignmentIdToDelete) => {
    deleteMyAssignment(assignmentIdToDelete);
    hideConfirmationModal();
  }

  return (
    <>
      <Row className="g-3">
        {breakpoints.up('xl') && (
          <Col xl={3}>
            <MyAssignmentFilters />
          </Col>
        )}
        <Col xl={9}>
          {/* Assignments Header */}
          <MyAssignmentHeader setShowFilterOffcanvas={setShowFilterOffcanvas}/>
          {/* Assignments */}
          <Row className="mb-3 g-3">
            {paginatedAssignments.length > 0 ? (
              paginatedAssignments.map(assignment =>
                (
                  <Col key={assignment.id} xs={12}>
                    <AssignmentListElement assignment={assignment} isOwner={true} deleteAssignment={showDeleteModal} />
                  </Col>
                ))
            ) : (
              <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="fs-6 mb-3"
                    />
                    <h5>Fant ingen oppdrag!</h5>
                    <p className="mb-0">
                      Søket ditt matchet ingen oppdrag.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Row>
          {/* Assignment pagination */}
          {paginatedAssignments.length > 0 && (
            <Card>
              <Card.Body>
                <Row className="g-3 flex-center justify-content-sm-between">
                  <Col xs="auto" as={Flex} alignItems="center">
                    <small className="d-none d-lg-block me-2">Per side:</small>
                    <Form.Select
                      size="sm"
                      value={itemsPerPage}
                      onChange={({ target }) => {
                        setItemsPerPage(target.value);
                        setAssignmentPerPage(target.value);
                      }}
                      style={{ maxWidth: '4.875rem' }}
                    >
                      <option value={2}>2</option>
                      <option value={4}>4</option>
                      <option value={6}>6</option>
                      <option value={totalItems}>Alle</option>
                    </Form.Select>
                  </Col>
                  <Col xs="auto" as={Flex}>
                    <div>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={{ position: 'fixed' }}>Prev</Tooltip>
                        }
                      >
                        <Button
                          variant="falcon-default"
                          size="sm"
                          disabled={!canPreviousPage}
                          onClick={prevPage}
                          className="me-2"
                          trigger="focus"
                        >
                          <FontAwesomeIcon icon="chevron-left" />
                        </Button>
                      </OverlayTrigger>
                    </div>

                    <ul className="pagination mb-0">
                      {paginationArray.map(page => (
                        <li
                          key={page}
                          className={classNames({
                            active: currentPage === page
                          })}
                        >
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="page me-2"
                            onClick={() => goToPage(page)}
                          >
                            {page}
                          </Button>
                        </li>
                      ))}
                    </ul>
                    <div>
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={
                          <Tooltip
                            style={{ position: 'fixed' }}
                            id="button-tooltip-2"
                          >
                            Next
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="falcon-default"
                          size="sm"
                          disabled={!canNextPage}
                          onClick={nextPage}
                          trigger="focus"
                        >
                          <FontAwesomeIcon icon="chevron-right" />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideConfirmationModal}
        confirmModal={deleteAndHideModal}
        id={assignmentId}
        name={assignmentName}
        message="Er du sikker på at vil slette oppdraget"
      />
      {breakpoints.down('xl') && (
        <Offcanvas
          show={showFilterOffcanvas}
          onHide={() => setShowFilterOffcanvas(false)}
          placement="start"
          className="offcanvas offcanvas-filter-sidebar"
        >
          <MyAssignmentFilters isOffcanvas={true} setShow={setShowFilterOffcanvas} />
        </Offcanvas>
      )}
    </>
  );
};

export default MyAssignments;
