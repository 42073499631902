/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const myAssignmentReducer = (state, action) => {
  const { type, payload } = action;

  const searchAndFilterAssignments = function (isAsc, options, searchedText) {
    let searchedAssignments = [...state.primaryMyAssignments];

    // Filter options
    if(options.length>0) {
      searchedAssignments = searchedAssignments.filter(assignment => {
        return options.some((o) => {
          return o.value.toUpperCase() === assignment.status.toUpperCase();
        });
      });
    }

    // Search text
    if(searchedText) {
      // console.log('searchedText ' + searchedText)
      searchedAssignments = searchedAssignments.filter(assignment =>
        assignment.name.toUpperCase().includes(searchedText.toUpperCase())
      );
    }

    // Sort Assignments
    let sortedAssignments = sortAssignment(isAsc,searchedAssignments)

    return sortedAssignments;
  };


  const sortAssignment = function (isAsc, assignments) {
    //console.log('[personReducer] sortPersons. isAsc: ' + isAsc);
    let sortedAssignments = assignments.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (isAsc) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
      }
      // names must be equal
      return 0;
    });
    // Force relaod of pagination reducer state
    sortedAssignments = sortedAssignments.map(e => ({...e, timestamp: new Date().valueOf()}));
    return sortedAssignments;
  }

  switch (type) {
    case 'RELOAD_MY_ASSIGNMENT_FILTERS':
      return {
        ...state,
        myAssignmentFilters: payload.myAssignmentFilters
      };
    case 'RELOAD_MY_ASSIGNMENT':
      //console.log(payload.myAssignments,' ->[myAssignmentReducer] RELOAD_MY_ASSIGNMENT')
      return {
        ...state,
        initMyAssignments: payload.myAssignments,
        myAssignments: payload.myAssignments,
        primaryMyAssignments: payload.myAssignments,
      };
    case 'SORT_MY_ASSIGNMENT':
      return {
        ...state,
        isAsc: payload.isAsc,
        myAssignments: searchAndFilterAssignments(payload.isAsc, state.filterOptions, state.searchedText)
      };
    case 'EMPTY_FILTER': {
      let options = [];
      return {
        ...state,
        filterOptions: options,
        myAssignments: searchAndFilterAssignments(state.isAsc, options,state.searchedText)
      }};
    case 'FILTER_MY_ASSIGNMENT': {
      //console.log('FILTER_MY_ASSIGNMENT payload ' + payload.id + ' - ' + payload.type + ' - '+ payload.name + ' - ' + payload.value + ' - ' + payload.checked + ' - ');
      let options = [...state.filterOptions];
      if (payload.type === 'checkbox') {
        options = options.filter(option => option.value !== payload.value);
        payload.checked && options.push({ id: payload.id, type:  payload.type, name: payload.name, value: payload.value });
      }
      // TODO
      // if (type === 'radio') {
      //   const isExists = filterOptions.some(el => el.name === name);
      //   isExists
      //     ? setFilterOptions(
      //         filterOptions.map(el => (el.name === name ? { ...el, value } : el))
      //       )
      //     : setFilterOptions([...filterOptions, { name, value }]);
      // }
      return {
        ...state,
        filterOptions: options,
        myAssignments: searchAndFilterAssignments(state.isAsc, options, state.searchedText)
      }};
    case 'SEARCH_MY_ASSIGNMENT':
      return {
        ...state,
        searchedText: payload.searchedText,
        myAssignments: searchAndFilterAssignments(state.isAsc, state.filterOptions, payload.searchedText)
      };
    default:
      return state;
  }
};
