import React, {useEffect, useRef} from 'react';
import {Button, Card, Col, Row} from 'react-bootstrap';
import Bottombar from 'components/common/Bottombar';
import useVisibilityObserver from 'falcon-react-lib/hooks/useVisibilityObserver';
import PropTypes from "prop-types";

const SubmitProfile = ({saveChanges}) => {
  //console.log(formData, '--> [SubmitProfile] formData ');
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '0px'
  );

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="mb-lg-3 order-lg-0 order-1">
        <Card.Header className="py-2 d-flex flex-between-center bg-light">
          <h5 className="mb-0">Lagre endringer</h5>
        </Card.Header>
        <Card.Body>
          Trykk her for å lagre endringene dine
        </Card.Body>
        <Card.Footer ref={targetElRef} className="py-2 bg-light">
          <Row className="flex-row-reverse g-0">
            <Col xs="auto">
              <Button
                size="md"
                variant="primary"
                className="fw-medium"
                onClick={saveChanges}
              >
                <span>Lagre</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <Bottombar
        inViewport={inViewport}
        buttonText={'Lagre'}
        handleClick={saveChanges}
      />
    </>
  );
};

SubmitProfile.propTypes = {
  saveChanges: PropTypes.func.isRequired,
};

export default SubmitProfile;
