import React, {forwardRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAssignments from "../../../../hooks/useAssignments";
import { registerLocale} from  "react-datepicker";
import nb from 'date-fns/locale/nb';
registerLocale('nb', nb)

const FilterDateInput = forwardRef(({ value, onClick }, ref) => {
  //console.log(value,' -> FilterDateInput');
  return (
    <div className="position-relative">
      <Form.Control
        className="rounded search-input ps-4"
        size="sm"
        ref={ref}
        placeholder="Datobegrensning..."
        onClick={onClick}
        value={value}
        onChange={e => {
          console.log({ e });
        }}
      />
      <FontAwesomeIcon
        icon="calendar-alt"
        className="text-primary position-absolute text-400 top-50 translate-middle-y ms-2"
      />
    </div>
  )
});

const FilterDate = ({searchedFromDate,searchedToDate}) => {
  const datePattern = 'dd.MM.yy'
  //console.log(searchedFromDate, '  -> FilterDate searchedFromDate');
  //console.log(searchedToDate, '  -> FilterDate searchedToDate');
  const { handleSearchOnDates } = useAssignments();
  const [startDate, setStartDate] = useState(searchedFromDate);
  const [endDate, setEndDate] = useState(searchedToDate);
  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleSearchOnDates(start,end);
  };

  useEffect(() => {
    setStartDate(searchedFromDate)
  }, [searchedFromDate]);

  useEffect(() => {
    setEndDate(searchedToDate)
  }, [searchedToDate]);
  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      formatWeekDay={day => day.slice(0, 3)}
      endDate={endDate}
      selectsRange
      dateFormat={datePattern}
      customInput={<FilterDateInput />}
      locale="nb"
      isClearable
      placeholderText="Datobegrensning"
    />
  );
};

FilterDate.propTypes = {
  searchedFromDate: PropTypes.object,
  searchedToDate: PropTypes.object
};


FilterDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default FilterDate;