import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Flex from 'falcon-react-lib/components/common/Flex';
import useUrlHelpers from "hooks/useUrlHelpers";

const getIcon = type => {
  const icon = ['far'];
  if (type.includes('image')) {
    icon.push('file-image');
  }
  if (type.includes('video')) {
    icon.push('file-video');
  }
  if (type.includes('audio')) {
    icon.push('file-audio');
  }
  if (type.includes('zip')) {
    icon.push('file-archive');
  }
  if (type.includes('pdf')) {
    icon.push('file-pdf');
  }
  if (
    type.includes('html') ||
    type.includes('css') ||
    type.includes('json') ||
    type.includes('javascript')
  ) {
    icon.push('file-code');
  }
  if (icon.length === 1) {
    icon.push('file');
  }
  return icon;
};

const getName = fileInfo => {
  let name = 'Undefined';
  if(fileInfo.name) {
    name = fileInfo.name
  } else {
    name = fileInfo.url.slice(fileInfo.url.lastIndexOf("/")+1,fileInfo.url.length);
  }
  if(name.length > 27) {
    const indexOfExtension = name.lastIndexOf(".");
    const fileName = name.substring(0,indexOfExtension);
    const extension = name.substring(indexOfExtension+1,name.length);
    return `${fileName.slice(0, 24)}....${extension}`;
  } else {
    return name;
  }

  //const [fileName, extension] = name.split('.');
  //
};

const getType = fileInfo => {
  const name = getName(fileInfo);
  const indexOfExtension = name.lastIndexOf(".");
  return name.substring(indexOfExtension+1,name.length);
};

const AttachedFile = ({index,fileInfo,handleRemovedFile}) => {
  const {getFileUrlPrivateS3Bucket} = useUrlHelpers();
  const [attachedFileUrl, setAttachedFileUrl] = useState(null);

  //console.log(attachedFileUrl , '  -> [AttachedFile] useEffect attachedFileUrl')
  if(attachedFileUrl===null) {
    //console.log(attachedFileUrl , '  -> [AttachedFile] useEffect attachedFileUrl')
    getFileUrlPrivateS3Bucket(fileInfo.url).then((url) => {
      //console.log(url, '  -> [AttachedFile] getFileUrlPrivateS3Bucket. url')
      setAttachedFileUrl(url);
    })
  }

  return (
    <Flex
      alignItems="center"
      className="border p-3 rounded-3 bg-white dark__bg-1000 my-1 fs--1"
      key={index}
    >
      <FontAwesomeIcon icon={getIcon(getType(fileInfo))} className="fs-1" />
      <a className="mx-2 flex-1 cursor-pointer" onClick={()=> window.open(attachedFileUrl, "_blank")} >
        {getName(fileInfo)}
      </a>
      {handleRemovedFile && (
        <span
          className="text-300 p-1 ml-auto cursor-pointer"
          onClick={() => handleRemovedFile(fileInfo)}
        >
        <FontAwesomeIcon icon="times" />
        </span>
      )}
    </Flex>
)};

AttachedFile.propTypes = {
  index: PropTypes.number.isRequired,
  fileInfo: PropTypes.object.isRequired,
  handleRemovedFile: PropTypes.func
};

export default AttachedFile;
