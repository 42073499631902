import React from 'react';
import {Card} from 'react-bootstrap';
import PropTypes from "prop-types";
import Flex from "../../../falcon-react-lib/components/common/Flex";
import AttachedFile from "../myProfile/editTempInfo/AttachedFile";

const ProfileCV = ({profileData}) => {
  // console.log('[ProfileCV] profileData: ' + JSON.stringify(profileData));

  return (
    <>
      {profileData && profileData.cvFile && profileData.cvFile.url && (
        <Card className="mb-3">
          <Card.Header className="bg-light">
            <h5 className="mb-0">CV</h5>
          </Card.Header>
          <Card.Body>
            <Flex direction="column" className="mt-3">
              <AttachedFile
                key={'attached-file-0'}
                index={0}
                fileInfo={profileData.cvFile}
              />
            </Flex>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

ProfileCV.propTypes = {
  profileData: PropTypes.object.isRequired
};


export default ProfileCV;
