import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Avatar from 'falcon-react-lib/components/common/Avatar';
import {isIterableArray} from 'falcon-react-lib/helpers/utils';
import Flex from 'falcon-react-lib/components/common/Flex';
import FalconCloseButton from 'falcon-react-lib/components/common/FalconCloseButton';
import useUrlHelpers from "hooks/useUrlHelpers";
import validator from 'validator';

const PersonSearchContent = ({ person, handleAddPerson, index }) => {
  const {getAvatarUrl} = useUrlHelpers();
  //console.log(person,' --> PersonSearchContent');
  //console.log(handleAddPerson,' --> PersonSearchContent');
  return (
    <Dropdown.Item key={index} className="px-x1 py-2" onMouseDown={() => handleAddPerson(person)}>
      <Flex alignItems="center">
        <Avatar src={getAvatarUrl(person.avatarSrc, person.name,null)} size="l"  />
        <div className="ms-2">
          <h6 className="mb-0">{person.fullName}</h6>
        </div>
      </Flex>
    </Dropdown.Item>
  );
};


const CompanyPersonsSearchBox = ({ searchForPersons, handleAddPerson, handleAddInvitation }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [resultItem, setResultItem] = useState([]);

  const handleLoadData = (data) => {
    if(data!==undefined&&data!==null) {
      setResultItem(data)
    } else {
      setResultItem([])
    }
  }

  const handleAddPersonFromResult = (person) => {
    //console.log(person,'  ---> handleAddPersonFromResult');
    setResultItem([]);
    setSearchInputValue('');
    handleAddPerson(person);
  }

  const handleAddInvitationFromButton = () => {
    //console.log('  ---> handleAddInvitationFromButton');
    let email = searchInputValue;
    //console.log(email,'  ---> handleAddInvitationFromButton');
    setResultItem([]);
    setSearchInputValue('');
    handleAddInvitation(email);
  }

  const showAddInvitationButton = () => {
    if(searchInputValue.length===0) {
      return false;
    }

    return validator.isEmail(searchInputValue);
  }

  useEffect(() => {
    if (searchInputValue) {
      searchForPersons(searchInputValue, handleLoadData);
    } else {
      //console.log('  --> [CompanyPersonsSearchBox] setResultItem 3')
      setResultItem([]);
    }
    // eslint-disable-next-line
  }, [searchInputValue]);

  return (
    <>
    <p className={'form-label'}>Legg til en person som administrator</p>
    <Dropdown show={dropdownOpen} className="search-box">
      <Form className="position-relative">
          <Form.Control
            type="search"
            placeholder="Epost..."
            aria-label="Epost"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          />
          <FontAwesomeIcon
            icon="search"
            className="position-absolute text-400 search-box-icon"
          />
          {(dropdownOpen || searchInputValue) && (
            <div className="search-box-close-btn-container">
              <FalconCloseButton
                size="sm"
                noOutline
                className="fs--2"
                onClick={() => {
                  setSearchInputValue('');
                  setDropdownOpen(false);
                }}
              />
            </div>
          )}
        </Form>

        <Dropdown.Menu>
          <div className="scrollbar py-3" style={{ maxHeight: '24rem' }}>
            {isIterableArray(resultItem) && (
              <>
                <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                  Personer
                </Dropdown.Header>
                {resultItem.map((person,index) => (
                  <PersonSearchContent
                    person={person}
                    handleAddPerson={handleAddPersonFromResult}
                    index={index}
                    key={index}
                  />
                ))}
              </>
            )}
            {resultItem.length === 0 && (
              <>
                <p className="fs-1 text-center mb-0">Fant ikke personen</p>
                {showAddInvitationButton() && (
                  <p className="fs-1 text-center mb-0 mt-3">
                    <Button
                      variant="primary"
                      size="md"
                      className=""
                      onMouseDown={() => handleAddInvitationFromButton()}
                    >
                      <span className="d-inline-block">Send en invitasjon</span>
                    </Button>

                  </p>
                  )}
              </>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

PersonSearchContent.propTypes = {
  person: PropTypes.object.isRequired,
  handleAddPerson: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

CompanyPersonsSearchBox.propTypes = {
  searchForPersons: PropTypes.func.isRequired,
  handleAddPerson: PropTypes.func.isRequired,
  handleAddInvitation: PropTypes.func.isRequired
};

export default CompanyPersonsSearchBox;
