import React, {useEffect, useReducer, useState} from "react";
import {config} from "../../../Constants";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import {favouritesReducer} from "../../../reducers/favouritesReducer";
import {FavouritesContext} from "../../../context/Context";

const FavouritesProvider = ({ children }) => {
  const initData = {
    favouriteAssignments: [],
    favouritePersons: []
  };
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const [favouritesState, favouritesDispatch] = useReducer(favouritesReducer, initData);

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  const apiUrl = config.url.API_URL;
  //console.log(accessToken,'  -> accessToken');

  const fetchFavouriteAssignments = async () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl + "/favourites/assignments", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        favouritesDispatch({
          type: 'RELOAD_FAVOURITE_ASSIGNMENTS',
          payload: {
            favouriteAssignments: data,
          }
        });
      })
  }

  const fetchFavouritePersons = async () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl + "/favourites/persons", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        favouritesDispatch({
          type: 'RELOAD_FAVOURITE_PERSONS',
          payload: {
            favouritePersons: data,
          }
        });
      })
  }

  useEffect(() => {
    getAccessToken();
  }, [])

  useEffect(() => {
    if(accessToken!=="") {
      fetchFavouriteAssignments();
      fetchFavouritePersons();
    }
  }, [accessToken])

  return (
    <FavouritesContext.Provider
      value={{
        favouritesState,
        favouritesDispatch
      }}
    >
      {children}
    </FavouritesContext.Provider>
  );
};

FavouritesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FavouritesProvider;