/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const categoriesReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'RELOAD_CATEGORIES':
      return {
        ...state,
        competences: payload.categories.competences,
        regions: payload.categories.regions,
        industries: payload.categories.industries,
        currentStudies: payload.categories.currentStudies,
        yearsSeniorityList: payload.categories.yearsSeniorityList
      };
    case 'RELOAD_REGIONS':
      return {
        ...state,
        regions: payload.regions
      };
    case 'RELOAD_COMPETENCES':
      return {
        ...state,
        competences: payload.competences
      };
    case 'RELOAD_INDUSTRIES':
      return {
        ...state,
        industries: payload.industries
      };
    case 'RELOAD_CURRENT_STUDIES':
      return {
        ...state,
        currentStudies: payload.currentStudies
      };
    case 'RELOAD_YEARS_SENIORITY_LIST':
      return {
        ...state,
        yearsSeniorityList: payload.yearsSeniorityList
      };
    default:
      return state;
  }
};
