import React, {useContext, useEffect, useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import { AssignmentContext, MyAssignmentContext} from 'context/Context';
import AssignmentBanner from './AssignmentBanner';
import AssignmentFooter from './AssignmentFooter';
import AssignmentRequirements from './AssignmentRequirements';
import { useParams } from 'react-router-dom';
import AssignmentApply from "./AssignmentApply";
import AssignmentFiles from "./AssignmentFiles";
import callApiAssignments from "../../../../api/callApiAssignments";
import useAuth from "../../../../hooks/useAuth";
import useMyAccess from "../../../../hooks/useMyAccess";
import AssignmentFooterOwner from "./AssignmentFooterOwner";

const Assignmentdetails = () => {
  const { assignmentId } = useParams();
  //console.log(assignmentId,' -> [Assignmentdetails] assignmentId')
  const {fetchAssignment} = callApiAssignments();
  const [assignment, setAssignment] = useState();
  const {assignmentsState: { assignments }} = useContext(AssignmentContext);
  const {myAssignmentsState: { myAssignments }} = useContext(MyAssignmentContext);
  //console.log(assignments,' -> [Assignmentdetails] assignments')
  const {isSuperAdmin} = useAuth();
  const {isCompanyAdminAndHaveAccessToCompany} = useMyAccess();

  const callbackForAssignmentFetch = (data) => {
    // console.log(data,'  -> callbackForAssignmentFetch ');
    setAssignment(data);
  }

  const loadAssignment = () => {
    // console.log(assignment,'  -> loadAssignment 1');
    let assignment = assignments.find(assignment => assignment.id === assignmentId);
    // console.log(assignment,'  -> loadAssignment 2');
    if(assignment===undefined) {
      assignment = myAssignments.find(assignment => assignment.id === assignmentId);
    }
    // console.log(assignment,'  -> loadAssignment 3');
    if(assignment!==undefined) {
      setAssignment(assignment);
    } else {
      // console.log(assignment,'  -> fetchAssignment 4');
      fetchAssignment(assignmentId, callbackForAssignmentFetch);
    }
  }

  useEffect(() => {
    loadAssignment();
  }, [assignmentId]);

  // console.log(assignment,' -> [Assignmentdetails] assignment')
  return assignment ? (
    <>
      <AssignmentBanner assignment={assignment} />
      <Row className="g-lg-3">
        <Col lg={8}>
          {/*className="order-1 order-lg-0"*/}
          <AssignmentRequirements assignment={assignment} />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar top-navbar-height d-flex flex-column">
            <AssignmentApply assignmentId={assignmentId} />
            <AssignmentFiles assignment={assignment} />
            <AssignmentFooter assignment={assignment} />
            {(isSuperAdmin() || isCompanyAdminAndHaveAccessToCompany(assignment.company?.id)) && (
              <AssignmentFooterOwner assignment={assignment} />
            )}
          </div>
        </Col>
      </Row>
      </>
  ) : (
    <span>Oppdraget er ikke tilgjengelig lenger</span>
  );
};

export default Assignmentdetails;
