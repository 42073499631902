/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

import {parse,isAfter,isBefore, isEqual} from 'date-fns'

export const assignmentReducer = (state, action) => {
  const { type, payload } = action;
  const datePattern = 'dd.MM.yyyy'

  const optionsInRegionsArray = function (options) {
    return [...state.regions].filter(region => {
      return options.some((o) => {
        return o.id === region.id;
      });
    }).length > 0;
  }

  const optionsInCompetenceArray = function (options) {
    return [...state.competences].filter(competence => {
      return options.some((o) => {
        return o.id === competence.id;
      });
    }).length > 0;
  }

  const optionsInIndustryArray = function (options) {
    return [...state.industries].filter(industry => {
      return options.some((o) => {
        return o.id === industry.id;
      });
    }).length > 0;
  }

  const optionsInCurrentStudiesArray = function (options) {
    return [...state.currentStudies].filter(currentStudy => {
      return options.some((o) => {
        return o.id === currentStudy.id;
      });
    }).length > 0;
  }

  const searchAndFilterAssignments = function (isAsc, options, searchedText, searchedFromDate, searchedToDate) {
    let searchedAssignments = [...state.primaryAssignments];
    // Filter options
    if (options && options.length > 0) {
      if(optionsInRegionsArray(options)) {
        searchedAssignments = searchedAssignments.filter(assignment => {
          return options.some((o) => {
            return assignment.regions.some((region) => {
              return o.value.toUpperCase() === region.name.toUpperCase();
            });
          });
        });
      }
      // Check if user has clicked a competence filter
      if(optionsInCompetenceArray(options)) {
        // Filter assignments based on chosen competences (options=chosen values)
        searchedAssignments = searchedAssignments.filter(assignment => {
          // Note: some function returns true if condition is met on at least one element
          return options.some((o) => {
            return assignment.competences.some((competence) => {
              return o.value.toUpperCase() === competence.name.toUpperCase();
            });
          });
        });
      }
      if(optionsInIndustryArray(options)) {
        searchedAssignments = searchedAssignments.filter(assignment => {
          return options.some((o) => {
            return assignment.industries.some((industry) => {
              return o.value.toUpperCase() === industry.name.toUpperCase();
            });
          });
        });
      }
    }
    // Check if user has clicked a current studies filter
    if(optionsInCurrentStudiesArray(options)) {
      // Filter assignments based on chosen competences (options=chosen values)
      searchedAssignments = searchedAssignments.filter(assignment => {
        // Note: some function returns true if condition is met on at least one element
        return options.some((o) => {
          return assignment.currentStudies.some((currentStudy) => {
            return o.value.toUpperCase() === currentStudy.name.toUpperCase();
          });
        });
      });
    }

    // Search text
    if(searchedText) {
      // console.log('searchedText ' + searchedText)
      searchedAssignments = searchedAssignments.filter(assignment =>
        assignment.name.toUpperCase().includes(searchedText.toUpperCase())
      );
    }

    if(searchedFromDate) {
      //console.log('[assignmentReducer] searchedFromDate ' + searchedFromDate)
      searchedAssignments = searchedAssignments.filter(assignment => {
        const startDate = parse(assignment.startDate, datePattern, new Date())
        //const endDate = parse(assignment.endDate, datePattern, new Date())
        return isBefore(searchedFromDate,startDate) || isEqual(searchedFromDate,startDate); // && isAfter(searchedFromDate,endDate)
      });
    }

    if(searchedToDate) {
      //console.log('[assignmentReducer]  searchedToDate ' + searchedToDate)
      searchedAssignments = searchedAssignments.filter(assignment => {
        const endDate = parse(assignment.endDate, datePattern, new Date())
        return isAfter(searchedToDate,endDate) ||  isEqual(searchedToDate,endDate)
      });
    }

    // Sort Assignments
    let sortedAssignments = sortAssignment(isAsc,searchedAssignments)

    return sortedAssignments;
  };



  // const sortAssignment = function (isAsc, assignments) {
  //   //console.log('[personReducer] sortPersons. isAsc: ' + isAsc);
  //   let sortedAssignments = assignments.sort((a, b) => {
  //     const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  //     const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  //     if (isAsc) {
  //       if (nameA < nameB) {
  //         return -1;
  //       }
  //       if (nameA > nameB) {
  //         return 1;
  //       }
  //     } else {
  //       if (nameA < nameB) {
  //         return 1;
  //       }
  //       if (nameA > nameB) {
  //         return -1;
  //       }
  //     }
  //     // names must be equal
  //     return 0;
  //   });
  //   // Force relaod of pagination reducer state
  //   sortedAssignments = sortedAssignments.map(e => ({...e, timestamp: new Date().valueOf()}));
  //   return sortedAssignments;
  // }

  const sortAssignment = function (isAsc, assignments) {
    //console.log('[personReducer] sortPersons. isAsc: ' + isAsc);
    let sortedAssignments = assignments.sort((a, b) => {
      const startDate_a = parse(a.startDate, datePattern, new Date())
      const startDate_b = parse(b.startDate, datePattern, new Date())
      if (isAsc) {
        if(isBefore(startDate_a,startDate_b)) {
          return -1;
        }
        if(isAfter(startDate_a,startDate_b)) {
          return 1;
        }
      } else {
        if(isBefore(startDate_a,startDate_b)) {
          return 1;
        }
        if(isAfter(startDate_a,startDate_b)) {
          return -1;
        }
      }
      // dates must be equal
      return 0;
    });
    // Force relaod of pagination reducer state
    sortedAssignments = sortedAssignments.map(e => ({...e, timestamp: new Date().valueOf()}));
    return sortedAssignments;
  }

  switch (type) {
    case 'RELOAD_ASSIGNMENT_FILTERS':
      //console.log('assignmentReducer RELOAD_ASSIGNMENT_FILTERS ');
      return {
        ...state,
        assignmentFilters: payload.assignmentFilters
      };
    case 'RELOAD_CATEGORIES':
      //console.log('assignmentReducer RELOAD_CATEGORIES ');
      return {
        ...state,
        competences: payload.categories.competences,
        regions: payload.categories.regions,
        industries: payload.categories.industries,
        currentStudies: payload.categories.currentStudies
      };
    case 'RELOAD_ASSIGNMENT':
      //console.log('assignmentReducer RELOAD_ASSIGNMENT ');
      return {
        ...state,
        isAsc: true,
        filterOptions: [],
        searchedText: null,
        searchedFromDate: null,
        searchedToDate: null,
        initAssignments: payload.assignments,
        assignments: sortAssignment(true,payload.assignments),
        primaryAssignments: payload.assignments,
      };
    case 'RELOAD_ASSIGNMENT_AND_FILTER':
      //console.log('assignmentReducer RELOAD_ASSIGNMENT_AND_FILTER ');
      return {
        ...state,
        initAssignments: payload.assignments,
        assignments: searchAndFilterAssignments(state.isAsc,state.filterOptions,state.searchedText,state.searchedFromDate,state.searchedToDate),
        primaryAssignments: payload.assignments,
      };
    case 'SORT_ASSIGNMENT':
      //console.log('assignmentReducer SORT_ASSIGNMENT ');
      return {
        ...state,
        isAsc: payload.isAsc,
        assignments: searchAndFilterAssignments(payload.isAsc,state.filterOptions,state.searchedText,state.searchedFromDate,state.searchedToDate)
      };
    case 'EMPTY_FILTER': {
      //console.log('assignmentReducer EMPTY_FILTER ');
      let options = [];
      return {
        ...state,
        filterOptions: options,
        assignments: searchAndFilterAssignments(state.isAsc,options,state.searchedText,state.searchedFromDate,state.searchedToDate)
      }};
    case 'FILTER_ASSIGNMENT': {
      //console.log('assignmentReducer FILTER_ASSIGNMENT ');
      //console.log('FILTER_ASSIGNMENT payload ' + payload.id + ' - ' + payload.type + ' - '+ payload.name + ' - ' + payload.value + ' - ' + payload.checked + ' - ');
      let options = [...state.filterOptions];
      if (payload.type === 'checkbox') {
        options = options.filter(option => option.value !== payload.value);
        payload.checked && options.push({ id: payload.id, type:  payload.type, name: payload.name, value: payload.value });
      }
      // TODO
      // if (type === 'radio') {
      //   const isExists = filterOptions.some(el => el.name === name);
      //   isExists
      //     ? setFilterOptions(
      //         filterOptions.map(el => (el.name === name ? { ...el, value } : el))
      //       )
      //     : setFilterOptions([...filterOptions, { name, value }]);
      // }
      return {
        ...state,
        filterOptions: options,
        assignments: searchAndFilterAssignments(state.isAsc,options, state.searchedText,state.searchedFromDate,state.searchedToDate)
      }};
    case 'SEARCH_ASSIGNMENT_TEXT':
      //console.log('assignmentReducer SEARCH_ASSIGNMENT_TEXT ');
      return {
        ...state,
        searchedText: payload.searchedText,
        assignments: searchAndFilterAssignments(state.isAsc,state.filterOptions, payload.searchedText,state.searchedFromDate,state.searchedToDate)
      };
    case 'SEARCH_ASSIGNMENT_ON_DATES':
      //console.log('assignmentReducer SEARCH_ASSIGNMENT_ON_DATES ');
      //console.log('assignmentReducer SEARCH_ASSIGNMENT_ON_DATES. payload ' + payload.searchedFromDate + ' - ' + payload.searchedToDate);
      return {
        ...state,
        searchedFromDate: payload.searchedFromDate,
        searchedToDate: payload.searchedToDate,
        assignments: searchAndFilterAssignments(state.isAsc,state.filterOptions, state.searchedText,payload.searchedFromDate,payload.searchedToDate)
      };
    default:
      return state;
  }
};
