import Compressor from 'compressorjs';
import S3 from 'react-aws-s3';
import callApiFiles from "../api/callApiFiles";
import {v4 as uuid} from "uuid";
import {config} from "../Constants";
import {useContext} from "react";
import {MyProfileContext} from "../context/Context";
window.Buffer = window.Buffer || require("buffer").Buffer;

const useS3Upload = () => {
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);
  const {retrievePresignedS3UploadUrl,retrieveS3Credentials} = callApiFiles();
  const awsS3BucketPublicUpload = config.url.AWS_S3_BUCKET_PUBLIC_UPLOAD;
  const awsS3BucketPrivateUpload = config.url.AWS_S3_BUCKET_PRIVATE_UPLOAD;
  const awsRegion = config.url.AWS_REGION;

  const getS3Key = (fileName) => {
    // console.log(fileName,  ' --> getS3Key');
    let s3Key = uuid();
    if(myProfilePerson) {
      s3Key = myProfilePerson.id + "/" + s3Key
    }
    s3Key = s3Key + "_" + fileName;
    return s3Key;
  }

  const uploadFilePublicBucket = async (file, handleFileUploadResponse) => {
    let encodedFileName = getS3Key(file.name);
    //console.log(encodedFileName,' --> [uploadFilePublicBucket] encodedFileName');
    retrieveS3Credentials()
      .then((credentials) => {
        const uploadPublicBucketConfig = {
          bucketName: awsS3BucketPublicUpload,
          region: awsRegion,
          accessKeyId: credentials.accessKey,
          secretAccessKey: credentials.secret,
        }
        const ReactS3Client = new S3(uploadPublicBucketConfig);
        ReactS3Client
          .uploadFile(file, encodedFileName)
          .then(() => {
            //console.log(data.location, '  -> uploadFilePublicBucket.ReactS3Client ');
            const fileInfo = {
              name: file.name,
              url: encodedFileName
            }
            handleFileUploadResponse(fileInfo);
          })
          .catch(err => console.error(err))
      });
  }

  const uploadFilePrivateBucket = async (file, handleFileUploadResponse) => {
    //console.log(file.size,' file size uploadFile');
    let encodedFileName = getS3Key(file.name);
    // console.log(encodedFileName,' --> encodedFileName');
    retrievePresignedS3UploadUrl(awsS3BucketPrivateUpload,encodedFileName)
      .then((uploadUrl) => {
        // console.log('[uploadFilePrivateBucket] uploadFile. Url: ' + uploadUrl);
        let formData = new FormData();
        formData.append("file", file);
        const requestOptions = {
          method: 'PUT',
          body: formData
        };
        //console.log('[uploadFilePrivateBucket] uploadFile. Url: ' + uploadUrl);
        return fetch(uploadUrl, requestOptions);
      })
      .then(async response => {
        if(response.ok) {
          const fileInfo = {
            name: file.name,
            url: encodedFileName
          }
          handleFileUploadResponse(fileInfo);
        }
      });
  }

  const uploadProfileAvatar = async (file, handleFileUploadResponse) => {
    compressAndUploadFilePublicBucket(file,350,350,handleFileUploadResponse);
  }

  const uploadCoverPhoto = async (file, handleFileUploadResponse) => {
    compressAndUploadFilePublicBucket(file,1000,1000,handleFileUploadResponse);
  }

  const uploadFeedPhoto = async (file, handleFileUploadResponse) => {
    compressAndUploadFilePublicBucket(file,1500,1500,handleFileUploadResponse);
  }

  const compressAndUploadFilePrivateBucket = async (file, maxWidth, maxHeight, handleFileUploadResponse) => {
    //console.log(file.size,' file size compressAndUploadFile');
    //console.log(file,' file compressAndUploadFile');
    if((file.type==='image/jpeg' || file.type==='image/png')) {
      // console.log(' [compressAndUploadFilePrivateBucket] Resizing ');
      new Compressor(file, {
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        success: (compressedResult) => {
          if(compressedResult.size > 500000) {
            // console.log(compressedResult.size,' [compressAndUploadFilePrivateBucket] Compression IS needed ');
            const qualityFactor = 500000 / file.size;
            // console.log(qualityFactor,' [compressAndUploadFilePrivateBucket] compressing with this qualityFactor. ');
            new Compressor(file, {
              quality: qualityFactor,
              success: (compressedResult) => {
                // console.log(compressedResult.size,' [compressAndUploadFilePrivateBucket] done compressing ');
                uploadFilePrivateBucket(compressedResult,handleFileUploadResponse);
              },
            });
          } else {
            // console.log(compressedResult.size,' [compressAndUploadFilePrivateBucket] no compression needed');
            uploadFilePrivateBucket(compressedResult,handleFileUploadResponse);
          }
        },
      });
    } else {
      uploadFilePrivateBucket(file,handleFileUploadResponse);
    }
  }

  const compressAndUploadFilePublicBucket = async (file, maxWidth, maxHeight, handleFileUploadResponse) => {
    //console.log(file,' file compressAndUploadFile');
    if((file.type==='image/jpeg' || file.type==='image/png')) {
      // console.log(' [compressAndUploadFilePublicBucket] Resizing ');
      new Compressor(file, {
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        success: (compressedResult) => {
          if(compressedResult.size > 500000) {
            // console.log(compressedResult.size,' [compressAndUploadFilePublicBucket] Compression IS needed ');
            const qualityFactor = 500000 / file.size;
            // console.log(qualityFactor,' [compressAndUploadFilePublicBucket] compressing with this qualityFactor. ');
            new Compressor(file, {
              quality: qualityFactor,
              success: (compressedResult) => {
                // console.log(compressedResult.size,' [compressAndUploadFilePublicBucket] done compressing ');
                uploadFilePublicBucket(compressedResult,handleFileUploadResponse);
              },
            });
          } else {
            // console.log(compressedResult.size,' [compressAndUploadFilePublicBucket] no compression needed');
            uploadFilePublicBucket(compressedResult,handleFileUploadResponse);
          }
        },
      });
    } else {
      uploadFilePublicBucket(file,handleFileUploadResponse);
    }
  }

  return {
    uploadFilePublicBucket,
    uploadFilePrivateBucket,
    uploadProfileAvatar,
    uploadCoverPhoto,
    uploadFeedPhoto,
    compressAndUploadFilePublicBucket,
    compressAndUploadFilePrivateBucket
  };
};

export default useS3Upload;