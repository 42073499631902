import React, {useEffect, useState} from 'react';
import {Card, Row, Col, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
import callApiFavourites from "../../../../api/callApiFavourites";
import Flex from "../../../../falcon-react-lib/components/common/Flex";
import AssignmentCategoriesPills from "../../../app/assignment/AssignmentCategoriesPills";
import Avatar from "../../../../falcon-react-lib/components/common/Avatar";
import useUrlHelpers from "../../../../hooks/useUrlHelpers";


const FavouriteAssignments = () => {

  const {fetchAllFavouriteAssignmentsExtended, removeFavouriteAssignment} = callApiFavourites();
  const [favouriteAssignmentsExtended, setFavouriteAssignmentsExtended] = useState([]);
  //console.log(favouriteAssignmentsExtended, '   -> favouriteAssignmentsExtended ');

  const {getCompanyAvatarUrl} = useUrlHelpers();

  const clickRemoveFavouriteAssignment = (id) => {
    //console.log(id, '   -> clickRemoveFavouriteAssignment ');
    removeFavouriteAssignment(id, fetchAndLoadFavouriteAssignment);
  }

  const fetchAndLoadFavouriteAssignment = () => {
    fetchAllFavouriteAssignmentsExtended().then(data => {
      setFavouriteAssignmentsExtended(data);
    });
  }

  useEffect(() => {
    fetchAndLoadFavouriteAssignment();
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <Row>
          <Col>
            <h5 className="mb-1 mb-md-0">Favoritt oppdrag</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Row className="">
          {favouriteAssignmentsExtended.length === 0  && (
            <Col key={'999'} className="p-2">
              <div className="ps-3">{' - Ingen -'}</div>
            </Col>
          )}
          {favouriteAssignmentsExtended.map((favAssignmentRow, index) => (
            <Col key={index} xs={12}>
              {index>0 && (
                <div className="border-dashed border-bottom"/>
              )}
              <Row className="g-0">
                <Col className="p-x1">
                  <Row className="g-0 h-100">
                    <Col as={Flex} className="flex-column pe-x1">
                      <AssignmentCategoriesPills assignment={favAssignmentRow.assignment} />

                      <Flex className="mb-2">
                        <div className="d-flex" to={`/company/${favAssignmentRow.assignment.company.id}`}>
                          <Avatar size="xl" src={getCompanyAvatarUrl(favAssignmentRow.assignment.company)}/>
                        </div>
                        <div className="flex-1 align-self-center ms-2">
                          <h5 className="fs-0" >
                            <Link
                              to={`/company/${favAssignmentRow.assignment.company.id}`}
                            >
                              {favAssignmentRow.assignment.company?.name}
                            </Link>
                          </h5>
                        </div>
                      </Flex>
                      <h4 className="mt-3 mt-sm-0 fs-0 fs-lg-1">
                        <Link
                          to={`/assignment/assignment-details/${favAssignmentRow.assignment.id}`}
                          className="text-900"
                        >
                          {favAssignmentRow.assignment.name}
                        </Link>
                      </h4>
                      {/*<p className="fs--1 mt-2">*/}
                      {/*  {fixDescription(favAssignmentRow.assignment.description)}...*/}
                      {/*</p>*/}
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto" className="d-flex flex-between-end flex">
                  <Row className="flex-row-reverse p-3">
                    <Col>
                      <Button
                        variant="falcon-danger"
                        size="md"
                        className="w-medium"
                        onClick={e => {
                          e.preventDefault();
                          clickRemoveFavouriteAssignment(favAssignmentRow.id);
                        }}
                      >
                        Fjern
                      </Button>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default FavouriteAssignments;
