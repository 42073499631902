import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import AppContext from 'falcon-react-lib/context/Context';
import {format} from 'date-fns'


const AvailabilityCalendarEventModal = ({
                                          setIsOpenEventModal,
                                          isOpenEventModal,
                                          event,
                                          handleRemoveMyAvailabilityEvent
                                        }) => {
  const datePattern = 'dd.MM.yyyy'
  const {config: {isDark}} = useContext(AppContext);
  const [formData, setFormData] = useState(event);

  const deleteEvent = () => {
    handleRemoveMyAvailabilityEvent(formData.id)
    setIsOpenEventModal(!isOpenEventModal);
  };
  const handleClose = () => {
    setIsOpenEventModal(!isOpenEventModal);
  };
  useEffect(() => {
    if (isOpenEventModal) {
      setFormData(event);
    }
  }, [isOpenEventModal, event]);
  //console.log(formData, ' -> [AvailabilityCalendarEventModal] formData')
  return (
    <Modal
      show={isOpenEventModal}
      onHide={handleClose}
      contentClassName="border"
    >
      {/*<Form>*/}
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">Tilgjengelighetsperiode</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          {/*<Form.Group className="mb-3" controlId="titleInput">*/}
          {/*  <Form.Label className="fs-0">Title</Form.Label>*/}
          {/*  <Form.Control*/}
          {/*    type="text"*/}
          {/*    name="title"*/}
          {/*    required*/}
          {/*    onChange={handleChange}*/}
          {/*  />*/}
          {/*</Form.Group>*/}

          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label className="fs-0">Fra</Form.Label>
            <Form.Control
              type="text"
              name="from"
              required
              readOnly={true}
              disabled={true}
              value={format(event.start,datePattern)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="endDate">
            <Form.Label className="fs-0">Til</Form.Label>
            <Form.Control
              type="text"
              name="from"
              required
              readOnly={true}
              disabled={true}
              value={format(event.end,datePattern)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          {/*<Link to="#!" className="me-3 text-600">*/}
          {/*  More options*/}
          {/*</Link>*/}
          <Button
            variant="falcon-danger"
            type="button"
            onClick={deleteEvent}
            className="px-4 mx-0 me-2"
          >
            Slett
          </Button>
          <Button
            variant="outline-primary"
            type="button"
            onClick={handleClose}
            className="px-4 mx-0"
          >
            Lukk
          </Button>
        </Modal.Footer>
      {/*</Form>*/}
    </Modal>
  );
};


AvailabilityCalendarEventModal.propTypes = {
  setIsOpenEventModal: PropTypes.func.isRequired,
  isOpenEventModal: PropTypes.bool.isRequired,
  event: PropTypes.object.isRequired,
  handleRemoveMyAvailabilityEvent: PropTypes.func.isRequired
};

export default AvailabilityCalendarEventModal;
