import React, {useEffect, useState} from 'react';
import {Card, Row, Col, Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
import callApiFavourites from "../../../../api/callApiFavourites";
import useUrlHelpers from "../../../../hooks/useUrlHelpers";


const FavouritePersons = () => {
  const {getAvatarUrl} = useUrlHelpers();
  const {fetchAllFavouritePersonsExtended, removeFavouritePerson} = callApiFavourites();
  const [favouritePersonsExtended, setFavouritePersonsExtended] = useState([]);
  //console.log(favouritePersonsExtended, '   -> favouritePersonsExtended ');

  const clickRemoveFavouritePerson = (id) => {
    //console.log(id, '   -> clickRemoveFavouritePerson ');
    removeFavouritePerson(id, fetchAndLoadFavouritePersons);
  }

  const fetchAndLoadFavouritePersons = () => {
    fetchAllFavouritePersonsExtended().then(data => {
      setFavouritePersonsExtended(data);
    });
  }

  useEffect(() => {
    fetchAndLoadFavouritePersons();
  }, []);

  return (
    <Card>
      <Card.Header className="bg-light">
        <Row>
          <Col>
            <h5 className="mb-1 mb-md-0">Favoritt profiler</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Row className="">
          {favouritePersonsExtended.length === 0  && (
            <Col key={'999'} className="p-2">
              <div className="ps-3">{' - Ingen -'}</div>
            </Col>
          )}
          {favouritePersonsExtended.map((favPersonRow, index) => (
            <Col key={index} xs={12}>
              {index>0 && (
                <div className="border-dashed border-bottom"/>
              )}
              <Row className="g-0">
                <Col className="p-x1">
                  <div className="d-flex align-items-center">
                    <div className="avatar avatar-3xl">
                      <img className="rounded-circle img-thumbnail shadow-sm" src={getAvatarUrl(favPersonRow.person.avatarSrc, favPersonRow.person.firstName,favPersonRow.person.lastName)} alt="" />
                    </div>
                    <div className="ps-2">
                      <h5 className="fs-0" >
                        <Link
                          to={`/profile/${favPersonRow.person.id}`}
                        >
                          {favPersonRow.person.firstName} {favPersonRow.person.lastName}
                        </Link>
                      </h5>
                    </div>
                  </div>
                </Col>
                <Col xs="auto" className="d-flex flex-between-end flex">
                  <Row className="flex-row-reverse p-3">
                    <Col>
                      <Button
                        variant="falcon-danger"
                        size="md"
                        className="w-medium"
                        onClick={e => {
                          e.preventDefault();
                          clickRemoveFavouritePerson(favPersonRow.id);
                        }}
                      >
                        Fjern
                      </Button>
                    </Col>
                  </Row>

                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default FavouritePersons;
