import React from 'react';
import corner4 from 'falcon-react-lib/assets/img/illustrations/corner-4.png';
import { Card, Col, Row } from 'react-bootstrap';
import Background from 'falcon-react-lib/components/common/Background';
import PropTypes from "prop-types";

const ApplyHeader = () => {
  return (
    <Card className="mb-3">
      <Background
        image={corner4}
        className="bg-card d-none d-md-block"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body className="position-relative">
        <Row>
          <Col xl={10}>
            <h3>
              Meld din interesse
              </h3>
            <p className="mb-0">
              For å melde din interesse for dette oppdraget så sender du en melding til oppdragsgiver for å opprette kontakt. Oppdragsgiver kan også se på din profil for mer informasjon om deg.
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ApplyHeader.propTypes = {
  assignmentId: PropTypes.string
};

export default ApplyHeader;
