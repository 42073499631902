import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'falcon-react-lib/helpers/initFA';
import { Auth0Provider } from '@auth0/auth0-react';
import {config} from "./Constants";

const container = document.getElementById('main');
const root = createRoot(container);
const auth0Domain = config.url.AUTH0_DOMAIN;
const auth0ClientId = config.url.AUTHO_CLIENT_ID;
const auth0Audience = config.url.AUTH0_AUDIENCE;

root.render(
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: auth0Audience
    }}
  >
    <Main>
      <App/>
    </Main>
  </Auth0Provider>
);
