import { useContext } from 'react';
import { CompanyContext } from '../context/Context';

const useCompanies = () => {
  const {companiesDispatch: companiesDispatch} = useContext(CompanyContext);

  const handleLoadMyCompanies = (myCompanies) => {
    //console.log(myCompanies,' ->[useCompanies] handleLoadMyCompanies')
    companiesDispatch({
      type: 'RELOAD_MY_COMPANY',
      payload: {
        myCompanies: myCompanies
      }
    });
  };

  const handleLoadCompanies = (companies) => {
    //console.log(companies,' ->[useCompanies] handleLoadCompanies')
    companiesDispatch({
      type: 'RELOAD_COMPANY',
      payload: {
        companies: companies
      }
    });
  };


  const handleSearch = searchedText => {
    companiesDispatch({
      type: 'SEARCH_COMPANY',
      payload: {
        searchedText
      }
    });
  };

  const handleIsAscClick = isAsc => {
    companiesDispatch({
      type: 'SORT_COMPANY',
      payload: {
        isAsc: isAsc
      }
    });
  };

  return {
    handleLoadMyCompanies,
    handleLoadCompanies,
    handleSearch,
    handleIsAscClick
  };
};

export default useCompanies;
