import React, { useEffect, useRef } from 'react';
import { Button, Card } from 'react-bootstrap';
import Bottombar from 'components/common/Bottombar';
import useVisibilityObserver from 'falcon-react-lib/hooks/useVisibilityObserver';
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

const AssignmentApply = ({assignmentId}) => {
  //console.log(formData, '--> [SubmitCompany] formData ');
  const navigate = useNavigate();
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '0px'
  );

  const submitInterest = () => {
    // console.log('submitInterest');
    navigate("/assignment/apply/"+assignmentId);
  }

  useEffect(() => {
    return () => {
      observer &&
      targetElRef.current &&
      observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="mb-lg-3 order-lg-0 order-1">
        <Card.Header className="py-2 d-flex flex-between-center bg-light">
          <h5 className="mb-0">Meld din interesse</h5>
        </Card.Header>
        <Card.Body ref={targetElRef}>
          <div className="text-center">
              <Button
                size="md"
                variant="primary"
                className="fw-medium"
                onClick={submitInterest}
              >
                <span>Jeg er interessert</span>
              </Button>
          </div>
        </Card.Body>
      </Card>
      <Bottombar
        inViewport={inViewport}
        buttonText={'Jeg er interessert'}
        handleClick={submitInterest}
      />
    </>
  );
};

AssignmentApply.propTypes = {
  assignmentId: PropTypes.string
};

export default AssignmentApply;
