import PropTypes from "prop-types";
import {Button, Modal} from 'react-bootstrap';
import React, {useContext} from "react";
import AppContext from "../../falcon-react-lib/context/Context";

const SubmitFormWarning = ({ showModal, hideModal, mandatoryMissingFields }) => {
  //console.log(mandatoryMissingFields, ' -> [SubmitFormWarning]')
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      contentClassName="border"
    >
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">Fyll ut følgende felter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <ul>
          {mandatoryMissingFields.map((missingField,index) => (
            <li key={index}>{missingField}</li>
          ))}
          </ul>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button
            variant="primary"
            type="submit"
            onClick={() => hideModal(false)}
            className="px-4 mx-0"
          >
            OK
          </Button>
        </Modal.Footer>
    </Modal>
  )};

SubmitFormWarning.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  mandatoryMissingFields: PropTypes.array
};

export default SubmitFormWarning;