/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

import {isAfter, isBefore, isEqual, parseISO} from "date-fns";

export const tempReducer = (state, action) => {
  const { type, payload } = action;


  const optionsInRegionsArray = function (options) {
    return [...state.regions].filter(region => {
      return options.some((o) => {
        return o.id === region.id;
      });
    }).length > 0;
  }

  const optionsInCompetenceArray = function (options) {
    return [...state.competences].filter(competence => {
      return options.some((o) => {
        return o.id === competence.id;
      });
    }).length > 0;
  }

  const optionsInIndustryArray = function (options) {
    return [...state.industries].filter(industry => {
      return options.some((o) => {
        return o.id === industry.id;
      });
    }).length > 0;
  }

  const optionsInCurrentStudiesArray = function (options) {
    return [...state.currentStudies].filter(currentStudy => {
      return options.some((o) => {
        return o.id === currentStudy.id;
      });
    }).length > 0;
  }

  const searchAndFilterTemps = function (isAsc, temps, options, searchedText, searchedFromDate, searchedToDate, availabilityPercent) {
    //console.log('[tempReducer] searchAndFilterTemps. isAsc ' + isAsc + ' searchedText '+ searchedText + ' options ' + JSON.stringify(options) + ' temps ' + temps.length)
    let filteredTemps = temps;
    // Filter options
    if (options && options.length > 0) {
      if(optionsInRegionsArray(options)) {
        filteredTemps = filteredTemps.filter(temp => {
          //console.log('Loop 1. temp ' + temp.firstName )
          return options.some((o) => {
            //console.log('Loop 2. option ' + o.name )
            return temp.interestedInRegions.filter(region => {
              return o.value.toUpperCase() === region.name.toUpperCase();
            }).length > 0;
          });
        });
      }
      if(optionsInCompetenceArray(options)) {
        filteredTemps = filteredTemps.filter(temp => {
          return options.some((o) => {
            return temp.competences.filter(competence => {
              return o.value.toUpperCase() === competence.name.toUpperCase();
            }).length > 0;
          });
        });
      }
      if(optionsInIndustryArray(options)) {
        filteredTemps = filteredTemps.filter(temp => {
          return options.some((o) => {
            return temp.interestedInIndustries.filter(industry => {
              return o.value.toUpperCase() === industry.name.toUpperCase();
            }).length > 0;
          });
        });
      }
      if(optionsInCurrentStudiesArray(options)) {
        filteredTemps = filteredTemps.filter(temp => {
          return options.some((o) => {
            return temp.currentStudies.some((currentStudy) => {
              return o.value.toUpperCase() === currentStudy.name.toUpperCase();
            });
          });
        });
      }
    }

    // Search text
    let searchedTemps = filteredTemps;
    if(searchedText) {
      // console.log('searchedText ' + searchedText)
      searchedTemps = searchedTemps.filter(temp =>
        temp.fullName.toUpperCase().includes(searchedText.toUpperCase())
      );
    }

    if(searchedFromDate) {
      // console.log('tempReducer searchedFromDate ' + searchedFromDate)
      searchedTemps = searchedTemps.filter(temp => {
        if(temp.availabilityStatus==='ALWAYS') {
          return true;
        }
        if(temp.availabilityStatus==='NONE') {
          return false;
        }
        if(temp.availabilityEvents===undefined||temp.availabilityEvents.length===0) {
          return false;
        }
        // Note: some function returns true if condition is met on at least one element
        return temp.availabilityEvents.some((event) => {
          const startDate = parseISO(event.startDate);
          const endDate = parseISO(event.endDate);
          // console.log('tempReducer startDate ' + startDate)
          // console.log('tempReducer endDate ' + endDate)
          // console.log('tempReducer isEqual ' + isEqual(searchedFromDate,startDate))
          return (isAfter(searchedFromDate,startDate) || isEqual(searchedFromDate,startDate)) &&
            (isBefore(searchedFromDate,endDate) || isEqual(searchedFromDate,endDate))
        });
      });
    }

    if(searchedToDate) {
      // console.log('tempReducer searchedToDate ' + searchedToDate)
      searchedTemps = searchedTemps.filter(temp => {
        if(temp.availabilityStatus==='ALWAYS') {
          return true;
        }
        if(temp.availabilityStatus==='NONE') {
          return false;
        }
        if(temp.availabilityEvents===undefined||temp.availabilityEvents.length===0) {
          return false;
        }
        // Note: some function returns true if condition is met on at least one element
        return temp.availabilityEvents.some((event) => {
          const startDate = parseISO(event.startDate);
          const endDate = parseISO(event.endDate);
          // console.log('tempReducer startDate ' + startDate)
          // console.log('tempReducer endDate ' + endDate)
          return (isAfter(searchedToDate,startDate) || isEqual(searchedToDate,startDate)) &&
            (isBefore(searchedToDate,endDate) || isEqual(searchedToDate,endDate))
        });
      });
    }

    // Search Availability Percent
    if(availabilityPercent && availabilityPercent>0) {
      // console.log('availabilityPercent ' + availabilityPercent)
      searchedTemps = searchedTemps.filter(temp => {
        // console.log('temp.percentAvailability ' + temp.percentAvailability)
        return temp.percentAvailability >= availabilityPercent;
      });
    }

    // Sort temps
    let sortedTemps = sortTemps(isAsc,searchedTemps)

    return sortedTemps;
  };


  const sortTemps = function (isAsc, temps) {
    //console.log('[tempReducer] sortTemps. isAsc: ' + isAsc);
    let sortedTemps = temps.sort((a, b) => {
      const nameA = a.fullName.toUpperCase(); // ignore upper and lowercase
      const nameB = b.fullName.toUpperCase(); // ignore upper and lowercase
      if (isAsc) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
      }
      // names must be equal
      return 0;
    });
    // Force relaod of pagination reducer state
    sortedTemps = sortedTemps.map(e => ({...e, timestamp: new Date().valueOf()}));
    return sortedTemps;
  }

  switch (type) {
    case 'RELOAD_TEMPS_FILTERS':
      return {
        ...state,
        tempFilters: payload.tempFilters
      };
    case 'RELOAD_CATEGORIES':
      return {
        ...state,
        competences: payload.categories.competences,
        regions: payload.categories.regions,
        industries: payload.categories.industries,
        currentStudies: payload.categories.currentStudies
      };
    // case 'RELOAD_REGIONS':
    //   return {
    //     ...state,
    //     regions: payload.regions
    //   };
    // case 'RELOAD_COMPETENCES':
    //   return {
    //     ...state,
    //     competences: payload.competences
    //   };
    // case 'RELOAD_INDUSTRIES':
    //   return {
    //     ...state,
    //     industries: payload.industries
    //   };
    case 'RELOAD_TEMPS_AND_FILTER':
      console.log(state.filterOptions, ' -> RELOAD_TEMPS_AND_FILTER. filterOptions')
      return {
        ...state,
        initTemps: payload.temps,
        temps: searchAndFilterTemps(state.isAsc, payload.temps,state.filterOptions,state.searchedText,state.searchedFromDate,state.searchedToDate,state.availabilityPercent),
        primaryTemps: payload.temps,
      };
    case 'RELOAD_TEMPS':
      return {
        ...state,
        //competences: [],
        //industries: [],
        //regions: [],
        //tempFilters: [],
        filterOptions: [],
        searchedText: null,
        searchedFromDate: null,
        searchedToDate: null,
        availabilityPercent: null,
        isAsc: true,
        initTemps: payload.temps,
        temps: payload.temps,
        primaryTemps: payload.temps,
      };
    case 'SORT_TEMPS':
      //console.log('SORT_PERSON order ' + payload.isAsc);
      return {
        ...state,
        isAsc: payload.isAsc,
        temps: searchAndFilterTemps(payload.isAsc, state.primaryTemps,state.filterOptions,state.searchedText,state.searchedFromDate,state.searchedToDate,state.availabilityPercent)
      };
    case 'EMPTY_FILTER': {
      let options = [];
      return {
        ...state,
        filterOptions: options,
        temps: searchAndFilterTemps(state.isAsc,state.primaryTemps,options,state.searchedText,state.searchedFromDate,state.searchedToDate,state.availabilityPercent)
      }};
    case 'FILTER_TEMPS': {
      //console.log('FILTER_PERSON payload ' + payload.id + ' - ' + payload.type + ' - '+ payload.name + ' - ' + payload.value + ' - ' + payload.checked + ' - ');
      let options = [...state.filterOptions];
      if (payload.type === 'checkbox') {
        options = options.filter(option => option.value !== payload.value);
        payload.checked && options.push({ id: payload.id, type:  payload.type, name: payload.name, value: payload.value });
      }
      // TODO
      // if (type === 'radio') {
      //   const isExists = filterOptions.some(el => el.name === name);
      //   isExists
      //     ? setFilterOptions(
      //         filterOptions.map(el => (el.name === name ? { ...el, value } : el))
      //       )
      //     : setFilterOptions([...filterOptions, { name, value }]);
      // }
      console.log(options, ' -> FILTER_TEMPS options ');
      return {
        ...state,
        filterOptions: options,
        temps: searchAndFilterTemps(state.isAsc,state.primaryTemps,options, state.searchedText,state.searchedFromDate,state.searchedToDate,state.availabilityPercent)
      }};
    case 'SEARCH_TEMPS':
      return {
        ...state,
        searchedText: payload.searchedText,
        temps: searchAndFilterTemps(state.isAsc,state.primaryTemps,state.filterOptions, payload.searchedText,state.searchedFromDate,state.searchedToDate,state.availabilityPercent)
      };
    case 'SEARCH_TEMPS_ON_DATES':
      //console.log('SEARCH_ASSIGNMENT_ON_DATES. payload ' + payload.searchedFromDate + ' - ' + payload.searchedToDate);
      return {
        ...state,
        searchedFromDate: payload.searchedFromDate,
        searchedToDate: payload.searchedToDate,
        temps: searchAndFilterTemps(state.isAsc,state.primaryTemps,state.filterOptions, state.searchedText,payload.searchedFromDate,payload.searchedToDate,state.availabilityPercent)
      };
    case 'SEARCH_TEMPS_ON_AVAIL_PERCENT':
      //console.log('SEARCH_ASSIGNMENT_ON_DATES. payload ' + payload.searchedFromDate + ' - ' + payload.searchedToDate);
      return {
        ...state,
        availabilityPercent: payload.availabilityPercent,
        temps: searchAndFilterTemps(state.isAsc,state.primaryTemps,state.filterOptions, state.searchedText,state.searchedFromDate,state.searchedToDate,payload.availabilityPercent)
      };
    default:
      return state;
  }
};
