import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Button, Nav, Stack} from 'react-bootstrap';
import ChatThread from './ChatThread';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import {ChatContext} from 'falcon-react-lib/context/Context';
import ChatBottomBar from "./ChatBottomBar";

const ChatSidebar = ({ hideSidebar, showRemovedThreads, setShowRemovedThreads }) => {
  const {chatState: { threads }} = useContext(ChatContext);

  const handleShowSoftDeletedThreads = () => {
    setShowRemovedThreads(!showRemovedThreads);
  }


  return (
    <div className={classNames('chat-sidebar', { 'start-0': hideSidebar })}>
      <div className="contacts-list">
        <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
          <Nav className="border-0">
            {threads.map((thread) => (
              <ChatThread thread={thread} index={thread.threadIndex} key={thread.threadIndex} />
            ))}

            <Nav.Link
              eventKey={9999}
              className={classNames(`chat-contact hover-actions-trigger p-3 read-message d-md-none`)}
            >
              <div className="contacts-show-hidden-threads-wrapper">
                <Stack className="contacts-show-hidden-threads-wrapper-element">
                  {threads.length === 0 && (
                    <div>
                      Du har ingen samtaler
                    </div>
                  )}
                  <div>
                    <Button
                      variant="link"
                      size="sm"
                      className=""
                      type="button"
                      onClick={() => {
                        handleShowSoftDeletedThreads()
                      }}
                    >
                      {showRemovedThreads ? 'Skjul fjernede samtaler' : 'Vis tidligere fjernede samtaler'}
                    </Button>
                  </div>
                </Stack>
              </div>
            </Nav.Link>

          </Nav>
        </SimpleBarReact>
      </div>
      <ChatBottomBar showRemovedThreads={showRemovedThreads} setShowRemovedThreads={setShowRemovedThreads}/>
    </div>
  );
};

ChatSidebar.propTypes = {
  hideSidebar: PropTypes.bool,
  showRemovedThreads: PropTypes.bool.isRequired,
  setShowRemovedThreads: PropTypes.func.isRequired
};

export default ChatSidebar;
