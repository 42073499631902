import React, {useState} from 'react';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
// import callApiOpenAi from "../../../../api/callApiOpenAi";
// import SubmitFormWarning from "../../../confirmation/SubmitFormWarning";
import AiIntroductionForm from "./AiIntroductionForm";

const EditProfileIntro = ({ formData, setFormData }) => {
  const [displayAiIntroModal, setDisplayAiIntroModal] = useState(false);

  const hideAiIntroModal = () => {
    setDisplayAiIntroModal(false);
  };

  const openAiIntroModal = () => {
    setDisplayAiIntroModal(true);
  }

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0 d-inline-block">
            Endre profil
          </h5>
        </Card.Header>
        <Card.Body>
          {/*<Form onSubmit={handleSubmit}>*/}
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="firstName">
                <Form.Label>Fornavn<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Fornavn"
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="lastName">
                <Form.Label>Etternavn<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Etternavn"
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6} controlId="email">
                <Form.Label>Epost<span className="text-danger">*</span></Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Epost"
                  value={formData.email}
                  name="email"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="phone">
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Telefon"
                  value={formData.phone}
                  name="phone"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            {/*<Form.Group className="mb-3" controlId="heading">*/}
            {/*  <Form.Label>Tittel</Form.Label>*/}
            {/*  <Form.Control*/}
            {/*    type="text"*/}
            {/*    placeholder="Tittel - Feks: 'Sosionom og Gründer'"*/}
            {/*    value={formData.professionalTitle}*/}
            {/*    name="professionalTitle"*/}
            {/*    onChange={handleChange}*/}
            {/*  />*/}
            {/*</Form.Group>*/}

            <Form.Group className="mb-3" controlId="heading">
              <Form.Label>Bosted</Form.Label>
              <Form.Control
                  type="text"
                  placeholder="Bosted - Feks: 'Oslo'"
                  value={formData.location}
                  name="location"
                  onChange={handleChange}
              />
            </Form.Group>

          <Row className="flex-row-reverse">
            <Col xs="auto">
              <Button
                size="md"
                variant="dark"
                className="fw-medium mt-3"
                onClick={openAiIntroModal}
              >
                <span>Bruk AI for å fylle ut "om meg"</span>
              </Button>
            </Col>
          </Row>

            <Form.Group className="mb-3" controlId="intro">
              <Form.Label>Om meg</Form.Label>
              <Form.Control
                as="textarea"
                rows={13}
                placeholder="Introduksjon - Fortell om deg selv"
                value={formData.introduction}
                name="introduction"
                onChange={handleChange}
              />
            </Form.Group>
        </Card.Body>
      </Card>
      <AiIntroductionForm
        showModal={displayAiIntroModal}
        hideModal={hideAiIntroModal}
        formData={formData}
        setFormData={setFormData}
      />
    </>
  );
};
EditProfileIntro.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};
export default EditProfileIntro;
