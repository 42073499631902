/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const feedReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'RELOAD_FEED_ITEMS':
      //console.log('RELOAD_FEED_ITEMS ' + JSON.stringify(payload.feedItems));
      return {
        ...state,
        feedItems: payload.feedItems
      };
    case 'RELOAD_FEED_EVENTS':
      //console.log('RELOAD_FEED_EVENTS ' + JSON.stringify(payload.feedEvents));
      return {
        ...state,
        feedEvents: payload.feedEvents
      };
    case 'RELOAD_TEMPS_AVAILABLE_TODAY':
      // console.log(payload.persons, '  --> RELOAD_TEMPS_AVAILABLE_TODAY success persons');
      return {
        ...state,
        tempsAvailableToday: payload.persons
      };
    case 'RELOAD_ASSIGNMENTS_AVAILABLE_TODAY':
      // console.log(payload.assignments, '  --> RELOAD_ASSIGNMENTS_AVAILABLE_TODAY success assignments');
      return {
        ...state,
        assignmentsAvailableToday: payload.assignments
      };
    case 'SET_SHOW_FEED_LOAD_SPINNER':
      // console.log(payload.assignments, '  --> RELOAD_ASSIGNMENTS_AVAILABLE_TODAY success assignments');
      return {
        ...state,
        showFeedLoadSpinner: payload.showSpinner
      };

    default:
      return state;
  }
};
