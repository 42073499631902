import React from "react";
import {ListGroup} from "react-bootstrap";
import Notification from "./Notification";
import PropTypes from "prop-types";

const NotificationsList = ({notifications,handleToggle}) => {
  //console.log('[NotificationsList] notifications ' + JSON.stringify(notifications))

  return (
  <>
    {notifications.map((notification) => (
      <ListGroup.Item key={notification.id} onClick={handleToggle}>
        <Notification {...notification} flush />
      </ListGroup.Item>
    ))}
  </>
)};

NotificationsList.propTypes = {
  notifications: PropTypes.array.isRequired,
  handleToggle: PropTypes.func.isRequired
};

export default NotificationsList;