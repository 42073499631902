import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AssignmentFooter = ({ assignment }) => {
  return (
    <Card className="mb-3">
      <Card.Footer className="bg-light">
        <p className="fs--1 mb-0">
          {assignment.publishedTimestamp!==undefined && assignment.publishedTimestamp!==null && (
            <span>Publisert {assignment.publishedTimestamp} av </span>
          )}
          <Link to={`/profile/${assignment.owner?.id}`} className="link ps-1">
            {assignment.owner?.fullName}
          </Link>
        </p>
      </Card.Footer>
    </Card>
  );
};

AssignmentFooter.propTypes = {
  assignment: PropTypes.object.isRequired
};

export default AssignmentFooter;
