import React, {useContext} from "react";
import {Col, Row} from "react-bootstrap";
import ChangePassword from "./ChangePassword";
import DangerZone from "./DangerZone";
import {MyProfileContext} from "../../../../context/Context";
import ActivityLog from "./ActivityLog";
import ProfileBanner from "../../../app/profile/ProfileBanner";
import useUrlHelpers from "../../../../hooks/useUrlHelpers";
import FavouriteAssignments from "./FavouriteAssignments";
import FavouritePersons from "./FavouritePersons";

const EditUser = () => {
  const {getAvatarUrl} = useUrlHelpers();
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);
  let avatarUrl = getAvatarUrl(myProfilePerson.thumbnail?.image, myProfilePerson.firstName,myProfilePerson.lastName)

  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={null}
          avatar={avatarUrl}
          className="mb-8"
        />
      </ProfileBanner>
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <ActivityLog />
          <FavouriteAssignments />
          <FavouritePersons />
        </Col>
        <Col lg={4}>
          <ChangePassword />
          <DangerZone personId={myProfilePerson.id}/>
        </Col>
      </Row>
    </>
  );
};

export default EditUser;