import React, {useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import { CompanyContext } from 'context/Context';
import { companyReducer } from 'reducers/companyReducer';
import { config } from 'Constants'
import { useAuth0 } from "@auth0/auth0-react";

const CompaniesProvider = ({ children }) => {
  const initData = {
    myCompanies: [],
    initCompanies: [],
    companies: [],
    primaryCompanies: [],
    searchedText: null,
    isAsc: true
  };
  const [companiesState, companiesDispatch] = useReducer(companyReducer, initData);
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  const fetchCompanies = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/company", {headers,})
      .then(response => {
          return response.json()
      })
      .then(data => {
          //console.log('[CompaniesProvider] fetchCompanies: data ' + JSON.stringify(data));
          companiesDispatch({
              type: 'RELOAD_COMPANY',
              payload: {
                  companies: data
              }
          });
      })
  }

  useEffect(() => {
    if(accessToken!=="") {
      fetchCompanies();
    }
  }, [])

  useEffect(() => {
    getAccessToken();
  }, [])


  return (
    <CompanyContext.Provider
      value={{
        companiesState,
        companiesDispatch
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

CompaniesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default CompaniesProvider;
