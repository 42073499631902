import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import Flex from "../../../falcon-react-lib/components/common/Flex";
import {Link} from "react-router-dom";
import useUrlHelpers from "../../../hooks/useUrlHelpers";
import Avatar from "../../../falcon-react-lib/components/common/Avatar";
import TempCategoriesPills from "./TempCategoriesPills";

const NarrowTempsList = ({temps, cardTitle}) => {
  const {getAvatarUrl} = useUrlHelpers();
  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0">{cardTitle}</h5>
        </Card.Header>
      <Card.Body className="p-0">
      <Row className="mb-3 g-3">
        {temps?.length === 0  && (
          <Col key={'999'} className="p-3">
            - Ingen -
          </Col>
        )}
        {temps?.map((temp,index) => (
          <Col key={temp.id} xs={12} className="mt-1">
              {index>0 && (
                <div className="border-dashed border-bottom m-0"/>
              )}

            <Row className="g-0">
              <Col className="p-x1">
                <Row className="g-0 h-100">
                  <Col as={Flex} className="flex-column pe-x1">
                    <TempCategoriesPills temp={temp} />
                    <Row className="justify-content-between">
                      <Col>
                        <Flex>
                          <Link className="d-flex" to={`/profile/${temp.id}`}>
                            <Avatar size="xl" src={getAvatarUrl(temp.thumbnail?.image, temp.firstName,temp.lastName)}/>
                          </Link>
                          <div className="flex-1 align-self-center ms-2">
                              <h5 className="fs-0" >
                                <Link
                                  to={`/profile/${temp.id}`}
                                >
                                  {temp.firstName} {temp.lastName}
                                </Link>
                              </h5>
                          </div>
                        </Flex>
                      </Col>
                    </Row>


                    <div className="fs--1 mt-2">
                      {temp.introduction?.substring(0, 142)}...
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>


          </Col>
        ))}
      </Row>
      </Card.Body>
      </Card>

    </>
  );
};

NarrowTempsList.propTypes = {
  temps: PropTypes.array.isRequired,
  cardTitle: PropTypes.string.isRequired
};


export default NarrowTempsList;
