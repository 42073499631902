import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "falcon-react-lib/components/common/IconButton";

const ShareButtonWithEmail = ({
                       url,
                       text,
                      ...rest
                    }) => {
    return (
      <a
        href={'mailto:?subject= ' + text + " " + url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton
          className="d-block w-100 mb-xl-0"
          variant="falcon-default"
          icon={'envelope'}
          iconClassName="text-email me-2"
          transform="grow-2"
          {...rest}
        >
          Del via Epostklient
        </IconButton>
      </a>
    )
};

ShareButtonWithEmail.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default ShareButtonWithEmail;