import React from "react";
import TempsList from "./TempsList";
import useAuth from "../../../hooks/useAuth";
import TempsForTempsList from "../tempsForTemps/TempsForTempsList";

const Temps = () => {
  const {isSuperAdmin,isCompanyAdmin} = useAuth();
  return (isSuperAdmin() || isCompanyAdmin()) ? (
    <TempsList />
  ) : (
    <TempsForTempsList />
  )
};

export default Temps;
