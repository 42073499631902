import {useContext} from "react";
import {NotificationsContext} from "../context/Context";

const useNotifications = () => {
  const {notificationsDispatch: notificationsDispatch} = useContext(NotificationsContext);


  const handleReloadNewNotifications = (data) => {
    notificationsDispatch({
      type: 'RELOAD_NEW_NOTIFICATIONS',
      payload: {
        newNotifications: data
      }
    });
  };

  const handleReloadAllNotifications = (data) => {
    notificationsDispatch({
      type: 'RELOAD_ALL_NOTIFICATIONS',
      payload: {
        allNotifications: data
      }
    });
  };

  const handleAllNotificationsRead= () => {
    notificationsDispatch({
      type: 'SET_ALL_NOTIFICATIONS_READ'
    });
  };

  const handleNotificationRead = (notificationId) => {
    notificationsDispatch({
      type: 'SET_NOTIFICATION_READ',
      payload: {
        notificationId: notificationId
      }
    });
  };

  const handleSetIsOpen = (isOpen) => {
    notificationsDispatch({
      type: 'SET_IS_OPEN',
      payload: {
        isOpen: isOpen
      }
    });
  };

  return {
    handleReloadNewNotifications,
    handleReloadAllNotifications,
    handleAllNotificationsRead,
    handleNotificationRead,
    handleSetIsOpen
  };
};

export default useNotifications;