import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "falcon-react-lib/components/common/IconButton";

const ShareButtonOnLinkedIn = ({
                       url,
                      ...rest
                    }) => {
    return (
      <a
        href={'https://www.linkedin.com/sharing/share-offsite/?url=' + url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton
          className="d-block w-100 mb-xl-0"
          variant="falcon-default"
          icon={['fab', 'linkedin-in']}
          iconClassName="text-linked-in me-2"
          transform="grow-2"
          {...rest}
        >
          Del på Linkedin
        </IconButton>
      </a>
    )
};

ShareButtonOnLinkedIn.propTypes = {
  url: PropTypes.string
};

export default ShareButtonOnLinkedIn;