/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const personReducer = (state, action) => {
  const { type, payload } = action;

  const PLATFORM_STATUS_NONE = "Ingen";
  const PLATFORM_STATUS_TEMP = "Vikar";
  const PLATFORM_STATUS_DELETED = "Slettet";
  const PLATFORM_STATUS_NOT_DELETED = "Ikke slettet";
  const PLATFORM_STATUS_ROLE_SUPER_ADMIN = "SUPERADMIN";
  const PLATFORM_STATUS_ROLE_COMPANY_ADMIN = "COMPANYADMIN";

  const checkIfPersonIsTemp = (person) => {
    return person.registeredAsTemp;
  }

  const checkIfPersonIsDeleted = (person) => {
    return person.deleted;
  }

  const checkIfPersonIsNotDeleted = (person) => {
    return !person.deleted;
  }

  const checkIfPersonIsSuperAdmin = (person) => {
    if(person.companies === undefined) {
      return false;
    }
    if(person.companies === null) {
      return false;
    }
    if(person.companies.length===0) {
      return false;
    }
    const searchIndex = person.companies.findIndex(o => o.name === 'Folkin.no')
    return searchIndex>-1;
  }

  const checkIfPersonIsCompanyAdmin = (person) => {
    return person.companies !== undefined && person.companies !== null && person.companies.length > 0;
  }

  const searchAndFilterPersons = function (isAsc, persons, options, searchedText) {
    //console.log('[personReducer] searchAndFilterPersons. isAsc ' + isAsc + ' searchedText '+ searchedText + ' options ' + JSON.stringify(options) + ' persons ' + persons.length)
    let filteredPersons = persons;
    // Filter options
    if (options && options.length > 0) {

      let searchIndexTemp = options.findIndex(o => o.value === PLATFORM_STATUS_TEMP);
      if(searchIndexTemp>-1) {
        filteredPersons = filteredPersons.filter(checkIfPersonIsTemp);
      }

      let searchIndexDeleted = options.findIndex(o => o.value === PLATFORM_STATUS_DELETED);
      if(searchIndexDeleted>-1) {
        filteredPersons = filteredPersons.filter(checkIfPersonIsDeleted);
      }

      let searchIndexNotDeleted = options.findIndex(o => o.value === PLATFORM_STATUS_NOT_DELETED);
      if(searchIndexNotDeleted>-1) {
        filteredPersons = filteredPersons.filter(checkIfPersonIsNotDeleted);
      }

      let searchIndexSuperAdmin = options.findIndex(o => o.value === PLATFORM_STATUS_ROLE_SUPER_ADMIN);
      if(searchIndexSuperAdmin>-1) {
        filteredPersons = filteredPersons.filter(checkIfPersonIsSuperAdmin);
      }

      let searchIndexCompanyAdmin = options.findIndex(o => o.value === PLATFORM_STATUS_ROLE_COMPANY_ADMIN);
      if(searchIndexCompanyAdmin>-1) {
        filteredPersons = filteredPersons.filter(checkIfPersonIsCompanyAdmin);
      }

      let searchIndexNone = options.findIndex(o => o.value === PLATFORM_STATUS_NONE);
      if(searchIndexNone>-1) {
        filteredPersons = filteredPersons.filter(person => {
            return !checkIfPersonIsTemp(person) && !checkIfPersonIsSuperAdmin(person) && !checkIfPersonIsCompanyAdmin(person);
        });
      }
    }

    // Search text
    let searchedPersons = filteredPersons;
    if(searchedText) {
      // console.log('searchedText ' + searchedText)
      searchedPersons = searchedPersons.filter(person =>
        person.fullName.toUpperCase().includes(searchedText.toUpperCase())
      );
    }

    // Sort persons
    let sortedPersons = sortPersons(isAsc,searchedPersons)

    return sortedPersons;
  };


  const sortPersons = function (isAsc, persons) {
    //console.log('[personReducer] sortPersons. isAsc: ' + isAsc);
    let sortedPersons = persons.sort((a, b) => {
      const nameA = a.fullName.toUpperCase(); // ignore upper and lowercase
      const nameB = b.fullName.toUpperCase(); // ignore upper and lowercase
      if (isAsc) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
      }
      // names must be equal
      return 0;
    });
    // Force relaod of pagination reducer state
    sortedPersons = sortedPersons.map(e => ({...e, timestamp: new Date().valueOf()}));
    return sortedPersons;
  }

  switch (type) {
    case 'RELOAD_PERSON_FILTERS':
      return {
        ...state,
        personFilters: payload.personFilters
      };
    case 'RELOAD_PERSON':
      return {
        ...state,
        initPersons: payload.persons,
        persons: payload.persons,
        primaryPersons: payload.persons,
      };
    case 'RELOAD_PERSON_AND_FILTER':
      console.log('RELOAD_PERSON_AND_FILTER payload ' + payload.persons.length);
      console.log('RELOAD_PERSON_AND_FILTER filterOptions ' + state.filterOptions);
      return {
        ...state,
        initPersons: payload.persons,
        persons: searchAndFilterPersons(state.isAsc, payload.persons,state.filterOptions,state.searchedText),
        primaryPersons: payload.persons,
      };
    case 'SORT_PERSON':
      //console.log('SORT_PERSON order ' + payload.isAsc);
      return {
        ...state,
        isAsc: payload.isAsc,
        persons: searchAndFilterPersons(payload.isAsc, state.primaryPersons,state.filterOptions,state.searchedText)
      };
    case 'EMPTY_FILTER': {
      let options = [];
      return {
        ...state,
        filterOptions: options,
        persons: searchAndFilterPersons(state.isAsc,state.primaryPersons,options,state.searchedText)
      }};
    case 'FILTER_PERSON': {
      //console.log('FILTER_PERSON payload ' + payload.id + ' - ' + payload.type + ' - '+ payload.name + ' - ' + payload.value + ' - ' + payload.checked + ' - ');
      let options = [...state.filterOptions];
      if (payload.type === 'checkbox') {
        options = options.filter(option => option.value !== payload.value);
        payload.checked && options.push({ id: payload.id, type:  payload.type, name: payload.name, value: payload.value });
      }
      // TODO
      // if (type === 'radio') {
      //   const isExists = filterOptions.some(el => el.name === name);
      //   isExists
      //     ? setFilterOptions(
      //         filterOptions.map(el => (el.name === name ? { ...el, value } : el))
      //       )
      //     : setFilterOptions([...filterOptions, { name, value }]);
      // }
      return {
        ...state,
        filterOptions: options,
        persons: searchAndFilterPersons(state.isAsc,state.primaryPersons,options, state.searchedText)
      }};
    case 'SEARCH_PERSON':
      return {
        ...state,
        searchedText: payload.searchedText,
        persons: searchAndFilterPersons(state.isAsc,state.primaryPersons,state.filterOptions, payload.searchedText)
      };
    default:
      return state;
  }
};
