import useAuth from "../hooks/useAuth";
import {config} from "../Constants";
import useFavourites from "../hooks/useFavourites";
import {v4 as uuidv4} from "uuid";

const callApiFavourites= () => {
  const apiUrl = config.url.API_URL;
  const { handleReloadFavouriteAssignments, handleReloadFavouritePersons } = useFavourites();
  const {getAccessToken} = useAuth();

  const fetchAllFavouriteAssignments = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/favourites/assignments", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log(data, ' [callApiFavourites] fetchAllFavouriteAssignments data load success')
        handleReloadFavouriteAssignments(data);
      });
  }

  const fetchAllFavouriteAssignmentsExtended = () => {
    return getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/favourites/assignments-extended", {headers,})
      })
      .then(response => {
        return response.json()
      });
  }

  const addFavouriteAssignment = (assignmentId) => {
    let formData = {
      id: uuidv4(),
      assignmentId: assignmentId,
      note: null
    }
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/favourites/assignments", requestOptions)
      })
      .then(response => {
        if(response.status<300) {
          fetchAllFavouriteAssignments();
        } else {
          console.log(response.statusText,' -> ERROR')
        }
      });
  };


  const removeFavouriteAssignment = (id, callback) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
        return fetch(apiUrl+"/favourites/assignments/"+id, requestOptions)
      })
      .then(response => {
        if(response.status<300) {
          fetchAllFavouriteAssignments();
          if(callback) {
            callback();
          }
        } else {
          console.log(response.statusText,' -> ERROR')
        }
      });
  };

  const fetchAllFavouritePersons = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/favourites/persons", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        // console.log(data, ' [callApiNotifications] fetchAllNotifications data load success')
        handleReloadFavouritePersons(data);
      });
  }




  const  fetchAllFavouritePersonsExtended = () => {
    return getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/favourites/persons-extended", {headers,})
      })
      .then(response => {
        return response.json()
      });
  }

  const addFavouritePerson = (personId) => {
    let formData = {
      id: uuidv4(),
      personId: personId,
      note: null
    }
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/favourites/persons", requestOptions)
      })
      .then(response => {
        if(response.status<300) {
          fetchAllFavouritePersons();
        } else {
          console.log(response.statusText,' -> ERROR')
        }
      });
  };


  const removeFavouritePerson = (id, callback) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
        return fetch(apiUrl+"/favourites/persons/"+id, requestOptions)
      })
      .then(response => {
        if(response.status<300) {
          fetchAllFavouritePersons();
          if(callback) {
            callback();
          }
        } else {
          console.log(response.statusText,' -> ERROR')
        }
      });
  };

  return {
    fetchAllFavouriteAssignments,
    fetchAllFavouriteAssignmentsExtended,
    addFavouriteAssignment,
    removeFavouriteAssignment,
    fetchAllFavouritePersons,
    fetchAllFavouritePersonsExtended,
    addFavouritePerson,
    removeFavouritePerson
  };
};

export default callApiFavourites;