import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import cloudUpload from 'falcon-react-lib/assets/img/icons/cloud-upload.svg';
import useUrlHelpers from "hooks/useUrlHelpers";
import ErrorConfirmation from "../../../confirmation/ErrorConfirmation";
import Avatar from "../../../../falcon-react-lib/components/common/Avatar";
import Hoverbox from "../../../../falcon-react-lib/components/common/Hoverbox";
import useS3Upload from "../../../../hooks/useS3Upload";

const CompanyLogoPhoto = ({ formData, setFormData }) => {
  const {uploadCoverPhoto} = useS3Upload();
  const {getImageUrl} = useUrlHelpers();
  const [cover, setLogo] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  //console.log(cover,' -> [CompanyLogoPhoto] cover')
  //console.log(formData,' -> [CompanyLogoPhoto] formData')

  // Handle the displaying of the error modal
  const showErrorModal = () => {
    setDisplayConfirmationModal(true);
  };
  // Hide the delete modal
  const hideErrorModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleFileUploadResponse = (fileInfo) => {
    // console.log(fileInfo, ' [CompanyLogoPhoto] onDrop - fileInfo');
    if(fileInfo.status!==undefined) {
      setErrorMessage(fileInfo.message);
      showErrorModal();
    } else {
      setLogo(fileInfo);
      setFormData({
        ...formData,
        thumbnail: {
          name: fileInfo.name,
          image: fileInfo.url
        }
      });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      if(acceptedFiles && acceptedFiles.length > 0) {
        uploadCoverPhoto(acceptedFiles[0], handleFileUploadResponse);
      } else {
        setErrorMessage('Dette er ikke en gyldig filtype for logo');
        showErrorModal();
      }
    }
  });

  const loadLogoFromFormData = () => {
      if(formData.thumbnail!==undefined && formData.thumbnail.image!==undefined && formData.thumbnail.image!==null && formData.thumbnail.image!=='') {
        const fileInfo = {
          name: formData.thumbnail.name,
          url: formData.thumbnail.image
        }
        setLogo(fileInfo);
      }
  }

  useEffect(() => {
    loadLogoFromFormData();
  }, [formData]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0 d-inline-block">
            Last opp en logo
            <OverlayTrigger
              overlay={
                <Tooltip style={{ position: 'fixed' }} id="logoTooltip">
                  Legg til en logo
                </Tooltip>
              }
            >
              <span className="ms-2 text-primary fs-0">
                <FontAwesomeIcon icon="info-circle" />
              </span>
            </OverlayTrigger>
          </h5>
        </Card.Header>
        <Card.Body>
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps({ multiple: false })} />
            <div className="fs--1">
              <img src={cloudUpload} alt="" width={20} className="me-2" />
              <span className="d-none d-lg-inline">
                Dra bildet ditt hit
                <br />
                eller,{' '}
              </span>
              <Button variant="link" size="sm" className="p-0 fs--1">
                Hent
              </Button>
            </div>
          </div>
          {cover && (
            <div className="mt-3">
              <Hoverbox className="text-center">
                <Avatar
                  size="5xl"
                  className="justify-content-center"
                  src={getImageUrl(cover.url)}
                  mediaClass="img-thumbnail shadow-sm"
                />
                <Hoverbox.Content className="light bg-dark p-5 flex-center">
                  <div>
                    <a
                      className="btn btn-light btn-sm mt-1"
                       href="#!"
                       onClick={() => {
                         setLogo();
                         setFormData({
                           ...formData,
                           thumbnail: {
                             name: null,
                             image: null
                           }
                         });
                       }}>
                      Fjern
                    </a>
                  </div>
                </Hoverbox.Content>
              </Hoverbox>

              {/*<Flex*/}
              {/*  alignItems="center"*/}
              {/*  key={cover.name}*/}
              {/*>*/}
              {/*  <Avatar*/}
              {/*    size="5xl"*/}
              {/*    className="justify-content-center"*/}
              {/*    src={getImageUrl(cover.url)}*/}
              {/*    mediaClass="img-thumbnail shadow-sm"*/}
              {/*  />*/}
              {/*</Flex>*/}


              {/*  <Button*/}
              {/*    variant="link"*/}
              {/*    size="sm"*/}
              {/*    className="fw-medium pt-3 float-end"*/}
              {/*    onClick={() => {*/}
              {/*      setLogo();*/}
              {/*      setFormData({*/}
              {/*        ...formData,*/}
              {/*        thumbnail: {*/}
              {/*          name: null,*/}
              {/*          image: null*/}
              {/*        }*/}
              {/*      });*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Fjern logoen*/}
              {/*  </Button>*/}
            </div>
          )}
        </Card.Body>
      </Card>
      <ErrorConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideErrorModal}
        message={errorMessage}
      />
    </>
  );
};

CompanyLogoPhoto.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};

export default CompanyLogoPhoto;
