import PropTypes from "prop-types";
import {Button, Modal} from 'react-bootstrap';
import React, {useContext} from "react";
import AppContext from "../../falcon-react-lib/context/Context";

const DeleteConfirmation = ({ showModal, hideModal, confirmModal, id, name, message }) => {
  // console.log(id, ' -> [DeleteConfirmation]')
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      contentClassName="border"
    >
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">Bekreft sletting</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <span>
            {message}
            {name && (
              <>
                `{name}`
              </>
            )}
            ?
          </span>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button
            variant="link"
            onClick={hideModal}
            className="px-4 mx-0"
          >
            Tilbake
          </Button>
          <Button
            variant="falcon-danger"
            type="submit"
            onClick={() => confirmModal(id) }
            className="px-4 mx-0"
          >
            Slett
          </Button>
        </Modal.Footer>
    </Modal>
  )};

DeleteConfirmation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  message: PropTypes.string
};

export default DeleteConfirmation;