import React from 'react';
import TinymceEditor from 'components/common/TinymceEditor';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';

const CompanyInformationForm = ({ formData, setFormData }) => {
  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-light">Informasjon om selskapet/avdelingen</Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="name">
              <Form.Label>
                Navn på enheten<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                placeholder="Enhetsnavn"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="location">
              <Form.Label>
                Lokasjon av enheten
              </Form.Label>
              <Form.Control
                type="text"
                name="location"
                placeholder="Lokasjon"
                value={formData.location}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs="12">
            <Form.Group>
              <Form.Label>
                Beskrivelse av enheten<span className="text-danger">*</span>
              </Form.Label>
              <div className="create-Company-introduction-textarea">
                <TinymceEditor
                  height="13.438rem"
                  value={formData.introduction}
                  handleChange={newValue =>
                    setFormData({
                      ...formData,
                      introduction: newValue
                    })
                  }
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CompanyInformationForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};
export default CompanyInformationForm;
