import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'falcon-react-lib/components/common/FalconCardHeader';

const AssignmentRequirements = ({ assignment }) => {
  return (
    <Card className="mb-3">
      <FalconCardHeader
        title="Oppdragsbesrivelse"
        titleTag="h5"
        className="bg-light"
      />
      <Card.Body className="position-relative">
        {/*<Background image={corner7} className="bg-card d-none d-md-block" />*/}
        <div dangerouslySetInnerHTML={{ __html: assignment.description }} />
      </Card.Body>
    </Card>
  );
};

AssignmentRequirements.propTypes = {
  assignment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    regions: PropTypes.array.isRequired,
    competences: PropTypes.array.isRequired,
    industries: PropTypes.array.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    thumbnail: PropTypes.shape({
      image: PropTypes.string,
      video: PropTypes.string,
      videoPoster: PropTypes.string
    }),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    description: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    publishedTimestamp: PropTypes.string
  })
};

export default AssignmentRequirements;
