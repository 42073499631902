import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Bottombar from '../../../common/Bottombar';
import useVisibilityObserver from 'falcon-react-lib/hooks/useVisibilityObserver';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

const SubmitCompany = ({companyId, saveChanges}) => {
  //console.log(formData, '--> [SubmitCompany] formData ');
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '0px'
  );

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);

  return (
    <>
      <Card className="mb-lg-3 order-lg-0 order-1">
        <Card.Header className="py-2 d-flex flex-between-center bg-light">
          <h5 className="mb-0">Lagre endringer</h5>
          {companyId!==undefined && companyId!==null && (
            <Link
              to={`/company/profile/${companyId}`}
              target="_blank"
            >
              <Button
                variant="link"
                size="sm"
                className="px-0 fw-medium text-secondary"
              >
                <span className="d-lg-none d-xxl-inline-block">Preview</span>
                <FontAwesomeIcon icon="external-link-alt" className="ms-2 fs--2" />
              </Button>
            </Link>
          )}
        </Card.Header>
        <Card.Body>
          Trykk her for å lagre endringene dine
        </Card.Body>
        <Card.Footer ref={targetElRef} className="py-2 bg-light">
          <Row className="flex-row-reverse g-0">
            <Col xs="auto">
              <Button
                size="md"
                variant="primary"
                className="fw-medium"
                onClick={saveChanges}
              >
                <span>Lagre</span>
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <Bottombar
        inViewport={inViewport}
        buttonText={'Lagre'}
        handleClick={saveChanges}
      />
    </>
  );
};

SubmitCompany.propTypes = {
  companyId: PropTypes.string,
  saveChanges: PropTypes.func.isRequired,
};

export default SubmitCompany;
