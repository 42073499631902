import React from 'react';
import {Card} from 'react-bootstrap';
import PropTypes from "prop-types";
import Flex from "../../../falcon-react-lib/components/common/Flex";
import AttachedFile from "../myProfile/editTempInfo/AttachedFile";

const ProfileFiles = ({profileData}) => {

  // console.log('[ProfileFiles] profileData: ' + JSON.stringify(profileData));

  return (
    <>
      {profileData && profileData.files && profileData.files.length > 0 && (
        <Card className="mb-3">
          <Card.Header className="bg-light">
            <h5 className="mb-0">Filer</h5>
          </Card.Header>
          <Card.Body>
            <Flex direction="column" className="mt-3">
              {profileData.files.map((fileInfo, index) => (
                <AttachedFile
                  key={'attached-file-' + index}
                  index={index}
                  fileInfo={fileInfo}
                />
              ))}
            </Flex>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

ProfileFiles.propTypes = {
  profileData: PropTypes.object.isRequired
};


export default ProfileFiles;
