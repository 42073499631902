import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Avatar from 'falcon-react-lib/components/common/Avatar';
import classNames from 'classnames';
import ProfileBannerBackground from "./ProfileBannerBackground";

const ProfileBannerHeader = ({ avatar, coverSrc, className }) => {
  let style = "linear-gradient(-45deg, rgba(51, 114, 102, 0.66), rgb(41, 91, 82))";
  if(coverSrc) {
    style = style + ",url(" +coverSrc + ")";
  }
  return (
    <Card.Header
      className={classNames(className, 'position-relative min-vh-25 mb-7')}
    >
      <ProfileBannerBackground className="rounded-3 rounded-bottom-0" style={{backgroundImage: style}} />
      <Avatar
        size="5xl"
        className="avatar-profile"
        src={avatar}
        mediaClass="img-thumbnail shadow-sm"
      />
    </Card.Header>
  );
};

const ProfileBannerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const ProfileBanner = ({ children }) => {
  return <Card className="mb-3">{children}</Card>;
};

ProfileBanner.Header = ProfileBannerHeader;
ProfileBanner.Body = ProfileBannerBody;

ProfileBannerHeader.propTypes = {
  avatar: PropTypes.string.isRequired,
  coverSrc: PropTypes.string,
  className: PropTypes.string
};

ProfileBannerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileBanner;
