import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// import AuthSimpleLayout from '../falcon-react-lib/layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../falcon-react-lib/layouts/ErrorLayout';
// import WizardAuth from '../falcon-react-lib/components/authentication/wizard/WizardAuth';

import Accordion from '../falcon-react-lib/components/doc-components/Accordion';
import Alerts from '../falcon-react-lib/components/doc-components/Alerts';
import Badges from '../falcon-react-lib/components/doc-components/Badges';
import Breadcrumbs from '../falcon-react-lib/components/doc-components/Breadcrumb';
import Buttons from '../falcon-react-lib/components/doc-components/Buttons';
import CalendarExample from '../falcon-react-lib/components/doc-components/CalendarExample';
import Cards from '../falcon-react-lib/components/doc-components/Cards';
import Dropdowns from '../falcon-react-lib/components/doc-components/Dropdowns';
import ListGroups from '../falcon-react-lib/components/doc-components/ListGroups';
import Modals from '../falcon-react-lib/components/doc-components/Modals';
import Offcanvas from '../falcon-react-lib/components/doc-components/Offcanvas';
import Pagination from '../falcon-react-lib/components/doc-components/Pagination';
import BasicProgressBar from '../falcon-react-lib/components/doc-components/ProgressBar';
import Spinners from '../falcon-react-lib/components/doc-components/Spinners';
import Toasts from '../falcon-react-lib/components/doc-components/Toasts';
import Avatar from '../falcon-react-lib/components/doc-components/Avatar';
import Image from '../falcon-react-lib/components/doc-components/Image';
import Tooltips from '../falcon-react-lib/components/doc-components/Tooltips';
import Popovers from '../falcon-react-lib/components/doc-components/Popovers';
import Figures from '../falcon-react-lib/components/doc-components/Figures';
import Hoverbox from '../falcon-react-lib/components/doc-components/Hoverbox';
import Tables from '../falcon-react-lib/components/doc-components/Tables';
import FormControl from '../falcon-react-lib/components/doc-components/FormControl';
import InputGroup from '../falcon-react-lib/components/doc-components/InputGroup';
import Select from '../falcon-react-lib/components/doc-components/Select';
import Checks from '../falcon-react-lib/components/doc-components/Checks';
import Range from '../falcon-react-lib/components/doc-components/Range';
import FormLayout from '../falcon-react-lib/components/doc-components/FormLayout';
import FloatingLabels from '../falcon-react-lib/components/doc-components/FloatingLabels';
import FormValidation from '../falcon-react-lib/components/doc-components/FormValidation';
import BootstrapCarousel from '../falcon-react-lib/components/doc-components/BootstrapCarousel';
import SlickCarousel from '../falcon-react-lib/components/doc-components/SlickCarousel';
import Navs from '../falcon-react-lib/components/doc-components/Navs';
import Navbars from '../falcon-react-lib/components/doc-components/Navbars';
import Tabs from '../falcon-react-lib/components/doc-components/Tabs';
import Collapse from '../falcon-react-lib/components/doc-components/Collapse';
import CountUp from '../falcon-react-lib/components/doc-components/CountUp';
import Embed from '../falcon-react-lib/components/doc-components/Embed';
import Background from '../falcon-react-lib/components/doc-components/Backgrounds';
import Search from '../falcon-react-lib/components/doc-components/Search';
import VerticalNavbar from '../falcon-react-lib/components/doc-components/VerticalNavbar';
import NavBarTop from '../falcon-react-lib/components/doc-components/NavBarTop';
import NavbarDoubleTop from '../falcon-react-lib/components/doc-components/NavbarDoubleTop';
import ComboNavbar from '../falcon-react-lib/components/doc-components/ComboNavbar';
import TypedText from '../falcon-react-lib/components/doc-components/TypedText';
import FileUploader from '../falcon-react-lib/components/doc-components/FileUploader';
import Borders from '../falcon-react-lib/components/utilities/Borders';
import Colors from '../falcon-react-lib/components/utilities/Colors';
import ColoredLinks from '../falcon-react-lib/components/utilities/ColoredLinks';
import Display from '../falcon-react-lib/components/utilities/Display';
import Visibility from '../falcon-react-lib/components/utilities/Visibility';
import StretchedLink from '../falcon-react-lib/components/utilities/StretchedLink';
import Float from '../falcon-react-lib/components/utilities/Float';
import Position from '../falcon-react-lib/components/utilities/Position';
import Spacing from '../falcon-react-lib/components/utilities/Spacing';
import Sizing from '../falcon-react-lib/components/utilities/Sizing';
import TextTruncation from '../falcon-react-lib/components/utilities/TextTruncation';
import Typography from '../falcon-react-lib/components/utilities/Typography';
import VerticalAlign from '../falcon-react-lib/components/utilities/VerticalAlign';
import Flex from '../falcon-react-lib/components/utilities/Flex';
import Grid from '../falcon-react-lib/components/utilities/Grid';
import WizardForms from '../falcon-react-lib/components/doc-components/WizardForms';
import GettingStarted from '../falcon-react-lib/components/documentation/GettingStarted';
import Configuration from '../falcon-react-lib/components/documentation/Configuration';
import DarkMode from '../falcon-react-lib/components/documentation/DarkMode';
import Plugins from '../falcon-react-lib/components/documentation/Plugins';
import Styling from '../falcon-react-lib/components/documentation/Styling';
import DesignFile from '../falcon-react-lib/components/documentation/DesignFile';
import Starter from '../falcon-react-lib/components/pages/Starter';
import AnimatedIcons from '../falcon-react-lib/components/doc-components/AnimatedIcons';
import DatePicker from '../falcon-react-lib/components/doc-components/DatePicker';
// import FontAwesome from '../falcon-react-lib/components/doc-components/FontAwesome';
import Changelog from '../falcon-react-lib/components/documentation/change-log/ChangeLog';
// import Analytics from '../falcon-react-lib/components/dashboards/analytics';
// import Crm from '../falcon-react-lib/components/dashboards/crm';
// import Saas from '../falcon-react-lib/components/dashboards/saas';
import MyProfile from '../components/app/myProfile/MyProfile';
import Profile from '../components/app/profile/Profile';
import Associations from '../falcon-react-lib/components/pages/asscociations/Associations';
// import Followers from '../falcon-react-lib/components/app/social/followers/Followers';
// import Notifications from '../falcon-react-lib/components/app/social/notifications/Notifications';
// import ActivityLog from '../falcon-react-lib/components/app/social/activity-log/ActivityLog';
//import Settings from '../falcon-react-lib/components/pages/user/settings/Settings';
// import Feed from '../falcon-react-lib/components/app/social/feed/Feed';
import Placeholder from '../falcon-react-lib/components/doc-components/Placeholder';
import Lightbox from '../falcon-react-lib/components/doc-components/Lightbox';
import AdvanceTableExamples from '../falcon-react-lib/components/doc-components/AdvanceTableExamples';
import ModalAuth from '../falcon-react-lib/components/authentication/modal/ModalAuth';
// import Calendar from '../components/app/calendar/Calendar';
import FaqAlt from '../falcon-react-lib/components/pages/faq/faq-alt/FaqAlt';
import FaqBasic from '../falcon-react-lib/components/pages/faq/faq-basic/FaqBasic';
import FaqAccordion from '../falcon-react-lib/components/pages/faq/faq-accordion/FaqAccordion';
import PrivacyPolicy from '../falcon-react-lib/components/pages/miscellaneous/privacy-policy/PrivacyPolicy';
import InvitePeople from 'components/pages/miscellaneous/invite-people/InvitePeople';
import PricingDefault from '../falcon-react-lib/components/pages/pricing/pricing-default/PricingDefault';
import PricingAlt from '../falcon-react-lib/components/pages/pricing/pricing-alt/PricingAlt';
// import Invoice from '../falcon-react-lib/components/app/e-commerce/Invoice';
// import Billing from '../falcon-react-lib/components/app/e-commerce/billing/Billing';
// import Checkout from '../falcon-react-lib/components/app/e-commerce/checkout/Checkout';
// import ShoppingCart from '../falcon-react-lib/components/app/e-commerce/cart/ShoppingCart';
// import CustomersDetails from '../falcon-react-lib/components/app/e-commerce/customers-details/CustomersDetails';
// import OrderDetails from '../falcon-react-lib/components/app/e-commerce/orders/order-details/OrderDetails';
// import Products from '../falcon-react-lib/components/app/e-commerce/product/Products';
// import ProductDetails from '../falcon-react-lib/components/app/e-commerce/product/product-details/ProductDetails';
// import Orders from '../falcon-react-lib/components/app/e-commerce/orders/order-list/Orders';
// import Customers from '../falcon-react-lib/components/app/e-commerce/customers/Customers';
// import Courses from '../falcon-react-lib/components/app/e-learning/course/Courses';
// import CourseDetails from '../falcon-react-lib/components/app/e-learning/course/course-details';
// import CreateCourse from '../falcon-react-lib/components/app/e-learning/course/create-a-course';
// import TrainerProfile from '../falcon-react-lib/components/app/e-learning/trainer-profile';
// import StudentOverview from '../falcon-react-lib/components/app/e-learning/student-overview';
// import CreateEvent from 'components/app/events/create-an-event/CreateEvent';
// import EventList from 'components/app/events/event-list/EventList';
// import EventDetail from 'components/app/events/event-detail/EventDetail';
// import EmailDetail from '../falcon-react-lib/components/app/email/email-detail/EmailDetail';
// import Compose from '../falcon-react-lib/components/app/email/compose/Compose';
// import Inbox from '../falcon-react-lib/components/app/email/inbox/Inbox';
import Rating from '../falcon-react-lib/components/doc-components/Rating';
import AdvanceSelect from '../falcon-react-lib/components/doc-components/AdvanceSelect';
import Editor from '../falcon-react-lib/components/doc-components/Editor';

// import Kanban from '../falcon-react-lib/components/app/kanban/Kanban';
import DraggableExample from '../falcon-react-lib/components/doc-components/DraggableExample';
import LineCharts from '../falcon-react-lib/components/doc-components/charts-example/echarts/line-charts';
import BarCharts from '../falcon-react-lib/components/doc-components/charts-example/echarts/bar-charts';
import CandlestickCharts from '../falcon-react-lib/components/doc-components/charts-example/echarts/candlestick-charts';
import GeoMaps from '../falcon-react-lib/components/doc-components/charts-example/echarts/geo-map';
import ScatterCharts from '../falcon-react-lib/components/doc-components/charts-example/echarts/scatter-charts';
import PieCharts from '../falcon-react-lib/components/doc-components/charts-example/echarts/pie-charts';
import RadarCharts from '../falcon-react-lib/components/doc-components/charts-example/echarts/radar-charts';
import HeatmapCharts from '../falcon-react-lib/components/doc-components/charts-example/echarts/heatmap-chart';
import Chartjs from '../falcon-react-lib/components/doc-components/charts-example/chartjs';
import D3js from '../falcon-react-lib/components/doc-components/charts-example/d3';
import HowToUse from '../falcon-react-lib/components/doc-components/charts-example/echarts/HowToUse';
// import GoogleMapExample from '../falcon-react-lib/components/doc-components/GoogleMapExample';
// import LeafletMapExample from '../falcon-react-lib/components/doc-components/LeafletMapExample';
import CookieNoticeExample from '../falcon-react-lib/components/doc-components/CookieNoticeExample';
import Scrollbar from '../falcon-react-lib/components/doc-components/Scrollbar';
import Scrollspy from '../falcon-react-lib/components/doc-components/Scrollspy';
// import ReactIcons from '../falcon-react-lib/components/doc-components/ReactIcons';
import ReactPlayerExample from '../falcon-react-lib/components/doc-components/ReactPlayerExample';
import EmojiMartExample from '../falcon-react-lib/components/doc-components/EmojiMart';
import TreeviewExample from '../falcon-react-lib/components/doc-components/TreeviewExample';
import Timeline from '../falcon-react-lib/components/doc-components/Timeline';
import Widgets from '../falcon-react-lib/widgets/Widgets';
// import Ecommerce from '../falcon-react-lib/components/dashboards/e-commerce';
// import Lms from '../falcon-react-lib/components/dashboards/lms';
// import ProjectManagement from '../falcon-react-lib/components/dashboards/project-management';

import Error404 from '../falcon-react-lib/components/errors/Error404';
import Error500 from '../falcon-react-lib/components/errors/Error500';

// import SimpleLogin from '../falcon-react-lib/components/authentication/simple/Login';
// import SimpleLogout from '../falcon-react-lib/components/authentication/simple/Logout';
// import SimpleRegistration from '../falcon-react-lib/components/authentication/simple/Registration';
// import SimpleForgetPassword from '../falcon-react-lib/components/authentication/simple/ForgetPassword';
// import SimplePasswordReset from '../falcon-react-lib/components/authentication/simple/PasswordReset';
// import SimpleConfirmMail from '../falcon-react-lib/components/authentication/simple/ConfirmMail';
// import SimpleLockScreen from '../falcon-react-lib/components/authentication/simple/LockScreen';
//
// import CardLogin from '../falcon-react-lib/components/authentication/card/Login';
// import Logout from '../falcon-react-lib/components/authentication/card/Logout';
// import CardRegistration from '../falcon-react-lib/components/authentication/card/Registration';
// import CardForgetPassword from '../falcon-react-lib/components/authentication/card/ForgetPassword';
// import CardConfirmMail from '../falcon-react-lib/components/authentication/card/ConfirmMail';
// import CardPasswordReset from '../falcon-react-lib/components/authentication/card/PasswordReset';
// import CardLockScreen from '../falcon-react-lib/components/authentication/card/LockScreen';
//
// import SplitLogin from '../falcon-react-lib/components/authentication/split/Login';
// import SplitLogout from '../falcon-react-lib/components/authentication/split/Logout';
// import SplitRegistration from '../falcon-react-lib/components/authentication/split/Registration';
// import SplitForgetPassword from '../falcon-react-lib/components/authentication/split/ForgetPassword';
// import SplitPasswordReset from '../falcon-react-lib/components/authentication/split/PasswordReset';
// import SplitConfirmMail from '../falcon-react-lib/components/authentication/split/ConfirmMail';
// import SplitLockScreen from '../falcon-react-lib/components/authentication/split/LockScreen';
//
// import Wizard from '../falcon-react-lib/components/wizard/Wizard';
import Faq from '../falcon-react-lib/components/documentation/Faq';

// import SupportDesk from '../falcon-react-lib/components/dashboards/support-desk';
// import TableView from '../falcon-react-lib/components/app/support-desk/tickets-layout/TableView';
// import CardView from '../falcon-react-lib/components/app/support-desk/tickets-layout/CardView';
// import Contacts from '../falcon-react-lib/components/app/support-desk/contacts/Contacts';
// import ContactDetails from '../falcon-react-lib/components/app/support-desk/contact-details/ContactDetails';
// import TicketsPreview from '../falcon-react-lib/components/app/support-desk/tickets-preview/TicketsPreview';
// import QuickLinks from '../falcon-react-lib/components/app/support-desk/quick-links/QuickLinks';
// import Reports from '../falcon-react-lib/components/app/support-desk/reports/Reports';
import InputMaskExample from '../falcon-react-lib/components/doc-components/InputMaskExample';
import RangeSlider from '../falcon-react-lib/components/doc-components/RangeSlider';
// import Landing from "../falcon-react-lib/components/pages/landing/Landing";
import SimpleConfirmMail from "components/authentication/simple/ConfirmMail";

import Dashboard from "components/app/dashboard/default";
import PrivateRoute from "components/helpers/PrivateRoute";
import MyBasicInfoProfileEdit from "components/app/myProfile/editBasicInfo/MyBasicInfoProfileEdit";
import MyTempProfileEdit from "components/app/myProfile/editTempInfo/MyTempProfileEdit";
import Persons from 'components/app/persons/Persons';
import Temps from 'components/app/temps/Temps';
import Companies from 'components/app/companies/list/Companies';
import Assignments from 'components/app/assignment/search/Assignments';
import MyAssignments from 'components/app/assignment/my-assignments/MyAssignments';
import AssignmentDetails from "../components/app/assignment/assignment-details";
import CreateAssignment from "../components/app/assignment/create-an-assignment";
import EditAssignment from "../components/app/assignment/edit-an-assignment";
import Chat from 'components/app/chat/Chat';
import RegisterSwitch from "../components/pages/register/RegisterSwitch";
import MyCompanies from "../components/app/companies/my-companies/MyCompanies";
import CreateCompany from "../components/app/companies/create-a-company";
import EditCompany from "../components/app/companies/edit-a-company";
import CompanyDetails from "../components/app/companies/company-details";
import MyAccessibilityEdit from "../components/app/myProfile/editAvailability"
import EditUser from "../components/pages/user/edit";
import Apply from "../components/app/assignment/apply";
import RegisterWizardLayout from "../layouts/RegisterWizardLayout";
import RegisterWizard from "../components/pages/welcomeWizard/RegisterWizard";
import RegisterTempProfileWizard from "../components/pages/tempWizard/RegisterTempProfileWizard";
import AuthSimpleLayout from "../layouts/AuthSimpleLayout";

const FolkinRoutes = () => {

  return (
        <Routes>
          {/*<Route path="landing" element={<Landing />} />*/}

          <Route element={<ErrorLayout />}>
            <Route path="errors/404" element={<Error404 />} />
            <Route path="errors/500" element={<Error500 />} />
          </Route>
          {/*/!*- ------------- Authentication ---------------------------  *!/*/}

          {/*/!*- ------------- simple ---------------------------  *!/*/}
          {/*<Route element={<AuthSimpleLayout />}>*/}
          {/*  <Route path="authentication/simple/login" element={<SimpleLogin />} />*/}
          {/*  <Route*/}
          {/*      path="authentication/simple/register"*/}
          {/*      element={<SimpleRegistration />}*/}
          {/*  />*/}
          {/*  <Route path="authentication/simple/logout" element={<SimpleLogout />} />*/}
          {/*  <Route*/}
          {/*      path="authentication/simple/forgot-password"*/}
          {/*      element={<SimpleForgetPassword />}*/}
          {/*  />*/}
          {/*  <Route*/}
          {/*      path="authentication/simple/reset-password"*/}
          {/*      element={<SimplePasswordReset />}*/}
          {/*  />*/}
          {/*  <Route*/}
          {/*      path="authentication/simple/confirm-mail"*/}
          {/*      element={<SimpleConfirmMail />}*/}
          {/*  />*/}
          {/*  <Route*/}
          {/*      path="authentication/simple/lock-screen"*/}
          {/*      element={<SimpleLockScreen />}*/}
          {/*  />*/}
          {/*</Route>*/}

          {/*/!*- ------------- Card ---------------------------  *!/*/}
          {/*<Route path="authentication/card/login" element={<CardLogin />} />*/}
          {/*<Route*/}
          {/*    path="authentication/card/register"*/}
          {/*    element={<CardRegistration />}*/}
          {/*/>*/}
          {/*<Route path="authentication/card/logout" element={<Logout />} />*/}
          {/*<Route*/}
          {/*    path="authentication/card/forgot-password"*/}
          {/*    element={<CardForgetPassword />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path="authentication/card/reset-password"*/}
          {/*    element={<CardPasswordReset />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path="authentication/card/confirm-mail"*/}
          {/*    element={<CardConfirmMail />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path="authentication/card/lock-screen"*/}
          {/*    element={<CardLockScreen />}*/}
          {/*/>*/}

          {/*/!*- ------------- Split ---------------------------  *!/*/}

          {/*<Route path="authentication/split/login" element={<SplitLogin />} />*/}
          {/*<Route path="authentication/split/logout" element={<SplitLogout />} />*/}
          {/*<Route*/}
          {/*    path="authentication/split/register"*/}
          {/*    element={<SplitRegistration />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path="authentication/split/forgot-password"*/}
          {/*    element={<SplitForgetPassword />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path="authentication/split/reset-password"*/}
          {/*    element={<SplitPasswordReset />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path="authentication/split/confirm-mail"*/}
          {/*    element={<SplitConfirmMail />}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path="authentication/split/lock-screen"*/}
          {/*    element={<SplitLockScreen />}*/}
          {/*/>*/}

          {/*<Route element={<WizardAuth />}>*/}
          {/*  <Route*/}
          {/*      path="authentication/wizard"*/}
          {/*      element={<Wizard validation={true} />}*/}
          {/*  />*/}
          {/*</Route>*/}

          {/*/!*- ------------- simple ---------------------------  *!/*/}
          <Route element={<AuthSimpleLayout />}>
            <Route
                path="authentication/confirm-mail"
                element={<SimpleConfirmMail />}
            />
          </Route>


          <Route element={<RegisterWizardLayout />}>
            <Route path="register/wizard" element={<RegisterWizard validation={true} />}/>
          </Route>

          {/* //--- MainLayout Starts  */}
          <Route element={<PrivateRoute><RegisterSwitch><RegisterWizardLayout /></RegisterSwitch></PrivateRoute>}>
            <Route path="temp/wizard" element={<RegisterTempProfileWizard validation={true} />}/>
          </Route>

          {/* //--- MainLayout Starts  */}
          <Route element={<PrivateRoute><RegisterSwitch><MainLayout /></RegisterSwitch></PrivateRoute>}>

            {/*Dashboard*/}
            <Route path="/" element={<Dashboard />} />

            {/* Assignment */}
            <Route path="assignment" element={<Assignments />} />
            <Route path="assignment/mine" element={<MyAssignments />} />
            <Route path="assignment/create" element={<CreateAssignment />} />
            <Route path="assignment/edit/:assignmentId" element={<EditAssignment />} />
            <Route path="assignment/assignment-details"  element={<AssignmentDetails />} />
            <Route path="assignment/assignment-details/:assignmentId" element={<AssignmentDetails />} />
            <Route path="assignment/apply/:assignmentId" element={<Apply />} />


            {/* Persons/Temps */}
            <Route path="person" element={<Persons />} />
            <Route path="temp" element={<Temps />} />

            {/* Company */}
            <Route path="company/:companyId" element={<CompanyDetails />} />
            <Route path="company" element={<Companies />} />
            <Route path="company/create" element={<CreateCompany />} />
            <Route path="company/edit/:companyId" element={<EditCompany />} />
            <Route path="company/mine" element={<MyCompanies />} />
            {/*<Route path="events/event-detail" element={<EventDetail />} />*/}
            {/*<Route path="events/create-an-event" element={<CreateEvent />} />*/}
            {/*<Route path="events/event-list" element={<EventList />} />*/}

            {/* Profile */}
            <Route path="profile/basic/edit" element={<MyBasicInfoProfileEdit />} />
            <Route path="profile/temp/edit" element={<MyTempProfileEdit />} />
            <Route path="profile" element={<MyProfile />} />
            <Route path="profile/:personId" element={<Profile />} />
            <Route path="profile/accessibility/edit" element={<MyAccessibilityEdit />} />
            <Route path="profile/chat" element={<Chat />} />
            <Route path="profile/chat/:threadId" element={<Chat />} />

            {/* Profile */}
            <Route path="user/edit" element={<EditUser />} />

            {/* Share */}
            <Route path="invite" element={<InvitePeople />} />


            {/*Dashboard*/}
            {/*<Route path="dashboard/analytics" element={<Analytics />} />*/}
            {/*<Route path="dashboard/crm" element={<Crm />} />*/}
            {/*<Route path="dashboard/saas" element={<Saas />} />*/}
            {/*<Route path="dashboard/e-commerce" element={<Ecommerce />} />*/}
            {/*<Route path="dashboard/lms" element={<Lms />} />*/}
            {/*<Route*/}
            {/*  path="dashboard/project-management"*/}
            {/*  element={<ProjectManagement />}*/}
            {/*/>*/}
            {/*<Route path="dashboard/support-desk" element={<SupportDesk />} />*/}
            {/*/!* E Commerce *!/*/}
            {/*<Route*/}
            {/*  path="e-commerce/orders/order-details"*/}
            {/*  element={<OrderDetails />}*/}
            {/*/>*/}
            {/*<Route path="e-commerce/orders/order-list" element={<Orders />} />*/}
            {/*<Route path="e-commerce/invoice" element={<Invoice />} />*/}
            {/*<Route path="e-commerce/billing" element={<Billing />} />*/}
            {/*<Route path="e-commerce/checkout" element={<Checkout />} />*/}
            {/*<Route path="e-commerce/shopping-cart" element={<ShoppingCart />} />*/}
            {/*<Route path="e-commerce/customers" element={<Customers />} />*/}
            {/*<Route*/}
            {/*  path="e-commerce/customer-details"*/}
            {/*  element={<CustomersDetails />}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  path="e-commerce/product/product-details"*/}
            {/*  element={<ProductDetails />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="e-commerce/product/product-details/:productId"*/}
            {/*  element={<ProductDetails />}*/}
            {/*/>*/}

            {/*<Route*/}
            {/*  path="e-commerce/product/:productLayout"*/}
            {/*  element={<Products />}*/}
            {/*/>*/}

            {/*<Route path="e-commerce/invoice" element={<Invoice />} />*/}

            {/*/!* E Learning *!/*/}
            {/*<Route path="e-learning/course/:courseLayout" element={<Courses />} />*/}
            {/*<Route*/}
            {/*  path="e-learning/course/course-details"*/}
            {/*  element={<CourseDetails />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="e-learning/course/course-details/:courseId"*/}
            {/*  element={<CourseDetails />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*  path="e-learning/course/create-a-course"*/}
            {/*  element={<CreateCourse />}*/}
            {/*/>*/}
            {/*<Route path="e-learning/trainer-profile" element={<TrainerProfile />} />*/}
            {/*<Route*/}
            {/*  path="e-learning/student-overview"*/}
            {/*  element={<StudentOverview />}*/}
            {/*/>*/}

            {/*/!*icons*!/*/}
            {/*<Route path="icons/font-awesome" element={<FontAwesome />} />*/}
            {/*<Route path="icons/react-icons" element={<ReactIcons />} />*/}

            {/*/!* maps *!/*/}
            {/*<Route path="maps/google-map" element={<GoogleMapExample />} />*/}
            {/*<Route path="maps/leaflet-map" element={<LeafletMapExample />} />*/}

            {/*/!*App*!/*/}
            {/*<Route path="app/kanban" element={<Kanban />} />*/}
            {/*<Route path="social/feed" element={<Feed />} />*/}
            {/*<Route path="social/activity-log" element={<ActivityLog />} />*/}
            {/*<Route path="social/notifications" element={<Notifications />} />*/}
            {/*<Route path="social/followers" element={<Followers />} />*/}

            {/*/!* Email *!/*/}
            {/*<Route path="email/email-detail" element={<EmailDetail />} />*/}
            {/*<Route path="email/inbox" element={<Inbox />} />*/}
            {/*<Route path="email/compose" element={<Compose />} />*/}

            {/*/!* support desk *!/*/}
            {/*<Route path="/support-desk/table-view" element={<TableView />} />*/}
            {/*<Route path="/support-desk/card-view" element={<CardView />} />*/}
            {/*<Route path="/support-desk/contacts" element={<Contacts />} />*/}
            {/*<Route*/}
            {/*    path="/support-desk/contact-details"*/}
            {/*    element={<ContactDetails />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*    path="/support-desk/tickets-preview"*/}
            {/*    element={<TicketsPreview />}*/}
            {/*/>*/}
            {/*<Route path="/support-desk/quick-links" element={<QuickLinks />} />*/}
            {/*<Route path="/support-desk/reports" element={<Reports />} />*/}

            {/*Pages*/}
            <Route path="pages/starter" element={<Starter />} />
            {/*<Route path="user/settings" element={<Settings />} />*/}
            <Route path="miscellaneous/associations" element={<Associations />} />
            <Route path="faq/faq-alt" element={<FaqAlt />} />
            <Route path="faq/faq-basic" element={<FaqBasic />} />
            <Route path="faq/faq-accordion" element={<FaqAccordion />} />
            <Route path="pricing/pricing-default" element={<PricingDefault />} />
            <Route path="pricing/pricing-alt" element={<PricingAlt />} />
            <Route
                path="miscellaneous/privacy-policy"
                element={<PrivacyPolicy />}
            />

            {/* charts-example */}

            <Route path="charts/chartjs" element={<Chartjs />} />
            <Route path="charts/d3js" element={<D3js />} />
            <Route path="charts/echarts/line-charts" element={<LineCharts />} />
            <Route path="charts/echarts/bar-charts" element={<BarCharts />} />
            <Route
                path="charts/echarts/candlestick-charts"
                element={<CandlestickCharts />}
            />
            <Route path="charts/echarts/geo-map" element={<GeoMaps />} />
            <Route
                path="charts/echarts/scatter-charts"
                element={<ScatterCharts />}
            />
            <Route path="charts/echarts/pie-charts" element={<PieCharts />} />
            <Route path="charts/echarts/radar-charts" element={<RadarCharts />} />
            <Route
                path="charts/echarts/heatmap-charts"
                element={<HeatmapCharts />}
            />
            <Route path="charts/echarts/how-to-use" element={<HowToUse />} />

            {/*Components*/}
            <Route path="components/alerts" element={<Alerts />} />
            <Route path="components/accordion" element={<Accordion />} />
            <Route path="components/animated-icons" element={<AnimatedIcons />} />
            <Route path="components/badges" element={<Badges />} />
            <Route path="components/breadcrumb" element={<Breadcrumbs />} />
            <Route path="components/buttons" element={<Buttons />} />
            <Route path="components/calendar" element={<CalendarExample />} />
            <Route path="components/cards" element={<Cards />} />
            <Route path="components/dropdowns" element={<Dropdowns />} />
            <Route path="components/list-group" element={<ListGroups />} />
            <Route path="components/modals" element={<Modals />} />
            <Route path="components/offcanvas" element={<Offcanvas />} />
            <Route path="components/pagination" element={<Pagination />} />
            <Route path="components/progress-bar" element={<BasicProgressBar />} />
            <Route path="components/placeholder" element={<Placeholder />} />
            <Route path="components/spinners" element={<Spinners />} />
            <Route path="components/toasts" element={<Toasts />} />
            <Route path="components/pictures/avatar" element={<Avatar />} />
            <Route path="components/pictures/images" element={<Image />} />
            <Route path="components/pictures/figures" element={<Figures />} />
            <Route path="components/pictures/hoverbox" element={<Hoverbox />} />
            <Route path="components/pictures/lightbox" element={<Lightbox />} />
            <Route path="components/tooltips" element={<Tooltips />} />
            <Route path="components/popovers" element={<Popovers />} />
            <Route path="components/draggable" element={<DraggableExample />} />
            <Route path="components/scrollspy" element={<Scrollspy />} />
            <Route path="components/timeline" element={<Timeline />} />
            <Route path="components/treeview" element={<TreeviewExample />} />
            <Route
                path="components/carousel/bootstrap"
                element={<BootstrapCarousel />}
            />
            <Route path="components/carousel/slick" element={<SlickCarousel />} />
            <Route path="components/navs-and-tabs/navs" element={<Navs />} />
            <Route path="tables/basic-tables" element={<Tables />} />
            <Route
                path="tables/advance-tables"
                element={<AdvanceTableExamples />}
            />
            <Route path="forms/basic/form-control" element={<FormControl />} />
            <Route path="forms/basic/input-group" element={<InputGroup />} />
            <Route path="forms/basic/select" element={<Select />} />
            <Route path="forms/basic/checks" element={<Checks />} />
            <Route path="forms/basic/range" element={<Range />} />
            <Route path="forms/basic/layout" element={<FormLayout />} />
            <Route path="forms/advance/date-picker" element={<DatePicker />} />
            <Route path="forms/advance/editor" element={<Editor />} />
            <Route
                path="forms/advance/emoji-button"
                element={<EmojiMartExample />}
            />
            <Route
                path="forms/advance/advance-select"
                element={<AdvanceSelect />}
            />
            <Route path="forms/advance/input-mask" element={<InputMaskExample />} />
            <Route path="forms/advance/range-slider" element={<RangeSlider />} />
            <Route path="forms/advance/file-uploader" element={<FileUploader />} />
            <Route path="forms/advance/rating" element={<Rating />} />
            <Route path="forms/floating-labels" element={<FloatingLabels />} />
            <Route path="forms/validation" element={<FormValidation />} />
            <Route path="forms/wizard" element={<WizardForms />} />
            <Route path="components/navs-and-tabs/navbar" element={<Navbars />} />
            <Route path="components/navs-and-tabs/tabs" element={<Tabs />} />
            <Route path="components/collapse" element={<Collapse />} />
            <Route
                path="components/cookie-notice"
                element={<CookieNoticeExample />}
            />
            <Route path="components/countup" element={<CountUp />} />
            <Route path="components/videos/embed" element={<Embed />} />
            <Route
                path="components/videos/react-player"
                element={<ReactPlayerExample />}
            />
            <Route path="components/background" element={<Background />} />
            <Route path="components/search" element={<Search />} />
            <Route path="components/typed-text" element={<TypedText />} />
            <Route
                path="components/navs-and-tabs/vertical-navbar"
                element={<VerticalNavbar />}
            />
            <Route
                path="components/navs-and-tabs/top-navbar"
                element={<NavBarTop />}
            />
            <Route
                path="components/navs-and-tabs/double-top-navbar"
                element={<NavbarDoubleTop />}
            />
            <Route
                path="components/navs-and-tabs/combo-navbar"
                element={<ComboNavbar />}
            />

            {/*Utilities*/}
            <Route path="utilities/borders" element={<Borders />} />
            <Route path="utilities/colors" element={<Colors />} />
            <Route path="utilities/colored-links" element={<ColoredLinks />} />
            <Route path="utilities/display" element={<Display />} />
            <Route path="utilities/visibility" element={<Visibility />} />
            <Route path="utilities/stretched-link" element={<StretchedLink />} />
            <Route path="utilities/stretched-link" element={<StretchedLink />} />
            <Route path="utilities/float" element={<Float />} />
            <Route path="utilities/position" element={<Position />} />
            <Route path="utilities/spacing" element={<Spacing />} />
            <Route path="utilities/sizing" element={<Sizing />} />
            <Route path="utilities/text-truncation" element={<TextTruncation />} />
            <Route path="utilities/typography" element={<Typography />} />
            <Route path="utilities/vertical-align" element={<VerticalAlign />} />
            <Route path="utilities/flex" element={<Flex />} />
            <Route path="utilities/grid" element={<Grid />} />
            <Route path="utilities/scroll-bar" element={<Scrollbar />} />

            <Route path="widgets" element={<Widgets />} />

            {/*Documentation*/}
            <Route
                path="documentation/getting-started"
                element={<GettingStarted />}
            />
            <Route path="documentation/configuration" element={<Configuration />} />
            <Route path="documentation/styling" element={<Styling />} />
            <Route path="documentation/dark-mode" element={<DarkMode />} />
            <Route path="documentation/plugin" element={<Plugins />} />
            <Route path="documentation/faq" element={<Faq />} />
            <Route path="documentation/design-file" element={<DesignFile />} />
            <Route path="changelog" element={<Changelog />} />
            <Route path="authentication-modal" element={<ModalAuth />} />
          </Route>

          {/* //--- MainLayout end  */}

          {/* <Navigate to="/errors/404" /> */}
          <Route path="*" element={<Navigate to="/errors/404" replace />} />
        </Routes>
  );
};

export default FolkinRoutes;
