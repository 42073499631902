import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import ProfileIntro from "./ProfileIntro";
import ProfileSsoInfo from "./ProfileSsoInfo";
import ProfileAvailabilityCalendar from "./ProfileAvailabilityCalendar";
import ProfileCategories from "./ProfileCategories";
import ProfileCV from "./ProfileCV";
import ProfileFiles from "./ProfileFiles";
import useAuth from "../../../hooks/useAuth";
import PersonRegistrationInfo from "./PersonRegistrationInfo";
import TempStatusWarning from "./TempStatusWarning";
import useMyProfile from "../../../hooks/useMyProfile";

const ProfileBottomSection = ({profile, isMyProfile, events}) => {
  const {isSuperAdmin,isCompanyAdmin} = useAuth();
  const {getTempStatusVisibility} = useMyProfile();
  return (
    <>
      {(isMyProfile || isCompanyAdmin() || isSuperAdmin()) && (
        <>
          {isMyProfile && !isCompanyAdmin() && !isSuperAdmin() && (
            <Row className="g-3 mb-3">
              <Col>
                <TempStatusWarning tempStatusVisibility={getTempStatusVisibility(profile, events)} />
              </Col>
            </Row>
          )}
          {!profile.registeredAsTemp && (
            <>
              <ProfileIntro profileData={profile} />
              {isSuperAdmin() && (
                <Row className="g-3 mb-3">
                  <Col lg={8}>
                    <ProfileSsoInfo personId={profile.id} />
                  </Col>
                  <Col lg={4}>
                    <PersonRegistrationInfo personId={profile.id} />
                  </Col>
                </Row>
              )}
            </>
          )}
          {profile.registeredAsTemp && (
            <>
              <Row className="g-3 mb-3 d-none d-lg-flex">
                <Col lg={8}>
                  <ProfileIntro profileData={profile} />
                  {profile.availabilityStatus === 'CALENDAR' && (
                    <ProfileAvailabilityCalendar isMyProfile={isMyProfile} events={events} />
                  )}
                  {isSuperAdmin() && (
                    <PersonRegistrationInfo personId={profile.id} />
                  )}
                </Col>
                <Col lg={4}>
                  <div className="sticky-sidebar">
                    <ProfileCategories profileData={profile} />
                    {(isMyProfile || !profile.privateProfile) && (
                      <>
                        <ProfileCV profileData={profile} />
                        <ProfileFiles profileData={profile} />
                      </>
                    )}
                    {isSuperAdmin() && (
                      <ProfileSsoInfo personId={profile.id} />
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="g-3 mb-3 d-lg-none">
                <Col>
                  <ProfileIntro profileData={profile} />
                  <ProfileCategories profileData={profile} />
                  {profile.availabilityStatus === 'CALENDAR' && (
                    <ProfileAvailabilityCalendar isMyProfile={isMyProfile} events={events} />
                  )}
                  {(isMyProfile || !profile.privateProfile) && (
                    <>
                      <ProfileCV profileData={profile} />
                      <ProfileFiles profileData={profile} />
                    </>
                  )}
                  {isSuperAdmin() && (
                    <>
                      <ProfileSsoInfo personId={profile.id} />
                      <PersonRegistrationInfo personId={profile.id} />
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

ProfileBottomSection.propTypes = {
  profile: PropTypes.object.isRequired,
  isMyProfile: PropTypes.bool.isRequired,
  events: PropTypes.array,
};

export default ProfileBottomSection;
