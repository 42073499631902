import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import useTemps from "../../../hooks/useTemps";
import PropTypes from "prop-types";


const FilterOnAvailabilityPercent = ({availabilityPercent}) => {
  const { handleSearchOnAvailabilityPercent } = useTemps();
  const [searchAvailabilityPercent, setSearchAvailabilityPercent] = useState(availabilityPercent);

  const onChange = e => {
    setSearchAvailabilityPercent(e.target.value);
    handleSearchOnAvailabilityPercent(e.target.value);
  };
  useEffect(() => {
    setSearchAvailabilityPercent(availabilityPercent)
  }, [availabilityPercent]);
  return (
    <Form.Group controlId="percentAvailability">
      <Form.Select
        id="percent-availability-select"
        className={searchAvailabilityPercent!==undefined&&searchAvailabilityPercent>0?'text-800':'text-500'}
        size="sm"
        onChange={onChange}
        placeholder="Kapasitetsprosent"
        value={searchAvailabilityPercent}
      >
        <option key="-1" value="-1" className="">Kapasitet</option>
        <option key="10" value="10">> 10%</option>
        <option key="20" value="20">> 20%</option>
        <option key="30" value="30">> 30%</option>
        <option key="40" value="40">> 40%</option>
        <option key="50" value="50">> 50%</option>
        <option key="60" value="60">> 60%</option>
        <option key="70" value="70">> 70%</option>
        <option key="80" value="80">> 80%</option>
        <option key="90" value="90">> 90%</option>
        <option key="100" value="100">100%</option>
      </Form.Select>
    </Form.Group>
  );
};

FilterOnAvailabilityPercent.propTypes = {
  availabilityPercent: PropTypes.number
};
export default FilterOnAvailabilityPercent;