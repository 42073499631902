import React, {useContext} from 'react';
import ProfileTopSection from '../profile/ProfileTopSection';
import {MyProfileContext} from "../../../context/Context";
import ProfileBottomSection from "../profile/ProfileBottomSection";

const MyProfile = () => {
  const {myProfileState: { myProfilePerson,myAvailabilityEvents }} = useContext(MyProfileContext);
  //console.log('[MyProfile] : ' + JSON.stringify(myProfilePerson));

  //console.log(myProfilePerson.registeredAsTemp, '[MyProfile] : myProfilePerson.registeredAsTemp');
  return (
    <>
      <ProfileTopSection profileData={myProfilePerson} isMyProfile={true} />
      <ProfileBottomSection profile={myProfilePerson} isMyProfile={true} events={myAvailabilityEvents} />
    </>
  );
};


export default MyProfile;
