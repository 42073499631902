/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const favouritesReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'RELOAD_FAVOURITE_ASSIGNMENTS':
      return {
        ...state,
        favouriteAssignments: payload.favouriteAssignments
      };
    case 'RELOAD_FAVOURITE_PERSONS':
      return {
        ...state,
        favouritePersons: payload.favouritePersons
      };
    default:
      return state;
  }
};
