import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom'
import CompanyBanner from './CompanyBanner';
import ProfileIntro from '../../profile/ProfileIntro';
import {Col, Row} from 'react-bootstrap';
import NarrowAssignmentListForCompany from "../../assignment/NarrowAssignmentListForCompany";
import callApiCompanies from "../../../../api/callApiCompanies";
import {useAuth0} from "@auth0/auth0-react";
import CompanyPersonsList from "./CompanyPersonsList";
import useAuth from "../../../../hooks/useAuth";
import useMyAccess from "../../../../hooks/useMyAccess";

const CompanyDetails = () => {
  const {companyId} = useParams();
  const {fetchCompanyWithInvitations} = callApiCompanies();
  const {isSuperAdmin} = useAuth()
  const {isCompanyAdminAndHaveAccessToCompany} = useMyAccess();
  //console.log('CompanyDetails. companyId : ' + companyId);
  const { isAuthenticated } = useAuth0();
  const [company, setCompany] = useState({});

  const handleLoadCompany = (data) => {
    setCompany(data);
  };
  useEffect(() => {
    fetchCompanyWithInvitations(companyId,handleLoadCompany);
  }, [isAuthenticated]);

  return (
    <>
      <CompanyBanner company={company}/>
      <Row className="g-3 mb-3">
        <Col lg={8}>
          <ProfileIntro profileData={company}/>
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            {isSuperAdmin() && (
              <CompanyPersonsList company={company} />
            )}
            {isCompanyAdminAndHaveAccessToCompany(companyId) && !isSuperAdmin() && (
              <CompanyPersonsList company={company} />
            )}
            <NarrowAssignmentListForCompany companyId={companyId} cardTitle={'Oppdrag'}/>
          </div>
        </Col>
      </Row>
    </>
  );
};


export default CompanyDetails;
