import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import cloudUpload from "../../../../falcon-react-lib/assets/img/icons/cloud-upload.svg";
import {Button, Card, Image} from 'react-bootstrap';
import Hoverbox from "../../../../falcon-react-lib/components/common/Hoverbox";
import useUrlHelpers from "hooks/useUrlHelpers";
import ErrorConfirmation from "../../../confirmation/ErrorConfirmation";
import PropTypes from "prop-types";
import useS3Upload from "../../../../hooks/useS3Upload";

const EditProfileAvatar = ({ formData, setFormData }) => {
  const {uploadProfileAvatar} = useS3Upload();
  const {getFileUrlPublicS3Bucket} = useUrlHelpers();
  const [profileThumbnail, setProfileThumbnail] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  // Handle the displaying of the error modal
  const showErrorModal = () => {
    setDisplayConfirmationModal(true);
  };
  // Hide the delete modal
  const hideErrorModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleFileUploadResponse = (fileInfo) => {
    // console.log(fileInfo, ' [EditProfileAvatar] onDrop - fileInfo');
    if(fileInfo.status!==undefined) {
      setErrorMessage(fileInfo.message);
      showErrorModal();
    } else {
      setProfileThumbnail(fileInfo);
      setFormData({
        ...formData,
        thumbnail: {
          name: fileInfo.name,
          image: fileInfo.url
        }
      });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      uploadProfileAvatar(acceptedFiles[0], handleFileUploadResponse);
    }
  });

  const loadLogoFromFormData = () => {
    if(formData.thumbnail!==undefined && formData.thumbnail.image!==undefined && formData.thumbnail.image!==null && formData.thumbnail.image!=='') {
      const fileInfo = {
        name: formData.thumbnail.name,
        url: formData.thumbnail.image
      }
      setProfileThumbnail(fileInfo);
    }
  }
  const removeProfileThumbnail = () => {
    setProfileThumbnail(null);
    setFormData({
      ...formData,
      thumbnail: {}
    });
  }

  useEffect(() => {
    loadLogoFromFormData();
  }, [formData]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-light">
          <h5 className="mb-0 d-inline-block">
            Endre profilbilde
          </h5>
        </Card.Header>
        <Card.Body>
          <div {...getRootProps({ className: 'dropzone-area py-6' })}>
            <input {...getInputProps({ multiple: false })} />
            <div className="fs--1">
              <img src={cloudUpload} alt="" width={20} className="me-2" />
              <span className="d-none d-lg-inline">
                Dra bildet ditt hit
                <br />
                eller,{' '}
              </span>
              <Button variant="link" size="sm" className="p-0 fs--1">
                Hent
              </Button>
            </div>
          </div>
          {profileThumbnail && (
            <div className="mt-3">
              <Hoverbox className="text-center">
                <Image
                  rounded
                  src={getFileUrlPublicS3Bucket(profileThumbnail.url)}
                  alt={profileThumbnail.name}
                  className="w-100 h-100 fit-cover" />
                <Hoverbox.Content className="light bg-dark p-5 flex-center">
                  <div>
                    <a
                      className="btn btn-light btn-sm mt-1"
                      onClick={() => {
                        removeProfileThumbnail();
                      }}
                    >
                      Fjern
                    </a>
                  </div>
                </Hoverbox.Content>
              </Hoverbox>
            </div>
          )}
        </Card.Body>
      </Card>
      <ErrorConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideErrorModal}
        message={errorMessage}
      />
    </>
  );
};

EditProfileAvatar.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};


export default EditProfileAvatar;