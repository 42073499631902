import React from 'react';
import PropTypes from 'prop-types';
import RegisterWizardSteps from './RegisterWizardSteps';
import AuthWizardProvider from 'falcon-react-lib/components/wizard/AuthWizardProvider';

const RegisterWizard = ({ variant, validation, progressBar }) => {
  return (
    <AuthWizardProvider>
      <RegisterWizardSteps
        variant={variant}
        validation={validation}
        progressBar={progressBar}
      />
    </AuthWizardProvider>
  );
};

RegisterWizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

export default RegisterWizard;
