import Flex from '../../../falcon-react-lib/components/common/Flex';
import React, {useContext} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import ProfileBanner from './ProfileBanner';
import PropTypes from "prop-types";
import Avatar from "../../../falcon-react-lib/components/common/Avatar";
import useUrlHelpers from "hooks/useUrlHelpers";
import callApiChats from "../../../api/callApiChats";
import {FavouritesContext} from "../../../context/Context";
import callApiFavourites from "../../../api/callApiFavourites";
import {v4 as uuidv4} from "uuid";
import {formatISO} from "date-fns";
import IconButton from "../../../falcon-react-lib/components/common/IconButton";
import classNames from "classnames";

const ProfileTopSection = ({profileData, isMyProfile}) => {
  //console.log(profileData.companies, ' - [Banner]. profileData companies ')
  const {getAvatarUrl,getCompanyAvatarUrl} = useUrlHelpers();
  const {createNewMessages} = callApiChats();

  const {favouritesState: { favouritePersons }} = useContext(FavouritesContext);
  const {addFavouritePerson,removeFavouritePerson} = callApiFavourites();

  //console.log(favouritePersons, ' - [ProfileTopSection]. favouritePersons ')

  const getFavouriteItem = () => {
    if(!favouritePersons) {
      return null;
    }
    return favouritePersons.find((fav) => fav.personId === profileData.id);
  }

  const isInFavouritePersons = () => {
    var check = getFavouriteItem();
    return check !== undefined;
  }

  const createTitle = () => {
    //console.log(profileData?.competences, '  ->  profileData?.competences');
    //console.log(profileData?.currentStudies, '  ->  profileData?.currentStudies');
    let competences = profileData?.competences?.map(c => c.name);
    let currentStudies = profileData?.currentStudies?.map(c => c.name);
    if(competences===undefined) {
      competences = [];
    }
    if(currentStudies===undefined) {
      currentStudies = [];
    }
    const combined = [...competences, ...currentStudies];
    if(combined.length===0) {
      return '';
    }
    if(combined.length===1) {
      return combined[0];
    }
    if(combined.length===2) {
      return combined[0] + ' og ' + combined[1];
    }

    var title = '';
    for (let i = 0; i < combined.length-2; i++) {
      title += combined[i] + ", ";
    }
    title += combined[combined.length-2] + ' og ' + combined[combined.length-1];
    return title;
  }

  const handleFavouriteClick  = (e) => {
    e.preventDefault();
    var element = getFavouriteItem();
    if(element !== undefined) {
      //console.log(element, ' -> removeFavouriteAssignment element');
      var index = favouritePersons.indexOf(element);
      favouritePersons.splice(index,1);
      removeFavouritePerson(element.id);
    } else {
      let newFavItem = {
        personId: profileData.id,
        id: uuidv4(),
        note: null,
        registered: formatISO(new Date())
      }
      favouritePersons.push(newFavItem);
      //console.log('addFavouriteAssignment');
      addFavouritePerson(profileData.id);
    }
  }


  let avatarUrl = getAvatarUrl(profileData.thumbnail?.image, profileData.firstName,profileData.lastName)

  const createNewMessagesWhenClicked = (e) => {
    e.preventDefault();
    createNewMessages(profileData.id)
  }

  return (
    <ProfileBanner>
      <ProfileBanner.Header avatar={avatarUrl} coverSrc={null} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {profileData.firstName} {profileData.lastName}
            </h4>
            <h5 className="fs-0 fw-normal">
              {createTitle()}
            </h5>
            <p className="text-500">{profileData.location}</p>

            {!isMyProfile && (
              <>
                <IconButton
                  size="sm"
                  variant={"falcon-default"}
                  className={classNames('w-75 w-sm-auto px-3', {
                    'border-300': !isInFavouritePersons()
                  })}
                  icon={isInFavouritePersons() ? 'heart' : ['far', 'heart']}
                  onClick={handleFavouriteClick}
                >
                  {isInFavouritePersons() ? 'Fjern fra favoritter' : 'Legg til som favoritt'}
                </IconButton>

                <Button variant="falcon-default" size="sm" className="w-50 w-sm-auto px-3 ms-0 ms-sm-2 mt-2 mt-sm-0" onClick={createNewMessagesWhenClicked}>
                  Message
                </Button>
              </>
            )}

            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            {profileData.companies!==undefined && profileData.companies.map((company) => (
              <Row key={company.id}>
                <Col>
                  <Flex alignItems="center" className="mb-2">
                    <Link className="d-flex pe-2" to={'/company/'+company?.id}>
                      <Avatar size="2xl" src={getCompanyAvatarUrl(company)} className={status} />
                    </Link>

                    <div className="flex-1">
                      <Link to={'/company/'+company?.id}>
                        <h6 className="mb-0">{company?.name}</h6>
                      </Link>

                    </div>
                  </Flex>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

ProfileTopSection.propTypes = {
  profileData: PropTypes.object.isRequired,
  isMyProfile: PropTypes.bool.isRequired
};

export default ProfileTopSection;
