import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { registerLocale} from  "react-datepicker";
import {format, parse} from 'date-fns'
import nb from 'date-fns/locale/nb';
registerLocale('nb', nb)

const AssignmentDuration = ({ formData, setFormData }) => {
  //console.log('[AssignmentDuration] formData ' +JSON.stringify(formData))
  const datePattern = 'dd.MM.yyyy'

  const handleChange = (name, value) => {
    //console.log('[AssignmentDuration] handleChange ' + value);
    //console.log('[AssignmentDuration] handleChange ' + format(value,datePattern));
    setFormData({
      ...formData,
      [name]: format(value,datePattern)
    });
  }

  return (
    <Card className="mb-3 mb-lg-3">
      <Card.Header as="h5" className="bg-light">
        Oppdragets varighet
      </Card.Header>
      <Card.Body>
        <Row className="g-lg-3 font-sans-serif">
          <Col sm={6}>
            <Form.Group controlId="startDate">
              <Form.Label>Fra</Form.Label>
              <DatePicker
                id="startDate"
                value={formData.startDate}
                selected={parse(formData.startDate,datePattern, new Date())}
                dateFormat={datePattern}
                locale="nb"
                onChange={newDate => {
                   handleChange('startDate', newDate);
                   //setValue('startDate', newDate);
                  }}
                // customInput={
                //   <CustomDateInput
                //     formControlProps={{
                //       placeholder: 'dd.MM.yyyy',
                //       ...register('startDate')
                //     }}
                //   />
                // }
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group controlId="endDate">
              <Form.Label>Til</Form.Label>
              <DatePicker
                id="endDate"
                value={formData.endDate}
                selected={parse(formData.endDate,datePattern, new Date())}
                dateFormat={datePattern}
                locale="nb"
                onChange={newDate => {
                  handleChange('endDate', newDate);
                  //setValue('endDate', newDate);
                }}
                // customInput={
                //   <CustomDateInput
                //     formControlProps={{
                //       placeholder: 'dd.MM.yyyy',
                //       ...register('endDate')
                //     }}
                //   />
                // }
              />
            </Form.Group>

            {/*<Form.Group controlId="endDate">*/}
            {/*  <Form.Label>Start</Form.Label>*/}
            {/*  <DatePicker*/}
            {/*    selected={formData.endDate}*/}
            {/*    locale="nb"*/}
            {/*    dateFormat="dd.MM.yyyy"*/}
            {/*    onChange={newDate => {*/}
            {/*      handleChange('endDate', newDate);*/}
            {/*      setValue('endDate', newDate);*/}
            {/*    }}*/}
            {/*    customInput={*/}
            {/*      <CustomDateInput*/}
            {/*        formControlProps={{*/}
            {/*          placeholder: 'dd.MM.yyyy',*/}
            {/*          ...register('endDate')*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Form.Group>*/}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

AssignmentDuration.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};
export default AssignmentDuration;
