import React from 'react';
import { Card } from 'react-bootstrap';
import SoftBadge from "../../../falcon-react-lib/components/common/SoftBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const ProfileCategories = ({profileData}) => {
  // console.log('[ProfileCategories] profileData: ' + JSON.stringify(profileData));
  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <h5 className="mb-0">Vikarinformasjon</h5>
      </Card.Header>
      <Card.Body>
          <h6 className="fw-bold">
            Tilgjengelighet
          </h6>

          <SoftBadge key={8888} pill bg="dark">
            <FontAwesomeIcon
              icon="calendar-alt"
              className="me-1"
              transform="shrink-4"
            />
            {profileData.availabilityStatus === 'CALENDAR' && (
              <span>Kalenderstyrt</span>
            )}
            {profileData.availabilityStatus === 'NONE' && (
              <span>Ikke tilgjengelig akkurat nå</span>
            )}
            {profileData.availabilityStatus === 'ALWAYS' && (
              <span>Alltid tilgjengelig</span>
            )}
          </SoftBadge>
          {profileData.percentAvailability && (
            <SoftBadge key={9999} pill bg="dark">
              <FontAwesomeIcon
                icon="calendar-alt"
                className="me-1"
                transform="shrink-4"
              />
              {profileData.percentAvailability}%
            </SoftBadge>
          )}

          <div className="border-dashed border-bottom my-3" />

          <h6 className="fw-bold">
            Ansenitet
          </h6>

          <div className="ps-2">
            {profileData.yearsSeniority && (
              <SoftBadge key={7777} pill bg="primary">
                <FontAwesomeIcon
                  icon="clock"
                  className="me-1"
                  transform="shrink-4"
                />
                {profileData.yearsSeniority.name}
              </SoftBadge>
            )}
          </div>

          <h6 className="fw-bold pt-3">
            Profesjon/Utdannelse
          </h6>


          <div className="ps-2">
            {profileData.competences!==undefined && profileData.competences.map((competence) => (
              <SoftBadge key={competence.id} pill bg="primary">
                <FontAwesomeIcon
                  icon="graduation-cap"
                  className="me-1"
                  transform="shrink-4"
                />
                {competence.name}
              </SoftBadge>
            ))}
          </div>

          {profileData.currentStudies!==undefined && profileData.currentStudies.length>0 && (
            <>
              <h6 className="fw-bold pt-3">
                Student
              </h6>

              <div className="ps-2">
                {profileData.currentStudies!==undefined && profileData.currentStudies.map((currentStudy) => (
                  <SoftBadge key={currentStudy.id} pill bg="primary">
                    <FontAwesomeIcon
                      icon="graduation-cap"
                      className="me-1"
                      transform="shrink-4"
                    />
                    {currentStudy.name}
                  </SoftBadge>
                ))}
              </div>
            </>
          )}

          <h6 className="fw-bold pt-3">
            Bransje
          </h6>

          <div className="ps-2">
            {profileData.interestedInIndustries!==undefined && profileData.interestedInIndustries.map((industry) => (
              <SoftBadge key={industry.id} pill bg="primary">
                <FontAwesomeIcon
                  icon="briefcase"
                  className="me-1"
                  transform="shrink-4"
                />
                {industry.name}
              </SoftBadge>
            ))}
          </div>


          <h6 className="fw-bold pt-3">
            Regioner
          </h6>

          <div className="ps-2">
            {profileData.interestedInRegions!==undefined && profileData.interestedInRegions.map((region) => (
              <SoftBadge key={region.id} pill bg="primary">
                <FontAwesomeIcon
                  icon="location-arrow"
                  className="me-1"
                  transform="shrink-4"
                />
                {region.name}
              </SoftBadge>
            ))}
          </div>

      </Card.Body>
    </Card>
  );
};

ProfileCategories.propTypes = {
  profileData: PropTypes.object.isRequired
};


export default ProfileCategories;
