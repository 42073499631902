import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import useUrlHelpers from "hooks/useUrlHelpers";

const TempsForTempsListElement = ({ temp }) => {
  const {getAvatarUrl} = useUrlHelpers();
  let avatarUrl = getAvatarUrl(temp.thumbnail?.image, temp.firstName,temp.lastName)
  return (
    <>
      <Card className="overflow-hidden">
        <Card.Body className="p-0">
          <Row className="g-0">
            <Col className="p-x1">
              <div className="d-flex align-items-center">
                <div className="avatar avatar-3xl">
                  <img className="rounded-circle img-thumbnail shadow-sm" src={avatarUrl} alt="" />
                </div>
                <div className="ps-2">
                      <h5 className="fs-0" >
                        <Link
                          to={`/profile/${temp.id}`}
                        >
                          {temp.firstName} {temp.lastName}
                        </Link>
                      </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

TempsForTempsListElement.propTypes = {
  temp: PropTypes.object.isRequired
};

export default TempsForTempsListElement;
