//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coverSrc from '../../../../falcon-react-lib/assets/img/generic/4.jpg';
//import Flex from '../../../../falcon-react-lib/components/common/Flex';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
//import { Link } from 'react-router-dom';
import ProfileBanner from 'components/app/profile/ProfileBanner';
import PropTypes from "prop-types";
import useUrlHelpers from "hooks/useUrlHelpers";
//import useAuthProvider from "../../../../hooks/useAuthProvider";

const CompanyBanner = ({company}) => {
  const {getCompanyAvatarUrl} = useUrlHelpers();
  let avatarUrl = getCompanyAvatarUrl(company)
  //console.log('CompanyProfileBanner. company: ' + JSON.stringify(company))
  return (
    <ProfileBanner>
      <ProfileBanner.Header avatar={avatarUrl} coverSrc={coverSrc} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">
              {company?.name}
            </h4>
            <p className="text-500">{company?.location}</p>
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            {/*<Link to="#!">*/}
            {/*  <Flex alignItems="center" className="mb-2">*/}
            {/*    <FontAwesomeIcon*/}
            {/*      icon="user-circle"*/}
            {/*      className="me-2 text-700"*/}
            {/*      style={{ height: '30px', width: '30px' }}*/}
            {/*    />*/}
            {/*    <div className="flex-1">*/}
            {/*      <h6 className="mb-0">Link til noe annet</h6>*/}
            {/*    </div>*/}
            {/*  </Flex>*/}
            {/*</Link>*/}
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

CompanyBanner.propTypes = {
  company: PropTypes.object.isRequired
};

export default CompanyBanner;
