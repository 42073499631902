import React from 'react';
import corner4 from 'falcon-react-lib/assets/img/illustrations/corner-4.png';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Background from 'falcon-react-lib/components/common/Background';
import PropTypes from "prop-types";

const CreateCompanyHeader = ({companyId}) => {
  return (
    <Card className="mb-3">
      <Background
        image={corner4}
        className="bg-card d-none d-md-block"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body className="position-relative">
        <Row>
          <Col xl={10}>
            <h3>
              {companyId===undefined || companyId===null ?(<span>Registrer ny enhet</span>) : (<span>Endre en enhet</span>)}
              </h3>
            <p className="mb-2">
              Her kan du registrere både et <span className="fw-bold">selskap</span> og en <span className="fw-bold">avdeling innenfor et selskap</span>. Dette er enheten som vil stå som oppdragsgiver når man registrerer oppdrag på folkin.
            </p>
            <p className="mb-0">
              Ønsker du å endre på et {companyId!==undefined && companyId!==null && (<span>annet</span>)} eksisterende enhet? Gå til
              <Link to="/company/mine"> Mine enheter</Link>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CreateCompanyHeader.propTypes = {
  companyId: PropTypes.string
};

export default CreateCompanyHeader;
