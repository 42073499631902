import React, {useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import { PersonContext } from 'context/Context';
import { personReducer } from 'reducers/personReducer';
import { config } from 'Constants'
import {useAuth0} from "@auth0/auth0-react";

const PersonsProvider = ({ children }) => {
  const initData = {
    initPersons: [],
    persons: [],
    primaryPersons: [],
    personFilters: [],
    filterOptions: [],
    searchedText: null,
    isAsc: true
  };
  const [personsState, personsDispatch] = useReducer(personReducer, initData);
  const {getAccessTokenSilently} = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  
  const fetchPersonFilters = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/person/filters", {headers,})
      .then(response => {
        return response.json()
      })
      .then(data => {
        personsDispatch({
          type: 'RELOAD_PERSON_FILTERS',
          payload: {
            personFilters: data,
          }
        });
      })
  }


  const fetchPersons = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/person", {headers,})
      .then(response => {
          return response.json()
      })
      .then(data => {
          //console.log('[PersonsProvider] fetchPersons: data ' + JSON.stringify(data));
          personsDispatch({
              type: 'RELOAD_PERSON',
              payload: {
                  persons: data
              }
          });
      })
  }

  useEffect(() => {
    if(accessToken!=="") {
      fetchPersonFilters();
      fetchPersons();
    }
  }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])
  
  return (
    <PersonContext.Provider
      value={{
        personsState,
        personsDispatch
      }}
    >
      {children}
    </PersonContext.Provider>
  );
};

PersonsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default PersonsProvider;
