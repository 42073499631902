/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const authReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'LOAD_ROLES':
      //console.log(payload, ' ->[authReducer] LOAD_ROLES')
      return {
        ...state,
        roles: payload.roles,
        superAdmin: payload.roles?.includes('super-admin'),
        companyAdmin: payload.roles?.includes('company-admin')
      };
    default:
      return state;
  }
};
