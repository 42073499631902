import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AppContext, { ChatContext } from 'falcon-react-lib/context/Context';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';
import useChats from "../../../../hooks/useChats";
import { v4 as uuidv4 } from 'uuid'
import {MyProfileContext} from "../../../../context/Context";
import callApiChats from "../../../../api/callApiChats";

const formatDate = date => {
  const optionsDateAndTime = {
    dateStyle: 'short',
    timeStyle: 'short'
  };

  const optionsWeekday = {
    weekday: 'short'
  };

  const nowDateAndTime = date
    .toLocaleString('no-No', optionsDateAndTime)
    .split(',')
    .map(item => item.trim());

  const nowWeekday = date
    .toLocaleString('no-No', optionsWeekday)
    .split(',')
    .map(item => item.trim());

  return {
    day: nowWeekday[0],
    hour: nowDateAndTime[1],
    date: nowDateAndTime[0]
  };
};

const MessageTextArea = () => {
  const { handleScrollToBottom, handleStartUpdate } = useChats();
  const { saveNewMessage } = callApiChats();
  const {chatState: { currentThread, isOpenThreadInfo }} = useContext(ChatContext);
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [message, setMessage] = useState('');
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);

  const {
    config: { isDark }
  } = useContext(AppContext);

  const addEmoji = e => {
    let emoji = e.native;
    //console.log(emoji, '  -> MessageTextArea. emoji')
    setMessage(message + emoji);
    setPreviewEmoji(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const date = new Date();
    let newMessage = {
      senderUserId: myProfilePerson.id,
      message: `${message.replace(/(?:\r\n|\r|\n)/g, '<br>')}`,
      status: 'delivered',
      time: formatDate(date),
      id:  uuidv4()
    };

    if (message) {
      //console.log('[MessageTextArea] currentThread.messagesId: ' + currentThread);
      //console.log('[MessageTextArea] newMessage: ' + newMessage.id);
      saveNewMessage(currentThread.messagesId, newMessage);
      handleStartUpdate(currentThread.id,true);
    }
    setMessage('');
    handleScrollToBottom(true);
    //console.log('[MessageTextArea] setting handleScrollToBottom = true ');
  };

  useEffect(() => {
    if (isOpenThreadInfo) {
      setPreviewEmoji(false);
    }
  }, [isOpenThreadInfo]);

  return (
    <Form className="chat-editor-area" onSubmit={handleSubmit}>
      <TextareaAutosize
        minRows={1}
        maxRows={6}
        value={message}
        placeholder="Skriv meldingen din her"
        onChange={({ target }) => setMessage(target.value)}
        className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
      />

      {/*<Form.Group controlId="chatFileUpload">*/}
      {/*  <Form.Label className="chat-file-upload cursor-pointer">*/}
      {/*    <FontAwesomeIcon icon="paperclip" />*/}
      {/*  </Form.Label>*/}
      {/*  <Form.Control type="file" className="d-none" />*/}
      {/*</Form.Group>*/}

      <Button
        variant="link"
        className="emoji-icon "
        onClick={() => setPreviewEmoji(!previewEmoji)}
      >
        <FontAwesomeIcon
          icon={['far', 'laugh-beam']}
          onClick={() => setPreviewEmoji(!previewEmoji)}
        />
      </Button>

      {previewEmoji && (
        <div className="chat-emoji-picker" dir="ltr">
          <Picker data={data}
                  onEmojiSelect={addEmoji}
                  theme={isDark ? 'dark' : 'light'}
                  previewPosition="none"
                  skinTonePosition="none"
          />
        </div>
      )}

      <Button
        variant="send"
        size="sm"
        className={classNames('shadow-none', {
          'text-primary': message.length > 0
        })}
        type="submit"
      >
        Send
      </Button>
    </Form>
  );
};

MessageTextArea.propTypes = {
  thread: PropTypes.object
};

export default MessageTextArea;
