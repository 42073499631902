import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'falcon-react-lib/assets/img/icons/spot-illustrations/16.png';

const ConfirmMailContent = ({ titleTag: TitleTag }) => (
  <>
    <img
      className="d-block mx-auto mb-4"
      src={envelope}
      alt="sent"
      width={100}
    />
    <TitleTag>Sjekk eposten din!</TitleTag>
    <p>
      En epost ble sendt til deg. Vennligst klikk på lenken i eposten for å bekrefte din epostadresse.
    </p>
    <Button
      as={Link}
      color="primary"
      size="md"
      className="mt-3"
      to={`/`}
    >
      <FontAwesomeIcon
        icon="chevron-left"
        transform="shrink-4 down-1"
        className="me-1"
      />
      Tilbake til login
    </Button>
  </>
);

ConfirmMailContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default ConfirmMailContent;
