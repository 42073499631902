import {useContext} from 'react';
import { ChatContext } from 'falcon-react-lib/context/Context';

const useChats = () => {
  //console.log(ChatContext, '   -> useChates ')
  const {chatDispatch: chatDispatch} = useContext(ChatContext);

  const handleNewMessage = (messagesId, newMessage) => {
    //console.log('[useChats] handleNewMessage ' + messagesId);
    chatDispatch({
      type: 'ADD_NEW_MESSAGE',
      payload: {
        messagesId: messagesId,
        newMessage: newMessage
      }
    });
  };

  const handleStartUpdate = (threadId, updateStarted) => {
    chatDispatch({
      type: 'SET_UPDATE_STARTED',
      payload: {
        updateStarted: updateStarted,
        threadId: threadId
      }
    });
  };

  const handleOpenThreadInfo = isOpenThreadInfo => {
    chatDispatch({
      type: 'SET_OPEN_THREAD_INFO',
      payload: {
        isOpenThreadInfo
      }
    });
  };


  const handleCurrentThread = threadId => {
    chatDispatch({
      type: 'SET_CURRENT_THREAD',
      payload: {
        threadId
      }
    });
  };

  const handleCurrentThreadByIndex = index => {
    chatDispatch({
      type: 'SET_CURRENT_THREAD_BY_INDEX',
      payload: {
        index
      }
    });
  };

  const handleActiveTabKeyByIndex = index => {
    chatDispatch({
      type: 'SET_ACTIVE_TAB_KEY_BY_INDEX',
      payload: {
        index
      }
    });
  };

  const handleSelectActiveThreadAndTabKeyForPerson = personId => {
    //console.log('[useChats] handleSelectActiveThreadAndTabKeyForPerson. personId: '+ personId)
    //console.log('[useChats] handleSelectActiveThreadAndTabKeyForPerson. users: '+ JSON.stringify(users))
    chatDispatch({
      type: 'SET_CURRENT_THREAD_AND_ACTIVE_TAB_KEY_BY_PERSON_ID',
      payload: {
        personId
      }
    });
  };

  const handleThreadRead= (threadId, read) => {
    //callApiMyProfileupdateThreadReadFlag(threadId);
    chatDispatch({
      type: 'SET_THREAD_READ',
      payload: {
        threadId: threadId,
        read: read
      }
    });
  };

  const handleThreadReadByIndex= (index, read) => {
    chatDispatch({
      type: 'SET_THREAD_READ_BY_INDEX',
      payload: {
        index: index,
        read: read
      }
    });
  };

  const handleScrollToBottom = scrollToBottom => {
    chatDispatch({
      type: 'SET_SCROLL_TO_BOTTOM',
      payload: {
        scrollToBottom
      }
    });
  };

  const handleReloadAssignments = (data,nextFunction) => {
    chatDispatch({
      type: 'RELOAD_ASSIGNMENTS',
      payload: {
        assignments: data,
        nextFunction: nextFunction
      }
    });
  };


  const handleReloadGroups = (data,nextFunction) => {
    chatDispatch({
      type: 'RELOAD_GROUPS',
      payload: {
        groups: data,
        nextFunction: nextFunction
      }
    });
  };

  const handleReloadChat = (data,nextFunction) => {
    chatDispatch({
      type: 'RELOAD_CHAT',
      payload: {
        chat: data,
        nextFunction: nextFunction
      }
    });
  };

  const handleReloadUsers = (data,nextFunction) => {
    chatDispatch({
      type: 'RELOAD_USERS',
      payload: {
        users: data,
        nextFunction: nextFunction
      }
    });
  };

  const handleReloadChatThreads = (data, handleInitCurrentThreadFirstTime) => {
    chatDispatch({
      type: 'RELOAD_CHAT_THREADS',
      payload: {
        threads: data,
        nextFunction: handleInitCurrentThreadFirstTime
      }
    });
  }

  const handleReloadChatMessages = (data, nextFunction) => {
    chatDispatch({
      type: 'RELOAD_CHAT_THREAD_MESSAGES',
      payload: {
        messages: data,
        nextFunction: nextFunction
      }
    });
  }

  const handleActiveForPersonId = (activeForPersonId) => {
    chatDispatch({
      type: 'SET_ACTIVE_FOR_PERSON_ID',
      payload: {
        activeForPersonId
      }
    });
  };

  const handleActiveThreadId = (activeThreadId) => {
    chatDispatch({
      type: 'SET_ACTIVE_FOR_THREAD_ID',
      payload: {
        activeThreadId
      }
    });
  };

  const handleInitCurrentThreadFirstTime = () => {
    // console.log('[useChats]. SET_CURRENT_THREAD_FIRST_TIME : ')
    chatDispatch({
      type: 'SET_CURRENT_THREAD_FIRST_TIME'
    });
  };

  const handleInitCurrentThreadForPersonId = (personId) => {
    // console.log('[useChats]. SET_CURRENT_THREAD_AND_ACTIVE_TAB_KEY_BY_PERSON_ID personId: ' + personId)
    chatDispatch({
      type: 'SET_CURRENT_THREAD_AND_ACTIVE_TAB_KEY_BY_PERSON_ID',
      payload: {
        personId
      }
    });
  };

  return {
    handleNewMessage,
    handleStartUpdate,
    handleOpenThreadInfo,
    handleCurrentThread,
    handleCurrentThreadByIndex,
    handleActiveTabKeyByIndex,
    handleActiveForPersonId,
    handleActiveThreadId,
    handleSelectActiveThreadAndTabKeyForPerson,
    handleThreadRead,
    handleThreadReadByIndex,
    handleScrollToBottom,
    handleReloadChat,
    handleReloadUsers,
    handleReloadChatThreads,
    handleReloadChatMessages,
    handleReloadAssignments,
    handleReloadGroups,
    handleInitCurrentThreadFirstTime,
    handleInitCurrentThreadForPersonId
  };
};

export default useChats;
