import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "falcon-react-lib/components/common/IconButton";

const ShareButton = ({
                       title,
                       text,
                       url,
                      ...rest
                    }) => {
  if(!url) {
    const canonical = document.querySelector("link[rel=canonical]");
    url = canonical ? canonical.href : document.location.href;
  }
  const shareDetails = { url, title, text };

  const handleSharing = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share(shareDetails);
      } catch (error) {
        console.log(error, `Oops! I couldn't share to the world`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };
  if (navigator.share) {
    return (
      <IconButton
        onClick={handleSharing}
        {...rest}
      >
      </IconButton>
    )
  } else {
    return (
      <>
          <a
            href={'https://www.facebook.com/sharer/sharer.php?u='+url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              className="d-block w-100 mb-xl-0"
              variant="falcon-default"
              icon={['fab', 'facebook-square']}
              iconClassName="text-facebook me-2"
              transform="grow-2"
            >
              Del på Facebook
            </IconButton>
          </a>
          <a
            href={'https://www.linkedin.com/sharing/share-offsite/?url='+url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              className="d-block w-100 mb-xl-0"
              variant="falcon-default"
              icon={['fab', 'linkedin-in']}
              iconClassName="text-linked-in me-2"
              transform="grow-2"
            >
              Del på Linkedin
            </IconButton>
          </a>
      </>
    )
  }
};

ShareButton.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string
};

export default ShareButton;