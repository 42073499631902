import FalconCardHeader from '../../../../falcon-react-lib/components/common/FalconCardHeader';
import React, {useState} from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import callApiMyProfile from "../../../../api/callApiMyProfile";
import {useAuth0} from "@auth0/auth0-react";
import DeleteConfirmation from "../../../confirmation/DeleteConfirmation";
import PropTypes from "prop-types";

const DangerZone = ({personId}) => {
  const {logout} = useAuth0();
  const {deleteMyProfile} = callApiMyProfile();
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  //console.log(personId,'  -> DangerZone');
  const logoutFromPlatform = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  // Handle the displaying of the delete modal
  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  // Hide the delete modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deleteAndHideModal = () => {
    deleteMyProfile(logoutFromPlatform);
    hideConfirmationModal();
  }

  return (
    <>
    <Card>
      <FalconCardHeader title="Danger Zone" />
      <Card.Body className="bg-light">
        <h5 className="mb-0">Slett meg</h5>
        <p className="fs--1">
            Når det har slettet brukerkontoen din og profilen din kan den ikke gjenopprettes.
        </p>
        <Button as={Link} onClick={showDeleteModal} variant="falcon-danger" className="w-100">
          Slett meg
        </Button>
      </Card.Body>
    </Card>
    <DeleteConfirmation
      showModal={displayConfirmationModal}
      hideModal={hideConfirmationModal}
      confirmModal={deleteAndHideModal}
      id={personId}
      name={null}
      message="Er du sikker på at vil slette din brukerkonto på folkin.no for alltid. Dette kan du ikke angre på"
    />
    </>
  );
};

DangerZone.propTypes = {
  personId: PropTypes.string.isRequired
};


export default DangerZone;
