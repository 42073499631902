import React, {useContext} from 'react';
import { Col, Row } from 'react-bootstrap';
import FeedContent from "./FeedContent";
import {FeedContext} from "../../../../falcon-react-lib/context/Context";
import NarrowAssignmentList from "../../assignment/NarrowAssignmentList";
import NarrowTempsList from "../../temps/NarrowTempsList";

const Feed = () => {
  const {feedState: { tempsAvailableToday,assignmentsAvailableToday }} = useContext(FeedContext);
  return (
    <Row className="g-0 g-lg-3">
      <Col lg={8}>
        <FeedContent />
      </Col>
      <Col lg={4}>
        {/*<div className="sticky-sidebar top-navbar-height d-flex flex-column">*/}
        <NarrowTempsList temps={tempsAvailableToday} cardTitle="Ledige vikarer akkurat nå" className="mb-3" />
        <NarrowAssignmentList assignments={assignmentsAvailableToday} cardTitle={'Ledige oppdrag akkurat nå'} />
        {/*<Events cardTitle="Du er kanskje interessert" />*/}
        {/*</div>*/}
      </Col>
    </Row>
  );
};

export default Feed;
