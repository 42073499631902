import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Offcanvas,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import classNames from 'classnames';
import { PersonContext } from 'context/Context';
import usePagination from '../../../falcon-react-lib/hooks/usePagination';
import PersonListElement from './PersonListElement';
import PersonsHeader from './PersonsHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../falcon-react-lib/components/common/Flex';
import PersonsFilters from './PersonsFilters';
import { useBreakpoints } from '../../../falcon-react-lib/hooks/useBreakpoints';
import callApiPersons from "../../../api/callApiPersons";
import {useAuth0} from "@auth0/auth0-react";
import DeleteConfirmation from "../../confirmation/DeleteConfirmation";
import useAuth from "../../../hooks/useAuth";
import {useLocation, useNavigate} from "react-router-dom";

const PersonsList = () => {
  function useQuery() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('clean');
  }
  console.log(useQuery(), ' --> PersonsList clean')
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const {isSuperAdmin} = useAuth();
  const [personId, setPersonId] = useState(null);
  const [personName, setPersonName] = useState(null);
  const [cleanSearhCriterias] = useState(useQuery());
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  const [showFilterOffcanvas, setShowFilterOffcanvas] = useState(false);
  const [personsPerPage, setPersonsPerPage] = useState(6);
  const {breakpoints} = useBreakpoints();
  const {personsState: { persons }} = useContext(PersonContext);
  const {fetchPersons, deletePerson} = callApiPersons();

  const {
    paginationState: {
      data: paginatedPersons,
      totalItems,
      itemsPerPage,
      currentPage,
      canNextPage,
      canPreviousPage,
      paginationArray
    },
    nextPage,
    prevPage,
    goToPage,
    setItemsPerPage
  } = usePagination(persons, personsPerPage);

  useEffect(() => {
    fetchPersons(!cleanSearhCriterias);
    navigate("/person", { replace: true });
  }, [isAuthenticated]);

  // Handle the displaying of the delete modal
  const showDeleteModal = (id, name) => {
    setPersonId(id);
    setPersonName(name);
    setDisplayConfirmationModal(true);
  };

  // Hide the delete modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const deleteAndHideModal = (personIdToDelete) => {
    deletePerson(personIdToDelete);
    hideConfirmationModal();
  }

  return (
    <>
      <Row className="g-3">
        {breakpoints.up('xl') && (
          <Col xl={3}>
            <PersonsFilters />
          </Col>
        )}
        <Col xl={9}>
          {/* Assignments Header */}
          <PersonsHeader setShowFilterOffcanvas={setShowFilterOffcanvas} />
          {/* Assignments */}
          <Row className="mb-3 g-3">
            {paginatedPersons.length > 0 ? (
              paginatedPersons.map(person =>
                (
                  <Col key={person.id} xs={12}>
                    <PersonListElement person={person} isSuperAdmin={isSuperAdmin()} deletePerson={showDeleteModal} />
                  </Col>
                )
              )
            ) : (
              <Card className="bg-transparent shadow-none">
                <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                  <div className="fs--1">
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      className="fs-6 mb-3"
                    />
                    <h5>Fant ingen vikarer!</h5>
                    <p className="mb-0">
                      Søket ditt matchet ingen vikarer.
                    </p>
                  </div>
                </Card.Body>
              </Card>
            )}
          </Row>
          {/* Assignment pagination */}
          {paginatedPersons.length > 0 && (
            <Card>
              <Card.Body>
                <Row className="g-3 flex-center justify-content-sm-between">
                  <Col xs="auto" as={Flex} alignItems="center">
                    <small className="d-none d-lg-block me-4">Totalt: {persons.length}</small>
                    <small className="d-none d-lg-block me-2">Per side:</small>
                    <Form.Select
                      size="sm"
                      value={itemsPerPage}
                      onChange={({ target }) => {
                        setItemsPerPage(target.value);
                        setPersonsPerPage(target.value);
                      }}
                      style={{ maxWidth: '4.875rem' }}
                    >
                      <option value={2}>2</option>
                      <option value={4}>4</option>
                      <option value={6}>6</option>
                      <option value={totalItems}>Alle</option>
                    </Form.Select>
                  </Col>
                  <Col xs="auto" as={Flex}>
                    <div>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={{ position: 'fixed' }}>Prev</Tooltip>
                        }
                      >
                        <Button
                          variant="falcon-default"
                          size="sm"
                          disabled={!canPreviousPage}
                          onClick={prevPage}
                          className="me-2"
                          trigger="focus"
                        >
                          <FontAwesomeIcon icon="chevron-left" />
                        </Button>
                      </OverlayTrigger>
                    </div>

                    <ul className="pagination mb-0">
                      {paginationArray.map(page => (
                        <li
                          key={page}
                          className={classNames({
                            active: currentPage === page
                          })}
                        >
                          <Button
                            size="sm"
                            variant="falcon-default"
                            className="page me-2"
                            onClick={() => goToPage(page)}
                          >
                            {page}
                          </Button>
                        </li>
                      ))}
                    </ul>
                    <div>
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={
                          <Tooltip
                            style={{ position: 'fixed' }}
                            id="button-tooltip-2"
                          >
                            Next
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="falcon-default"
                          size="sm"
                          disabled={!canNextPage}
                          onClick={nextPage}
                          trigger="focus"
                        >
                          <FontAwesomeIcon icon="chevron-right" />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        hideModal={hideConfirmationModal}
        confirmModal={deleteAndHideModal}
        id={personId}
        name={personName}
        message="Er du sikker på at vil slette denne personen"
      />
      {breakpoints.down('xl') && (
        <Offcanvas
          show={showFilterOffcanvas}
          onHide={() => setShowFilterOffcanvas(false)}
          placement="start"
          className="offcanvas offcanvas-filter-sidebar"
        >
          <Offcanvas.Body className="scrollbar scrollbar-none-xl p-0">
            <PersonsFilters isOffcanvas={true} setShow={setShowFilterOffcanvas} />
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default PersonsList;
