import { useContext } from 'react';
import { AssignmentContext } from '../context/Context';

const useAssignments = () => {
  const {assignmentsDispatch: assignmentsDispatch,} = useContext(AssignmentContext);

  const parse = require('html-react-parser');

  const fixAssignmentDescriptionForListView = (description, noOfCharacters) => {
    let descriptionPart = description;
    descriptionPart = descriptionPart.replaceAll("<p>","");
    descriptionPart = descriptionPart.replaceAll("</p>","");
    descriptionPart = descriptionPart.replaceAll("<div>","");
    descriptionPart = descriptionPart.replaceAll("</div>","");
    if (description.length > noOfCharacters) {
      descriptionPart = descriptionPart.substring(0, descriptionPart.substring(0, noOfCharacters-1).lastIndexOf(" "));
    }
    return parse(descriptionPart);
  }

  const handleLoadAssignments = (assignments) => {
    //console.log(assignments,' ->[useAssignments] handleLoadAssignments')
    assignmentsDispatch({
      type: 'RELOAD_ASSIGNMENT',
      payload: {
        assignments: assignments
      }
    });
  };

  const handleLoadAssignmentsAndFilter = (assignments) => {
    //console.log(assignments,' ->[useAssignments] handleLoadAssignments')
    assignmentsDispatch({
      type: 'RELOAD_ASSIGNMENT_AND_FILTER',
      payload: {
        assignments: assignments
      }
    });
  };

  const handleFilter = (id,type,name,value,checked) => {
    assignmentsDispatch({
      type: 'FILTER_ASSIGNMENT',
      payload: {
        id: id,
        type: type,
        name: name,
        value: value,
        checked: checked
      }
    });
  };

  const emptyFilter = () => {
    assignmentsDispatch({
      type: 'EMPTY_FILTER'
    });
  };

  const handleSearchOnText = searchedText => {
    assignmentsDispatch({
      type: 'SEARCH_ASSIGNMENT_TEXT',
      payload: {
        searchedText
      }
    });
  };

  const handleSearchOnDates = (searchedFromDate, searchedToDate) => {
    assignmentsDispatch({
      type: 'SEARCH_ASSIGNMENT_ON_DATES',
      payload: {
        searchedFromDate,
        searchedToDate
      }
    });
  };

  const handleIsAscClick = isAsc => {
    assignmentsDispatch({
      type: 'SORT_ASSIGNMENT',
      payload: {
        isAsc: isAsc
      }
    });
  };

  return {
    fixAssignmentDescriptionForListView,
    handleLoadAssignmentsAndFilter,
    handleLoadAssignments,
    handleFilter,
    emptyFilter,
    handleSearchOnText,
    handleSearchOnDates,
    handleIsAscClick
  };
};

export default useAssignments;
