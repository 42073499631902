import React, {useEffect, useState} from 'react';
import {Card, Form} from 'react-bootstrap';
import PropTypes from "prop-types";
import callApiRegistration from "../../../api/callApiRegistration";

const PersonRegistrationInfo = ({personId}) => {
  //console.log('[PersonRegistrationInfo] personId: ' + personId);
  const {fetchRegistrationForPerson} = callApiRegistration();
  const initRegistration = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    location: '',
    registered: '',
    personId: '',
    isCompanyUser: false,
    companyName: '',
    agreedToTermsFlag: ''
  }
  const [registration, setRegistration] = useState(initRegistration);
  //console.log('[PersonRegistrationInfo] ssoUser: ' + JSON.stringify(ssoUser));
  //console.log('[PersonRegistrationInfo] ssoRoles: ' + JSON.stringify(ssoRoles));

  const handleLoadData = (data) => {
    //console.log(data, ' -handleLoadData')
    setRegistration(data);
  }

  useEffect(() => {
    if(personId) {
      fetchRegistrationForPerson(personId,handleLoadData);
    }
  }, [personId]);

  return (
    <Card className="mb-3" bg={'secondary'} text={'white'}>
      <Card.Header className={'bg-700'}>
        <h5 className="mb-0 text-white">Registrering</h5>
        <h6 className="mb-0 text-white">(Bare synlig for superadmin)</h6>
      </Card.Header>
      <Card.Body>
        <Form.Group className="mb-3" controlId="user_id">
            <Form.Label>Navn</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              value={registration?.firstName+' '+registration?.lastName}
              name="name"
              disabled={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="last_login">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              value={registration?.email}
              name="email"
              disabled={true}
            />
          </Form.Group>
        <Form.Group className="mb-3" controlId="last_login">
          <Form.Label>Registrert</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            value={registration?.registered}
            name="registered"
            disabled={true}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="last_login">
          <Form.Label>Selskap</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            value={registration?.isCompanyUser ? registration?.companyName : 'Privatperson'}
            name="isCompanyUser"
            disabled={true}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

PersonRegistrationInfo.propTypes = {
  personId: PropTypes.string
};


export default PersonRegistrationInfo;
