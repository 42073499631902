import React from 'react';
import {Link} from "react-router-dom";
import WizardInput from "../../../falcon-react-lib/components/wizard/WizardInput";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";

const RegisterFormTemp = ({ register, errors }) => {

  return (
    <>
      <Row className="align-items-center mb-2">
        <Col>
          <h5 id="">Velkommen!</h5>
        </Col>
      </Row>
      <Row className="align-items-center mb-2">
        <Col>
          <p>
            Du har nå laget deg en brukerkonto. Men vi trenger litt mer informasjon om deg.
          </p>
        </Col>
      </Row>
      <WizardInput
        label="Fornavn*"
        name="firstName"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('firstName', {
            required: 'Fornavn er påkrevd'
          })
        }}
      />

      <WizardInput
        label="Etternavn*"
        name="lastName"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('lastName', {
            required: 'Etternavn er påkrevd'
          })
        }}
      />

      <WizardInput
        type="email"
        errors={errors}
        label="Epost*"
        name="email"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('email', {
            required: 'Epost er påkrevd',
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Det må være en gyldig epost adresse'
            }
          })
        }}
      />

      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            Jeg samtykker til Folkins <Link target="_blank" to="https://backend.folkin.no/assets/files/gdpr/Rev-2024-08-14-Brukervilkaar-folkin.pdf">brukervilkår</Link> og{' '}
            <Link target="_blank" to="https://backend.folkin.no/assets/files/gdpr/Rev-2024-08-14-Integritetspolicy.pdf">bruk av personlige data</Link>
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: 'Du må gi ditt samtykke.'
          })
        }}
      />
    </>
  );
};

RegisterFormTemp.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};




const RegisterFormCompany = ({ register, errors }) => {
  return (
    <>
      <WizardInput
        label="Fornavn*"
        name="firstName"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('firstName', {
            required: 'Fornavn er påkrevd'
          })
        }}
      />

      <WizardInput
        label="Etternavn*"
        name="lastName"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('lastName', {
            required: 'Etternavn er påkrevd'
          })
        }}
      />

      <WizardInput
        type="email"
        errors={errors}
        label="Epost*"
        name="email"
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('email', {
            required: 'Epost er påkrevd',
            pattern: {
              value:
                /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
              message: 'Det må være en gyldig epost adresse'
            }
          })
        }}
      />

      <WizardInput
        label="Hvilket selskap representerer du*"
        name="companyName"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('companyName',{
              required: 'Selskapsnavn er påkrevd'
            })
        }}
      />

      <WizardInput
        label="Telefon"
        name="phone"
        errors={errors}
        formGroupProps={{ className: 'mb-5' }}
        formControlProps={{
          ...register('phone')
        }}
      />

      <WizardInput
        type="checkbox"
        errors={errors}
        label={
          <>
            Jeg samtykker til Folkins <Link to="#!">brukervilkår</Link> og{' '}
            <Link to="#!">bruk av personlige data</Link>
          </>
        }
        name="agreedToTerms"
        formControlProps={{
          ...register('agreedToTerms', {
            required: 'Du må gi ditt samtykke.'
          })
        }}
      />
    </>
  );
};

RegisterFormCompany.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};




const RegisterForm = ({ register, errors }) => {
  //const { user } = useContext(AuthWizardContext);
  // console.log(user,' --> RegisterForm user');
  return (
    <>
      {/*{user.isCompanyUser==='false' && (*/}
        <RegisterFormTemp register={register} errors={errors} />
       {/*)}*/}
       {/*{user.isCompanyUser==='true' && (*/}
       {/*  <RegisterFormCompany register={register} errors={errors} />*/}
       {/*)}*/}
    </>
  );
};

RegisterForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default RegisterForm;
