import {config} from "../Constants";
import useAuth from "../hooks/useAuth";
import useFeed from "../hooks/useFeed";
import useTemps from "../hooks/useTemps";

const callApiTemps = () => {
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;
  const { handleLoadTemps, handleLoadTempsAndFilter } = useTemps();
  const { handleLoadTempsAvailableToday } = useFeed();

  const fetchTemps = (filterAlso) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/temp", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        console.log('[fetchPersons] fetchPersons: filterAlso ' + filterAlso);
        if(filterAlso) {
          handleLoadTempsAndFilter(data);
        } else {
          handleLoadTemps(data);
        }
        //handleLoadTempsAvailableToday(data);
      });
  }

  const fetchTempsAvailableToday = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/temp/tempsAvailaibleToday/filter", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('[fetchPersons] fetchPersons: data ' + JSON.stringify(data));
        //handleLoadPersons(data);
        handleLoadTempsAvailableToday(data);
      });
  }

  return {
    fetchTemps,
    fetchTempsAvailableToday
  };
};

export default callApiTemps;