import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Form, Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import AppContext from 'falcon-react-lib/context/Context';
import {registerLocale} from "react-datepicker";
import nb from 'date-fns/locale/nb';

registerLocale('nb', nb)

const ForwardToEditAvailabilityModal = ({
                                             setIsOpenModal,
                                             isOpenModal
                                           }) => {
  //console.log('[ForwardToEditAvailabilityModal]')
  const {config: {isDark}} = useContext(AppContext);

  const handleClose = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <Modal
      show={isOpenModal}
      onHide={handleClose}
      contentClassName="border"
    >
      <Form>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5"> Endre tilgjengelighetsperiode</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <div className="mt-2">
            For å endre tilgjengelighetskalenderen gå <Link className="text-decoration-underline" to={'/profile/accessibility/edit'}>hit</Link>
          </div>

        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">

        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ForwardToEditAvailabilityModal.propTypes = {
  setIsOpenModal: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired
};

export default ForwardToEditAvailabilityModal;
