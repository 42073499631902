import {config} from "../Constants";
import callApiFiles from "../api/callApiFiles";


const useUrlHelpers = () => {
  const awsS3BucketPublicUpload = config.url.AWS_S3_BUCKET_PUBLIC_UPLOAD;
  const awsRegion = config.url.AWS_REGION;
  const {retrievePresignedS3GetUrl} = callApiFiles();


  const getAvatarUrlsFromChatUsersArray = (user) => {
    //console.log(user, '  --> getAvatarUrlsFromChatUsersArray users ')
    const avatarUrls = [];
    user.avatarSrc.forEach((avatarSrc) => {
         let avatarUrl = getAvatarUrl(avatarSrc,user.name,null);
         //console.log(avatarUrl, '  --> getAvatarUrlsFromChatUsersArray avatarSrc ')
      avatarUrls.push(avatarUrl);
    });
    return avatarUrls;
  }

  const getAssignmentCoverPhoto = (assignmentImage, company) => {
    if(assignmentImage) {
      return getImageUrl(assignmentImage);
    }
    return getCompanyAvatarUrl(company);
  }

  const getCompanyAvatarUrl = (company) => {
    //console.log(company,'  --> [useUrlHelpers] getCompanyAvatarUrl company')
    const name = company.name?company.name:'';
    return getAvatarUrl(company.thumbnail?.image,name);
  }


  const getAvatarUrl = (personThumbnailUrl, firstName, lastName) => {
    if(personThumbnailUrl) {
      return getFileUrlPublicS3Bucket(personThumbnailUrl);
    }
    const fullName = (firstName?firstName:'') + ' ' + (lastName?lastName:'');
    const fullNameArray = fullName.split(" ");
    const fullNameV2 = fullNameArray.join('+');
    //console.log(fullNameV2,'  --> [useUrlHelpers] getAvatarUrl fullNameV2')
    //return 'https://avatar.oxro.io/avatar.svg?name='+encodeURI(fullNameV2)+'&background=336666';
    return 'https://ui-avatars.com/api/?name='+encodeURI(fullNameV2)+'&background=336666&color=ffffff';
  }

  const getImageUrl = (url) => {
    return getFileUrlPublicS3Bucket(url)
  }


  const getImagesUrls = (urls) => {
    const newUrls = [];
    urls.forEach((url) => {
      let newUrl = getFileUrlPublicS3Bucket(url)
      newUrls.push(newUrl);
    });
    return newUrls;
  }

  const getFileUrl = (url) => {
    return getImageUrl(url);
  }

  const getFileUrlPrivateS3Bucket = async (url) => {
    if(url===undefined) {
      return url
    }
    if(url===null) {
      return url
    }
    if(url.includes('http')) {
      return url;
    } else {
      return await retrievePresignedS3GetUrl(url);
    }
  }

  const getFileUrlPublicS3Bucket = (url) => {
    //console.log(url, '  -> getFileUrlPublicS3Bucket url ')
    if(url===undefined) {
      return url
    }
    if(url===null) {
      return url
    }
    if(url.includes('http')) {
      return url;
    } else {
      return 'https://'+awsS3BucketPublicUpload+'.s3.'+awsRegion+'.amazonaws.com/'+url;
    }
  }

  return {
    getAvatarUrlsFromChatUsersArray,
    getAssignmentCoverPhoto,
    getCompanyAvatarUrl,
    getAvatarUrl,
    getImageUrl,
    getImagesUrls,
    getFileUrl,
    getFileUrlPrivateS3Bucket,
    getFileUrlPublicS3Bucket
  };
};

export default useUrlHelpers;

