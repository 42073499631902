import PropTypes from "prop-types";
import {Button, Modal} from 'react-bootstrap';
import React, {useContext, useState} from "react";
import AppContext from "../../../../falcon-react-lib/context/Context";
import {useDropzone} from "react-dropzone";
import cloudUpload from "../../../../falcon-react-lib/assets/img/icons/cloud-upload.svg";
import AddImagesFeedGallery from "./AddImagesFeedGallery";
import IconAlert from "../../../../falcon-react-lib/components/common/IconAlert";
import useS3Upload from "../../../../hooks/useS3Upload";

const AddImagesModal = ({showModal, hideModal, setImages}) => {
  const {config: {isDark}} = useContext(AppContext);
  const {uploadFeedPhoto} = useS3Upload();
  const [uploadedThumbnails, setUploadedThumbnails] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      uploadFeedPhoto(acceptedFiles[0],handleFileUploadResponse);
    }
  });

  const handleFileUploadResponse = (fileInfo) => {
    // console.log(fileInfo, ' [AddImagesModal] onDrop - fileInfo');
    setErrorMessage('');
    if (uploadedImages.length === 6) {
      setErrorMessage('Du kan ikke laste opp mer enn 6 bilder');
    }
    else if (fileInfo.status !== undefined) {
      setErrorMessage(fileInfo.message);
    } else {
      setUploadedThumbnails(uploadedThumbnails => [...uploadedThumbnails, {
        name: fileInfo.name,
        image: fileInfo.url
      }]);
      setUploadedImages(uploadedImages => [...uploadedImages, fileInfo.url]);
    }
  }

  const removeImage = (image) => {
    // console.log(image,' -> removeImage');
    setErrorMessage('');
    setUploadedImages(uploadedImages.filter(function (i) {
      return i !== image;
    }));
    setUploadedThumbnails(uploadedThumbnails.filter(function (i) {
      return i.url !== image;
    }));
  }

  const doneWithUploadingImages = () => {
    setErrorMessage('');
    hideModal(false);
    //setThumbnails(uploadedThumbnails);
    setImages(uploadedImages);
    setUploadedImages([]);
    setUploadedThumbnails([]);
  }

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      contentClassName="border"
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title as="h5">Legg til bilder</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-x1">
        {errorMessage && (
          <>
            <IconAlert key={'danger'} variant={'danger'}>
              {errorMessage}
            </IconAlert>
          </>
        )}
        <div {...getRootProps({className: 'dropzone-area py-6'})}>
          <input {...getInputProps({multiple: false})} />
          <div className="fs--1">
            <img src={cloudUpload} alt="" width={20} className="me-2"/>
            <span className="d-none d-lg-inline">
                Dra bildet ditt hit
                <br/>
                eller,{' '}
              </span>
            <Button variant="link" size="sm" className="p-0 fs--1">
              Hent
            </Button>
          </div>
        </div>
        {uploadedImages.length > 0 && (
          <div className="pt-3">
            <AddImagesFeedGallery images={uploadedImages} removeImage={removeImage} />
            {/*{thumbnails.map((thumbnail,index) => (*/}
            {/*  <div key={index}>*/}
            {/*    /!*<span>{thumbnail.image}</span>*!/*/}
            {/*    <div className="mt-3 border-3" >*/}
            {/*      <Flex*/}
            {/*        alignItems="center"*/}
            {/*        key={thumbnail.name}*/}
            {/*      >*/}
            {/*        <Image*/}
            {/*          rounded*/}
            {/*          src={getImageUrl(thumbnail.image)}*/}
            {/*          alt={thumbnail.name}*/}
            {/*          className="w-100 h-100 fit-cover"/>*/}
            {/*      </Flex>*/}


            {/*      <Button*/}
            {/*        variant="link"*/}
            {/*        size="sm"*/}
            {/*        className="fw-medium pt-3 float-end"*/}
            {/*        // onClick={() => {*/}
            {/*        //   setCover();*/}
            {/*        //   setFormData({*/}
            {/*        //     ...formData,*/}
            {/*        //     thumbnail: {*/}
            {/*        //       name: null,*/}
            {/*        //       image: null*/}
            {/*        //     }*/}
            {/*        //   });*/}
            {/*        // }}*/}
            {/*      >*/}
            {/*        Fjern bildet*/}
            {/*      </Button>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*))}*/}
          </div>

        )}
      </Modal.Body>
      <Modal.Footer className="bg-light px-x1 border-top-0">
        <Button
          variant="primary"
          type="submit"
          onClick={() => doneWithUploadingImages()}
          className="px-4 mx-0"
        >
          Ferdig
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

AddImagesModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  setImages: PropTypes.func.isRequired
};

export default AddImagesModal;