import React, {useContext, useEffect, useState} from "react";
import useChats from "../../../hooks/useChats";
import {Card, Tab} from "react-bootstrap";
import Flex from "../../../falcon-react-lib/components/common/Flex";
import ChatSidebar from "./sidebar/ChatSidebar";
import ChatContent from "./content/ChatContent";
import {ChatContext} from "../../../falcon-react-lib/context/Context";
import callApiChats from "../../../api/callApiChats";
import PropTypes from "prop-types";

const ChatTab = ({showRemovedThreads, setShowRemovedThreads}) => {
  const {chatState: { activeTabKey }} = useContext(ChatContext);
  const [hideSidebar, setHideSidebar] = useState(false);
  const { handleOpenThreadInfo, handleCurrentThreadByIndex, handleActiveTabKeyByIndex, handleScrollToBottom } = useChats();
  const { updateThreadReadFlag } = callApiChats();

  const handleSelect = e => {
    let threadIndex =  parseInt(e);
    console.log('[ChatTab] handleSelect ' + threadIndex)
    if(threadIndex!==9999) {
      setHideSidebar(false);
      handleOpenThreadInfo(false);
      updateThreadReadFlag(threadIndex, true)
      handleCurrentThreadByIndex(threadIndex);
      handleActiveTabKeyByIndex(threadIndex);
      handleScrollToBottom(true);
      //console.log('[ChatTab] handleSelect ' + threadIndex)
    } else {
      setShowRemovedThreads(!showRemovedThreads);
    }
  };

  useEffect(() => {
    if(activeTabKey === 9999) {
      setHideSidebar(true);
      //console.log('[ChatTab] useEffect ' + activeTabKey);
    }
  }, [activeTabKey]);

  return (
    <Tab.Container
      id="left-tabs-example"
      activeKey={activeTabKey}
      onSelect={handleSelect}
    >
      <Card className="card-chat overflow-hidden">
        <Card.Body as={Flex} className="p-0 h-75">
          <ChatSidebar hideSidebar={hideSidebar} setShowRemovedThreads={setShowRemovedThreads} showRemovedThreads={showRemovedThreads} />
          <ChatContent setHideSidebar={setHideSidebar} setShowRemovedThreads={setShowRemovedThreads} />
        </Card.Body>
      </Card>
    </Tab.Container>
  );
};

ChatTab.propTypes = {
  showRemovedThreads: PropTypes.bool.isRequired,
  setShowRemovedThreads: PropTypes.func.isRequired
};

export default ChatTab;