import React, {useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import CompanyLogoPhoto from '../edit-a-company/CompanyLogoPhoto';
import SubmitCompany from '../edit-a-company/SubmitCompany';
import CompanyInformationForm from '../edit-a-company/CompanyInformationForm';
import CreateCompanyHeader from '../edit-a-company/CreateCompanyHeader';
import callApiCompanies from "../../../../api/callApiCompanies";
import SubmitFormWarning from "../../../confirmation/SubmitFormWarning";

const CreateCompany = () => {
  //console.log('[CreateCompany]');
  const [displaySubmitFormWarningModal, setDisplaySubmitFormWarningModal] = useState(false);
  const [mandatoryMissingFields, setMandatoryMissingFields] = useState([]);
  const { saveNewCompany } = callApiCompanies();
  const defaultValues = {
    name: '',
    introduction: '',
    location: '',
    thumbnail: {}
  };
  const [formData, setFormData] = useState(defaultValues);

  // Hide the submit form warning modal
  const hideSubmitFormWarningModal = () => {
    setDisplaySubmitFormWarningModal(false);
  };

  const missingMandatoryFieldsBeforeSave = () => {
    let missingMandatoryField = false;
    let mandatoryMissingFieldNames = [];
    //console.log(' missingMandatoryFieldsBeforeSave. formData ' + JSON.stringify(formData));
    if(formData.name === undefined || formData.name === "") {
      mandatoryMissingFieldNames.push("Tittel");
      missingMandatoryField = true;
    }
    if(formData.introduction === undefined || formData.introduction === "") {
      mandatoryMissingFieldNames.push("Beskrivelse");
      missingMandatoryField = true;
    }
    setMandatoryMissingFields(mandatoryMissingFieldNames);
    setDisplaySubmitFormWarningModal(missingMandatoryField);
    return missingMandatoryField;
  }

  const saveChanges = () => {
    //console.log(formData, '--> onSaveDraft - Submitted data');
    if(!missingMandatoryFieldsBeforeSave()) {
      saveNewCompany(formData);
    }
  };


  return (
    <>
      <Form id="company-form">
        <Row className="g-lg-3 font-sans-serif">
          <Col lg={8}>
            <CreateCompanyHeader />
            <CompanyInformationForm
              formData={formData}
              setFormData={setFormData}
            />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar top-navbar-height d-flex flex-column">
              <SubmitCompany
                companyId={null}
                formData={formData}
                saveChanges={saveChanges}
              />
              <CompanyLogoPhoto
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </Col>
        </Row>
      </Form>
      <SubmitFormWarning
        showModal={displaySubmitFormWarningModal}
        hideModal={hideSubmitFormWarningModal}
        mandatoryMissingFields={mandatoryMissingFields}
      />
    </>
  );
};

export default CreateCompany;
