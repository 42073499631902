import useAuth from "../hooks/useAuth";
import {config} from "../Constants";

const callApiInvitePeople= () => {
  const apiUrl = config.url.API_URL;
  const {getAccessToken} = useAuth();

  const inviteByEmail = (emailAddress, successHandler) => {
    let formData = {
      emailAddress: emailAddress
    }
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/invite-people/email", requestOptions)
      })
      .then(response => {
        if(response.status===200) {
          successHandler();
        } else {
          throw Error;
        }
      })
  };


  return {
    inviteByEmail
  };
};

export default callApiInvitePeople;