import React, {useEffect, useReducer, useState} from "react";
import {myProfileReducer} from "../../../reducers/myProfileReducer";
import {config} from "../../../Constants";
import {MyProfileContext} from "../../../context/Context";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

const MyProfileProvider = ({ children }) => {
  const navigate = useNavigate();
  const initData = {
    myProfilePerson: {},
    myAvailabilityEvents: []
  };
  const [myProfileState, myProfileDispatch] = useReducer(myProfileReducer, initData);
  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  const postProfileData = (newProfileData) => {
    //console.log('postProfileData: ' + JSON.stringify(newProfileData));
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(newProfileData)
    };
    fetch(apiUrl+"/myProfile", requestOptions)
      .then(response => {
        return response.json()
      })
      .then(data => {
        // console.log('postProfileData data: ' + JSON.stringify(data));
        myProfileDispatch({
          type: 'RELOAD_MY_PROFILE',
          payload: {
            myProfilePerson: data
          }
        });

        fetchMyAvailabilityEventsApi();
        navigate("/register/welcome",{ replace: true });
      });
  }

  const fetchUserDetailsFromKeycloak = async () => {
    try {
      console.info('Loaded data from provider : ', user);
      let firstNameFromProvider = "John";
      let lastNameFromProvider = "Doe";
      let emailFromProvider = "";
      if(user.given_name) {
        firstNameFromProvider = user.given_name;
      }
      if(user.family_name) {
        lastNameFromProvider = user.family_name;
      }
      if(!user.family_name && user.name) {
        firstNameFromProvider = user.name.substring(0,user.name.indexOf(" "));
        lastNameFromProvider = user.name.substring(user.name.indexOf(" "),user.name.length);
      }
      if(!user.email) {
        emailFromProvider = user.email;
      }
      const newProfileData = {
        firstName: firstNameFromProvider,
        lastName: lastNameFromProvider,
        email: emailFromProvider,
      }
      // console.log('fetchUserDetailsFromKeycloak: formData.firstName ' + newProfileData.firstName);
      postProfileData(newProfileData);
    } catch (error) {
      console.error('Failed to fetch user details', error);
    }
  };


  const checkProfileStateApi = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/myProfile", {headers,})
      .then(async response => {
        if (response.status === 204) {
          // console.log('checkProfileStateApi: no data returned ');
          fetchUserDetailsFromKeycloak();
        } else {
          let json = await response.json();
          // console.log(json, '  checkProfileStateApi: data returned. status code: ' + response.status);
          myProfileDispatch({
            type: 'RELOAD_MY_PROFILE',
            payload: {
              myProfilePerson: json
            }
          });
          fetchMyAvailabilityEventsApi();
        }
      });
  };

  const fetchMyAvailabilityEventsApi = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/myProfile/availabilityEvents", {headers,})
      .then(async response => {
        if (response.status === 200) {
          let json = await response.json();
          myProfileDispatch({
            type: 'RELOAD_MY_AVAILABILITY_EVENTS',
            payload: {
              myAvailabilityEvents: json
            }
          });
        }
      });
  };

  const checkProfileState = () => {
    if(!myProfileState.myProfilePerson || JSON.stringify(myProfileState.myProfilePerson)==='{}') {
      // console.log('MyProfileProvider. myProfile not loadad')
      checkProfileStateApi();
    } else {
      // console.log('MyProfileProvider. All good')
      //console.log('MyProfileProvider. ' + JSON.stringify(myProfileState.myProfilePerson));
    }
  };

  useEffect(() => {
    if(accessToken!=="") {
      checkProfileState();
    }
  }, [accessToken])

  useEffect(() => {
    getAccessToken();
  }, [])

  return (
    <MyProfileContext.Provider
      value={{
        myProfileState,
        myProfileDispatch
      }}
    >
      {children}
    </MyProfileContext.Provider>
  );
};

MyProfileProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default MyProfileProvider;