import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import Flex from 'falcon-react-lib/components/common/Flex';
import Avatar from 'falcon-react-lib/components/common/Avatar';
import { Link } from 'react-router-dom';
import useUrlHelpers from "hooks/useUrlHelpers";
import FeedDropdown from "./FeedDropdown";
import {MyProfileContext} from "../../../../context/Context";
import useAuth from "../../../../hooks/useAuth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppContext from "../../../../falcon-react-lib/context/Context";

const FeedCardHeader = ({showDeleteModal, user, feedItemId, feedItemName, pinned}) => {
  const {myProfileState: { myProfilePerson }} = useContext(MyProfileContext);
  const {isSuperAdmin, isCompanyAdmin} = useAuth();
  const {getAvatarUrl} = useUrlHelpers();
  //console.log(user,' --> [FeedCardHeader] user ');
  const linkUrl = user.linkType==='COMPANY'?`/company/`+user.id:`/profile/`+user.id;
  const avatarUrl = getAvatarUrl(user.avatarSrc, user.name,user.name)
  const {config: { isRTL }} = useContext(AppContext);

  const hasDeleteRights = () => {
    if(isSuperAdmin()) {
      return true;
    }
    //console.log(user,' [FeedCardHeader] hasDeleteRights user');
    if(user.linkType==='PERSON') {
      //console.log(myProfilePerson,' [FeedCardHeader] hasDeleteRights');
      if(user.id===myProfilePerson.id) {
        return true;
      }
    }
    if(user.linkType==='COMPANY') {
      //console.log(myProfilePerson,' [FeedCardHeader] hasDeleteRights');
      const found = myProfilePerson.companies?.some(company => company.id === user.id);
      if(found && isCompanyAdmin()) {
        return true;
      }
    }
    return false;
  }

  const hasPinRights = () => {
    return isSuperAdmin();
  }

  return (
    <Card.Header className="bg-light">
      <Row className="justify-content-between">
        <Col>
          <Flex>
            <Link className="d-flex" to={linkUrl}>
              <Avatar size="2xl" src={avatarUrl} className={user.status} />
            </Link>
            <div className="flex-1 align-self-center ms-2">
              <p className="mb-1 lh-1">
                <Link className="fw-semi-bold" to={linkUrl}>
                  {user.name}
                </Link>
                {/*{!!user.share && (*/}
                {/*  <span className="ms-1">*/}
                {/*    shared {/^[aeiou]/g.test(user.share.toLowerCase()) ? 'an' : 'a'}{' '}*/}
                {/*    <a href="falcon-react-lib/components/app/social/feed/FeedCardHeader#!">{user.share}</a>*/}
                {/*  </span>*/}
                {/*)}*/}
              </p>
              <p className="mb-0 fs--1">
                {user.time} • {user.location}
                {/*•{' '}*/}
                {/*<FontAwesomeIcon*/}
                {/*  icon={classNames({*/}
                {/*    users: privacy === 'friends',*/}
                {/*    lock: privacy === 'private',*/}
                {/*    'globe-americas': privacy === 'public'*/}
                {/*  })}*/}
                {/*/>*/}
              </p>
            </div>
          </Flex>
        </Col>
        <Col xs="auto">
          <Flex className="gap-2">
            {pinned && (
              <OverlayTrigger
                key="left"
                placement={isRTL ? 'bottom' : 'left'}
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="ThemeColor">
                    Festet til toppen
                  </Tooltip>
                }
              >
                <div>
                  <FontAwesomeIcon icon={['fas', 'thumbtack']} className="text-primary fs-0 mt-1"/>
                </div>
              </OverlayTrigger>
            )}
            {(hasDeleteRights() || hasPinRights()) && (
              <FeedDropdown feedItemId={feedItemId} feedItemName={feedItemName} pinned={pinned} showDeleteModal={showDeleteModal} hasDeleteRights={hasDeleteRights} hasPinRights={hasPinRights} />
            )}
          </Flex>
        </Col>
      </Row>
    </Card.Header>
  );
};

FeedCardHeader.propTypes = {
  showDeleteModal: PropTypes.func,
  user: PropTypes.object.isRequired,
  feedItemId: PropTypes.string.isRequired,
  feedItemName: PropTypes.string,
  pinned: PropTypes.bool.isRequired
  };

export default FeedCardHeader;
