import PropTypes from "prop-types";
import {Button, Form, Modal, Row, Col, Spinner} from 'react-bootstrap';
import React, {useContext, useEffect, useState} from "react";
import AppContext from "falcon-react-lib/context/Context";
import callApiOpenAi from "api/callApiOpenAi";
import classNames from "classnames";
import IconButton from "falcon-react-lib/components/common/IconButton";

const AiWizardIntroductionForm = ({ showModal, hideModal, getValues, setValue }) => {
  const {callOpenAiForWizardBasicIntroSuggestion} = callApiOpenAi();
  const {config: { isDark }} = useContext(AppContext);
  const [showExtraPromptInput, setShowExtraPromptInput] = useState(false);
  const [extraPromptInput, setExtraPromptInput] = useState('');
  const [aiResult, setAiResult] = useState('');


  const successCallback = (data) => {
    console.log(data, ' --> successCallback');
    setAiResult(data.introduction);
  }

  const useAiResultAndClose = () => {
    setValue('introduction',aiResult);
    close();
  }

  const close = () => {
    setAiResult('')
    hideModal();
  }

  const createAiIntroduction = () => {
    setAiResult('');

    const extraPrompt = showExtraPromptInput ? extraPromptInput : '';
    const yearsSeniority = getValues('yearsSeniority')
    const location = getValues('location')
    const professionalTitle = getValues('professionalTitle')
    const chosenCompetencesAndCurrentStudies = getValues('chosenCompetencesAndCurrentStudies')
    const chosenIndustries = getValues('chosenIndustries')
    const chosenRegions = getValues('chosenRegions')
    const percentAvailabilityValue = getValues('percentAvailability')

    console.log(yearsSeniority,'yearsSeniority');
    console.log(location,'location');
    console.log(professionalTitle,'professionalTitleValue');
    console.log(chosenCompetencesAndCurrentStudies,'chosenCompetencesAndCurrentStudiesIds');
    console.log(chosenIndustries,'industriesIds');
    console.log(chosenRegions,'chosenRegionsArray');
    console.log(percentAvailabilityValue,'percentAvailabilityValue');

    callOpenAiForWizardBasicIntroSuggestion(chosenCompetencesAndCurrentStudies, chosenIndustries, chosenRegions, yearsSeniority, location, professionalTitle, extraPrompt, successCallback)
      .then(() => console.log('callOpenAiForWizardBasicIntroSuggestion Done') );
  }

  const handleChange = e => {
    setAiResult(e.target.value)
  };

  const handleChangeToExtraPromptInput = e => {
    setExtraPromptInput(e.target.value)
  }

  useEffect(() => {
    setAiResult('');
    if(showModal) {
        createAiIntroduction();
    }
  }, [showModal])

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      contentClassName="border"
    >
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5">Forslag fra AI</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          {showExtraPromptInput && (
            <Form.Group className="mb-3" controlId="intro">
              <Form.Label>Hjelp til AI</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Mer informasjon om deg som kan hjelpe AI til å generere en bedre tekst."
                value={extraPromptInput}
                name="extraPromptInput"
                onChange={handleChangeToExtraPromptInput}
              />
            </Form.Group>
          )}


          <Row className="mb-3">
              {!aiResult && (
                <>
                  <Col sm={6} className="">
                    <Button
                      variant="outline-dark"
                      type="button"
                      className="d-block w-100 mb-2 mb-xl-0"
                      disabled
                    >
                      Gi mer info til AI
                    </Button>
                  </Col>
                  <Col sm={6} className="">
                    <Button variant="dark" className="d-block w-100 mb-2 mb-xl-0" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Tenker...
                    </Button>
                  </Col>
                </>
              )}
              {aiResult && (
                <>
                  <Col sm={6} className="">
                    <Button
                      variant="outline-dark"
                      type="button"
                      className="d-block w-100 mb-2 mb-xl-0"
                      onClick={() => setShowExtraPromptInput(!showExtraPromptInput)}
                    >
                      {showExtraPromptInput ? 'Skjul' : 'Gi mer info til AI'}
                    </Button>
                  </Col>
                  <Col sm={6} className="">
                    <Button
                      variant="dark"
                      type="submit"
                      onClick={createAiIntroduction}
                      className={classNames('d-block w-100 mb-2 mb-xl-0', {
                        'disabled': !aiResult
                      })}
                    >
                      Lag et nytt forslag
                    </Button>
                  </Col>
                </>
              )}
          </Row>

          <Row className="mb-3 mt-5">
            <Col>
              <span>AI har generert følgende tekst basert på det du har fylt ut på din vikarprofil:</span>
            </Col>
          </Row>

          <Form.Group className="mb-3" controlId="intro">
            <Form.Label>Om meg</Form.Label>
            <Form.Control
              as="textarea"
              rows={13}
              placeholder="Introduksjon - Fortell om deg selv"
              value={aiResult}
              name="aiResult"
              disabled={!aiResult}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button
            variant="link"
            onClick={close}
            className="px-4 mx-0"
          >
            Avbryt
          </Button>
          <IconButton
            variant="primary"
            type="submit"
            iconClassName="me-2"
            icon="thumbs-up"
            onClick={useAiResultAndClose}
            className={classNames('px-4 mx-0', {
              'disabled': !aiResult
            })}
          >
            Bruk denne teksten
          </IconButton>
        </Modal.Footer>
    </Modal>
  )};

AiWizardIntroductionForm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};


export default AiWizardIntroductionForm;