import React from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Image, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Flex from 'falcon-react-lib/components/common/Flex';
import useAssignments from "../../../../hooks/useAssignments";
import useUrlHelpers from "hooks/useUrlHelpers";
import AssignmentCategoriesPills from "../../assignment/AssignmentCategoriesPills";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const FeedAssignment = ({ assignment }) => {
  // console.log('[FeedAssignment] assignment: ' + JSON.stringify(assignment));
  const {fixAssignmentDescriptionForListView} = useAssignments();
  const {getAssignmentCoverPhoto} = useUrlHelpers();
  return (
    <Card.Body className="p-0">
        <div className="feed-assignment-box rounded-3 ">
          <Link
            to={`/assignment/assignment-details/${assignment.id}`}
            className="text-900"
          >
            <Flex
              key={assignment.id}
              alignItems="center"
              justifyContent="center"
              className="p-3 mb-2 bg-800"
            >
              <h6 className="mb-0 text-white">
                <FontAwesomeIcon
                  icon="wrench"
                  className={`fs--1 me-3 text-white`}
                />
                Nytt oppdrag tilgjengelig
              </h6>
            </Flex>
          </Link>
          <Row className="p-3">
            <Col md={4} lg={3}>
              <Link
                to={`/assignment/assignment-details/${assignment.id}`}
                className="text-900"
              >
                <Image src={getAssignmentCoverPhoto(assignment.thumbnail.image, assignment.company)} alt=""
                       className="w-100 h-100 fit-cover"/>
              </Link>
            </Col>
            <Col md={8} lg={9} className="pt-3 pt-sm-0">
              <Row className="g-0 h-100">
                <Col as={Flex} className="flex-column pe-x1">
                  <AssignmentCategoriesPills assignment={assignment}/>
                  <h4 className="mt-3 mt-sm-0 fs-0 fs-lg-1">
                    <Link
                      to={`/assignment/assignment-details/${assignment.id}`}
                      className="text-900"
                    >
                      {assignment.name}
                    </Link>
                  </h4>
                  <p className="fs--1 mt-2">
                    {fixAssignmentDescriptionForListView(assignment.description, 142)}...
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
    </Card.Body>
);
}

FeedAssignment.propTypes = {
  assignment: PropTypes.object.isRequired
};

export default FeedAssignment;
