import {config} from "../Constants";
import useAuth from "../hooks/useAuth";

const callApiAdmin = () => {
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;

  const fetchPersonSsoInfo = (personId, handleData) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/admin/person/sso/"+personId, {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        handleData(data);
      });
  };

  return {
    fetchPersonSsoInfo
  };
};

export default callApiAdmin;