import {config} from "../Constants";
import useMyProfile from "../hooks/useMyProfile";
import {useNavigate} from "react-router-dom";
import {formatISO} from "date-fns";
import useAuth from "../hooks/useAuth";

const callApiMyProfile = () => {
  const navigate = useNavigate();
  const {getAccessToken} = useAuth();
  const apiUrl = config.url.API_URL;
  const { handleReloadMyProfile,handleReloadMyProfileEvents } = useMyProfile();


  const fetchMyProfile = () => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        return fetch(apiUrl+"/myProfile", {headers,})
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        handleReloadMyProfile(data);
      });
  };

  const saveMyBasicInfoProfile = (formData) => {
    //console.log(formData, ' -- > [callApiMyProfile] saveMyProfile ')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(formData)
        };
        //console.log(requestOptions,' -> [callApiMyProfile] saveMyProfile requestOptions ')
        return fetch(apiUrl+"/myProfile/basicInfo", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('successfull save. data: ' + JSON.stringify(data));
        handleReloadMyProfile(data);
        navigate('/profile');
      });
  }

  const saveMyTempProfile = (formData) => {
    //console.log(formData, ' -- > [callApiMyProfile] saveMyProfile ')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(formData)
        };
        //console.log(requestOptions,' -> [callApiMyProfile] saveMyProfile requestOptions ')
        return fetch(apiUrl+"/myProfile/temp", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('successfull save. data: ' + JSON.stringify(data));
        handleReloadMyProfile(data);
        navigate('/profile');
      });
  }


  const saveMyProfileCategories = (formData) => {
    //console.log(formData, ' -- > [callApiMyProfile] handleSaveData ')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/myProfile/profileCategories", requestOptions);
      })
      .then(response => {
        return response.json()
      });
  };

  const saveMyProfileThumbnail = (thumbnail) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;

        let formData = {
          id: null,
          name: null
        }
        if(thumbnail) {
          formData = thumbnail;
        }
        const requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(formData)
        };
        return fetch(apiUrl+"/myProfile/thumbnail", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        // console.log('saveMyProfileThumbnail: successfull save. data: ' + JSON.stringify(data));
        handleReloadMyProfile(data);
        navigate('/profile');
      });
  }

  const saveMyAvailability = (myPersonId, availabilityStatus, percentAvailability, addToFeed) => {
    const formData = {
      personId: myPersonId,
      availabilityStatus: availabilityStatus,
      percentAvailability: percentAvailability,
      addToFeed: addToFeed
    };
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(formData)
        };
        //console.log(requestOptions, '  -> saveMyAvailability ')
        return fetch(apiUrl+"/myProfile/availability", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('successfull save. data: ' + JSON.stringify(data));
        handleReloadMyProfile(data);
        navigate('/profile');
      });
  }

  const saveMyAvailabilityEvent = (eventId, myPersonId, startDate, endDate) => {
    const formData = {
      id: eventId,
      personId: myPersonId,
      startDate: formatISO(startDate),
      endDate: formatISO(endDate)
    };

    getAccessToken()
      .then((accessToken) => {
        //console.log(formData, ' --> saveMyAvailabilityEvent  ');
        let method = eventId===undefined||eventId===null||eventId===''?'POST':'PUT';
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: method,
          headers: headers,
          body: JSON.stringify(formData)
        };
        //console.log(requestOptions, '  -> saveMyAvailabilityEvent ')
        return fetch(apiUrl+"/myProfile/availabilityEvents", requestOptions)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('successfull save. data: ' + JSON.stringify(data));
        handleReloadMyProfileEvents(data);
        //handleData(data);
      });
  }


  const deleteMyAvailabilityEvent = (eventIdToDelete) => {
    //console.log(eventIdToDelete, ' [callApiMyProfile] deleteMyAvailabilityEvent')
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
        return fetch(apiUrl + "/myProfile/availabilityEvents/" + eventIdToDelete, requestOptions)
      })
      .then(response => {
        if(response.status===204) {
          //console.log('[callApiMyProfile] deleteMyAvailabilityEvent. No avail events found. ')
          handleReloadMyProfileEvents([]);
        }
        else if(response.status===200) {
          return response.json()
        }
      })
      .then(data => {
        //console.log('[callApiMyProfile] deleteMyAvailabilityEvent successfull delete. data: ' + JSON.stringify(data));
        handleReloadMyProfileEvents(data);
      });
  };

  const deleteMyProfile = (logout) => {
    getAccessToken()
      .then((accessToken) => {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${accessToken}`;
        const requestOptions = {
          method: 'DELETE',
          headers: headers
        };
        return fetch(apiUrl+"/myProfile", requestOptions)
      })
      .then(response => {
        return response.status
      })
      .then(responseCode => {
        if(responseCode===200) {
          console.log('deleteMyProfile ' + responseCode)
          logout();
        } else {
          console.error(' Could not delete profile. ' + responseCode)
        }
      });
  }


  return {
    fetchMyProfile,
    saveMyBasicInfoProfile,
    saveMyTempProfile,
    saveMyProfileCategories,
    saveMyProfileThumbnail,
    saveMyAvailability,
    saveMyAvailabilityEvent,
    deleteMyAvailabilityEvent,
    deleteMyProfile
  };
};

export default callApiMyProfile;