import {parseISO,isAfter,isBefore} from 'date-fns'

const useAvailabilityEvents = () => {

  const checkForOverlappingAvailabilityEvents = (newEventStart, newEventEnd, existingEvents) => {
    //console.log(' checkForOverlappingAvailabilityEvents newEventStart:' +newEventStart + ' newEventEnd: ' + newEventEnd);
    let overlappingEvents = [];
    if(existingEvents!==undefined && existingEvents.length>0) {
      overlappingEvents = existingEvents.filter(e => {
        const existingEventStart = parseISO(e.start);
        const existingEventEnd = parseISO(e.end);

        const newEventStartIsInsideThisEventPeriod = isAfter(newEventStart,existingEventStart) && isBefore(newEventStart,existingEventEnd);
        const newEventEndIsInsideThisEventPeriod = isAfter(newEventEnd,existingEventStart) && isBefore(newEventEnd,existingEventEnd);
        const newEventContainsCompletelyOverlapsThisEvent = isBefore(newEventStart,existingEventStart) && isAfter(newEventEnd,existingEventEnd);

        //console.log('existingEventStart: ' + e.start + ' existingEventEnd: ' + e.end +
        //  ' newEventStartIsInsideThisEventPeriod: ' + newEventStartIsInsideThisEventPeriod+
        //  ' newEventEndIsInsideThisEventPeriod: ' + newEventEndIsInsideThisEventPeriod+
        //  ' newEventContainsCompletelyOverlapsThisEvent: ' + newEventContainsCompletelyOverlapsThisEvent);
        return  newEventStartIsInsideThisEventPeriod || newEventEndIsInsideThisEventPeriod || newEventContainsCompletelyOverlapsThisEvent;
      });
    }

    return overlappingEvents;
  }

  return {
    checkForOverlappingAvailabilityEvents
  };
};

export default useAvailabilityEvents;
