import React, {useEffect, useReducer, useState} from "react";
import {config} from "../../Constants";
import PropTypes from "prop-types";
import {notificationsReducer} from "../../reducers/notificationsReducer";
import {NotificationsContext} from "../../context/Context";
import {useAuth0} from "@auth0/auth0-react";

const NotificationsProvider = ({ children }) => {
  const initData = {
    newNotifications: [],
    allNotifications: [],
    isAllRead: true,
    isOpen: false
  };
  const [notificationsState, notificationsDispatch] = useReducer(notificationsReducer, initData);
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const apiUrl = config.url.API_URL;

  const getAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    } catch (e) {
      console.log(e);
    }
  }

  const fetchNewNotifications = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/notification/new", {headers,})
      .then(async response => {
        if (response.status === 200) {
          let json = await response.json();
          notificationsDispatch({
            type: 'RELOAD_NEW_NOTIFICATIONS',
            payload: {
              newNotifications: json
            }
          });
        }
      });
  };

  const fetchAllNotifications = () => {
    let headers = {"Content-Type": "application/json"};
    headers["Authorization"] = `Bearer ${accessToken}`;
    fetch(apiUrl+"/notification", {headers,})
      .then(async response => {
        if (response.status === 200) {
          let json = await response.json();
          notificationsDispatch({
            type: 'RELOAD_ALL_NOTIFICATIONS',
            payload: {
              allNotifications: json
            }
          });
        }
      });
  };

  useEffect(() => {
    if(accessToken!=="") {
      fetchNewNotifications();
      fetchAllNotifications();
    }
  }, [accessToken])

  useEffect(() => {
    if(accessToken!=="") {
      const interval = setInterval(() => {
        fetchNewNotifications();
        fetchAllNotifications();
      }, 30000); //set your time here. repeat every 5 seconds
      return () => clearInterval(interval);
    }
  }, [accessToken]);

  useEffect(() => {
    getAccessToken();
  }, [])

  return (
    <NotificationsContext.Provider
      value={{
        notificationsState,
        notificationsDispatch
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NotificationsProvider;