import PropTypes from "prop-types";
import React, {useContext, useState} from "react";
import AppContext from "../../../../falcon-react-lib/context/Context";
import {useDropzone} from "react-dropzone";
import IconAlert from "../../../../falcon-react-lib/components/common/IconAlert";
import cloudUpload from "../../../../falcon-react-lib/assets/img/icons/cloud-upload.svg";
import {Button, Form, Image, Modal} from 'react-bootstrap';
import Hoverbox from "../../../../falcon-react-lib/components/common/Hoverbox";
import useS3Upload from "../../../../hooks/useS3Upload";
import useUrlHelpers from "../../../../hooks/useUrlHelpers";

const AddUrlModal = ({ showModal, hideModal, setFeedUrlData }) => {
  const {config: {isDark}} = useContext(AppContext);
  const {uploadFeedPhoto} = useS3Upload();
  const {getImageUrl} = useUrlHelpers();
  const [thisUrl, setThisUrl] = useState('');
  const [thisTitle, setThisTitle] = useState('');
  const [thisCoverImage, setThisCoverImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      uploadFeedPhoto(acceptedFiles[0],handleFileUploadResponse);
    }
  });

  const handleFileUploadResponse = (fileInfo) => {
    // console.log(fileInfo, ' [AddImagesModal] onDrop - fileInfo');
    setErrorMessage('');
    if (fileInfo.status !== undefined) {
      setErrorMessage(fileInfo.message);
    } else {
      setThisCoverImage(fileInfo.url);
    }
  }

  // const removeImage = (image) => {
  //   console.log(image,' -> removeImage');
  //   setErrorMessage('');
  //   setThisCoverImage(null);
  // }

  const missingMandatoryFieldsBeforeClose = () => {
    let missingMandatoryField = false;
    let mandatoryMissingFieldNames = [];

    if(!thisTitle || thisTitle==='') {
      mandatoryMissingFieldNames.push("Tittel");
      missingMandatoryField = true;
    }
    if(!thisUrl || thisUrl==='') {
      mandatoryMissingFieldNames.push("Lenke");
      missingMandatoryField = true;
    }
    // console.log(missingMandatoryField, ' missingMandatoryFieldsBeforeClose. missingMandatoryField ');
    if(missingMandatoryField) {
      let errorMessage = '<span>Fyll ut følgende felter</span>';
      errorMessage += '<ul>';
      mandatoryMissingFieldNames.forEach(function(missingField) {
        errorMessage += '<li>'+ missingField + '</li>';
      });
      errorMessage += '</ul>';
      setErrorMessage(errorMessage);
    }
    return missingMandatoryField;
  }

  const doneWithUploadingImages = () => {
    if(!missingMandatoryFieldsBeforeClose()) {
      setErrorMessage('');
      hideModal(false);
      setFeedUrlData({
        imgUrl: thisCoverImage,
        urlAddress: thisUrl,
        title: thisTitle
      })
      setThisUrl('');
      setThisTitle('');
      setThisCoverImage(null);
    }
  }

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      contentClassName="border"
    >
      <Modal.Header
        closeButton
        closeVariant={isDark ? 'white' : undefined}
        className="bg-light px-x1 border-bottom-0"
      >
        <Modal.Title as="h5">Legg til en lenke</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-x1">
        {errorMessage && (
          <IconAlert key={'danger'} variant={'danger'}>
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
          </IconAlert>
        )}
        <Form.Group controlId="title">
          <Form.Label>
            Tittel<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="title"
            required
            placeholder="Tittel"
            value={thisTitle}
            onChange={(e) => setThisTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="url" className="mb-3">
          <Form.Label>
            Lenke<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="url"
            name="url"
            required
            placeholder="Lenke"
            value={thisUrl}
            onChange={(e) => setThisUrl(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="url" className="mb-3">
          <Form.Label>
            Coverbilde
          </Form.Label>
        <div {...getRootProps({className: 'dropzone-area py-6'})}>
          <input {...getInputProps({multiple: false})} />
          <div className="fs--1">
            <img src={cloudUpload} alt="" width={20} className="me-2"/>
            <span className="d-none d-lg-inline">
                Dra bildet ditt hit
                <br/>
                eller,{' '}
              </span>
            <Button variant="link" size="sm" className="p-0 fs--1">
              Hent
            </Button>
          </div>
        </div>
        {thisCoverImage && (
          <div className="mt-3">
            <Hoverbox className="text-center">
              <Image
                src={getImageUrl(thisCoverImage)}
                fluid
                rounded
              />
              <Hoverbox.Content className="light bg-dark p-5 flex-center">
                <div>
                  <a className="btn btn-light btn-sm mt-1" href="#!" onClick={() => setThisCoverImage(null)}>
                    Fjern
                  </a>
                </div>
              </Hoverbox.Content>
            </Hoverbox>
          </div>
        )}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="bg-light px-x1 border-top-0">
        <Button
          variant="primary"
          type="submit"
          onClick={() => doneWithUploadingImages()}
          className="px-4 mx-0"
        >
          Ferdig
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

AddUrlModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  setFeedUrlData: PropTypes.func.isRequired
};

export default AddUrlModal;