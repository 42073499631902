import React, {useEffect, useState} from 'react';
import ChatTab from './ChatTab';
import callApiChats from "../../../api/callApiChats";
import {useAuth0} from "@auth0/auth0-react";
import {useParams} from "react-router-dom";
import useChats from "../../../hooks/useChats";


const Chat = () => {
  const { threadId } = useParams();
  const { isAuthenticated } = useAuth0();
  const {fetchChat} = callApiChats();
  const {handleActiveThreadId} = useChats();
  const [initForThreadId, setInitFoThreadId] = useState(null);
  const [showRemovedThreads, setShowRemovedThreads] = useState(false);

  useEffect(() => {
    if(threadId !== null) {
      setInitFoThreadId(threadId);
    }
    fetchChat(true, showRemovedThreads);

    const interval = setInterval(() => {
      fetchChat(false, showRemovedThreads);
    }, 5000); //set your time here. repeat every 5 seconds
    return () => clearInterval(interval);

  }, [isAuthenticated,threadId, showRemovedThreads]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchChat(false, showRemovedThreads);
  //   }, 5000); //set your time here. repeat every 5 seconds
  //   return () => clearInterval(interval);
  // }, [isAuthenticated]);

  useEffect(() => {
    handleActiveThreadId(initForThreadId);
  }, [initForThreadId]);

  return (
      <ChatTab setShowRemovedThreads={setShowRemovedThreads} showRemovedThreads={showRemovedThreads} />
  );
};

export default Chat;
