import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player/youtube';
import createMarkup from 'falcon-react-lib/helpers/createMarkup';
import FeedEvent from './FeedEvent';
import classNames from 'classnames';
import FeedUrl from './FeedUrl';
import FeedGallery from './FeedGallery';
import FalconLightBox from 'falcon-react-lib/components/common/FalconLightBox';
import FeedAssignment from "./FeedAssignment";
import FeedAvailability from "./FeedAvailability";

const FeedCardContent = ({
  status,
  imgSrc,
  gallery,
  feedEvent,
  feedAssignment,
  feedAvailability,
  url,
  video
}) => {
  // console.log('[FeedCardContent] status: ' + status + ', feedAssignment ' + JSON.stringify(feedAssignment));
  return (
    <Card.Body className={classNames({ 'p-0': !!feedEvent })}>
      {!!status && <p dangerouslySetInnerHTML={createMarkup(status)} />}
      {!!imgSrc && (
        <FalconLightBox image={imgSrc}>
          <Image src={imgSrc} fluid rounded />
        </FalconLightBox>
      )}
      {!!gallery && <FeedGallery images={gallery} />}
      {!!feedEvent && <FeedEvent {...feedEvent} />}
      {!!feedAssignment && <FeedAssignment assignment={feedAssignment} />}
      {!!feedAvailability && <FeedAvailability availability={feedAvailability} />}
      {!!url && <FeedUrl {...url} />}
      {!!video && (
        <ReactPlayer url={video.src} controls={true} className="react-player" />
      )}
    </Card.Body>
  );
};

FeedCardContent.propTypes = {
  status: PropTypes.string,
  imgSrc: PropTypes.string,
  gallery: PropTypes.array,
  feedEvent: PropTypes.object,
  feedAssignment: PropTypes.object,
  feedAvailability: PropTypes.object,
  url: PropTypes.object,
  video: PropTypes.object
};

export default FeedCardContent;
