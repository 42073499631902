const prod = {
  url: {
    API_URL: 'https://backend.folkin.no/api',
    ENV_TAG: 'prod',
    REACT_APP_TINYMCE_APIKEY: 'bk4g2bbgrb98q92icykwwvbjymkzezdxvyrccpzaxnoznddo',
    AUTH0_DOMAIN: 'login.folkin.no',
    AUTHO_CLIENT_ID: 'DIaIltGd8K7uxg56JwvcDjbKrYGzBgfL',
    AUTH0_AUDIENCE: 'https://backend.folkin.no',
    AWS_S3_BUCKET_PUBLIC_UPLOAD: 'folkin-file-upload',
    AWS_S3_BUCKET_PRIVATE_UPLOAD: 'folkin-file-upload-private',
    AWS_REGION: 'eu-west-1'
  },
};
const dev = {
  url: {
    API_URL: 'http://localhost:3000/api',
    ENV_TAG: 'dev',
    REACT_APP_TINYMCE_APIKEY: 'bk4g2bbgrb98q92icykwwvbjymkzezdxvyrccpzaxnoznddo',
    AUTH0_DOMAIN: 'login.folkin.no',
    AUTHO_CLIENT_ID: 'IjR9muYe1GI6m2Mf3M2KiMmv3n2drQtf',
    AUTH0_AUDIENCE: 'http://localhost:8081',
    AWS_S3_BUCKET_PUBLIC_UPLOAD: 'folkin-file-upload',
    AWS_S3_BUCKET_PRIVATE_UPLOAD: 'folkin-file-upload-private',
    AWS_REGION: 'eu-west-1'
  }
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;