import React, {useState} from 'react';
import {Card, Collapse, Button} from 'react-bootstrap';
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ProfileIntro = ({profileData}) => {
  //console.log('ProfileIntro. companyData : ' + JSON.stringify(profileData) )
  const [collapsed, setCollapsed] = useState(false);
  let intro = "";
  if (profileData !== undefined &&
    profileData !== null &&
    profileData.introduction !== undefined &&
    profileData.introduction !== null) {
    intro = profileData.introduction;
  }

  function splitIsRequired() {
    return intro.length > 600;
  }

  function splitAndGetFirstSection() {
    let introSub = intro;
    if (splitIsRequired()) {
      //introSub = intro.substring(0, intro.substring(0, 399).lastIndexOf(" "));
      if (intro.substring(0, 599).lastIndexOf("\n") > 200) {
        introSub = intro.substring(0, intro.substring(0, 599).lastIndexOf("\n"));
      } else {
        introSub = intro.substring(0, intro.substring(0, 599).lastIndexOf(" "));
      }
    }
    introSub = introSub.replaceAll("\n", "</div><div className='pt-2'>")
    introSub = introSub + "...."
    const parse = require('html-react-parser');
    return (
      <div className="pt-2">
        {parse(introSub)}
      </div>
    )
  };

  const introMainSection = splitAndGetFirstSection();

  function splitAndGetSecondSection() {
    let introSub = intro;
    // if(splitIsRequired()) {
    //   if(intro.substring(0,199).lastIndexOf("\n") > 0) {
    //     let splitIndex = intro.substring(0,199).lastIndexOf("\n") + 1;
    //     introSub = intro.substring(splitIndex);
    //   } else {
    //     let splitIndex = intro.substring(0,199).lastIndexOf(" ");
    //     introSub = intro.substring(splitIndex);
    //   }
    //   introSub = introSub.replaceAll("\n","</div><div className='pt-2'>")
    // }
    introSub = introSub.replaceAll("\n", "</div><div className='pt-2'>")
    const parse = require('html-react-parser');
    return (
      <div className="pt-2">
        {parse(introSub)}
      </div>
    )
  };

  const introSubSection = splitAndGetSecondSection();

  return <Card className="mb-3">
    <Card.Header className="bg-light">
      <h5 className="mb-0">Om meg</h5>
    </Card.Header>

    <Card.Body className="text-1000">
      {/*<div dangerouslySetInnerHTML={{__html: intro}} />*/}
      {/*{introMainSection}*/}
      {profileData.introduction && (
        <>
          <Collapse key={0} in={collapsed === false} timeout={0}>
            {introMainSection}
          </Collapse>
          <Collapse key={1} in={collapsed === true} timeout={100}>
            {introSubSection}
          </Collapse>
        </>
        )}
      {!profileData.introduction && (
        <> - Ikke lagt inn enda - </>
        )}
    </Card.Body>

    {splitIsRequired() && (
        <Card.Footer className="bg-light p-0 border-top d-grid">
          <Button
            variant="link"
            onClick={() => setCollapsed(!collapsed)}
          >
            Vis {collapsed ? 'mindre' : 'mer'}
            <FontAwesomeIcon
              icon="chevron-down"
              className="ms-2 fs--2"
              transform={collapsed ? 'rotate-180' : ''}
            />
          </Button>
        </Card.Footer>
      )}
      < /Card>
      ;
    };

    ProfileIntro.propTypes = {
    profileData: PropTypes.object.isRequired
  };

    export default ProfileIntro;
