import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Card, Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import DropdownFilter from '../../../falcon-react-lib/components/common/DropdownFilter';
import AppContext from '../../../falcon-react-lib/context/Context';
import IconButton from 'falcon-react-lib/components/common/IconButton';
import AddAvailabilityCalenderEventModal from "./AddAvailabilityCalenderEventModal";
import AvailabilityCalendarEventModal from "./AvailabilityCalendarEventModal"
import {addDays, setHours, setMinutes, setSeconds} from "date-fns";
import noLocale from '@fullcalendar/core/locales/nb';
import PropTypes from "prop-types";


const AvailabilityCalendar = ({myProfilePerson,myAvailabilityEvents,handleAddMyAvailabilityEvent,handleRemoveMyAvailabilityEvent}) => {
  const {config: { isRTL }} = useContext(AppContext);
  const calendarRef = useRef();
  const [eventSource, setEventSource] = useState({
    events: myAvailabilityEvents?myAvailabilityEvents:[],
    color: '#FFC0CB',   // an option!
    textColor: '#FFC0CB' // an option!
  });
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState('Flermånedsvisning');
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const initialEvent = {
    id: "",
    personId: myProfilePerson.id,
    start: setHours(setMinutes(new Date(),0),0),
    end: addDays(setHours(setMinutes(new Date(),59),23),1)
  }
  const [event, setEvent] = useState(initialEvent);
  const viewName = [
    'Flermånedsvisning',
    'Månedsvisning',
    'Ukesvisning',
    'Dagsvisning',
    'Ukelistevisning',
    'Årslistevisning'
  ];

  const handleEventClick = info => {
    console.log(myProfilePerson.id,' ---> myProfilePerson.id')
    // console.log(info.event.end,' ---> handleEventClick')
    const event = {
      id: info.event.id,
      personId: myProfilePerson.id,
      start: info.event.start,
      end: info.event.end
    }
    setEvent(event);
    setIsOpenEventModal(true);
  };

  const handleSelectEvent = info => {
    const event = {
      id: "",
      personId: myProfilePerson.id,
      start: setHours(setMinutes(setSeconds(info.start,0),0),0),
      end: addDays(setHours(setMinutes(setSeconds(info.end,0),59),23),-1)
    }
    //console.log(event,' ---> handleSelectEvent')
    setEvent(event);
    setIsOpenScheduleModal(true);
  }

  const handleEventDrop = info => {
    handleAddMyAvailabilityEvent(info.event.id, myProfilePerson.id, info.event.start, info.event.end)
  }

  const handleAddNewEventButtonClick = () => {
    //console.log(initialEvent,'  -> handleAddNewEventButtonClick');
    setEvent(initialEvent);
    setIsOpenScheduleModal(!isOpenScheduleModal);
  }

  //console.log(myAvailabilityEvents, '   AvailabilityCalendar --> myAvailabilityEvents');

  const handleFilter = filter => {
    setCurrentFilter(filter);
    switch (filter) {
      case 'Flermånedsvisning':
        calendarApi.changeView('multiMonthYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Månedsvisning':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Ukesvisning':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Dagsvisning':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Ukelistevisning':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());

    const newEventSource = {
      events: myAvailabilityEvents,
      color: '#FFC0CB',
      textColor: '#FFC0CB'
    };
    setEventSource(newEventSource)

  }, [myAvailabilityEvents]);

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                    Forrige
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="previousTooltip">
                    Neste
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                I dag
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            <Col xs="auto" className="d-flex order-md-0">
              <IconButton
                variant="primary"
                iconClassName="me-2"
                icon="plus"
                // transform="shrink-3"
                size="sm"
                onClick={handleAddNewEventButtonClick}
              >
                Legg til
              </IconButton>
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            locale={noLocale}
            firstDay={1}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
              multiMonthPlugin
            ]}
            initialView="multiMonthYear"
            //multiMonthMaxColumns={1}
            themeSystem="bootstrap5"
            dayMaxEvents={2}
            dayMaxEventRows={true}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={false}
            displayEventTime={false}
            editable
            selectable
            selectMirror
            eventOverlap={false}
            select={handleSelectEvent}
            eventClick={handleEventClick}
            eventDrop={handleEventDrop}
            //events={events}
            eventSources={[eventSource]}
          />
        </Card.Body>
      </Card>

      <AddAvailabilityCalenderEventModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        currentEvents={eventSource.events}
        event={event}
        handleAddMyAvailabilityEvent={handleAddMyAvailabilityEvent}
      />

      <AvailabilityCalendarEventModal
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
        event={event}
        handleRemoveMyAvailabilityEvent={handleRemoveMyAvailabilityEvent}
      />
    </>
  );
};

AvailabilityCalendar.propTypes = {
  myProfilePerson: PropTypes.object.isRequired,
  myAvailabilityEvents: PropTypes.array,
  handleAddMyAvailabilityEvent: PropTypes.func.isRequired,
  handleRemoveMyAvailabilityEvent: PropTypes.func.isRequired
};

export default AvailabilityCalendar;
