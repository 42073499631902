import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {useAuth0} from "@auth0/auth0-react";
import PropTypes from "prop-types";

const PrivateRoute = ({children}) => {
  const {loginWithRedirect, isAuthenticated, isLoading} = useAuth0();
  if (isLoading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Venter...</span>
        </Spinner>
      </div>
    );
  }
  if (!isAuthenticated) {
    // Store the current URL in local storage when appropriate
    let targetUrl = window.location.pathname + window.location.search;
    if (targetUrl && targetUrl.startsWith("/assignment")) {
      window.localStorage.setItem('redirectUrl', targetUrl);
    }

    //console.log( window.location.pathname, '  --> window.location.pathname isRegistration');
    //console.log( window.location.search, '  --> window.location.search isRegistration');
    //console.log( window.location.search.split('='), '  --> window.location.searchsplit(\'=\') isRegistration');
    //console.log( window.location.href, '  --> window.location.href isRegistration');
    let isRegistration = window.location.href.includes('registrationToken');
    //console.log(isRegistration, '  --> PrivateRoute isRegistration');
    if (isRegistration) {
      let invitationEmail = window.location.search.split('=')[2];
      console.log(invitationEmail, '  --> PrivateRoute isRegistration - invitationEmail');
      loginWithRedirect({authorizationParams: { screen_hint: "signup", login_hint: invitationEmail }});
    } else {
      loginWithRedirect();
    }


    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Venter...</span>
        </Spinner>
      </div>
    );
  }
  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default PrivateRoute;