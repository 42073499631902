import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Modal, Alert} from 'react-bootstrap';
import AppContext from 'falcon-react-lib/context/Context';
import DatePicker from 'react-datepicker';
import {setMinutes, setHours, setSeconds, format, parseISO, isAfter, addDays} from 'date-fns'
import { registerLocale} from  "react-datepicker";
import nb from 'date-fns/locale/nb';
registerLocale('nb', nb)
import useAvailabilityEvents from "../../../hooks/useAvailabilityEvents";

const AddAvailabilityCalenderEventModal = ({
                                             setIsOpenScheduleModal,
                                             isOpenScheduleModal,
                                             currentEvents,
                                             event,
                                             handleAddMyAvailabilityEvent
                                           }) => {
  //console.log(event, '  --> [AddAvailabilityCalenderEventModal] event')
  const datePattern = 'dd.MM.yyyy'
  const {config: {isDark}} = useContext(AppContext);
  const {checkForOverlappingAvailabilityEvents} = useAvailabilityEvents();
  const [formData, setFormData] = useState(event);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClose = () => {
    setErrorMessage("");
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };

  const handleClickSave = e => {
    console.log(formData, ' -> handleSubmit 1')
    e.preventDefault();
    console.log(formData, ' -> handleSubmit 2')
    let overlappingEvents = checkForOverlappingAvailabilityEvents(formData.start,formData.end,currentEvents);
    if(overlappingEvents.length===0) {
      setErrorMessage("");
      handleAddMyAvailabilityEvent(
        null,
        formData.personId,
        formData.start,
        formData.end
      );
      setIsOpenScheduleModal(false);
    } else {

      let formatOptions = {
        locale: nb
      };
      let overlappingHtml = "<p>Kan ikke lagres siden perioden overlapper med: <ul>";
      overlappingEvents.forEach(e => {
        overlappingHtml += "<li>Periode med startdato <span className=\"fw-bold\">" + format(parseISO(e.start),datePattern,formatOptions) + "</span> og sluttdato <span className=\"fw-bold\">" + format(parseISO(e.end),datePattern,formatOptions) + "</span></li>";
      })
      overlappingHtml += "</ul></p>";
      setErrorMessage(overlappingHtml)
    }
  };

  useEffect(() => {
    if (isOpenScheduleModal) {
      setFormData(event);
      //console.log(formData, ' -> [AddAvailabilityCalenderEventModal] formData')
    }
  }, [isOpenScheduleModal, event]);

  //console.log(formData, ' -> [AddAvailabilityCalenderEventModal] formData')

  return (
    <Modal
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
    >
      <Form>
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5"> Ny tilgjengelighetsperiode</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          {errorMessage && (
            <Alert variant="danger">
              <p className="" dangerouslySetInnerHTML={{
                __html: errorMessage
              }} />
            </Alert>
          )}
          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label className="fs-0">Fra</Form.Label>
            <DatePicker
              selected={formData.start}
              startDate={formData.start}
              endDate={formData.end}
              selectsStart
              onChange={date => {
                let adjustedStartDate = setHours(setMinutes(setSeconds(date,0),0),0);
                // console.log(adjustedStartDate,' --> adjustedStartDate');
                if(isAfter(adjustedStartDate,formData.end)) {
                  //console.log(formData.end,' --> Before Adjusting adjustedEndDate');
                  let adjustedEndDate = adjustedStartDate;
                  addDays(adjustedEndDate,1)
                  adjustedEndDate = setHours(setMinutes(setSeconds(date,0),59),23);
                  //console.log(adjustedEndDate,' --> Adjusting adjustedEndDate');
                  setFormData({...formData, start: adjustedStartDate, end: adjustedEndDate});
                } else {
                  setFormData({...formData, start: adjustedStartDate});
                }

              }}
              className="form-control"
              placeholderText="DD.MM.YYYY"
              dateFormat="dd.MM.yyyy"
              locale="nb"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="endDate">
            <Form.Label className="fs-0">Til</Form.Label>
            <DatePicker
              selected={formData.end}
              startDate={formData.start}
              endDate={formData.end}
              minDate={formData.start}
              selectsEnd
              onChange={date => {
                let adjustedEndDate = setHours(setMinutes(setSeconds(date,0),59),23);
                //console.log(adjustedEndDate,' --> adjustedEndDate');
                setFormData({...formData, end: adjustedEndDate});
              }}
              className="form-control"
              placeholderText="DD.MM.YYYY"
              dateFormat="dd.MM.yyyy"
              locale="nb"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button
            variant="primary"
            type="button"
            onClick={handleClickSave}
            className="px-4 mx-0"
          >
            Lagre
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AddAvailabilityCalenderEventModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  currentEvents: PropTypes.array,
  event: PropTypes.object.isRequired,
  handleAddMyAvailabilityEvent: PropTypes.func.isRequired
};

export default AddAvailabilityCalenderEventModal;
